import _ from 'underscore'
import Vue from 'vue'
import pusher from '@/service/pusher'
import constants from '@/constants'
import util from '@/util'

export default {
	
	namespaced: true,

	state: {

		schedule: [],

	},

	getters: {

		get: function(state) {

			return state.schedule

		},

		associated: function(state) {

			var associated = {}

			_.each(state.schedule, function(value) {

				associated[value.identity] = value

			})

			return associated

		},

		time: function(state, getters, rootState, rootGetters) {

			return rootGetters['time']

		},

		start: function(state) {

			return (state.schedule.length) ? state.schedule[0].start : false

		},

		active: function(state, getters) {

			var previousInactive = false
			var pausedPlayback = false

			var active = _.filter(state.schedule, function(item) {

				if (!item.active) previousInactive = true
				
				pausedPlayback = item.playback

				return (item.start <= getters['time'] && (item.end >= getters['time'] || pausedPlayback) && item.active && !previousInactive) || (item.start === 0 && item.end === 0 && item.duration === 0 && state.schedule.length === 1)

			})

			if (active.length === 2) {

				if (active[0].type === constants.schedule.type.chaperone) {
					
					return active[1]

				} else if (active[1].type === constants.schedule.type.station) {

					return active[1]

				} else {

					return active[0]

				}

			} else {

				return (active.length) ? active[0] : false

			}
			
		},

		last: function(state) {

			return (state.schedule.length) ? state.schedule[state.schedule.length - 1] : false
			
		},

		'active/identity': function(state, getters) {

			var active = getters['active']

			return (active) ? active.identity : false
			
		},

		'previous/identity': function(state, getters) {

            var completed = getters['completed']
            
            return (completed.length) ? completed[completed.length - 1] : false
			
		},

		'active/permissions': function(state, getters) {

			var active = getters['active']

			return (active) ? active.permissions : {}

		},

		previous: function(state, getters) {

            var previous = getters['previous/identity']
            
            return (previous) ? _.findWhere(state.schedule, {
                identity: previous
            }) : false
			
		},

		completed: function(state, getters) {

			var completed = []
			var previousInactive = false
			var pausedPlayback = false

			_.each(state.schedule, function(item) {

				if (!item.active) previousInactive = true
				
				pausedPlayback = item.playback

				if (item.end < getters['time'] && !pausedPlayback && item.active && item.start !== 0 && !previousInactive) completed.push(item.identity)

			})

			return completed
			
		},

		finished: function(state, getters) {

			return getters['completed'].length === state.schedule.length

		},

		next: function(state, getters) {

			var previousInactive = false

			var next = _.filter(state.schedule, function(item) {

				var isNext = (item.start > getters['time'] && !previousInactive) || !item.active || item.start === 0

				if (!item.active) previousInactive = true

				return isNext

			})

			return (next.length) ? next[0] : false
			
		},

		'next/identity': function(state, getters) {

			var next = getters['next']

			return (next) ? next.identity : false
			
		}

	},

	mutations: {

		set: function(state, data) {

			state.schedule = data

		},
		
		add: function(state, data) {

			var exists = _.findIndex(state.schedule, {
				identity: data.identity
			})

			if (exists >= 0) {

				Vue.set(state.schedule, exists, data)

			} else {

				state.schedule.push(data)

			}

		},

		remove: function(state, data) {

			var exists = _.findIndex(state.schedule, {
				identity: data.identity
			})

			if (exists >= 0) state.schedule.splice(exists, 1)

		},

		update: function(state, data) {

			_.each(state.schedule, function(schedule, index) {

				if (schedule.identity === data.identity) {

					if(data.meta) Vue.set(state.schedule[index], 'meta', data.meta)
					if(data.consults) state.schedule[index].consults = data.consults
					if(data.inventory) state.schedule[index].inventory = data.inventory
					if(data.participants) state.schedule[index].participants = data.participants
					if(data.highlighted) state.schedule[index].highlighted = data.highlighted
					if(data.observers) state.schedule[index].observers = data.observers
					if(data.pinned) state.schedule[index].pinned = data.pinned
					if(data.pinnedInventory) state.schedule[index].pinnedInventory = data.pinnedInventory
					if(data.triggered) state.schedule[index].triggered = data.triggered

				}

			})

		}

	},

	actions: {

		init: function(context, data) {

			context.commit('set', data)

			_.each(context.getters['get'], function(schedule) {

				context.dispatch('pusher', schedule)

			})

			// listen schedule extensions
			pusher.on('user', 'schedule', function(data) {

				context.commit('set', data)

				_.each(data, function(schedule) {

					context.dispatch('pusher', schedule)

				})

			}.bind(this))

			// listen to new schedules
			pusher.on('user', 'schedule', function(data) {

				context.commit('set', data)

				_.each(data, function(schedule) {

					context.dispatch('pusher', schedule)

				})

			}.bind(this))

			pusher.on('user', 'schedule/update', function(schedule) {

				if (context.rootGetters['session/is/roaming/observer']) {

					context.commit('overview/add', util.copy(schedule), {
						root: true
					})

				}

				if (_.contains(schedule.participants, context.rootGetters['session/identity']) || _.contains(schedule.observers, context.rootGetters['session/identity'])) {

					context.commit('add', schedule)
					context.dispatch('pusher', schedule)

				} 

			}.bind(this))

			pusher.on('user', 'schedule/delete', function(schedule) {

				if (context.rootGetters['session/is/roaming/observer']) {

					context.commit('overview/remove', schedule, {
						root: true
					})

				}

				context.commit('remove', schedule)

			}.bind(this))

			if (context.rootGetters['session/is/roaming/observer']) {

				_.each(context.rootGetters['circuits/get'], function(circuit) {

					pusher.on('circuit.' + circuit.identity, 'schedule/update', function(schedule) {
	
						context.commit('overview/add', util.copy(schedule), {
							root: true
						})
		
					}.bind(this))

					pusher.on('circuit.' + circuit.identity, 'schedule/delete', function(schedule) {
	
						context.commit('overview/remove', schedule, {
							root: true
						})
		
					}.bind(this))

				})

				pusher.on('event', 'schedule/update', function(schedule) {
	
					context.commit('overview/add', util.copy(schedule), {
						root: true
					})
	
				}.bind(this))

				pusher.on('event', 'schedule/delete', function(schedule) {

					context.commit('overview/remove', schedule, {
						root: true
					})

				}.bind(this))

			}

			pusher.on('user', 'schedule/remove', function(schedule) {

				context.commit('remove', schedule)

			}.bind(this))
			
			// listen for event delays (ended pauses)
			pusher.on('event', 'delay', function(data) {

				_.each(context.getters['get'], function(schedule) {

					if (
						(
							data.schedule 
							&&
							data.schedule !== schedule.identity
						)
						||
						(
							!data.schedule
							&& 
							(
								schedule.circuit === data.circuit 
								|| 
								!data.circuit 
								|| 
								!schedule.circuit
							)
						)
					) {

						if (schedule.start >= data.start) schedule.start += data.duration
						if (schedule.end >= data.start && (!data.schedule || schedule.start >= data.start)) schedule.end += data.duration

						if (schedule.type === constants.schedule.type.station && (!data.schedule || schedule.start >= data.start)) {

							if (schedule.roleplay >= data.start) schedule.roleplay += data.duration
							if (schedule.feedback >= data.start) schedule.feedback += data.duration

						}
						
					}

				})

			})

			// listen for event progress
			pusher.on('event', 'progress', function(data) {

				_.each(data.schedule, function(identity) {

					if (context.getters['associated'][identity]) Vue.set(context.getters['associated'][identity], 'active', data.active)

				})

			})

		},

		pusher: function(context, schedule) {

			pusher.subscribe.schedule(schedule.identity)

			pusher.on('schedule.' + schedule.identity, 'update', function(data) {

				context.commit('update', data)

			})

			pusher.on('schedule.' + schedule.identity, 'message', function(data) {

				context.commit('messages/add', data, {
					root: true
				})

			})

		}

	}

}