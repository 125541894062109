<template>

<div tabindex="0" class="schedule" :data-type="type">

	<div class="schedule-head">
			
		<div class="schedule-head-name">{{ $store.getters['people'][item.candidate].name }}</div>

		<div class="schedule-head-actions" v-if="type !== 'approved'">
			<button v-if="hasPlayback" class="schedule-head-actions-item" v-on:click="onWatchClick">Watch <i class="fa fa-play" /></button>
			<button class="schedule-head-actions-item" v-on:click="onApproveClick">Approve <i class="fa fa-check" /></button>
			<button class="schedule-head-actions-item" :class="{'is-loading': is.flaggingHide}" v-on:click="onHideClick">Hide <i :class="{'fas fa-eye-slash': !is.flaggingHide, 'fa fa-spinner fa-spin': is.flaggingHide}" /></button>
		</div>
			
	</div>

	<div class="schedule-body">

		<div class="schedule-body-item">
			<div class="schedule-body-item-value">{{ item.name }}</div>
			<div class="schedule-body-item-label">Station</div>
		</div>

		<div class="schedule-body-item" v-if="type === 'approved'">
			<div class="schedule-body-item-value"><span v-for="roleplayer in item.roleplayer" :key="roleplayer">{{ $store.getters['people'][roleplayer].name }}</span></div>
			<div class="schedule-body-item-label">Roleplayer</div>
		</div>

		<div class="schedule-body-item" v-if="type !== 'approved'">
			<div class="schedule-body-item-value">{{ item.round }}</div>
			<div class="schedule-body-item-label">Round</div>
		</div>

		<div class="schedule-body-item">
			<div class="schedule-body-item-value">{{ item.start | formatDate('HH:mm:ss') }}</div>
			<div class="schedule-body-item-label" v-if="type !== 'approved'">Started</div>
			<div class="schedule-body-item-label" v-if="type === 'approved'">Starts at</div>
		</div>

		<div class="schedule-body-item">
			<div class="schedule-body-item-value">{{ item.end | formatDate('HH:mm:ss') }}</div>
			<div class="schedule-body-item-label" v-if="type !== 'approved'">Ended</div>
			<div class="schedule-body-item-label" v-if="type === 'approved'">Ends at</div>
		</div>

		<div class="schedule-body-item" v-if="type === 'threshold'">
			<div class="schedule-body-item-value">{{ lost }}</div>
			<div class="schedule-body-item-label">Time lost</div>
		</div>

	</div>

</div>

</template>

<script>

export default {

	props: ['item', 'type'],

	data: function() {

		return {
			is: {
				flaggingHide: false
			}
		}

	},

	computed: {

		canPlayback: function() {

			return this.hasPermission(this.$constants.observer.permission.playback) && this.$store.getters['event/playback'].enabled

		},
		
		hasPlayback: function() {

			return this.canPlayback && this.$_.contains(this.$store.getters['playback/ready'], this.item.id)

		},

		lost: function() {

			var value = this.item.lost

			if (!value) return '00:00'
		
			var minutes = Math.floor(value / 60)
			var seconds = value - (minutes * 60)

			return minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0')

		}

	},

	methods: {

		onWatchClick: function() {

			if (this.hasPlayback(this.item.identity)) {

				this.$pubsub.$emit('observer.playback', this.item.identity)
			} 

		},

		onApproveClick: function() {

			this.$pubsub.$emit('modal.adhoc', {
				approve: this.item.identity,
				candidate: this.item.candidate,
				station: this.item.station
			})

		},

		onHideClick: function() {

			if (!this.is.flaggingHide) {

				this.is.flaggingHide = true

				this.$api.request('flag/hide', {
					identity: this.item.identity
				}).then(function() {

					this.is.flaggingHide = false

				}.bind(this), function() {

					this.is.flaggingHide = false

				}.bind(this))

			}
			
		}

	}

}

</script>

<style scoped>

.schedule {
	background-color: #fff;
	display: flex;
	flex-direction: column;
	margin-bottom: 10px;
	width: 100%;
}

.schedule-head {
	background-color: #287ABD;
	display: flex;
	border-radius: 4px 4px 0px 0px;
	overflow: hidden;
}

.schedule[data-type="threshold"] .schedule-head {
	background-color: #d34545;
}

.schedule[data-type="flagged"] .schedule-head {
	background-color: #ffbe19;
}

.schedule:focus .schedule-head {
	background-color: #205f93;
}

.schedule[data-type="threshold"]:focus .schedule-head {
	background-color: #a52828;
}

.schedule[data-type="flagged"]:focus .schedule-head {
	background-color: #a52828;
}

.schedule:focus .schedule-body {
	border-color: #205f93;
}

.schedule[data-type="threshold"]:focus .schedule-body {
	border-color: #a52828;
}

.schedule[data-type="flagged"]:focus .schedule-body {
	border-color: #a52828;
}

.schedule-head-name {
	font-size: 16px;
	color: #fff;
	font-weight: 400;
	padding: 0px 10px;
	flex-grow: 1;
	line-height: 32px;
}

.schedule-head-actions {
	flex-shrink: 0;
	display: flex;
}

.schedule-head-actions-item {
	color: #fff;
	font-size: 14px;
	padding: 0px 10px;
	font-weight: 400;
	line-height: 32px;
	text-align: left;
	border-left: 1px solid #fff;
	cursor: pointer;
	min-width: 100px;
	padding-right: 32px;
}

.schedule-head-actions-item.is-loading {
	pointer-events: none;
}

.schedule-head-actions-item:focus,
.schedule-head-actions-item:hover {
	background-color: rgba(0, 0, 0, 0.2);
}

.schedule-head-actions-item .fa,
.schedule-head-actions-item .fas {
	margin-left: 4px;
	position: absolute;
	right: 10px;
	top: 9px;
}

.schedule-body {
	border: 1px solid #ddd;
	border-top: 0px;
	border-radius: 0px 0px 4px 4px;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 100px;
}

.schedule-body-item {
	padding: 10px;
}

.schedule-body-item-value {
	font-size: 16px;
	color: #333;
}

.schedule-body-item-value span {
	margin-right: 5px;
}

.schedule-body-item-label {
	font-size: 14px;
	color: #666;
	margin-top: 4px;
}

</style>