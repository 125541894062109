<template>

<app-modal v-shortkey="['esc']" v-on:shortkey.native="onClose" v-if="is.active" :active="is.active">

	<template v-slot:head>

		<h1>Enforce logout</h1>

	</template>

	<p>Force users to be logged out with an optional message.</p>

	<textarea ref="input" v-on:contextmenu="$allowContext" v-model="input.message" :disabled="is.loading" placeholder="Enter a message (optional)..." class="textarea"></textarea>

	<app-toggle :default="$constants.observer.announcement.limited" text="Select who will be logged out" :options="typeOptions" v-on:change="onTypeChange" />

	<app-directory v-if="input.type === $constants.observer.announcement.limited" :multiple="true" v-on:change="onDirectoryChange" />

	<template v-slot:buttons>

		<app-button :loading="is.loading" :disabled="!is.valid" v-on:click="onSubmitClick">Confirm</app-button>

		<app-button :disabled="is.loading" theme="white" v-on:click="onClose">Cancel</app-button>

	</template>

</app-modal>

</template>

<script>

import Modal from '@/mixin/modal.js'

export default {

	mixins: [Modal],

	data: function() {

		return {

			name: 'enforce.logout',

			input: {
				message: '',
				recipients: [],
				type: 3
			},

			typeOptions: {
				2: 'Everyone',
				3: 'Specified'
			}

		}

	},

	methods: {

		reset: function() {

			this.is.loading = false 
			this.input.type = 3
			this.input.recipients = []
			this.input.message = ''

			this.$nextTick(function() {

				this.$refs.input.focus()

			}.bind(this))

		},

		onInputChange: function() {

			this.is.valid = this.input.type === this.$constants.observer.announcement.all || this.input.recipients.length

		},

		onDirectoryChange: function(recipients) {

			this.input.recipients = recipients

		},

		onTypeChange: function(value) {

			this.input.type = value

		},

		onSubmitClick: function() {

			this.is.loading = true

			this.$api.request('observer/enforce/logout', {
				message: this.input.message,
				type: this.input.type,
				recipients: this.input.recipients
			}).then(function() {

				this.onClose()

			}.bind(this), function() {

				this.is.loading = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.textarea {
	border: 2px solid #ddd;
	border-radius: 4px;
	font-size: 14px;
	line-height: 20px;
	padding: 3px 10px;
	text-align: left;
	background-color: #fff;
	margin-bottom: 20px;
	width: 100%;
	height: 66px;
	resize: none;
}

.textarea:focus {
    border: 2px solid #4285f4;
}

p {
	font-size: 14px;
	line-height: 20px;
	font-weight: 300;
	margin-bottom: 10px;
}

</style>
