<template>

<div class="flags">

	<div class="flags-empty" v-if="!flags.length">There have been no flags.</div>

	<div tabindex="0" class="flags-item" v-for="flag in flags" :key="flag.identity">

		<div class="flags-item-head">

			<div class="flags-item-head-time">{{ flag.opened | formatDate('HH:mm') }}</div>

			<div class="flags-item-head-user">{{ name(flag) }}</div>

			<div class="flags-item-head-schedule">{{ schedule(flag) }}</div>

			<div class="flags-item-head-type">{{ $constants.flagName[flag.type] }}</div>

		</div>

		<div class="flags-item-body" v-html="text(flag)" />

	</div>

</div>

</template>

<script>

export default {

	computed: {

		flags: function() {

			return this.$_.filter(this.$store.getters['flags'], function(flag) {

				return this.$_.contains([
					this.$constants.flag.case,
					this.$constants.flag.inconsistency,
					this.$constants.flag.unable,
					this.$constants.flag.training,
				], flag.type) && this.$store.getters['overview/associated'][flag.attach]

			}.bind(this)).reverse()

		}

	},

	methods: {

		name: function(flag) {

			return this.$store.getters['people'][flag.user].name

		},

		schedule: function(flag) {

			var schedule = this.$store.getters['overview/associated'][flag.attach]

			var candidate = this.$store.getters['people'][schedule.candidate]

			return candidate.name + ' - No. ' + candidate.reference

		},

		text: function(flag) {

			return this.$filter('nl2br', flag.text)

		}

	}

}

</script>

<style scoped>

.flags {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	flex-grow: 1;
	justify-content: flex-start;
}

.flags-empty {
	position: absolute;
	left: 50%;
	top: 50%;
	font-size: 24px;
	color: #ddd;
	font-weight: 500;
	text-align: center;
	transform: translate(-50%, -50%);
}

.flags-item {
	margin: 0px 10px 10px 0px;
	width: 100%;
}

.flags-item > *:last-child {
	margin-bottom: 0px;
}

.flags-item-head {
	background-color: #287ABD;
	color: #fff;
	height: 32px;
	font-size: 16px;
	line-height: 32px;
	padding: 0px 10px;
	border-radius: 4px 4px 0px 0px;
	display: flex;
	flex-direction: row;
	font-weight: 400;
}

.flags-item:focus .flags-item-head {
	background-color: #205f93;
}

.flags-item.has-colour .flags-item-head {
	border-radius: 0px 4px 0px 0px;
}

.flags-item-head-time {
	width: 50px;
	border-right: 1px solid #fff;
}

.flags-item-head-user {
	width: 200px;
	padding: 0px 10px;
	border-right: 1px solid #fff;
}

.flags-item-head-schedule {
	flex-grow: 1;
	padding: 0px 10px;
	border-right: 1px solid #fff;
}

.flags-item-head-type {
	width: 200px;
	padding: 0px 10px;
}

.flags-item-head-user small {
	font-size: 12px;
	margin-left: 10px;
}

.flags-item-body {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	border-radius: 0px 0px 4px 4px;
	border: 1px solid #ddd;
	border-width: 0px 1px 1px 1px;
	overflow: hidden;
	padding: 10px;
	font-size: 16px;
	line-height: 22px;
	font-weight: 300;
	background-color: #fff;
}

.flags-item:focus .flags-item-body {
	border-color: #205f93;
}

</style>