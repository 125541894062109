<template>

<app-actions-icons class="icons">

	<app-actions-icon onboarding="foot.action.messages" icon="comments" name="Direct messages" :counter="unreadCount" />

	<app-actions-icon onboarding="foot.action.announcements" icon="bullhorn" name="Announcements" v-if="hasAnnouncements" />

	<app-actions-icon onboarding="foot.action.incidents" icon="exclamation-triangle" name="Report incident" />

	<div class="divide"></div>

	<app-actions-icon onboarding="foot.action.preview" icon="user-cog" name="Camera & microphone preview" :disabled="(isStation && !isStationPreparing) || isMeeting" />

	<app-actions-icon onboarding="foot.action.onboarding" icon="graduation-cap" name="Walkthrough" :disabled="isStation || isMeeting" />

	<app-actions-icon onboarding="foot.action.fullscreen" icon="expand" name="Go fullscreen" v-if="!isFullscreen" />

	<app-actions-icon icon="compress" name="Stop fullscreen" v-if="isFullscreen" />

	<app-actions-icon onboarding="foot.action.refresh" icon="sync-alt" name="Refresh" />

	<div class="divide"></div>

	<app-actions-icon onboarding="foot.action.logout" icon="sign-out-alt" name="Log out" />

	<template v-if="hasTools && isAccepted">

	<div class="divide"></div>

	<app-actions-icon onboarding="foot.action.tools" icon="tools" name="Show observer tools" v-on:click="is.expanded = true" v-if="!is.expanded" />

	<app-actions-icon icon="times" :stack="true" name="Hide observer tools" v-on:click="is.expanded = false" v-if="is.expanded" class="tools-hide" />

	</template>

</app-actions-icons>

</template>

<script>

export default {

	data: function() {

		return {
			is: {
				expanded: false
			}
		}

	},

	computed: {

		canOnboard: function() {

			return true //this.$store.getters['session/onboarding/enabled']

		},

		hasAnnouncements: function() {

			return 0 //this.$store.getters['announcements'].length

		},

		hasTools: function() {

			return false

			/*return this.hasPermission(this.$constants.observer.permission.adhoc) 
				|| this.hasPermission(this.$constants.observer.permission.pause)
				|| this.hasPermission(this.$constants.observer.permission.announce)
				|| this.hasPermission(this.$constants.observer.permission.logout)
				|| this.hasPermission(this.$constants.observer.permission.refresh)
				|| this.hasPermission(this.$constants.observer.permission.reassign)*/

		},

		isStationPreparing: function() {

			return false//this.time < this.$store.getters['schedule/active'].roleplay

		},

		isFullscreen: function() {

			return false// this.$store.getters['device/fullscreen']

		},

		unreadCount: function() {

			return 0

		}

	}

}

</script>

<style scoped>

.divide {
	width: 1px;
	background-color: rgba(255, 255, 255, 0.25);
	height: 54px;
	margin-right: 14px;
	margin-left: 10px;
	margin-top: -5px;
}

.tools-hide {
	background-color: transparent!important;
    border-color: transparent!important;
    color: #fff!important;
}

.icons {
	position: relative;
}

</style>