<template>

    <div class="playback">
    
        <div class="playback-empty" v-if="!playback.length">You have no standardisation videos to watch.</div>
    
        <div tabindex="0" class="playback-item" v-for="item in playback" :key="item.identity">
    
            <div class="playback-item-details">
    
                <div class="playback-item-details-name">#{{ candidateReference(item.candidate) }}</div>
                <div class="playback-item-details-date">{{ item.case }}</div>
    
            </div>
    
            <div class="playback-item-actions">
    
                <app-button :small="true" v-on:click="onPlayClick(item.identity)" :loading="is.loading === item.identity">Watch <i class="fa fa-chevron-right"></i></app-button>
    
            </div>
    
        </div>
    
    </div>
    
    </template>
    
    <script>
    
    export default {
    
        data: function() {
    
            return {
                is: {
                    loading: false
                }
            }
    
        },
    
        computed: {
    
            playback: function() {

                var playback = []

                this.$_.each(this.$store.getters['schedule'], function(item) {

                    this.$_.each(item.consults, function(consult) {

                        var schedule = this.$store.getters['overview/associated'][consult.identity]

                        playback.push({
                            identity: consult.identity,
                            candidate: schedule.candidate,
                            case: item.name
                        })

                    }.bind(this))

                }.bind(this))
    
                return playback
    
            }
    
        },
    
        methods: {
    
            candidateReference: function(id) {
    
                return (this.$store.getters['people'][id]) ? this.$store.getters['people'][id].reference : '?'
    
            },
    
            onPlayClick: function(id) {
                
                this.is.loading = id
    
                this.$api.request('playback/start', {
                    identity: id
                }).then(function(json) {
                
                    this.is.loading = false
    
                    this.$store.commit('schedule/add', this.$util.copy(json.schedule))
    
                }.bind(this), function() {
    
                    this.is.loading = false
    
                }.bind(this))
    
            }
    
        }
    
    }
    
    </script>
    
    <style scoped>
    
    .playback {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-grow: 1;
        justify-content: flex-start;
    }
    
    .playback-empty {
        position: absolute;
        left: 50%;
        top: 50%;
        font-size: 24px;
        color: #ddd;
        font-weight: 500;
        text-align: center;
        transform: translate(-50%, -50%);
    }
    
    .playback-item {
        margin: 0px 10px 10px 0px;
        overflow: hidden;
        width: 100%;
        display: flex;
        flex-direction: row;
        border-radius: 4px;
    }
    
    .playback-item-status {
        color: #fff;
        padding: 10px;
        font-weight: 400;
        font-size: 16px;
        height: 54px;
        display: flex;
        align-items: center;
        width: 120px;
    }
    
    .playback-item-status[data-status="2"] {
        background-color: #27a258;
    }
    
    .playback-item-status[data-status="1"] {
        background-color: #1c6aa9;
        color: #5ea9e6;
    }
    
    .playback-item-status[data-status="3"] {
        background-color: #cc3939;
    }
    
    .playback-item-status[data-status="5"] {
        background-color: #ce9921;
    }
    
    .playback-item-status[data-status="4"] {
        background-color: #56aef5;
    }
    
    .playback-item-status[data-status="6"] {
        background-color: #6d38a1;
    }
    
    .playback-item-details {
        border: 1px solid #ddd;
        border-width: 1px 0px 1px 1px;
        padding: 10px;
        height: 54px;
        display: flex;
        flex-direction: column;
        justify-items: center;
        border-radius: 4px 0px 0px 4px;
        flex-grow: 1;
    }
    
    .playback-item:focus .playback-item-details {
        border-color: #287ABD;
    }
    
    .playback-item-details-name {
        font-size: 16px;
        font-weight: 300;
    }
    
    .playback-item-details-date {
        font-size: 12px;
        font-weight: 300;
        margin-top: 4px;
    }
    
    .playback-item-timeline {
        width: 240px;
        border: 1px solid #ddd;
        border-right-width: 0px;
        display: flex;
        padding: 0px 10px;
        flex-direction: column;
        justify-content: center;
    }
    
    .playback-item:focus .playback-item-timeline {
        border-color: #287ABD;
    }
    
    .playback-item-timeline-bar {
        width: 100%;
        height: 18px;
        border-radius: 4px;
        background-color: #ddd;
    }
    
    .playback-item-timeline-bar-progress {
        height: 18px;
        border-radius: 4px;
        background-image: linear-gradient(135deg, #287ABD 25%, #1a72ba 25%, #1a72ba 50%, #287ABD 50%, #287ABD 75%, #1a72ba 75%, #1a72ba 100%);
        background-size: 28.28px 28.28px;
    }
    
    .playback-item-timeline-time {
        font-size: 12px;
        font-weight: 300;
        margin-top: 3px;
        color: #666;
    }	
    
    .playback-item-actions {
        padding: 10px;
        border: 1px solid #ddd;
    }
    
    .playback-item:focus .playback-item-actions {
        border-color: #287ABD;
    }
    
    .playback-item-actions >>> .button {
        width: 120px;
        text-align: left;
        padding: 0px 10px;
    }
    
    .playback-item-actions >>> .button .fa {
        position: absolute;
        right: 10px;
        top: 8px;
    }
    
    </style>
    