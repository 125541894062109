<template>

<div class="station" onboarding="station" :class="{'is-marking': scene.setup.marking}">
	
	<div class="station-warning" v-if="isPrepareEnding" :class="{'is-active': isPrepareEnding}"><div onboarding="station.warning.preparation">{{ countdown(deadline) }}</div></div>
	<div class="station-warning" v-if="isRoleplayStarting" :class="{'is-active': isRoleplayStarting}">Roleplay</div>
	<div class="station-warning" v-if="isRoleplayWarning" :class="{'is-active': isWarningActive}"><div onboarding="station.warning.roleplay">{{ countdown(deadline) }}</div></div>
	<div class="station-warning" v-if="isRoleplayEnding" :class="{'is-active': isRoleplayEnding}"><div onboarding="station.warning.roleplayending">{{ countdown(deadline) }}</div></div>
	<div class="station-warning" v-if="isFeedbackStarting" :class="{'is-active': isFeedbackStarting}">Feedback</div>
	<div class="station-warning" v-if="isFeedbackEnding" :class="{'is-active': isFeedbackEnding}"><div onboarding="station.warning.feedback">{{ countdown(deadline) }}</div></div>

	<template v-if="scene.setup.status !== 'preparation'">

		<app-room-grid :pinsCurrent="pinned" :pinsEnabled="pinned.length" :pinsHidden="true" :pinsSpecial="true" :pinsEditable="false" :participantsCurrent="$_.keys(participants)" :cardsCurrent="$_.keys(cards)">

			<com-participant v-for="participant in participants" :expanded="scene.setup.expanded === participant.id" :key="participant.id" :person="participant" :speaking="participant.id === scene.setup.speaking" />

			<template v-for="card in cardsByType('file')">

				<com-file :onboarding="'card.' + card.id" :onboardingPrefix="'card.' + card.id" :key="card.id" :showNote="card.id === scene.setup.expanded || participants.length < 9" :expanded="card.id === scene.setup.expanded" :item="card" />

			</template>

			<template v-for="card in cardsByType('notepad')">

				<com-notepad :onboarding="'card.' + card.id" :onboardingPrefix="'card.' + card.id" :key="card.id" :canExpand="true" :item="card" :expanded="card.id === scene.setup.expanded" />

			</template>

		</app-room-grid>

	</template>

	<template v-if="scene.setup.status === 'preparation'">

		<div onboarding="station.brief.candidate" v-if="scene.setup.brief.candidate" class="station-preparation" :class="{'is-split': scene.setup.brief.split, 'is-candidate': scene.role === 'candidate', 'has-notepad': notepad}">

			<com-shredded class="station-preparation-text" :shredded="scene.setup.brief.candidate" :showNote="true" />

			<div class="station-preparation-label" v-if="scene.setup.brief.verbose">{{ $language.role.candidate }} brief</div>

		</div>

		<div onboarding="station.brief.roleplayer" v-if="scene.setup.brief.roleplayer" class="station-preparation" :class="{'is-split': scene.setup.brief.split}">

			<com-shredded class="station-preparation-text" :shredded="scene.setup.brief.roleplayer" :showNote="true" />

			<div class="station-preparation-label" v-if="scene.setup.brief.verbose">{{ $language.role.roleplayer }} brief</div>

		</div>

		<div onboarding="station.brief.examiner" v-if="scene.setup.brief.examiner" class="station-preparation" :class="{'is-split': scene.setup.brief.split}">

			<com-shredded class="station-preparation-text" :shredded="scene.setup.brief.examiner" :showNote="true" />

			<div class="station-preparation-label" v-if="scene.setup.brief.verbose">{{ $language.role.examiner }} brief</div>

		</div>

        <com-notepad onboarding="station.brief.notepad" v-if="notepad" :item="{title: '', subtitle: ''}" class="station-notes" />

	</template>

</div>

</template>

<script>

import comParticipant from './meeting/Participant'
import comFile from './meeting/File'
import comNotepad from './meeting/Notepad'
import comShredded from './common/Shredded'

export default {

	props: ['scene', 'initTime'],

	components: {
		'com-participant': comParticipant,
		'com-file': comFile,
		'com-notepad': comNotepad,
		'com-shredded': comShredded
	},

	computed: {

		deadline: function() {

			var duration = 0

			if (this.scene.setup.status === 'preparation') {

				duration = this.scene.setup.duration.preparation

			} else if (this.scene.setup.status === 'roleplay') {

				duration = this.scene.setup.duration.roleplay + this.scene.setup.duration.preparation

			} else if (this.scene.setup.status === 'feedback') {

				duration = this.scene.setup.duration.feedback + this.scene.setup.duration.roleplay + this.scene.setup.duration.preparation

			}

			return this.initTime + duration

		},

		isPrepareEnding: function() {

			return this.scene.setup.status === 'preparation' && this.countdown(this.deadline, true) <= 5

		},

		isRoleplayStarting: function() {

			var remaining = this.countdown(this.deadline, true)
			return this.scene.setup.status === 'roleplay' && (remaining >= this.scene.setup.duration.roleplay - 3)

		},

		isRoleplayWarning: function() {

			var remaining = this.countdown(this.deadline, true)
			return remaining <= 63 && remaining >= 57 && this.scene.setup.status === 'roleplay'

		},

		isWarningActive: function() {

			var remaining = this.countdown(this.deadline, true)
			return remaining <= 61 && remaining >= 59 && this.scene.setup.status === 'roleplay'

		},

		isRoleplayEnding: function() {

			return this.scene.setup.status === 'roleplay' && this.countdown(this.deadline, true) <= 5

		},

		isFeedbackStarting: function() {

			var remaining = this.countdown(this.deadline, true)
			return this.scene.setup.status === 'feedback' && (remaining >= this.scene.setup.duration.feedback - 3)

		},

		isFeedbackEnding: function() {

			return this.scene.setup.status === 'feedback' && this.countdown(this.deadline, true) <= 5

		},

		participants: function() {

			return this.scene.setup.participants

		},

		cards: function() {

			return this.scene.setup.cards

		},

		pinned: function() {

			if (this.scene.setup.expanded) {

				return [this.scene.setup.expanded]

			} else {

				return this.scene.setup.pinned

			}

		},

		notepad: function() {

			return this.scene.setup.notepad

		}

	},

	methods: {

		cardsByType: function(type) {

            return this.$_.where(this.cards, {
                type: type
            })

		}

	}

}

</script>

<style scoped>

.station {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 1;
}

.station.is-marking {
    padding-right: 256px;
}

.station-warning {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	height: 100%;
	z-index: 1;
	color: red;
	font-size: 64px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	font-weight: 700;
	z-index: 20;
	text-shadow: 0px 0px 30px rgba(0, 0, 0, 1);
	opacity: 0;
	transition: opacity 300ms ease-in-out;
	pointer-events: none;
}

.station-warning.is-active {
	opacity: 1;
}

.station-preparation {
	height: 100%;
	display: flex;
	flex-direction: column;
	border: 1px solid #ddd;
	border-radius: 4px;
	overflow: hidden;
	background-color: #fff;
	box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
}

.station-preparation.is-candidate.has-notepad {
    height: calc(100% - 210px);
}

.station-preparation.is-split {
	height: calc(50% - 5px);
}

.station-preparation.is-split:last-child {
	margin-top: 10px;
}

.station-preparation-text {
	width: 100%;
	flex-grow: 1;
	border-radius: 4px;
	flex-basis: 0;
}

.station-preparation-label {
	flex-shrink: 0;
	padding: 10px;
	text-align: left;
	font-size: 16px;
	font-weight: 400;
	color: #333;
}

.station-notes {
    position: absolute;
    z-index: 2;
    left: 0px;
    bottom: 0px;
    right: 0px;
	height: 200px;
}


</style>