<template>

<div class="items">

	<slot></slot>

</div>

</template>

<script>

export default {

}

</script>

<style scoped>

.items {
	margin-top: 10px;
	display: flex;
	flex-direction: column;
	border-radius: 4px;
	margin-top: 10px;
	background-color: #fff;
	box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
	border: 1px solid #ddd;
}

</style>
