<template>

<app-modal v-shortkey="['esc']" v-on:shortkey.native="onClose" v-if="is.active" :active="is.active">

	<template v-slot:head>
		<h1>{{ $constants.flagName[type] }}</h1>
	</template>

	<textarea ref="input" v-on:contextmenu="$allowContext" class="textarea" v-model="input.text" :disabled="is.loading" placeholder="(optional) Add any further details relating to your flag..." />

	<template v-slot:buttons>
		<app-button :loading="is.loading" :disabled="!is.valid || is.revoking" v-on:click="onSubmitClick" v-if="existing">Update</app-button>
		<app-button :loading="is.revoking" theme="red" :disabled="!is.valid || is.loading" v-on:click="onCancelClick" v-if="existing">Revoke</app-button>
		<app-button :loading="is.loading" :disabled="!is.valid" v-on:click="onSubmitClick" v-if="!existing">Confirm</app-button>
		<app-button :disabled="is.loading" theme="plain" v-on:click="onClose">Cancel</app-button>
	</template>

</app-modal>

</template>

<script>

import Modal from '@/mixin/modal.js'

export default {

	mixins: [Modal],

	data: function() {

		return {

			name: 'flag',

			is: {
				revoking: false
			},

			input: {
				text: ''
			},

			existing: false

		}

	},

	computed: {

		type: function() {

			return this.$constants.flag[this.params.type]

		}

	},

	methods: {

		reset: function() {

			this.existing = false

			if (this.$hasFlagged(this.params.type, this.params.identity, this.selfIdentity)) {

				this.existing = this.$_.findWhere(this.$store.getters['flags'], {
					type: this.$constants.flag[this.params.type],
					attach: this.params.identity,
					user: this.selfIdentity
				})

			}

			this.is.revoking = false

			this.input.text = (this.existing) ? this.existing.text : ''

			this.$nextTick(function() {

				this.$refs.input.focus()

			}.bind(this))

		},

		onInputChange: function() {

			this.is.valid = true /* n.text.trim() */

		},

		onCancelClick: function() {

			this.is.revoking = true

			this.$api.request('flag/cancel', {
				identity: this.existing.id
			}).then(function() {

				this.onClose()

			}.bind(this), function() {

				this.is.revoking = false

			}.bind(this))

		},

		onSubmitClick: function() {

			this.is.loading = true

			this.$api.request('flag/submit', {
				type: this.type,
				identity: this.params.identity,
				text: this.input.text
			}).then(function() {

				this.onClose()

			}.bind(this), function() {

				this.is.loading = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.textarea {
	border: 2px solid #ddd;
	border-radius: 4px;
	font-size: 14px;
	line-height: 20px;
	padding: 7px 10px;
	text-align: left;
	background-color: #fff;
	margin-bottom: 20px;
	width: 100%;
	height: 106px;
}

.textarea:focus {
    border: 2px solid #4285f4;
}

</style>
