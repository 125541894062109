<template>

<div tabindex="0" ref="wrapper" class="lead">

	<app-waiting v-if="is.joining && !is.playback" text="One moment, requesting to join room..." />
	<app-waiting v-if="is.joining && is.playback" text="One moment, loading playback..." />

	<div class="lead-content" ref="content" v-if="!is.joining">
		<com-marking v-if="section === $constants.lead.section.marking" /> 
		<com-examiners v-if="section === $constants.lead.section.examiners" /> 
		<com-flags v-if="section === $constants.lead.section.flags" /> 
		<com-standardisation v-if="section === $constants.lead.section.standardisation" /> 
	</div>

</div>

</template>

<script>

import comMarking from './lead/Marking'
import comExaminers from './lead/Examiners'
import comStandardisation from './lead/Standardisation'
import comFlags from './lead/Flags'

export default {

	components: {
		'com-marking': comMarking,
		'com-examiners': comExaminers,
		'com-standardisation': comStandardisation,
		'com-flags': comFlags
	},

	data: function() {

		return {
			is: {
				joining: false,
				playback: false
			}
		}

	},

	created: function() {

		this.$pubsub.$on('lead.section', this.onSectionChange.bind(this))
		this.$pubsub.$on('lead.watch', this.onPlayback.bind(this))
		this.$pubsub.$on('lead.join', this.onJoin.bind(this))

	},

	beforeDestroy: function() {

		this.$pubsub.$off('lead.section', this.onSectionChange.bind(this))

	},

	watch: {

		section: function() {

			this.$nextTick(function() {

				this.$refs.wrapper.focus()

			}.bind(this))

		}

	},

	computed: {

		section: function() {

			return this.$store.getters['session/lead/section']

		}

	},

	methods: {

		onJoin: function(identity) {

			this.is.joining = true
			this.is.playback = false

			this.$store.commit('settings/video', false)
			this.$store.commit('settings/audio', false)

			this.$api.request('room/join', {
				room: identity
			}).then(function() {

				this.is.joining = false

			}.bind(this), function() {

				this.is.joining = false

			}.bind(this))

		},

		onPlayback: function(identity) {

			this.is.joining = true
			this.is.playback = true

			this.$store.commit('settings/video', false)
			this.$store.commit('settings/audio', false)

			this.$api.request('playback/start', {
				identity: identity
			}).then(function(json) {

				this.$store.commit('schedule/add', this.$util.copy(json.schedule))
				
				this.is.joining = false

			}.bind(this), function() {

				this.is.joining = false

			}.bind(this))

		},

		onSectionChange: function(section) {

			this.$store.commit('session/lead/section', section)

		}

	}

}

</script>

<style scoped>

.lead {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 1;
	display: flex;
	flex-direction: column;
}

.lead-content {
	flex-grow: 1;
	box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
	z-index: 1;
	overflow-y: scroll;
	display: flex;
	flex-direction: column;
	padding: 10px;
	border: 1px solid #ddd;
	border-radius: 4px;
	background-color: #fff;
}

</style>
