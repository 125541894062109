<template>

<div>

	<app-actions-items v-if="isRoamingObserver && !hasNext">

		<app-actions-item :shortcut="['shift', 's']" icon="clock" :active="isSchedule" name="Schedule" v-on:click="onSectionClick($constants.observer.schedule)" />

		<app-actions-item :shortcut="['shift', 'u']" icon="users" :active="isRollcall" name="Users" v-on:click="onSectionClick($constants.observer.rollcall)" />

		<app-actions-item :shortcut="['shift', 'n']" v-if="canConnections" icon="wifi" :active="isConnections" name="Connections" v-on:click="onSectionClick($constants.observer.connections)" />

		<app-actions-item :shortcut="['shift', 'm']" v-if="canMarking" icon="marker" :active="isMarking" name="Marking" v-on:click="onSectionClick($constants.observer.marking)" />

		<app-actions-item :shortcut="['shift', 'c']" v-if="canChaperone" icon="user" :active="isChaperones" name="Chaperones" v-on:click="onSectionClick($constants.observer.chaperones)" />

		<app-actions-item :shortcut="['shift', 'a']" v-if="canAdhoc" icon="calendar-plus" :active="isAdhoc" name="Ad-hocs" v-on:click="onSectionClick($constants.observer.adhocs)" />

		<app-actions-item :shortcut="['shift', 'i']" v-if="canIncidents" icon="exclamation-triangle" :active="isIncidents" name="Incidents" v-on:click="onSectionClick($constants.observer.incidents)" />

	</app-actions-items>

</div>

</template>

<script>

export default {

	computed: {

		hasNext: function() {

			return this.$store.getters['schedule/next']

		},
		
		isMarking: function() {
			
			return this.currentSection === this.$constants.observer.marking

		},

		isRollcall: function() {
			
			return this.currentSection === this.$constants.observer.rollcall

		},

		isSchedule: function() {
			
			return this.currentSection === this.$constants.observer.schedule

		},

		isChaperones: function() {
			
			return this.currentSection === this.$constants.observer.chaperones

		},

		isConnections: function() {
			
			return this.currentSection === this.$constants.observer.connections

		},

		isIncidents: function() {
			
			return this.currentSection === this.$constants.observer.incidents

		},

		isAdhoc: function() {
			
			return this.currentSection === this.$constants.observer.adhocs

		},

		currentSection: function() {

			return this.$store.getters['session/observer/section']

		},

		hasSchedule: function() {

			return this.$store.getters['schedule'].length

		},

		canConnections: function() {

			return this.hasPermission(this.$constants.observer.permission.connections) && this.$isExam

		},

		canAdhoc: function() {

			return this.hasPermission(this.$constants.observer.permission.adhoc) && this.$isExam

		},

		canIncidents: function() {

			return this.hasPermission(this.$constants.observer.permission.incidents)

		},

		canMarking: function() {

			return this.hasPermission(this.$constants.observer.permission.marking) && this.$store.getters['event/marking/enabled']

		},

		canChaperone: function() {

			return this.$store.getters['event/chaperones/enabled'] === this.$constants.chaperone.group && this.hasPermission(this.$constants.observer.permission.chaperone) && this.$isExam

		}

	},

	methods: {

		onSectionClick: function(value) {

			this.$pubsub.$emit('observer.section', value)

		}

	}

}

</script>

<style scoped>

</style>
