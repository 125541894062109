<template>
	
    <div class="marksheet" onboarding="marksheet" :style="style">

        <com-resize onboarding="marksheet.resize" />
    
        <com-questions ref="questions" :locked="isLocked" :questions="questions" :sheet="setup.marks" :standardisation="sheet.standardisation" />
    
        <com-actions onboarding="marksheet.actions" :hidenext="setup.hidenext" v-if="!isLocked" :is-saving="is.saving" :is-saved="isSaved" :is-changed="isChanged" :is-valid="isValid" />
    
    </div>
    
    </template>
    
    <script>	
    
    import comResize from './../../../event/content/station/marksheet/Resize'
    import comQuestions from './../../../event/content/station/marksheet/Questions'
    import comActions from './../../../event/content/station/marksheet/Actions'
    
    export default {
    
        props: ['setup'],
    
        components: {
            'com-resize': comResize,
            'com-questions': comQuestions,
            'com-actions': comActions
        },
    
        data: function() {
    
            return {
                is: {
                    saving: false
                },
                sheet: {
                    session: {},
                    saved: {},
                    standardisation: {}
                },
                wasStandardisation: false,
                lastIdentity: false,
                timer: {
                    autosave: false,
                    standardisation: false
                }
            }
    
        },

        watch: {

            scrollPosition: function(n) {

                this.$refs.questions.$children[n - 1].$el.scrollIntoView()

            }

        },
    
        computed: {

            scrollPosition: function() {

                return this.setup.scroll

            },
    
            isLocked: function() {
    
                return false
    
            },
    
            playback: function() {
    
                return []
    
            },
    
            revealed: function() {
    
                return []
    
            },
    
            revealedString: function() {
    
                return ''
    
            },
    
            isChanged: function() {
    
                return this.setup.changed
    
            },
    
            isSaved: function() {
    
                return this.setup.saved
    
            },
    
            isValid: function() {
    
                return this.setup.valid
    
            },
    
            identity: function() {
    
                return 'x'
    
            },
    
            questions: function() {
    
                return {
                    "q1": {
                        "identity": "q1",
                        "text": "<p><strong>Data gathering and diagnosis</strong></p>",
                        "type": 7,
                        "link": 1,
                        "scale": 5,
                        "multi": 0,
                        "options": [
                            {
                                "value": 3,
                                "text": "CLEAR PASS - demonstrated above the standard of a newly qualified GP "
                            },
                            {
                                "value": 2,
                                "text": "PASS - sufficiently demonstrated at the standard of a newly qualified GP"
                            },
                            {
                                "value": 1,
                                "text": "FAIL - insufficiently demonstrated at the standard of a newly qualified GP "
                            },
                            {
                                "value": 0,
                                "text": "CLEAR FAIL - clearly demonstrated below the standard of a newly GP"
                            }
                        ],
                        "required": 1
                    },
                    "q2": {
                        "identity": "q2",
                        "text": "<p><strong>Clinical management and medical complexity </strong></p>",
                        "type": 7,
                        "link": 2,
                        "scale": 5,
                        "multi": 0,
                        "options": [
                            {
                                "value": 3,
                                "text": "CLEAR PASS"
                            },
                            {
                                "value": 2,
                                "text": "PASS"
                            },
                            {
                                "value": 1,
                                "text": "FAIL"
                            },
                            {
                                "value": 0,
                                "text": "CLEAR FAIL"
                            }
                        ],
                        "required": 1
                    },
                    "q3": {
                        "identity": "q3",
                        "text": "<p><strong>Relating to Others</strong></p>",
                        "type": 7,
                        "link": 3,
                        "scale": 5,
                        "multi": 0,
                        "options": [
                            {
                                "value": 3,
                                "text": "CLEAR PASS"
                            },
                            {
                                "value": 2,
                                "text": "PASS"
                            },
                            {
                                "value": 1,
                                "text": "FAIL"
                            },
                            {
                                "value": 0,
                                "text": "CLEAR FAIL"
                            }
                        ],
                        "required": 1
                    },
                    "q4": {
                        "identity": "q4",
                        "text": "<p><strong>Standard Setting</strong></p>",
                        "type": 7,
                        "link": 4,
                        "scale": 5,
                        "multi": 0,
                        "options": [
                            {
                                "value": 3,
                                "text": "PASS - Meets the standard for a newly qualified GP who is fit to consult (Any omissions or errors made are minor/trivial)"
                            },
                            {
                                "value": 2,
                                "text": "BARE PASS - A just passing candidate. Omissions or errors made, but done enough to demonstrate fitness to consult as a newly qualified GP"
                            },
                            {
                                "value": 1,
                                "text": "BARE FAIL - A just failing candidate. Presented some evidence, but insufficient for fitness to consult as a newly qualified GP. Omissions or errors likely to impact on patient care / outcomes"
                            },
                            {
                                "value": 0,
                                "text": "FAIL - Does not meet the standard for a newly qualified GP who is fit to consult. Minimal evidence presented and/or patient put at risk of harm"
                            }
                        ],
                        "required": 1
                    },
                    "q5": {
                        "identity": "q5",
                        "text": "<p><strong>Data Gathering \u0026 Diagnosis Feedback</strong></p>",
                        "type": 9,
                        "link": 5,
                        "scale": 5,
                        "multi": 1,
                        "options": [
                            {
                                "value": 1,
                                "text": "Data gathering was insufficient to enable safe assessment of the condition/situation"
                            },
                            {
                                "value": 2,
                                "text": "Existing information about the case was insufficiently utilised"
                            },
                            {
                                "value": 3,
                                "text": "Relevant psychological or social information was inadequately or inappropriately recognised or responded to"
                            },
                            {
                                "value": 4,
                                "text": "Data gathering was unsystematic and/or disorganised"
                            },
                            {
                                "value": 5,
                                "text": "Ineffective approach or prioritisation in data gathering, when presented with multiple or complex problems"
                            },
                            {
                                "value": 6,
                                "text": "The implications of relevant findings identified during the data gathering were insufficiently recognised or understood"
                            },
                            {
                                "value": 7,
                                "text": "Differential diagnoses or hypotheses were inadequately generated or tested"
                            },
                            {
                                "value": 8,
                                "text": "Decision making or the diagnosis was illogical, incorrect or incomplete"
                            }
                        ],
                        "required": 0
                    },
                    "q6": {
                        "identity": "q6",
                        "text": "<p><strong>Domain 2 Feedback</strong></p>",
                        "type": 9,
                        "link": 6,
                        "scale": 5,
                        "multi": 1,
                        "options": [
                            {
                                "value": 11,
                                "text": "The management plan relating to referral was inappropriate or not reflective of current practice"
                            },
                            {
                                "value": 12,
                                "text": "The management plan relating to prescribing of medication was inappropriate or not reflective of current practice"
                            },
                            {
                                "value": 13,
                                "text": "The management plan relating to investigations was inappropriate or not reflective of current practice"
                            },
                            {
                                "value": 14,
                                "text": "The management plan relating to prevention, health promotion or rehabilitation was inadequate or inappropriate"
                            },
                            {
                                "value": 15,
                                "text": "The plan relating to the medical management of risk was inadequate or inappropriate"
                            },
                            {
                                "value": 16,
                                "text": "The implications of co-morbidity were insufficiently considered"
                            },
                            {
                                "value": 17,
                                "text": "Uncertainty, including that experienced by the patient, was ineffectively managed"
                            },
                            {
                                "value": 18,
                                "text": "Inappropriate or inadequate arrangements for follow-up, continuity and/or safety netting"
                            },
                            {
                                "value": 19,
                                "text": "Time management in the consultation was ineffective"
                            }
                        ],
                        "required": 0
                    },
                    "q7": {
                        "identity": "q7",
                        "text": "<p><strong>Domain 3 Feedback</strong></p>",
                        "type": 9,
                        "link": 7,
                        "scale": 5,
                        "multi": 1,
                        "options": [
                            {
                                "value": 22,
                                "text": "Communication skills, verbal and/or nonverbal, including active listening skills were insufficiently demonstrated"
                            },
                            {
                                "value": 23,
                                "text": "The patient\u2019s agenda, health beliefs and/or preferences were insufficiently explored"
                            },
                            {
                                "value": 24,
                                "text": "The circumstances, relevant cultural differences and/or preferences of those involved were insufficiently responded to"
                            },
                            {
                                "value": 25,
                                "text": "Explanations were inadequately shared or adapted for the person\u2019s needs"
                            },
                            {
                                "value": 26,
                                "text": "A judgemental approach was shown to the person"
                            },
                            {
                                "value": 27,
                                "text": "Respect and/or sensitivity shown to the person was inadequate or inappropriate"
                            },
                            {
                                "value": 28,
                                "text": "Ownership or responsibility of decision-making was inadequate or inappropriate"
                            },
                            {
                                "value": 29,
                                "text": "Teamwork and/or understanding of others\u2019 roles was insufficiently recognised or responded to"
                            },
                            {
                                "value": 30,
                                "text": "Safeguarding concerns were inadequately recognised or responded to"
                            }
                        ],
                        "required": 0
                    }
                }
    
            },
    
            style: function() {
    
                return {
                    height: this.setup.height
                }
    
            }
    
        }
    
    }
    
    </script>
    
    <style scoped>
    
    .marksheet {
        box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
        border: 1px solid #ddd;
        border-radius: 4px;
        background-color: #fff;
        margin-top: 10px;
        min-height: 48px;
        max-height: calc(100% - 102px);
        flex-shrink: 0;
        display: flex;
        transition: height 300ms linear;
        flex-direction: column;
    }
    
    </style>