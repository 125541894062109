<template>

<div id="app" v-on:contextmenu.prevent="$ignoreContext">

	<template v-if="$isAppCheck">

		<com-onboarding v-if="isOnboarding && !isActive" :script="script" />
		<com-check v-on:onboarding="onboardingRole = $event" />

	</template>

	<template v-if="!$isAppCheck">

		<com-onboarding v-if="isOnboarding && !isActive" :script="script" />
		<com-login v-show="!isOnboarding" v-if="!isLoggedin" />
		<com-event v-show="!isOnboarding" v-if="isLoggedin && $isEventMode" />
		<com-assistant v-show="!isOnboarding" v-if="isLoggedin && $isAssistantMode" />
		<com-dashboard v-show="!isOnboarding" v-if="isLoggedin && $isDashboardMode" />

	</template>

</div>

</template>

<script>

import comCheck from './components/Check'
import comLogin from './components/Login'
import comEvent from './components/Event'
import comAssistant from './components/Assistant'
import comOnboarding from './components/Onboarding'
import comDashboard from './components/Dashboard'

export default {

	components: {
		'com-login': comLogin,
		'com-check': comCheck,
		'com-event': comEvent,
		'com-assistant': comAssistant,
		'com-onboarding': comOnboarding,
		'com-dashboard': comDashboard
	},

	data: function() {

		return {
			onboardingRole: 0
		}

	},

	created: function() {

		this.$pubsub.$emit('window.initiated')

		window.addEventListener('beforeunload', function() {

			this.$monitor.log('user/window/unload')

		}.bind(this))

		window.addEventListener('blur', function() {

			this.$monitor.log('user/window/blur')

		}.bind(this))

		window.addEventListener('focus', function() {

			this.$monitor.log('user/window/focus')

		}.bind(this))

	},

	computed: {

		script: function() {

			var role = 'candidate'
			if (this.isExaminer) role = 'examiner'

			if(this.onboardingRole == 1) {

				role = 'candidate'

			} else if(this.onboardingRole == 2) {

				role = 'examiner'

			}

			return this.$store.getters['onboarding/script'][role]

		},

		isOnboarding: function() {

			return this.$store.getters['onboarding/active']

		}

	}

}

</script>

<style>

body {
	overflow: hidden;
	background-color: #fff;
	min-height: 100%;
}

#app {
	position: fixed;
	left: 0px;
	top: 0px;
	background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
	width: 100%;
	height: 100%;
}

</style>
