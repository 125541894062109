import { render, staticRenderFns } from "./Lead.vue?vue&type=template&id=c8c60758&scoped=true"
import script from "./Lead.vue?vue&type=script&lang=js"
export * from "./Lead.vue?vue&type=script&lang=js"
import style0 from "./Lead.vue?vue&type=style&index=0&id=c8c60758&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c8c60758",
  null
  
)

export default component.exports