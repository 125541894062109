<template>
    
<div v-shortkey="['esc']" v-on:shortkey="onExpandClick" class="extend" ref="panel" tabindex="0" v-if="is.expanded" :class="{'is-active': is.expanded}">

	<div class="extend-buttons">

		<button class="extend-buttons-item" v-if="is.expanded" v-on:click="onExpandClick" v-tooltip.left="'Close panel'"><i class="fa fa-remove"></i></button>

	</div>

	<div class="extend-content">

		<div class="extend-head">

			<div class="extend-head-title">Extend time</div>

		</div>

		<app-scroll class="extend-body">

			<p>You can extend the running time of this room by 1 to 99 minutes. All schedules will be automatically updated to reflect the timetable change. It cannot be undone.</p>

			<div class="extend-widget">

				<button class="extend-widget-button" v-on:click="minutes--" :class="{'is-disabled': minutes <= 1}" v-tooltip="'Decrease extension'"><i class="fa fa-minus" /></button>
				<div class="extend-widget-input">

					<input ref="input" type="text" v-on:keypress="onKeyPress" maxlength="2" v-model="minutes" placeholder="0" />

				</div>
				<button class="extend-widget-button" v-on:click="minutes++" :class="{'is-disabled': minutes > 99}" v-tooltip="'Increase extension'"><i class="fa fa-plus" /></button>

			</div>

			<app-button v-on:click="onConfirmClick" :disabled="!minutes || minutes > 99" :loading="is.confirming" theme="white" class="extend-widget-confirm">Apply</app-button>

		</app-scroll>

	</div>

</div>

</template>

<script>

export default {

	data: function() {

		return {
			minutes: 1,
			is: {
				expanded: false,
				confirming: false
			}
		}

	},

	created: function() {

		this.$pubsub.$on('extend.toggle', this.onExpandClick.bind(this))

	},

	beforeDestroy: function() {

		this.$pubsub.$off('extend.toggle', this.onExpandClick.bind(this))

	},

	methods: {

		onConfirmClick: function() {

			this.is.confirming = true

			this.$api.request('room/extend', {
				room: this.isActive.identity,
				minutes: this.minutes
			}).then(function() {

				this.is.confirming = false
				this.is.expanded = false

			}.bind(this))

		},

		onKeyPress: function(e) {

			if(!this.$_.contains(['1','2','3','4','5','6','7','8','9','0'], e.key.toString())) {

				e.preventDefault()

			}

		},

		onExpandClick: function() {

			this.is.expanded = !this.is.expanded

			if (this.is.expanded) {

				this.minutes = 1
				this.is.confirming = false

				this.$nextTick(function() {

					if (this.$refs.input) this.$refs.input.focus()

				}.bind(this))

			}

		}

	}
	
}

</script>

<style scoped>

.extend {
	position: fixed;
	right: -256px;
	user-select: none;
	top: 0px;
	width: 256px;
	bottom: 54px;
	z-index: 2;
	transition: right 300ms ease-in-out;
    background-color: #2A7ABB;
}

.extend.is-active {
    right: 0px;
}

.extend-buttons {
	position: absolute;
	left: -45px;
	width: 45px;
	height: 45px;
	top: 0px;
	display: flex;
	align-items: flex-end;
	flex-direction: column;
}

.extend-buttons-item {
	width: 45px;
	height: 45px;
    background-color: #2A7ABB;
	line-height: 45px;
	color: #fff;
	font-size: 20px;
	text-align: center;
	cursor: pointer;
	transition: all 100ms linear;
}

.extend:not(.is-active) .extend-buttons-item {
    background-color: #287ABD;
}

.extend-buttons-item:not(.is-active):hover {
    background-color: #56a0dc;
}

.extend:not(.is-active) .extend-buttons-item:not(.is-active):hover {
    background-color: #2A7ABB;
}

.extend-buttons-item.is-saved {
    background-color: #38b56a;
}

.extend-content {
	width: 100%;
	height: 100%;
}

.extend-head {
    color: #fff;
    font-size: 24px;
    font-weight: 300;
    flex-shrink: 0;
	padding: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row;
    align-items: center;
}

.extend-head-title {
    flex-grow: 1;
}

.extend-body {
    flex-grow: 1;
    flex-basis: 0;
	height: calc(100% - 44px);
	padding: 10px;
}

.extend-body >>> p {
	color: #fff;
	font-size: 14px;
	line-height: 18px;
}

.extend-widget {
	display: flex;
	margin-top: 15px;
}

.extend-widget-button {
	width: 40px;
	height: 40px;
	border-radius: 4px;
	line-height: 40px;
	text-align: center;
	font-size: 20px;
	cursor: pointer;
	color: #fff;
	flex-shrink: 0;
	background-color: rgba(255, 255, 255, 0.25);
}

.extend-widget-button.is-disabled {
	pointer-events: none;
	opacity: 0.25;
}

.extend-widget-button:focus,
.extend-widget-button:hover {
	background-color: rgba(255, 255, 255, 0.5);
}

.extend-widget-input {
	width: 156px;
	padding: 0px 10px;
}

.extend-widget-input input {
	width: 136px;
	text-align: center;
	line-height: 40px;
	font-size: 32px;
	color: #fff;
}

.extend-widget-input input:focus {
	background-color: rgba(255, 255, 255, 0.25);
	border-radius: 4px;
}

.extend-widget-input input::placeholder {
	color: rgba(255, 255, 255, 0.5);
}

.extend-widget-confirm {
	width: 100%;
	margin-top: 15px;
}

.extend-widget-confirm:focus {
	box-shadow: rgba(255, 255, 255, 0.5) 0 0 0 1px inset, rgb(255 255 255 / 50%) 0 4px 4px 0, rgba(0, 0, 0, .1) 0 2px 2px 0, rgb(255 255 255 / 50%) 0 0 0 4px!important;
}

</style>