<template>

<div class="scroll-wrapper">

	<simplebar v-if="!disabled" data-simplebar-auto-hide="false" ref="simplebar" class="scroll-container">

		<slot></slot>

	</simplebar>

	<div v-if="disabled" class="scroll-container">

		<slot></slot>

	</div>

</div>

</template>

<script>

import 'simplebar/dist/simplebar.css'
import simplebar from 'simplebar-vue'

export default {

	components: {
		'simplebar': simplebar
	},

	props: ['disabled'],

	created: function() {

		this.$nextTick(function() {

			this.$refs.simplebar.scrollElement.addEventListener('scroll', this.onScroll.bind(this))

		}.bind(this))

	},

	beforeDestroy: function() {

		this.$refs.simplebar.scrollElement.removeEventListener('scroll', this.onScroll.bind(this))

	},

	methods: {

		scrollTo: function(value) {

			this.$refs.simplebar.scrollElement.scrollTop = value

		},

		onScroll: function() {

			this.$emit('scroll', this.$refs.simplebar.scrollElement.scrollTop)

		}

	}

}

</script>

<style scoped>

.scroll-wrapper {
	overflow: hidden;
}

.scroll-container {
	overflow: auto;
	width: 100%;
	display: flex;
	flex-direction: column;
	height: 100%;
}

</style>
