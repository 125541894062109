<template>
	
<div class="standardisation" :class="{'is-selected': selected === value}" v-if="matches.length">

	<div class="standardisation-item" v-for="match in matches" :key="match.examiner">
	
		{{ name(match.examiner) }}
	
	</div>

</div>

</template>

<script>	

export default {

	props: ['value', 'selected', 'standardisation'],

	computed: {

		matches: function() {

			return this.$_.filter(this.standardisation, {
				value: this.value
			})

		}

	},

	methods: {

		name: function(identity) {

			return this.$store.getters['people'][identity].name.split(' ')[0]

		}

	}

}

</script>

<style scoped>

.standardisation {
	position: absolute;
	top: -1px;
	right: -1px;
	display: flex;
	border-radius: 0px 4px 0px 4px;
	overflow: hidden;
}

.standardisation-item {
	height: 24px;
	padding: 0px 4px;
	min-width: 24px;
	text-align: center;
	font-size: 16px;
	background-color: #c62323;
	margin-left: 1px;
	color: #fff;
	line-height: 22px;
}

.standardisation.is-selected .standardisation-item { 
	background-color: #38b56a;
}

</style>