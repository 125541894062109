import _ from 'underscore'
import Vue from 'vue'
import pusher from '@/service/pusher'
import constants from '@/constants'

export default {

	namespaced: true,

	state: {

		overview: []

	},

	getters: {

		get: function(state) {

			return state.overview

		},

		start: function(state) {

			return (state.overview.length) ? state.overview[0].start : false

		},

		associated: function(state) {

			var associated = {}

			_.each(state.overview, function(value) {

				associated[value.identity] = value

			})

			return associated

		}

	},

	mutations: {

		set: function(state, data) {

			state.overview = data

		},

		add: function(state, data) {

			var exists = _.findIndex(state.overview, {
				identity: data.identity
			})

			if (exists >= 0) {

				Vue.set(state.overview, exists, data)

			} else {

				state.overview.push(data)

			}

		},

		remove: function(state, data) {

			var exists = _.findIndex(state.overview, {
				identity: data.identity
			})

			if (exists >= 0) state.overview.splice(exists, 1)

		}

	},

	actions: {

		init: function(context, data) {

			context.commit('set', data)
			
			// listen for event delays (ended pauses)
			pusher.on('event', 'delay', function(data) {

				var isActive = context.rootGetters['schedule/active']

				_.each(context.getters['get'], function(schedule) {

					if (
						(
							data.schedule 
							&&
							isActive.identity !== schedule.identity
						)
						||
						(
							!data.schedule
							&& 
							(
								schedule.circuit === data.circuit 
								|| 
								!data.circuit 
								|| 
								!schedule.circuit
							)
						)
					) {

						if (schedule.start >= data.start) schedule.start += data.duration
						if (schedule.end >= data.start && (!data.schedule || schedule.start >= data.start)) schedule.end += data.duration

						if (schedule.type === constants.schedule.type.station && (!data.schedule || schedule.start >= data.start)) {

							if (schedule.roleplay >= data.start) schedule.roleplay += data.duration
							if (schedule.feedback >= data.start) schedule.feedback += data.duration

						}
						
					}

				})

			})

			// listen for event progress
			pusher.on('event', 'progress', function(data) {

				_.each(data.schedule, function(identity) {

					if (context.getters['associated'][identity]) Vue.set(context.getters['associated'][identity], 'active', data.active)

				})

			})

		}

	}

}