import Vue from 'vue'
import _ from 'underscore'
import pusher from '@/service/pusher'

export default {

	namespaced: true,

	state: {

        flags: {}

	},

	getters: {

		get: function(state) {

			return state.flags

        }

	},

	mutations: {

		set: function(state, data) {

			_.each(data, function(flag) {

				Vue.set(state.flags, flag.id, flag)
				
			})

        },

		add: function(state, data) {

			Vue.set(state.flags, data.id, data)

		},

		remove: function(state, data) {

			Vue.delete(state.flags, data.id)

		},

	},

	actions: {

		init: function(context, data) {

			if (data) context.commit('set', data)

			pusher.on('user', 'flags/add', function(json) {

				context.commit('add', json)

			})

			pusher.on('user', 'flags/remove', function(json) {

				context.commit('remove', json)

			})

			if (context.rootGetters['session/is/observer'] && !context.rootGetters['people/self'].chaperone) {

				pusher.subscribe.observers('flags')

				pusher.on('observers.flags', 'add', function(json) {

					context.commit('add', json)
	
				})

				pusher.on('observers.flags', 'remove', function(json) {

					context.commit('remove', json)

				})

			}

		}

	}

}