export default {

	computed: {

		$url: function() {

			return process.env.VUE_APP_URL

		},

		$isAppCheck: function() {

			return process.env.VUE_APP_TYPE === 'check'

		},

		$canSeeMarksheet: function() {

			return (this.hasPermission(this.$constants.observer.permission.marking) || this.isExaminer || this.isLayExaminer) && this.$store.getters['event/marking/enabled'] && this.isAccepted

		},

		$roomTimeLapsed: function() {

			return (this.isActive) ? this.time - this.isActive.start : false

		},

		$isEventChaperoned: function() {
			
			return this.$store.getters['event/chaperones/enabled'] 

		},

		$isEventFinished: function() {

			return (this.$store.getters['schedule/finished'] && !this.isRoamingObserver) || (this.isChaperone && this.$store.getters['people/self'].verified)

		},

		$isMarkingEnabled: function() {

			if (this.$store.getters['event/marking/enabled']) {

				if (this.$store.getters['event/playback/marking'].enabled === this.$constants.playback.marking.both) {

					if (this.isExaminer || this.isLayExaminer) {

						return this.time <= this.$store.getters['playback/to']
						
					} else {

						return this.time <= this.$store.getters['event/playback/marking'].to

					}

				} else if (this.$store.getters['event/playback/marking'].enabled === this.$constants.playback.marking.recorded) {

					if (this.isExaminer || this.isLayExaminer) {

						return this.time >= this.$store.getters['playback/from'] && this.time <= this.$store.getters['playback/to']

					} else {

						return this.time >= this.$store.getters['event/playback/marking'].from && this.time <= this.$store.getters['event/playback/marking'].to

					}

				} else {

					return true

				}

			} else {

				return false

			}

		},

		$isPlaybackEnabled: function() {

			return (this.isExaminer || this.isLayExaminer) && this.$store.getters['event/playback/marking'].enabled

		},

		$isEventMode: function() {

			return this.$store.getters['session/mode'] === this.$constants.mode.event

		},

		$isDashboardMode: function() {

			return this.$store.getters['session/mode'] === this.$constants.mode.dashboard

		},

		$isAssistantMode: function() {

			return this.$store.getters['session/mode'] === this.$constants.mode.assistant

		},

		$colour: function() {

			return this.$store.getters['circuits/current'] ? this.$store.getters['circuits/associated'][this.$store.getters['circuits/current']].colour : false

		},
		
		$language: function() {

			return this.$store.getters['language']

		},

		isLiveEnding: function() {

			if (this.isStation) {

				if ( this.time <= this.isActive.roleplay) {

					return this.countdown(this.isActive.roleplay) < 60

				} else {

					return this.countdown(this.isActive.end) < 60

				}

			} else if (this.isMeeting) {

				return this.countdown(this.isActive.end) < 60

			}

			return false

		},

		liveStatus: function() {

			var name

			if (this.isActive) {

				if (this.$isAssistantMode && this.isStation) {

					name = this.$store.getters['people'][this.isActive.candidate].name

				} else {

					name = this.isActive.name

				}

			}

			if (this.isStation) {

				if (this.time <= this.isActive.roleplay) {

					return '<b>' + name + ' reading time</b> ends in ' + this.countdown(this.isActive.roleplay)

				} else {

					return '<b>' + name + ' roleplay</b> ends in ' + this.countdown(this.isActive.end)

				}

			} else if (this.isMeeting) {

				return '<b>' + name + '</b> ends in ' + this.countdown(this.isActive.end)

			} else if (this.isBreak) {

				return '<b>' + name + '</b> ends in ' + this.countdown(this.isActive.end)

			} else if (this.$store.getters['schedule/finished']) {

				return (this.isRoamingObserver) ? '' : '<b>Exam has finished</b>'

			} else {

				var left = this.countdown(this.$store.getters['schedule/next'].start)

				if (left === 'a moment') {

					return '<b>' + this.$store.getters['schedule/next'].name + '</b> will begin once unlocked by an observer</b>'

				} else {

					return '<b>' + this.$store.getters['schedule/next'].name + '</b> begins in ' + left

				}

			}

		},

		isAccepted: function() {

			return this.$store.getters['session/accepted']

		},

		isLoggedin: function() {

			return this.$store.getters['session/loggedin']

		},

		isInitiated: function() {

			return this.$store.getters['device/initiated']

		},

		isExam: function() {

			return this.$isExam

		},

		$isEventMarking: function() {

			return this.$store.getters['event/type'] === this.$constants.event.type.marking

		},

		$isEventCalibration: function() {

			return this.$store.getters['event/type'] === this.$constants.event.type.calibration

		},

		$isExam: function() {

			return this.$store.getters['event/type'] === this.$constants.event.type.exam

		},

		isEarly: function() {

			return this.time < this.$store.getters['schedule/start'] - this.$constants.schedule.early

		},

		isDeliveryOnline: function() {

			return this.$store.getters['event/delivery'] === this.$constants.delivery.online

		},

		isPaused: function() {

			return this.$store.getters['event/paused']

		},

		isLead: function() {

			return this.$isLead

		},

		$isLead: function() {

			return (this.isActive) ? this.$_.contains(this.isActive.leads, this.$store.getters['session/identity']) || this.isRoamingObserver : false

		},

		isActive: function() {

			return this.$store.getters['schedule/active']

		},

		$isActivePlayback: function() {

			return (this.isActive) ? this.isActive.playback : false

		},

		$isActiveReady: function() {

			return (this.$isActivePlayback) ? this.isActive.playback.ready : true

		},

		$isActivePlaying: function() {

			return (this.$isActivePlayback) ? this.isActive.playback.playing : true

		},

		isStation: function() {

			return (this.isActive) ? this.isActive.type === this.$constants.schedule.type.station : false

		},

		$isActiveStation: function() {

			return (this.isActive) ? this.isActive.type === this.$constants.schedule.type.station : false

		},
		
		isChaperoneGroup: function() { 

			return (this.isActive) ? this.isActive.type === this.$constants.schedule.type.chaperone : false

		},

		isAudioStation: function() {

			return (this.isStation) ? !this.isActive.permissions.video : false

		},

		isStationExaminerOnly: function() {

			return (this.isStation) ? this.isActive.roleplayer.length === 0 : false

		},

		$isInStandardisation: function() {

			return (this.isMeeting) ? this.isActive.format === this.$constants.meeting.format.standardisation : false

		},

		$isInWebinar: function() {

			return (this.isMeeting) ? this.isActive.format === this.$constants.meeting.format.webinar : false

		},

		$isWebinar: function() {

			return this.$isInWebinar

		},

		$isInMeeting: function() {

			return (this.isActive) ? this.isActive.type === this.$constants.schedule.type.meeting : false

		},

		isMeeting: function() {

			return this.$isInMeeting

		},

		$isInterval: function() {

			return (this.isActive) ? this.isActive.type === this.$constants.schedule.type.interval : false

		},

		isBreak: function() {

			return (this.isActive) ? this.isActive.type === this.$constants.schedule.type.interval && this.isActive.format === this.$constants.interval.format.standard : false

		},

		isObserving: function() {

			return (this.$store.getters['schedule/active']) ? this.$_.contains(this.$store.getters['schedule/active'].observers, this.$store.getters['session/identity']) : false

		},

		isParticipating: function() {

			return (this.$store.getters['schedule/active']) ? this.$_.contains(this.$store.getters['schedule/active'].participants, this.$store.getters['session/identity']) : false

		},
		
		isCandidate: function() {

			return this.$isCandidate

		},
		
		$isCandidate: function() {

			return this.$store.getters['people/self'].role === this.$constants.role.candidate

		},
		
		$isLeadExaminer: function() {

			return (this.$isDashboardMode) ? this.$store.getters['user/type'] == this.$constants.user.type.lead : this.$isExaminer && this.$store.getters['people/self'].lead

		},

		isObserver: function() {

			return this.$store.getters['people/self'].role === this.$constants.role.observer

		},

		isChaperone: function() {

			return this.$store.getters['people/self'].chaperone

		},

		selfIdentity: function() {

			return this.$store.getters['people/self'].identity

		},

		isRoamingChaperone: function() {
			
			return this.isChaperone && this.$store.getters['event/chaperones/enabled'] === this.$constants.chaperone.group

		},

		isTalking: function() {

			return this.$store.getters['chaperone/talking']

		},

		isPreparation: function() {

			return (this.isStation) ? this.time < this.isActive.roleplay : false

		},

		$isInStationRoleplay: function() {

			return (this.isStation) ? this.time > this.isActive.roleplay : false

		},

		$isObservingMultipleCircuits: function() {

			return this.isObserver && this.$_.contains([this.$constants.observer.scope.circuits], this.$store.getters['people/self'].scope)

		},

		isRoamingObserver: function() {

			return this.$isRoamingObserver
			
		},

		$isRoamingObserver: function() {

			return this.isObserver && !this.isChaperone && this.$_.contains([this.$constants.observer.scope.event, this.$constants.observer.scope.circuit, this.$constants.observer.scope.circuits], this.$store.getters['people/self'].scope)

		},

		isRoamingExaminer: function() {

			return this.$isRoamingExaminer

		},

		$isRoamingExaminer: function() {

			return this.$isRoamingObserver || (this.$isLeadExaminer && this.$isEventMarking)

		},

		isRoleplayer: function() {

			return this.$store.getters['people/self'].role === this.$constants.role.roleplayer

		},

		isExaminer: function() {

			return this.$isExaminer

		},

		$isExaminer: function() {

			return (this.$isDashboardMode) ? this.$store.getters['user/type'] == this.$constants.user.type.examiner : this.$store.getters['people/self'].role === this.$constants.role.examiner

		},

		isLayExaminer: function() {

			return this.$store.getters['people/self'].role === this.$constants.role.layexaminer

		}

	},

	methods: {
		
		$filter: function(name, value, args) {

			return this.$options.filters[name](value, args);

		},

		$name: function(id) {

			return this.$store.getters['people'][id].name

		},

		$ignoreContext: function() {

		},

		$allowContext: function(e) {

			e.stopPropagation()

		},

		hasPermission: function(type) {

			return this.$_.contains(this.$store.getters['people/self'].permissions, type.toString())

		},

		$isFlagEnabled: function(type) {

			return this.$_.contains(this.$store.getters['event/flags/enabled'], type)

		},

		$isFlagging: function(type, id) {

			id = (id || id === null) ? id : false

			return this.$_.filter(this.$store.getters['flags'], function(flag) {

				return flag.type === this.$constants.flag[type] && (id === false || flag.attach === id) && flag.status === 0

			}.bind(this)).length

		},

		$hasFlagged: function(type, attach, user) {

			attach = attach || false
			user = user || false

			var types = (this.$_.isArray(type)) ? type : [type]

			return this.$_.filter(this.$store.getters['flags'], function(flag) {

				return this.$_.filter(types, function(type) {

					return flag.type === this.$constants.flag[type] && (attach === false || flag.attach === attach) && (user === false || flag.user === user) && flag.status === 1

				}.bind(this)).length

			}.bind(this)).length

		},

		$getMarkByLink: function(type, questions, sheet) {

			var link = this.$_.find(questions, function(question) {
						
				return question.link === type

			}.bind(this))

			return (link) ? sheet[link.identity] : false

		},

		$validateSheet: function(questions, sheet) {

			var required = 7
			var valid = 0
			var linkedMark

			this.$_.each(questions, function(question) {

				var mark = sheet[question.identity]

				if (this.$_.contains([
					this.$constants.marking.question.link.domain.mark['1'],
					this.$constants.marking.question.link.domain.mark['2'],
					this.$constants.marking.question.link.domain.mark['3'],
					this.$constants.marking.question.link.standard
				], question.link)) {

					if (mark !== null) valid++

				} else if (this.$constants.marking.question.link.domain.feedback['1'] === question.link) {

					linkedMark = this.$getMarkByLink(this.$constants.marking.question.link.domain.mark['1'], questions, sheet)

					if (linkedMark === this.$constants.marking.mark.clearfail || linkedMark === this.$constants.marking.mark.fail) {

						if (mark !== null) {

							if (mark.length > 0) valid++

						}

					} else {

						valid++
							
					}

				} else if (this.$constants.marking.question.link.domain.feedback['2'] === question.link) {

					linkedMark = this.$getMarkByLink(this.$constants.marking.question.link.domain.mark['2'], questions, sheet)

					if (linkedMark === this.$constants.marking.mark.clearfail || linkedMark === this.$constants.marking.mark.fail) {

						if (mark !== null) {

							if (mark.length > 0) valid++

						}

					} else {

						valid++
							
					}

				} else if (this.$constants.marking.question.link.domain.feedback['3'] === question.link) {

					linkedMark = this.$getMarkByLink(this.$constants.marking.question.link.domain.mark['3'], questions, sheet)

					if (linkedMark === this.$constants.marking.mark.clearfail || linkedMark === this.$constants.marking.mark.fail) {

						if (mark !== null) {

							if (mark.length > 0) valid++

						}

					} else {

						valid++
							
					}

				}

			}.bind(this))

			return required === valid

		}

	}

}