<template>

<div class="current" onboarding="sidebar.current" :class="{'is-station': current.type === 'station'}">
	
	<div class="current-item current-item-head" onboarding="sidebar.current.name">

		<div class="current-item-name">{{ current.name }}</div>

	</div>

	<div class="current-item-wrapper">
	
		<div class="current-item is-active" v-if="current.type !== 'station'" onboarding="sidebar.current.duration">

			<div class="current-item-name">In progress</div>
			<div class="current-item-countdown">{{ countdown(deadline) }}</div>

		</div>
		
		<div class="current-item" onboarding="sidebar.current.duration.preparation" :class="{'is-active': current.status === 'preparation', 'is-complete': current.status === 'roleplay'}" v-if="current.type === 'station'">

			<div class="current-item-name">Reading time</div>
			<div class="current-item-status">{{ durationPreparation }}</div>
			<div class="current-item-countdown" v-if="current.status === 'preparation'">{{ countdown(deadline) }}</div>
			<div class="current-item-check fa fa-check-circle" v-if="current.status === 'roleplay' || current.status === 'feedback'"></div>

		</div>
		
		<div class="current-item" onboarding="sidebar.current.duration.roleplay" :class="{'is-active': current.status === 'roleplay'}" v-if="current.type === 'station'">

			<div class="current-item-name">Roleplay</div>
			<div class="current-item-status">{{ durationRoleplay }}</div>
			<div class="current-item-countdown" v-if="current.status === 'roleplay'">{{ countdown(deadline) }}</div>
			<div class="current-item-check fa fa-check-circle" v-if="current.status === 'feedback'"></div>

		</div>
		
		<div class="current-item" onboarding="sidebar.current.duration.feedback" :class="{'is-active': current.status === 'feedback'}" v-if="current.type === 'station' && hasFeedback">

			<div class="current-item-name">Feedback</div>
			<div class="current-item-status">{{ durationFeedback }}</div>
			<div class="current-item-countdown" v-if="current.status === 'feedback'">{{ countdown(deadline) }}</div>

		</div>

	</div>

	<div class="current-item is-slim">

		<com-online :current="current" />

	</div>

</div>

</template>

<script>

import comOnline from './Online'

export default {

	props: ['current', 'initTime'],

	components: {
		'com-online': comOnline
	},

	computed: {

		deadline: function() {

			var time = this.initTime 

			if (this.current.type === 'station') {

				if (this.current.status === 'preparation') time += this.current.duration.preparation
				if (this.current.status === 'roleplay') time += this.current.duration.roleplay + this.current.duration.preparation
				if (this.current.status === 'feedback') time += this.current.duration.feedback + this.current.duration.roleplay + this.current.duration.preparation

			} else {

				time += this.current.duration

			}

			return time

		},

		durationPreparation: function() {

			var minutes = this.current.duration.preparation / 60;

			return minutes + ' minute' + ((minutes === 1) ? '' : 's')

		},

		durationRoleplay: function() {

			var minutes = this.current.duration.roleplay / 60;

			return minutes + ' minute' + ((minutes === 1) ? '' : 's')

		},

		durationFeedback: function() {

			var minutes = this.current.duration.feedback / 60;

			return minutes + ' minute' + ((minutes === 1) ? '' : 's')

		},

		hasFeedback: function() {

			return this.current.duration.feedback !== false

		}

	},

	methods: {

		onUsersClick: function() {

			if (this.isLead) {

				this.$pubsub.$emit('users.toggle')

			}

		}

	}

}

</script>

<style scoped>

.current {
	user-select: none;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	border-radius: 4px;
	flex-shrink: 0;
	background-color: #fff;
	margin-top: 10px;
	box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
}

.current-item {
	padding: 10px;
	height: 56px;
	display: flex;
	color: #333;
	flex-direction: column;
	justify-content: center;
	border: 1px solid #ddd;
	border-width: 0px 1px 1px 1px;
	pointer-events: none;
}

@media (max-height: 799px) {

	.current-item-wrapper.is-webinar .current-item {
		height: auto;
		text-align: center;
	}

}

.current-item.is-clickable {
	cursor: pointer;
	pointer-events: initial;
}

.current-item.is-clickable:hover {
	background-color: #eee;
}

.current-item.is-slim {
	height: auto;
}

.current-item:last-child {
	border-radius: 0px 0px 4px 4px;
}

.current-item-head {
	border-top: 0px;
	background-color: #287ABD;
	color: #fff;
	border-color: #287ABD;
	height: 40px;
}

.current-item.is-complete {
	color: #999;
}

.current-item-name {
	font-size: 16px;
	font-weight: 300;
}

@media (max-height: 799px) {

	.current-item-name {
		height: 32px;
		margin-bottom: 10px;
	}

}

.current-item-head .current-item-name {
	margin-bottom: 0px;
	font-weight: 400;
}

.current-item-status {
	font-size: 12px;
	font-weight: 300;
	margin-top: 4px;
}

.current-item-check {
	position: absolute;
	right: 10px;
	top: 50%;
	margin-top: -12px;
	font-size: 24px;
	color: #287ABD;
}

.current-item-counter {
	font-size: 16px;
	line-height: 32px;
	font-weight: 500;
	border-radius: 4px;
	min-width: 32px;
	text-align: center;
	position: absolute;
	color: #333;
	right: 10px;
	top: 12px;
}

.current-item-counter.is-blue {
	background-color: #287ABD;
	color: #fff;
}

.current-item-counter.is-disabled {
	color: #ccc;
}

.current-item-countdown {
	font-size: 16px;
	line-height: 36px;
	font-weight: 500;
	color: #333;
	position: absolute;
	right: 10px;
	top: 10px;
}

@media (max-height: 799px) {

	.current-item-wrapper.is-webinar .current-item-counter,
	.current-item-wrapper.is-webinar .current-item-countdown {
		position: relative;
		top: auto;
		right: auto;
	}

}

@media (max-height: 799px) {

	.current-item-wrapper.is-webinar {
		display: flex;
		flex-direction: row;
	}

}

</style>
