import _ from 'underscore'
import Vue from 'vue'
import pusher from '@/service/pusher'
import constants from '@/constants'

export default {

	namespaced: true,

	state: {

		structure: []

	},

	getters: {

		get: function(state) {

			return state.structure

		},

		start: function(state) {

			return (state.structure.length) ? state.structure[0].start : false

		},

		associated: function(state) {

			var associated = {}

			_.each(state.structure, function(value) {

				associated[value.identity] = value

			})

			return associated

		}

	},

	mutations: {

		set: function(state, data) {

			state.structure = data

		},

		add: function(state, data) {

			var exists = _.findIndex(state.structure, {
				identity: data.identity
			})

			if (exists >= 0) {

				Vue.set(state.structure, exists, data)

			} else {

				state.structure.push(data)

			}

		},

		remove: function(state, data) {

			var exists = _.findIndex(state.structure, {
				identity: data.identity
			})

			if (exists >= 0) state.structure.splice(exists, 1)

		}

	},

	actions: {

		init: function(context, data) {

			context.commit('set', data)

			pusher.on('user', 'structure/update', function(structure) {

				context.commit('add', structure)

			}.bind(this))

			pusher.on('user', 'structure/delete', function(structure) {

				context.commit('remove', structure)

			}.bind(this))
			
			// listen for event delays (ended pauses)
			pusher.on('event', 'delay', function(data) {

				_.each(context.getters['get'], function(structure) {

					if (structure.circuit === data.circuit || !data.circuit || !structure.circuit) {

						if (structure.start >= data.start) structure.start += data.duration
						if (structure.end >= data.start) structure.end += data.duration

						if (structure.type === constants.schedule.type.station) {

							if (structure.roleplay >= data.start) structure.roleplay += data.duration
							if (structure.feedback >= data.start) structure.feedback += data.duration

						}

					}

				})

			})

		}

	}

}