<template>

<div class="schedule">

	<com-personal v-if="!isRoamingObserver" />
	<com-observer v-if="isRoamingObserver" />

</div>

</template>

<script>

import comPersonal from './schedule/Personal'
import comObserver from './schedule/Observer'

export default {

	components: {
		'com-personal': comPersonal,
		'com-observer': comObserver
	}

}

</script>

<style scoped>

</style>
