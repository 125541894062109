import _ from 'underscore'

export default {

	namespaced: true,

	state: {

		stations: []

	},

	getters: {

		get: function(state) {

			return state.stations

		},

		associated: function(state) {

			var associated = []

			_.each(state.stations, function(station) {

				associated[station.identity] = station
					
			})

			return associated

		}

	},

	mutations: {

		set: function(state, data) {

			state.stations = data

		}

	},

	actions: {

		init: function(context, data) {

			context.commit('set', data)

		}

	}

}