<template>

<button v-shortkey="shortcut" v-on:shortkey="$emit('click')" class="item" v-tooltip="tooltip" v-on:click="$emit('click')" :class="{'is-active': active}">

	<div class="item-name">{{ name }}</div>
	<div class="item-count" v-if="count">{{ count }}</div>
	<div class="item-icon"><i :class="{[iconClass]: true}"></i></div>

</button>

</template>

<script>

export default {

	props: ['icon', 'shortcut', 'count', 'name', 'active'],

	computed: {

		tooltip: function() {

			return (this.shortcut) ? this.shortcut.join(' + ') : false

		},

		iconClass: function() {

			return 'fa fa-' + this.icon

		}

	}

}

</script>

<style scoped>

.item {
	color: #333;
	padding: 10px;
	height: 56px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	border-top: 1px solid #ddd;
	height: 40px;
}

.item:focus {
	box-shadow: rgba(50, 50, 93, .1) 0 0 0 1px inset, rgba(50, 50, 93, .2) 0 4px 4px 0, rgba(0, 0, 0, .1) 0 2px 2px 0, rgba(50, 151, 211, .3) 0 0 0 4px;
}

.item.is-active {
	background-color: #287ABD;
	border-color: #287ABD;
	color: #fff;
}

.item:first-child {
	border-radius: 4px 4px 0px 0px;
	border-top: 0px;
}

.item:last-child {
	border-radius: 0px 0px 4px 4px;
}

.item:not(.is-active):hover {
	background-color: #eee;	
}

.item-name {
	font-size: 14px;
	font-weight: 300;
	line-height: 40px;
	cursor: pointer;
}

.item-icon {
	position: absolute;
	right: 10px;
	top: 50%;
	margin-top: -8px;
	width: 24px;
	text-align: center;
	font-size: 16px;
	color: #287ABD;
}

.item.is-active .item-icon {
	color: #fff;
}

.item-count {
	position: absolute;
	right: 48px;
	background-color: #287ABD;
	color: #fff;
	border-radius: 4px;
	height: 20px;
	min-width: 20px;
	line-height: 20px;
	text-align: center;
	font-weight: 500;
}

.item.is-active .item-count {
	color: #287ABD;
	background-color: #fff;
}

</style>
