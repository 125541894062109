<template>
    
<div v-shortkey="['esc']" v-on:shortkey="onExpandClick" class="extend" ref="panel" tabindex="0" v-if="is.expanded" :class="{'is-active': is.expanded}">

	<div class="extend-buttons">

		<button class="extend-buttons-item" v-if="is.expanded" v-on:click="onExpandClick" v-tooltip.left="'Close panel'"><i class="fa fa-remove"></i></button>

	</div>

	<div class="extend-content">

		<div class="extend-head">

			<div class="extend-head-title">Log</div>

		</div>

		<app-scroll class="extend-body">

			<div class="extend-log">

				<i class="fa fa-spinner fa-spin extend-log-load" v-if="is.loading" />

				<div class="extend-log-item" v-for="(item, index) in log" :key="index"><span v-html="text(item)" /><small>{{ item.time | formatDate('HH:mm') }}</small></div>

			</div>

		</app-scroll>

	</div>

</div>

</template>

<script>

import Vue from 'vue'

export default {

	data: function() {

		return {
			is: {
				expanded: false
			},
			profile: false,
			log: []
		}

	},

	created: function() {

		this.$pubsub.$on('connections.log.toggle', this.onExpandClick.bind(this))

	},

	beforeDestroy: function() {

		this.$pubsub.$off('connections.log.toggle', this.onExpandClick.bind(this))

	},

	methods: {

		text: function(item) {

			var name = (item.login) ? this.$store.getters['people'][item.login] : ''
			var room = (item.schedule && this.$store.getters['overview/associated'][item.schedule]) ? this.$store.getters['overview/associated'][item.schedule] : ''

			name = (name) ? name.name : 'Unknown Participant'
			room = (room) ? room.name : 'Unknown Room'

			var text = ''

			if (item.type === this.$constants.monitor.user.strength) {

				text = '{{name}} network strength is ' + item.data.strength

			} else if (item.type === this.$constants.monitor.user.online) {

				text = '{{name}} is online'

			} else if (item.type === this.$constants.monitor.user.offline) {

				text = '{{name}} is offline'

			} else if (item.type === this.$constants.monitor.user.location) {

				text = '{{name}} geolocated successfully'

			} else if (item.type === this.$constants.monitor.user.refresh) {

				text = '{{name}} refreshed'

			} else if (item.type === this.$constants.monitor.user.logout) {

				text = '{{name}} logged out'

			} else if (item.type === this.$constants.monitor.user.room.connected) {

				text = '{{name}} connected to {{room}}'

			} else if (item.type === this.$constants.monitor.user.room.disconnected) {

				text = '{{name}} disconnected from {{room}}'

			} else if (item.type === this.$constants.monitor.user.room.track.enabled) {

				text = '{{name}} ' + item.data.type + ' turned on'

			} else if (item.type === this.$constants.monitor.user.room.track.disabled) {

				text = '{{name}} ' + item.data.type + ' turned off'

			} else if (item.type === this.$constants.monitor.user.room.track.added) {

				text = '{{name}} ' + item.data.type + ' added'

			} else if (item.type === this.$constants.monitor.user.room.track.removed) {

				text = '{{name}} ' + item.data.type + ' removed'

			} else if (item.type === this.$constants.monitor.user.room.join) {

				text = '{{name}} joined {{room}}'

			} else if (item.type === this.$constants.monitor.user.check.pass) {

				text = '{{name}} passed device check'

			} else if (item.type === this.$constants.monitor.user.check.fail) {

				text = '{{name}} failed device check'

			} else if (item.type === this.$constants.monitor.user.flag.assistance) {

				text = '{{name}} flagged for assistance'

			} else if (item.type === this.$constants.monitor.user.flag.rerun) {

				text = '{{name}} flagged {{room}} as potential re-run'

			} 

			text = text.replace('{{name}}', '<b>' + name + '</b>')
			text = text.replace('{{room}}', '<b>' + room + '</b>')

			return text

		},

		load: function() {

			this.is.loading = true

			this.$api.request('monitor/candidate', {
				identity: this.profile.id
			}).then(function(json) {

				this.log = json.log

				this.is.loading = false

			}.bind(this))

		},

		onExpandClick: function(e) {

			if (e.data) {
				
				Vue.set(this, 'profile', e.data)

				this.load()

			} else {

				this.log = []

			}

			this.$nextTick(function() {
				
				this.is.expanded = !this.is.expanded

				this.$nextTick(function() {

					if (this.is.expanded) this.$refs.panel.focus()
					
				}.bind(this))

			}.bind(this))

		}

	}
	
}

</script>

<style scoped>

.extend {
	position: fixed;
	right: -256px;
	user-select: none;
	top: 0px;
	width: 256px;
	bottom: 54px;
	z-index: 2;
	transition: right 300ms ease-in-out;
    background-color: #2A7ABB;
}

.extend.is-active {
    right: 0px;
}

.extend-log {
	min-height: 100px;
}

.extend-log-item {
	padding: 7px 10px;
	color: #fff;
	font-size: 14px;
	line-height: 20px;
}

.extend-log-item small {
	display: block;
	font-size: 12px;
	opacity: 0.75;
}

.extend-log-item:nth-child(even) {
	background-color: rgba(0, 0, 0, 0.15);
}

.extend-log-load {
	position: absolute;
	left: 50%;
	top: 50%;
	margin-left: -16px;
	margin-top: -16px;
	color: #fff;
	font-size: 32px;
}

.extend-buttons {
	position: absolute;
	left: -45px;
	width: 45px;
	height: 45px;
	top: 0px;
	display: flex;
	align-items: flex-end;
	flex-direction: column;
}

.extend-buttons-item {
	width: 45px;
	height: 45px;
    background-color: #2A7ABB;
	line-height: 45px;
	color: #fff;
	font-size: 20px;
	text-align: center;
	cursor: pointer;
	transition: all 100ms linear;
}

.extend:not(.is-active) .extend-buttons-item {
    background-color: #287ABD;
}

.extend-buttons-item:not(.is-active):focus,
.extend-buttons-item:not(.is-active):hover {
    background-color: #56a0dc;
}

.extend:not(.is-active) .extend-buttons-item:not(.is-active):focus,
.extend:not(.is-active) .extend-buttons-item:not(.is-active):hover {
    background-color: #2A7ABB;
}

.extend-buttons-item.is-saved {
    background-color: #38b56a;
}

.extend-content {
	width: 100%;
	height: 100%;
}

.extend-head {
    color: #fff;
    font-size: 24px;
    font-weight: 300;
    flex-shrink: 0;
	padding: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row;
    align-items: center;
}

.extend-head-title {
    flex-grow: 1;
}

.extend-body {
    flex-grow: 1;
    flex-basis: 0;
	height: calc(100% - 44px);
}

.extend-body >>> p {
	color: #fff;
	font-size: 14px;
	line-height: 18px;
}

.extend-widget {
	display: flex;
	margin-top: 15px;
}

.extend-widget-button {
	width: 40px;
	height: 40px;
	border-radius: 4px;
	line-height: 40px;
	text-align: center;
	font-size: 20px;
	cursor: pointer;
	color: #fff;
	flex-shrink: 0;
	background-color: rgba(255, 255, 255, 0.25);
}

.extend-widget-button.is-disabled {
	pointer-events: none;
	opacity: 0.25;
}

.extend-widget-button:hover {
	background-color: rgba(255, 255, 255, 0.5);
}

.extend-widget-input {
	width: 156px;
	padding: 0px 10px;
}

.extend-widget-input input {
	width: 136px;
	text-align: center;
	line-height: 40px;
	font-size: 32px;
	color: #fff;
}

.extend-widget-input input::placeholder {
	color: rgba(255, 255, 255, 0.5);
}

.extend-widget-confirm {
	width: 100%;
	margin-top: 15px;
}

.extend-metrics {
	display: grid;
	grid-gap: 5px;
	padding: 10px;
	grid-template-columns: 90px 1fr;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.extend-metrics-label,
.extend-metrics-value {
	color: #fff;
	line-height: 20px;
	font-size: 14px;
}

.extend-metrics-label {
	font-weight: 500;
}

.extend-metrics-value span {
	display: inline;
	padding: 2px 4px;
	border-radius: 4px;
}

.extend-metrics-value[data-value="0"] span {
	background-color: #d34545;
}

.extend-metrics-value[data-value="1"] span {
	background-color: #21ca21;
}

</style>