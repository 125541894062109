import Cookies from 'js-cookie'
import script from '@/assets/onboarding.json'

export default {

	namespaced: true,

	state: {

		active: false,
		taken: false,
		script: script

	},

	getters: {

		active: function(state) {

			return state.active

		},

		script: function(state) {

			return state.script

		},

		taken: function(state) {

			return state.taken

		}

	},

	mutations: {

		active: function(state, value) {

			state.active = value

		},

		taken: function(state, value) {

			state.taken = value

			Cookies.set('onboarding.taken', value)

		}

	},

	actions: {

		init: function(context) {

			context.commit('taken', (Cookies.get('onboarding.taken') === undefined) ? false : Cookies.get('onboarding.taken'))

		}

	}

}