<template>

<div ref="text" class="highlight" :position="line.position" :style="position">

	<div class="highlight-caret" :style="caretPosition" />

	<div class="highlight-text" v-html="line.text" />
	
</div>

</template>

<script>

export default {

	props: ['line', 'element'],

	data: function() {

		return {
			position: {
				left: false,
				top: false,
				right: false,
				bottom: false
			},
			caretPosition: {
				left: false,
				right: false
			}
		}

	},

	computed: {

		highlight: function() {

			return (this.element) ? {
				key: this.$vnode.key,
				rect: this.element.getBoundingClientRect() 
			} : false

		},

		anchor: function() {

			return (this.element) ? this.element.getBoundingClientRect() : false

		}

	},

	watch: {

		highlight: {
			deep: true,
			handler: function(n) {

				this.$nextTick(function() {

					if (n) this.calculatePosition()

				}.bind(this))

			}
		}

	},

	methods: {

		calculatePosition: function() {

			if (this.anchor) {

				this.caretPosition.left = false
				this.caretPosition.right = false

				this.position.left = false
				this.position.bottom = false
				this.position.right = false
				this.position.top = false
				
				var width = this.$refs.text.getBoundingClientRect().width / 2

				if (this.line.position === 'top') {

					this.position.left = this.anchor.left + (this.anchor.width / 2)
					this.position.bottom = this.window.height - this.anchor.top

				} else if (this.line.position === 'left') {

					this.position.right = this.window.width - this.anchor.left
					this.position.top = this.anchor.top + (this.anchor.height / 2)

				} else if (this.line.position === 'right') {

					this.position.left = this.anchor.right
					this.position.top = this.anchor.top + (this.anchor.height / 2)

				}

				if (this.line.position === 'top' && this.position.left && this.position.left - width < 10) {
					
					this.position.left = 10 + width
					this.caretPosition.left = ((this.anchor.left - (this.position.left - width)) + (this.anchor.width / 2)) + 'px'

				} else if (this.position.left && this.position.left + width > this.window.width) {

					this.position.left = false
					this.position.right = 10 - width
					this.caretPosition.left = ((this.anchor.left - (this.window.width - (width * 2) - 10)) + (this.anchor.width / 2)) + 'px'

				} 

				if (this.position.right && this.position.right + (width * 2) > this.window.width) {

					this.position.left = 16

				}

				if (this.position.left) this.position.left = this.position.left + 'px'
				if (this.position.bottom) this.position.bottom = this.position.bottom + 'px'
				if (this.position.right) this.position.right = this.position.right + 'px'
				if (this.position.top) this.position.top = this.position.top + 'px'

			}

		}

	}

}

</script>

<style scoped>

.highlight {
	position: absolute;
	z-index: 10;
	background-color: #287ABD;
	color: #fff;
	padding: 20px;
	max-width: 320px;
	text-align: center;
	border-radius: 4px;
	font-size: 16px;
	line-height: 24px;
}

.highlight[position="top"] {
	transform: translate(-50%, -6px);
}

.highlight[position="left"] {
	transform: translate(-6px, -50%);
}

.highlight[position="right"] {
	transform: translate(6px, -50%);
}

.highlight-text >>> b {
	display: block;
}

.highlight-caret {
	position: absolute;
	border-style: solid;
	width: 0;
	height: 0;
}

.highlight[position="top"] .highlight-caret {
	border-width: 12px 12px 0 12px;
	border-color: #287ABD transparent transparent transparent;
	left: 50%;
	bottom: -12px;
	margin-left: -12px;
}

.highlight[position="left"] .highlight-caret {
	border-width: 12px 0 12px 12px;
	border-color: transparent transparent transparent #287ABD;
	top: 50%;
	right: -12px;
	margin-top: -12px;
}

.highlight[position="right"] .highlight-caret {
	border-width: 12px 12px 12px 0;
	border-color: transparent #287ABD transparent transparent ;
	top: 50%;
	left: -12px;
	margin-top: -12px;
}

</style>