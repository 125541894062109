<template>

<div class="playback">

	<div class="playback-timeline">

        <i class="fa fa-spinner fa-spin playback-timeline-load" v-if="isSeeking" />
		
		<div ref="timeline" class="playback-timeline-bar" v-on:mousemove="onTimelineMove" v-on:mouseenter="isOver= true" v-on:mouseleave="isOver = false">

			<div class="playback-timeline-bar-tooltip" v-tooltip="tooltip" :style="{left: tooltipLeft}" v-if="isOver" />

			<div class="playback-timeline-bar-item is-progress" :style="{width: progressWidth}" v-on:click="onTimelineClick"></div>
			<div class="playback-timeline-bar-item is-full" v-on:click="onTimelineClick"></div>

		</div>

	</div>

	<app-actions-icons class="playback-actions">

		<app-actions-icon :shortcut="['shift', 's']" icon="play" :disabled="isPlaying || isSeeking" name="Start playback" v-on:click="onPlayClick" />
		<app-actions-icon :shortcut="['shift', 'p']" icon="pause" :disabled="!isPlaying || isSeeking" name="Pause playback" v-on:click="onPauseClick" />

	</app-actions-icons>

</div>

</template>

<script>

export default {

    props: ['isPlaying', 'isSeeking', 'duration', 'position'],

	data: function() {

		return {
			isOver: false,
            tooltipLeft: 0
		}

	},

	computed: {

		tooltip: function() {

			var seconds = parseInt((this.duration / 100) * parseFloat(this.tooltipLeft))

			var minutes = Math.floor(seconds / 60)
			seconds = seconds % 60

			return {
				content: minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0'),
				show: true,
				autohide: false
			}

		},

		progressWidth: function() {

			var percent = (100 / this.duration) * this.position

			percent = percent > 100 ? 100 : percent

			return percent.toString() + '%'

		}

	},

	methods: {

		onTimelineMove: function(e) {

			var percent = (100 / this.$refs.timeline.getBoundingClientRect().width) * (e.x - this.$refs.timeline.getBoundingClientRect().left)

			this.tooltipLeft = percent.toString() + '%'

		},

		onTimelineClick: function(e) {

            if(this.isSeeking) return false

			var percent = (100 / this.$refs.timeline.getBoundingClientRect().width) * (e.x - this.$refs.timeline.getBoundingClientRect().left)

			var position = parseInt((this.duration / 100) * percent)

			this.$emit('skip', position)

		},

		onPlayClick: function() {

			this.$emit('play')

		},

		onPauseClick: function() {

			this.$emit('pause')

		}

	}

}

</script>

<style scoped>

.playback {
	display: flex;
}

.playback-timeline {
	background-color: #fff;
	padding: 12px 10px;
    width: calc(100vw - 550px);
	border-right-width: 0px;
	flex-grow: 1;
}

.playback-timeline-load {
    color: #287ABD;
    position: absolute;
    left: -20px;
    font-size: 20px;
    top: 0px;
    line-height: 47px;
}

.playback-timeline-bar {
	width: 100%;
	height: 22px;
}

.playback-timeline.no-skip {
	pointer-events: none;
}

.playback-timeline-bar-tooltip {
	width: 1px;
}

.playback-timeline-bar-item {
	position: absolute;
	left: 0px;
	top: 0px;
	bottom: 0px;
	border-radius: 4px;
	cursor: pointer;
}

.playback-timeline-bar-item.is-progress {
	z-index: 3;
	background-color: #287ABD;
}

.playback-timeline-bar-item.is-full {
	z-index: 1;
	width: 100%;
	background-color: #ddd;
}

.playback-actions {
	margin-top: 0px!important;
}

.playback-actions >>> .icon {
	border-radius: 0px;
	margin-right: 0px;
	border-top-width: 0px;
	border-right-width: 0px;
	border-bottom-width: 0px;
	flex-shrink: 0;
	line-height: 47px!important;
    height: 47px;
    width: 47px;
}

.playback-actions >>> .icon:nth-child(3) {
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	border-right-width: 1px;
}

</style>
