<template>

<div class="scene">

	<div class="scene-content">
		<com-early v-if="scene.type === 'early'" :scene="scene" :initTime="initTime" />
		<com-waiting v-if="scene.type === 'waiting'" :scene="scene" :initTime="initTime" />
		<com-meeting v-if="scene.type === 'meeting'" :scene="scene" :initTime="initTime" />
		<com-station v-if="scene.type === 'station'" :scene="scene" :initTime="initTime" />
		<com-finished v-if="scene.type === 'finished'" :scene="scene" :initTime="initTime" />
		<com-break v-if="scene.type === 'break'" :scene="scene" :initTime="initTime" />
		<com-login v-if="scene.type === 'login'" :scene="scene" :initTime="initTime" />
		<com-dashboard v-if="scene.type === 'dashboard'" :scene="scene" :initTime="initTime" />
		<com-brief v-if="scene.type === 'brief'" :scene="scene" :initTime="initTime" />
		<com-consults v-if="scene.type === 'consults'" :scene="scene" :initTime="initTime" />
	</div>
	
	<com-sidebar :sidebar="scene.sidebar" :initTime="initTime" />
	<com-panel :panel="scene.panel" :initTime="initTime" />

	<com-modal-flag v-if="scene.modal === 'flag'" :scene="scene" :initTime="initTime" />

	<com-foot />
	
</div>

</template>

<script>

import comModalFlag from './modal/Flag'
import comEarly from './scene/Early'
import comWaiting from './scene/Waiting'
import comStation from './scene/Station'
import comMeeting from './scene/Meeting'
import comFoot from './scene/Foot'
import comSidebar from './scene/Sidebar'
import comPanel from './scene/Panel'
import comFinished from './scene/Finished'
import comBreak from './scene/Break'
import comLogin from './scene/Login'
import comDashboard from './scene/Dashboard'
import comBrief from './scene/Brief'
import comConsults from './scene/Consults'

export default {

	props: ['scene', 'initTime'],

	components: {
		'com-modal-flag': comModalFlag,
		'com-early': comEarly,
		'com-waiting': comWaiting,
		'com-station': comStation,
		'com-meeting': comMeeting,
		'com-finished': comFinished,
		'com-break': comBreak,
		'com-foot': comFoot,
		'com-sidebar': comSidebar,
		'com-panel': comPanel,
		'com-login': comLogin,
		'com-dashboard': comDashboard,
		'com-brief': comBrief,
		'com-consults': comConsults
	}

}

</script>

<style scoped>

.scene {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 1;
}

.scene-content {
	position: absolute;
	left: 256px;
	top: 10px;
	right: 10px;
	bottom: 64px;
	user-select: none;
}

</style>