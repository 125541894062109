export default {

	copy: function(value) {

		return JSON.parse(JSON.stringify(value))

    },
    setCookie: function(name, value, days) {
        let expires = "";

        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    },
    getCookie: function(name) {
        const nameEQ = name + "=";
        const ca = document.cookie.split(';');

        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    },
    deleteCookie: function(name, path = '/') {
        let domain = window.location.hostname;
        let subdomains = domain.split('.').reverse();

        for (let i = 0; i < subdomains.length; i++) {
            let currentDomain = subdomains.slice(0, i + 1).reverse().join('.');
            if (currentDomain) {
                currentDomain = '.' + currentDomain;
            }

            document.cookie = name + '=; Expires=Thu, 01 Jan 1970 00:00:00 GMT; Domain=' + currentDomain + '; Path=' + path;
        }

        document.cookie = name + '=; Expires=Thu, 01 Jan 1970 00:00:00 GMT; Path=/';
    },
    fetchClusterDataSync: function () {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', '/api/pusher/clusters/data', false);  // `false` makes the request synchronous
        xhr.send(null);

        if (xhr.status === 200) {
            return JSON.parse(xhr.responseText);
        } else {
            throw new Error('Cannot fetch the pusher data ' + xhr.statusText);
        }
    },
    triggerFailover: function () {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', '/api/failover/trigger', false);
        xhr.send(null);

        if (xhr.status === 200) {
            return JSON.parse(xhr.responseText);
        } else {
            throw new Error('Cannot fetch the pusher data ' + xhr.statusText);
        }
    },
    findKeyByValue: function (data, value) {
        if (Array.isArray(data)) {
            // Find key by value in array
            for (const obj of data) {
                if (typeof obj === 'object' && obj !== null) {
                    for (const [k, v] of Object.entries(obj)) {
                        if (v === value) {
                            return k;
                        }
                    }
                }
            }
        } else if (typeof data === 'object' && data !== null) {
            // Find key by value in object
            for (const [k, v] of Object.entries(data)) {
                if (v === value) {
                    return k;
                }
            }
        }
        return null;
    },
    deletePusherFailoverCookies() {
        this.deleteCookie('pusher_reload')
        this.deleteCookie('pusher_reload_completed')
    },

    deleteExtraPusherFailoverCookies() {
        this.deleteCookie('pusher_cluster')
        this.deleteCookie('pusher_had_failover')
    }
}
