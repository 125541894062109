import Vue from 'vue'

import modAvailability from './user/availability'
import modSchedule from './user/schedule'

export default {
	
	namespaced: true,

	modules: {
		availability: modAvailability,
		schedule: modSchedule
	},

	state: {

		data: {}

	},

	getters: {

		get: function(state) {

			return state.data

		},

		availability: function(state, getters) {

			return getters['availability/get']

		},

		schedule: function(state, getters) {

			return getters['schedule/get']

		},

		firstname: function(state) {

			return state.data.firstname

		},

		lastname: function(state) {

			return state.data.lastname

		},

		email: function(state) {

			return state.data.email

		},

		type: function(state) {

			return state.data.type

		}

	},

	mutations: {

		set: function(state, data) {

			Vue.set(state, 'data', data)

		}

	},

	actions: {

		init: function(context, data) {

			context.commit('set', data)

		}

	}

}