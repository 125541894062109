<template>

<app-modal v-shortkey="['esc']" v-on:shortkey.native="onClose" v-if="is.active" :active="is.active">

	<template v-slot:head>
		<h1>Create ad hoc station</h1>
	</template>

	<p tabindex="0" ref="modal">If a {{ $language.role.candidate }} needs to re-take a station you can add it here and their schedule will automatically update. You must also select the relevant {{ $language.role.roleplayer }}, {{ $language.role.examiner }}, and {{ $language.role.layexaminer }} too.</p>

	<select class="select" v-model="input.station" :disabled="is.loading" v-if="stations.length">
		<option value="0">Select the station...</option>
		<option v-for="station in stations" :key="station.identity" :value="station.identity">{{ station.name }}<template v-if="station.type === $constants.station.type.backup"> (Backup)</template></option>
	</select>

	<select class="select" v-model="input.candidate" :disabled="is.loading" v-if="candidates.length">
		<option value="0">Select the {{ $language.role.candidate }}</option>
		<option v-for="candidate in candidates" :key="candidate.identity" :value="candidate.identity">{{ candidate.name }} ({{circuitName(candidate)}})</option>
	</select>

	<select v-for="roleplayerIndex in hasRoleplayer" :key="roleplayerIndex" class="select" v-model="input['roleplayer_' + roleplayerIndex]" :disabled="is.loading">
		<option value="0">Select {{ $language.role.roleplayer }} {{ roleplayerIndex }}</option>
		<option v-for="roleplayer in roleplayers" :key="roleplayer.identity" :value="roleplayer.identity">{{ roleplayer.name }} ({{circuitName(roleplayer)}})</option>
	</select>

	<select v-for="examinerIndex in hasExaminer" :key="examinerIndex" class="select" v-model="input['examiner_' + examinerIndex]" :disabled="is.loading">
		<option value="0">Select {{ $language.role.examiner }} {{ examinerIndex }}</option>
		<option v-for="examiner in examiners" :key="examiner.identity" :value="examiner.identity">{{ examiner.name }} ({{circuitName(examiner)}})</option>
	</select>

	<select v-for="layexaminerIndex in hasLayExaminer" :key="layexaminerIndex" class="select" v-model="input['layexaminer_' + layexaminerIndex]" :disabled="is.loading">
		<option value="0">Select {{ $language.role.layexaminer }} {{ layexaminerIndex }}</option>
		<option v-for="layexaminer in layexaminers" :key="layexaminer.identity" :value="layexaminer.identity">{{ layexaminer.name }} ({{circuitName(layexaminer)}})</option>
	</select>

	<select class="select" v-model="input.observer" :disabled="is.loading" v-if="observers.length">
		<option value="0">Select an optional {{ $language.role.observer }}...</option>
		<option v-for="observer in observers" :key="observer.identity" :value="observer.identity">{{ observer.name }}</option>
	</select>

	<p>Ensure the time you enter does not overlap with any other existing scheduled stations for this {{ $language.role.candidate }}, {{ $language.role.roleplayer }}, {{ $language.role.examiner }}, or {{ $language.role.layexaminer }}.</p>

	<input class="input" v-model="input.time" :disabled="is.loading" placeholder="HH:MM" />

	<template v-slot:buttons>
		<app-button :loading="is.loading" :disabled="!is.valid" v-on:click="onSubmitClick">Create</app-button>
		<app-button :disabled="is.loading" v-on:click="onClose">Cancel</app-button>
	</template>

</app-modal>

</template>

<script>

import Modal from '@/mixin/modal.js'

export default {

	mixins: [Modal],

	data: function() {

		return {

			name: 'adhoc',

			input: {
				approve: 0,
				roleplayer_1: 0,
				roleplayer_2: 0,
				roleplayer_3: 0,
				roleplayer_4: 0,
				observer: 0,
				examiner_1: 0,
				examiner_2: 0,
				examiner_3: 0,
				examiner_4: 0,
				layexaminer_1: 0,
				layexaminer_2: 0,
				layexaminer_3: 0,
				layexaminer_4: 0,
				candidate: 0,
				station: 0,
				time: ''
			}

		}

	},

	computed: {

		isBackup: function() {

			return (this.input.station) ? this.$store.getters['stations/associated'][this.input.station].type === this.$constants.station.type.backup : false

		},

		hasExaminer: function() {

			return (this.input.station) ? this.$store.getters['stations/associated'][this.input.station].examiner : false

		},

		hasRoleplayer: function() {

			return (this.input.station) ? this.$store.getters['stations/associated'][this.input.station].roleplayer : false

		},

		hasLayExaminer: function() {

			return (this.input.station) ? this.$store.getters['stations/associated'][this.input.station].layexaminer : false

		},

		candidates: function() {

			return this.$_.sortBy(this.$_.filter(this.$store.getters['people'], function(person) {

				return person.role === this.$constants.role.candidate && !person.empty

			}.bind(this)), 'name')

		},

		roleplayers: function() {

			return this.$_.sortBy(this.$_.filter(this.$store.getters['people'], function(person) {

				return person.role === this.$constants.role.roleplayer

			}.bind(this)), 'name')

		},

		examiners: function() {

			return this.$_.sortBy(this.$_.filter(this.$store.getters['people'], function(person) {

				return person.role === this.$constants.role.examiner

			}.bind(this)), 'name')

		},

		layexaminers: function() {

			return this.$_.sortBy(this.$_.filter(this.$store.getters['people'], function(person) {

				return person.role === this.$constants.role.layexaminer

			}.bind(this)), 'name')

		},

		observers: function() {

			return this.$_.sortBy(this.$_.filter(this.$store.getters['people'], function(person) {

				return person.role === this.$constants.role.observer

			}.bind(this)), 'name')

		},

		stations: function() {

			return this.$store.getters['stations']

		}

	},

	methods: {

		reset: function() {

			this.input.approve = this.params.approve || 0
			this.input.candidate = this.params.candidate || 0
			this.input.layexaminer_1 = 0
			this.input.layexaminer_2 = 0
			this.input.layexaminer_3 = 0
			this.input.layexaminer_4 = 0
			this.input.examiner_1 = 0
			this.input.examiner_2 = 0
			this.input.examiner_3 = 0
			this.input.examiner_4 = 0
			this.input.roleplayer_1 = 0
			this.input.roleplayer_2 = 0
			this.input.roleplayer_3 = 0
			this.input.roleplayer_4 = 0
			this.input.observer = 0
			this.input.station = this.params.station || 0
			this.input.time = ''

			this.$nextTick(function() {

				this.$refs.modal.focus()

			}.bind(this))

		},

		circuitName: function(person) {

			return this.$store.getters['circuits/associated'][person.circuit].name

		},

		onInputChange: function(n) {

			this.is.valid = n.candidate && n.station && n.time

		},

		onSubmitClick: function() {

			this.is.loading = true

			this.$api.request('adhoc/create', this.input).then(function() {

				this.onClose()
				
				if (!this.input.approve) this.$pubsub.$emit('observer.section', this.$constants.observer.schedule)

			}.bind(this), function() {

				this.is.loading = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.select {
	border: 2px solid #ddd;
	border-radius: 4px;
	font-size: 14px;
	line-height: 40px;
	padding: 3px 10px;
	text-align: left;
	background-color: #fff;
	margin-bottom: 20px;
	width: 100%;
}

.select:focus {
    border: 2px solid #4285f4;
}

.input {
	border: 2px solid #ddd;
	border-radius: 4px;
	font-size: 14px;
	line-height: 40px;
	padding: 3px 10px;
	text-align: left;
	background-color: #fff;
	margin-bottom: 20px;
	width: 100%;
	height: 40px;
}

.input:focus {
    border: 2px solid #4285f4;
}

p {
	font-size: 14px;
	line-height: 20px;
	font-weight: 300;
	margin-bottom: 10px;
}

</style>
