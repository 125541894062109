<template>

<app-modal v-shortkey="['esc']" v-on:shortkey.native="onClose" v-if="is.active" :active="is.active">

	<template v-slot:head>

		<h1 v-if="isPaused">Give update</h1>
		<h1 v-if="!isPaused">Make announcement</h1>

	</template>

	<p v-if="isPaused">Keep the users updated on the current situation in regards to the pausing of the event.</p>
	<p v-if="!isPaused">Make an announcement to one or more users that will appear on their screen but not interrupt or pause their current activity.</p>

	<textarea ref="input" v-on:contextmenu="$allowContext" v-model="input.message" :disabled="is.loading" placeholder="Enter a message..." class="textarea"></textarea>

	<template v-if="!isPaused">

	<app-toggle class="announcement-toggle" :default="$constants.observer.announcement.all" text="Who will be receiving this announcement" :options="typeOptions" v-on:change="onTypeChange" />

	<template v-if="input.type === $constants.observer.announcement.role">

		<app-toggle class="announcement-role" v-for="(role, index) in roleOptions" :text="role" :key="index" :default="2" :options="roleOptionsToggle" v-on:change="onRoleChange(index, $event)" />

	</template>

	<app-directory v-if="input.type === $constants.observer.announcement.limited" :multiple="true" v-on:change="onDirectoryChange" />

	</template>

	<template v-slot:buttons>

		<app-button :loading="is.loading" :disabled="!is.valid" v-on:click="onSubmitClick">Confirm</app-button>

		<app-button :disabled="is.loading" theme="white" v-on:click="onClose">Cancel</app-button>

	</template>

</app-modal>

</template>

<script>

import Modal from '@/mixin/modal.js'

export default {

	mixins: [Modal],

	data: function() {

		return {

			name: 'announce',

			input: {
				message: '',
				recipients: [],
				type: 2,
				roles: []
			},

			typeOptions: {
				2: 'Everyone',
				4: 'Role(s)',
				3: 'User(s)'
			},

			roleOptions: {
				1: 'Candidates',
				2: 'Roleplayers',
				3: 'Examiners',
				5: 'Layexaminers',
				4: 'Observers',
				6: 'Chaperones'
			},

			roleOptionsToggle: {
				1: 'Yes',
				2: 'No'
			}

		}

	},

	methods: {

		reset: function() {

			this.is.loading = false 
			this.input.type = 2
			this.input.recipients = []
			this.input.message = ''
			this.input.roles = []

			this.$nextTick(function() {

				this.$refs.input.focus()

			}.bind(this))

		},

		onInputChange: function() {

			this.is.valid = this.input.message && (this.isPaused || this.input.type === this.$constants.observer.announcement.all || (this.input.type === this.$constants.observer.announcement.role && this.input.roles.length) || this.input.recipients.length)

		},

		onDirectoryChange: function(recipients) {

			this.input.recipients = recipients

		},

		onTypeChange: function(value) {

			this.input.type = value

		},

		onRoleChange: function(role, value) {

			if (value === 1) {

				this.input.roles.push(parseInt(role))

			} else {

				this.input.roles.splice(this.input.roles.indexOf(parseInt(role)), 1)

			}

		},

		onSubmitClick: function() {

			this.is.loading = true

			var action = (this.isPaused) ? 'pause/update' : 'observer/announcement'

			var params = {
				message: this.input.message
			}

			if (!this.isPaused) {

				params.type = this.input.type
				params.roles = this.input.roles
				params.recipients = this.input.recipients

			}

			this.$api.request(action, params).then(function() {

				this.onClose()

			}.bind(this), function() {

				this.is.loading = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.textarea {
	border: 2px solid #ddd;
	border-radius: 4px;
	font-size: 14px;
	line-height: 20px;
	padding: 3px 10px;
	text-align: left;
	background-color: #fff;
	margin-bottom: 20px;
	width: 100%;
	height: 66px;
	resize: none;
}

.textarea:focus {
    border: 2px solid #4285f4;
}

.announcement-toggle {
	margin-bottom: 2px;
}

.announcement-role {
	margin-bottom: 2px;
}

p {
	font-size: 14px;
	line-height: 20px;
	font-weight: 300;
	margin-bottom: 10px;
}

</style>
