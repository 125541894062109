<template>

    <div class="waiting" onboarding="early">
    
        <div class="waiting-icon fa fa-clock"></div>
    
        <div class="waiting-countdown">Begins in {{ hours }} hour<template v-if="hours > 1">s</template></div>
    
        <div class="waiting-test">
    
            Why not check your position and volume?<br/>It will help make sure others see and hear you clearly.
    
            <app-button>Ok, let's check!</app-button>
    
        </div>
        
    </div>
    
    </template>
    
    <script>
    
    export default {

        props: ['scene', 'initTime'],
    
        computed: {
    
            hours: function() {
    
                return Math.round(this.scene.setup.duration / 3600)
    
            }
    
        }	
    
    }
    
    </script>
    
    <style scoped>
    
    .waiting {
        height: 100%;
        display: flex;
        flex-direction: column;
        color: #333;
        align-items: center;
        justify-content: center;
        border: 1px solid #ddd;
        border-radius: 4px;
        background-color: #fff;
        overflow: hidden;
        box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
    }
    
    .waiting-icon {
        font-size: 64px;
        margin-bottom: 20px;
        color: #287ABD;
    }
    
    .waiting-text {
        font-size: 24px;
        font-weight: 300;
        text-align: center;
    }
    
    .waiting-text small {
        font-size: 14px;
        margin-top: 4px;
        display: block;
    }
    
    .waiting-countdown {
        font-size: 32px;
        font-weight: 400;
    }
    
    .waiting-test {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        font-size: 24px;
        line-height: 28px;
        font-weight: 300;
        text-align: center;
        align-items: center;
    }
    
    .waiting-test >>> .button {
        margin-top: 20px;
    }
    
    </style>
    