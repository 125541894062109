<template>
	
<div class="toggle">

	<div class="toggle-text">{{ text }}</div>
	
	<div class="toggle-options">

		<app-button :small="true" class="toggle-options-item" :theme="(isSelected(index)) ? 'blue' : 'white'" v-for="(option, index) in options" :key="index" v-on:click="onOptionClick(index)">

			{{ option }}

		</app-button>

	</div>

</div>

</template>

<script>

export default {

	props: ['default', 'text', 'options'],

	data: function() {

		return {
			selected: false
		}

	},
	
	created: function() {

		this.selected = this.default

	},

	methods: {

		isSelected: function(option) {

			return this.selected === parseInt(option)

		},

		onOptionClick: function(option) {

			this.selected = parseInt(option)
			this.$emit('change', this.selected)

		}

	}
	
}

</script>

<style scoped>

.toggle {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.toggle-options >>> .button {
	margin-left: 4px;
}

</style>