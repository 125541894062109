<template>
    
<div tabindex="0" class="sheet" ref="sheet" :class="{'is-incomplete': !isValid, 'is-complete': isValid, 'is-unsaved': isChanged && isValid && isRoamingExaminer, 'is-saved': isSaved && !isChanged}">

    <div class="sheet-head">

        <div class="sheet-head-title">Mark Sheet</div>

        <div class="sheet-head-status">
             <div class="sheet-head-status-item is-completed" v-if="!isValid && !$isEventMarking">{{ required.total - required.answered }} left</div>
             <div class="sheet-head-status-item is-completed" v-if="isValid && (!isChanged || !isRoamingExaminer)"><i class="fa fa-check"></i></div>
             <div class="sheet-head-status-item is-unsaved" v-if="isValid && isChanged && isRoamingExaminer"><i class="fa fa-exclamation"></i></div>
        </div>

    </div>

    <div class="sheet-context is-dark" v-if="isRoamingExaminer">

        <div>{{ examinerReference }}</div>

    </div>

    <div class="sheet-context">

        <div>{{ date | formatDate('HH:mm DD/MM') }}</div>
        <div>{{ $language.role.candidate }} No. {{ candidateReference }}</div>

    </div>

    <app-scroll ref="scroll" class="sheet-body" v-on:scroll="onScroll">

		<template v-for="question in questions">

			<com-question v-on:expand="$emit('expand', identity + question.identity)" v-on:collapse="$emit('collapse')" :expanded="expanded === identity + question.identity" v-if="!expanded || expanded === identity + question.identity" :question="question" :value="sheet[question.identity]" :key="question.identity" v-on:change="onQuestionChange" />

		</template>

    </app-scroll>

    <div class="sheet-foot" v-if="!isRoamingExaminer">

        <app-button v-on:click="$emit('collapse')" v-if="expanded" theme="white" class="sheet-foot-button">Back</app-button>
        <app-button v-on:click="onSaveClick" v-if="!expanded" :disabled="!isValid || (!isChanged && isSaved)" :loading="is.saving" theme="white" class="sheet-foot-button">{{ buttonText }}</app-button>
        <app-button v-on:click="onResetClick" v-if="isSaved && isChanged && !expanded" theme="plain" class="sheet-foot-button">Reset changes</app-button>

    </div>

    <div class="sheet-foot" v-if="isRoamingExaminer">

        <app-button v-on:click="onPlaybackClick" theme="white" :loading="is.joining" class="playback-button">Watch consultation</app-button>

    </div>

</div>

</template>

<script>

import Vue from 'vue'

import comQuestion from './sheet/Question'

export default {

	props: ['candidate', 'examiner', 'identity', 'expanded', 'saved', 'session', 'validSave'],

    components: {
        'com-question': comQuestion
    },

    data: function() {

        return {
			timer: false,
            is: {
				joining: false,
				expanded: false,
                valid: false,
                saving: false
			},
			sheet: {},
			scrollPosition: 0,
			lastScrollPosition: 0
        }

    },

    created: function() {

		this.setupCandidate()

		this.$nextTick(function() {
				
			this.$refs.sheet.focus()

		}.bind(this))

    },

	beforeDestroy: function() {

		clearTimeout(this.timer)

	},

	watch: {

		session: {

			deep: true,

			handler: function() {

				this.setupCandidate()

			}

		},

		expanded: function(n) {

			if (n) {
				
				this.lastScrollPosition = this.scrollPosition

			} else {

				this.$nextTick(function() {
					
					this.$refs.scroll.scrollTo(this.lastScrollPosition)

				}.bind(this))

			}

		}

	},

    computed: {

        candidateReference: function() {

            return (this.candidate) ? this.$store.getters['people'][this.candidate].reference : ''

        },

        examinerReference: function() {

            return (this.examiner) ? this.$store.getters['people'][this.examiner].name : ''

        },

		scheduleName: function() {

			return this.markingSchedule.name

		},

		markingSchedule: function() {

			if (this.isActive && this.isRoamingExaminer) {

				return this.isActive 

			} else if (this.$store.getters['schedule'].length === 0 && !this.isRoamingExaminer) {

				return this.$store.getters['marking/candidates'][this.identity]

			} else {

				return (this.isRoamingExaminer) ? this.$store.getters['overview/associated'][this.identity] : this.$store.getters['schedule/associated'][this.identity]

			}

		},

		isChanged: function() {

			return !this.$_.isEqual(this.saved, this.session)

		},

		isSaved: function() {

			return this.$validateSheet(this.questions, this.saved)

		},

		isValid: function() {

			return this.$validateSheet(this.questions, this.session)

		},

		answered: function() {

			var answered = []

			this.$_.each(this.sheet, function(mark, identity) {

				if (mark || mark === 0) {

					answered.push(identity)

				}

			}.bind(this))

			return answered

		},

        buttonText: function() {

			if (this.isChanged) {

				return (this.isSaved) ? 'Save changes' : 'Save' 

			} else {

				return (this.isSaved) ? 'Saved' : 'Save' 

			}

        },

        questions: function() {

            return this.$store.getters['marking/questions'][this.identity]

        },

        total: function() {

            return this.questions.length

		},
		
		required: function() {

			var required = {
				total: 0,
				answered: 0
			}

			this.$_.each(this.questions, function(question) {

				if (question.required) {

					required.total++

					if (this.$_.contains(this.answered, question.identity)) {

						required.answered++

					}

				}

			}.bind(this))

			return required 

		},

        date: function() {

            return this.markingSchedule.start

        }

    },

    methods: {

		onPlaybackClick: function() {

			this.is.joining = true

			this.$store.commit('settings/video', false)
			this.$store.commit('settings/audio', false)

			this.$api.request('playback/start', {
				identity: this.identity
			}).then(function(json) {

				this.$store.commit('schedule/add', this.$util.copy(json.schedule))
				
				this.is.joining = false

			}.bind(this), function() {

				this.is.joining = false

			}.bind(this))

		},

		onScroll: function(value) {

			this.scrollPosition = value

		},

		autosave: function() {

			if (this.isRoamingExaminer) return false

			clearTimeout(this.timer)

			this.$api.request('marking/save', {
				identity: this.identity,
				marks: this.sheet,
				autosave: true
			})

		},

		setupCandidate: function() {

			this.is.saving = false

			this.sheet = (this.session) ? JSON.parse(JSON.stringify(this.session)) : {}

		},

		onResetClick: function() {

			if (this.isRoamingExaminer) return false

			clearTimeout(this.timer)

			this.sheet = (this.saved) ? JSON.parse(JSON.stringify(this.saved)) : {}

			this.$emit('change', {
				sheet: this.sheet,
				schedule: this.identity
			})

			this.autosave()

		},

        onSaveClick: function() {

			if (this.isRoamingExaminer) return false

			clearTimeout(this.timer)

            this.is.saving = true

            this.$api.request('marking/save', {
                identity: this.identity,
				marks: this.sheet,
				autosave: false
            }).then(function() {

				this.is.saving = false
				
				this.$emit('saved', {
					sheet: JSON.parse(JSON.stringify(this.sheet)),
					schedule: this.identity
				})
				
            }.bind(this), function() {

                this.is.saving = false

            }.bind(this))

        },

        onQuestionChange: function(e) {

			if (this.isRoamingExaminer) return false

			if (e.value !== this.sheet[e.identity]) {

				clearTimeout(this.timer)

				Vue.set(this.sheet, e.identity, e.value)
				
				this.$emit('change', {
					sheet: JSON.parse(JSON.stringify(this.sheet)),
					schedule: this.identity
				})

				this.timer = this.$_.delay(this.autosave.bind(this), 5000)
				
			}

        }

    }

}

</script>

<style scoped>

.sheet {
	width: 100%;
	height: 100%;
	display: flex;
    background-color: #287ABD;
	transition: background-color 100ms linear;
    flex-direction: column;
}

.sheet.is-unsaved {
	background-color: #ce9921;
}

.sheet.is-saved {
    background-color: #21874A;
}

.sheet-head {
    color: #fff;
    font-size: 24px;
    font-weight: 300;
    flex-shrink: 0;
	padding: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row;
    align-items: center;
}

.sheet-head-title {
    flex-grow: 1;
}

.sheet-head-status {
    flex-shrink: 0px;
    display: flex;
    flex-direction: row;
}

.sheet-head-status-item {
    background-color: #fff;
    color: #287ABD;
    font-size: 14px;
    font-weight: 500;
    height: 24px;
	white-space: nowrap;
    line-height: 25px;
	min-width: 24px;
	padding: 0px 5px;
    text-align: center;
    transition: all 100ms linear;
}

.sheet-head-status-item.is-completed {
    border-radius: 4px;
}

.sheet.is-incomplete .sheet-head-status-item.is-completed {
    color: #cc3939;
}

.sheet.is-complete .sheet-head-status-item.is-completed {
    color: #27a258;
}

.sheet.is-complete .sheet-head-status-item.is-unsaved {
    color: #ce9921;
}

.sheet-context {
    padding: 10px;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    color: #fff;
    font-weight: 400;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    justify-content: space-between;
}

.sheet-context.is-dark {
    border: 0px;
	background-color: rgba(0, 0, 0, 0.1);
}

.sheet-body {
    flex-grow: 1;
    flex-basis: 0;
}

.sheet-foot {
	padding: 10px;
    flex-shrink: 0;
}

.sheet-foot-button {
    width: 100%;
}

.sheet-foot-button.theme-plain {
	color: #fff!important;
	margin-top: 4px;
}

.sheet.is-saved .sheet-foot-button {
    color: #27a258;
}

.sheet-foot >>> .button:focus {
	box-shadow: rgba(255, 255, 255, 0.5) 0 0 0 1px inset, rgb(255 255 255 / 50%) 0 2px 2px 0, rgba(0, 0, 0, .1) 0 4px 4px 0, rgb(255 255 255 / 50%) 0 0 0 4px;
}

.playback-button {
	width: 100%;
}

</style>