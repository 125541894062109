<template>

<div v-shortkey="['esc']" v-on:shortkey="onCloseClick" tabindex="0" ref="panel" class="announcements" v-if="is.active" :class="{'is-active': is.active}">

	<div class="announcements-head">
		
		Announcements

		<button class="announcements-close" v-on:click="onCloseClick"><i class="fa fa-remove"></i></button>
		
	</div>

	<app-scroll class="announcements-messages-wrapper">

		<div v-for="(announcement, index) in messages" :key="index" class="announcements-messages-item">

			<p>{{ announcement.text }}</p>

			<small>{{ announcement.time | fromNow() }} &middot; {{ announcement.time | formatDate('h:mma') }}</small>

		</div>

	</app-scroll>

</div>

</template>

<script>

export default {

	data: function() {

		return {
			is: {
				active: false
			}
		}

	},

	watch: {

		messages: function() {

			this.is.active = true

		}

	},

	created: function() {

		this.$pubsub.$on('panel.announcements', this.onOpen.bind(this))

	},

	beforeDestroy: function() {

		this.$pubsub.$off('panel.announcements', this.onOpen.bind(this))

	},

	computed: {

		chats: function() {

			return this.$store.getters['chats']

		},

		messages: function() {

			return this.$_.sortBy(this.$store.getters['announcements'], 'time').reverse()

		}

	},

	methods: {

		onOpen: function() {

			this.is.active = !this.is.active

			this.$nextTick(function() {

				if (this.is.active) this.$refs.panel.focus()

			}.bind(this))

		},

		onCloseClick: function() {

			this.is.active = false

		}

	}

}

</script>

<style scoped>

.announcements {
	position: fixed;
	left: -256px; 
	top: 0px;
	height: calc(100% - 54px);
	width: 256px;
	display: flex;
	flex-direction: column;
	background-color: #FBB516;
	justify-content: flex-start;
	z-index: 10;
	transition: left 300ms ease-in-out;
	border-bottom: 1px solid #fff;
}

.announcements.is-active {
	left: 0px;
}

.announcements-head {
	flex-shrink: 0;
	padding: 10px;
	font-size: 24px;
	color: #000;
	font-weight: 300;
}

.announcements-messages-wrapper {
	flex-grow: 1;
}

.announcements-messages.is-empty:before {
	content: 'There are no messages.';
	font-size: 14px;
	color: #000;
	font-weight: 400;
	width: calc(100% - 20px);
	padding: 10px;
	position: absolute;
	text-align: center;
	top: 50%;
	transform: translateY(-50%);
}

.announcements-messages-item {
	padding: 10px;
}

.announcements-messages-item p {
	font-size: 16px;
	line-height: 20px;
	font-weight: 300;
	color: #000;
}

.announcements-messages-item p b {
	font-weight: 500;
	font-size: 16px;
	line-height: 14px;
	margin-bottom: 2px;
	display: block;
}

.announcements-messages-item small {
	font-size: 12px;
	font-weight: 300;
	margin-top: 6px;
	text-align: right;
	display: block;
	color: #000;
}

.announcements-messages-item:nth-child(even) {
	background-color: rgba(0, 0, 0, 0.1);
}

.announcements-close {
	position: absolute;
	right: 4px;
	height: 40px;
	width: 40px;
	top: 4px;
	line-height: 40px;
	color: #00;
	font-size: 24px;
	text-align: center;
	cursor: pointer;
}

.announcements-close:focus {
	border-radius: 50%;
	box-shadow: rgba(255, 255, 255, 0.5) 0 0 0 1px inset, rgb(255 255 255 / 50%) 0 4px 4px 0, rgba(0, 0, 0, .1) 0 2px 2px 0, rgb(255 255 255 / 50%) 0 0 0 4px;
}

</style>