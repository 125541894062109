<template>

<div class="welcome">

	<div class="welcome-logo prp-loading"></div>

	<div class="welcome-title">Hello, {{ check.name }}!</div>

	<div class="welcome-text">{{ template.text.intro }}</div>

	<app-button v-on:click="onProceedClick" class="welcome-button" :loading="is.loading">Start <i class="fa fa-chevron-right"></i></app-button>

</div>

</template>

<script>

export default {

	props: ['template', 'check'],

	data: function() {

		return {
			is: {
				loading: false
			}
		}

	},

	methods: {

		onProceedClick: function() {

			this.is.loading = true

			this.$api.request('check/start', {
				check: this.check.identity
			}).then(function(json) {

				this.$emit('proceed', json.attempt)

			}.bind(this))


		}

	}

}

</script>

<style scoped>

.welcome {
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 100%;
	max-width: 480px;
	align-items: center;
	background-color: #fff;
	padding: 40px 20px;
	border: 1px solid #ddd;
	border-radius: 4px;
}

.welcome-title {
	font-size: 24px;
	font-weight: 400;
	text-align: center;
	color: #333;
}

.welcome-text {
	font-size: 16px;
	line-height: 20px;
	font-weight: 400;
	text-align: center;
	color: #333;
	margin: 40px 0px;
}

.welcome-logo {
	animation: none;
	margin-bottom: 20px;
}

.welcome-button  >>> .fa {
	margin-left: 10px;
}

</style>