<template>

<div class="panel">

	<com-chat />
	<com-announcements />
    <com-marking v-if="$canSeeMarksheet && !isActive" />
    <com-users v-if="$isWebinar && isLead" />
    <com-extend v-if="canExtend" />

	<com-connections-location v-if="canConnections" />
	<com-connections-check v-if="canConnections" />
	<com-connections-log v-if="canConnections" />
	<com-connections-station v-if="canConnections" />

</div>

</template>

<script>

import comChat from './panel/Chat';
import comMarking from './panel/Marking'
import comUsers from './panel/Users'
import comAnnouncements from './panel/Announcements'
import comExtend from './panel/Extend'
import comConnectionsLocation from './panel/connections/Location'
import comConnectionsCheck from './panel/connections/Check'
import comConnectionsLog from './panel/connections/Log'
import comConnectionsStation from './panel/connections/Station'

export default {

	components: {
		'com-chat': comChat,
		'com-marking': comMarking,
		'com-users': comUsers,
		'com-announcements': comAnnouncements,
		'com-extend': comExtend,
		'com-connections-location': comConnectionsLocation,
		'com-connections-check': comConnectionsCheck,
		'com-connections-log': comConnectionsLog,
		'com-connections-station': comConnectionsStation
	},

	computed: {

		canConnections: function() {

			return this.hasPermission(this.$constants.observer.permission.connections)

		},

		canExtend: function() {

			return this.isActive && this.hasPermission(this.$constants.observer.permission.extend)

		}

	}

}

</script>