<template>

<com-card :onboarding="'participant.' + person.id" class="participant" :miniview="miniview" :allowFullsceen="allowFullsceen" :expanded="expanded" v-on:expand="$emit('expand')" v-on:collapse="$emit('collapse')" :class="{'is-online': online}" :type="$constants.card.type.participant" :lead="leading" :circuit="circuit" :title="title" :subtitle="subtitle" :role="role" :plain="plain" :hideMore="isCandidate && isStation && !local" :focus="speaking">

	<com-stream v-if="(!showAsPinned || !local) && !offcamera" :onboardingPrefix="'participant.' + person.id" :video="person.video" :local="local" :muted="person.muted" :speaking="person.speaking" :quality="person.quality" />

	<div class="participant-status" v-if="(!plain && !miniview) || offcamera">

		<template v-if="!showAsPinned && !showAsAlone && !showAsObserving && !showAsHighlighted">

			<div class="participant-status-label" v-if="!online && !local && !offcamera">Offline</div>
			<i class="participant-status-icon fa fa-user-slash" v-if="!online && !local && !offcamera"></i>

			<i class="participant-status-icon fa fa-user" v-if="online || offcamera"></i>

		</template> 

		<i class="participant-status-icon fa fa-thumbtack" v-if="(showAsPinned && local) || showAsAlone"></i>
		<i class="participant-status-icon fa fa-video-slash" v-if="showAsObserving && !showAsHighlighted"></i>
		<i class="participant-status-icon is-blue fa fa-hand-paper" v-if="showAsHighlighted && local"></i>

	</div>

</com-card>

</template>

<script>

import comCard from './Card'
import comStream from './Stream'

export default {

	props: ['person', 'speaking', 'forced', 'miniview', 'allowFullsceen', 'local', 'plain', 'expanded'],

	components: {

		'com-card': comCard,
		'com-stream': comStream

	},

	computed: {

		offcamera: function() {

			return this.person.offcamera

		},

		showAsObserving: function() {

			return this.person.observing

		},

		showAsHighlighted: function() {
		
			return this.person.highlighted

		},

		showAsPinned: function() {

			return this.person.pinned

		},

		showAsAlone: function() {
			
			return this.person.alone

		},

		online: function() {

			return this.person.online

		},

		leading: function() {

			return this.person.lead

		},

		audioOnly: function() {

			return this.person.audioOnly

		},

		circuit: function() {

			return this.person.circuit

		},

		title: function() {

			return this.person.name || 'Unnamed'

		},

		subtitle: function() {

			return this.person.label

		},

		role: function() {

			if (this.person.role === 'roleplayer') return this.$language.role.roleplayer
			if (this.person.role === 'examiner') return this.$language.role.examiner
			if (this.person.role === 'layexaminer') return this.$language.role.layexaminer
			if (this.person.role === 'candidate') return this.$language.role.candidate
			if (this.person.role === 'observer') return this.$language.role.observer

			return ''

		}

	}

}

</script>

<style scoped>

.participant-status {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #eee;
}

.participant-status-label {
	position: absolute;
	right: 10px;
	top: 10px;
	font-size: 12px;
	color: #fff;
	letter-spacing: 1px;
	padding: 2px 4px;
	font-weight: 500;
	border-radius: 4px;
	margin-left: 4px;
	background-color: #c62323;
}

.participant.is-online .participant-status-label {
	background-color: green;
}

.participant-status-icon {
	font-size: 128px;
	color: #ddd;
}

.participant-status-icon.is-blue {
	color: #287ABD;
}

</style>