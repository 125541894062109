<template>

    <div onboarding="consults" class="playback">
    
        <template v-for="(chunk, index) in chunks">
    
            <div tabindex="0" class="playback-item" :onboarding="'consults.' + item.identity" v-for="item in chunk" :key="item.identity">
    
                <div class="playback-item-status" :data-status="item.status">
    
                    {{ textStatus(item) }}
    
                </div>
    
                <div class="playback-item-details">
    
                    <div class="playback-item-details-name">{{ item.text }}</div>
                    <div class="playback-item-details-date">{{ item.date }}</div>
    
                </div>
    
                <div class="playback-item-timeline">
    
                    <div class="playback-item-timeline-bar">
                        <div class="playback-item-timeline-bar-progress" :style="{width: stylePosition(item)}"></div>
                    </div>
    
                    <div class="playback-item-timeline-time">{{ textPosition(item) }}</div>
    
                </div>
    
                <div class="playback-item-actions">
    
                    <app-button :small="true" :loading="is.loading === item.identity">{{ textButton(item) }}<i class="fa fa-chevron-right"></i></app-button>
    
                </div>
    
            </div>
    
            <div class="playback-break" :key="index">
    
                <i class="fa fa-coffee"></i>
    
            </div>
    
        </template>
    
    </div>
    
    </template>
    
    <script>
    
    export default {

        props: ['scene'],
    
        data: function() {
    
            return {
                is: {
                    loading: false
                }
            }
    
        },
    
        computed: {
    
            chunks: function() {
    
                return this.$_.chunk(this.playback, 20)
    
            },
    
            playback: function() {
    
                return this.scene.setup.consults
    
            }
    
        },
    
        methods: {
    
            textStatus: function(item) {
    
                return this.$constants.marksheet.statusLabel[item.status]
    
            },
    
            stylePosition: function(item) {
    
                if (item.position) {
    
                    return ((100 / item.duration) * item.position).toString() + '%'
    
                } else if (item.views) {
    
                    return '100%'
    
                } else {
    
                    return '0%'
    
                }
    
            },
    
            textPosition: function(item) {
    
                if (item.position) {
    
                    var mins = Math.floor(item.position / 60)
    
                    return (mins) ? mins.toString() + ' minutes viewed' : '< 1 minute viewed'
    
                } else if (item.views) {
    
                    return 'Viewed ' + item.views.toString() + ' time' + ((item.views > 1) ? 's' : '')
    
                } else {
    
                    return 'Not viewed'
    
                }
    
            },
    
            textButton: function(item) {
    
                return (item.position) ? 'Resume' : 'Start'
    
            }
    
        }
    
    }
    
    </script>
    
    <style scoped>
    
    .playback {
        height: 100%;
        border: 1px solid #ddd;
        border-radius: 4px;
        overflow-y: auto;
        background-color: #fff;
        box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
        padding: 10px;
    }
    
    .playback-break:last-child {
        display: none;
    }
    
    .playback-break {
        height: 2px;
        background-color: #ccc;
        margin: 40px 0px 40px 0px;
    }
    
    .playback-break .fa {
        color: red;
        position: absolute;
        left: 50%;
        font-size: 24px;
        background-color: #fff;
        color: #1c6aa9;
        width: 96px;
        text-align: center;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    
    .playback-item {
        margin: 0px 10px 10px 0px;
        overflow: hidden;
        width: 100%;
        display: flex;
        flex-direction: row;
        border-radius: 4px;
    }
    
    .playback-item-status {
        color: #fff;
        padding: 10px;
        font-weight: 400;
        font-size: 16px;
        height: 54px;
        display: flex;
        align-items: center;
        width: 120px;
    }
    
    .playback-item-status[data-status="2"] {
        background-color: #27a258;
    }
    
    .playback-item-status[data-status="1"] {
        background-color: #1c6aa9;
        color: #5ea9e6;
    }
    
    .playback-item-status[data-status="3"] {
        background-color: #cc3939;
    }
    
    .playback-item-status[data-status="5"] {
        background-color: #ce9921;
    }
    
    .playback-item-status[data-status="4"] {
        background-color: #56aef5;
    }
    
    .playback-item-status[data-status="6"] {
        background-color: #6d38a1;
    }
    
    .playback-item-details {
        border: 1px solid #ddd;
        border-width: 1px 0px;
        padding: 10px;
        height: 54px;
        display: flex;
        flex-direction: column;
        justify-items: center;
        flex-grow: 1;
    }
    
    .playback-item:focus .playback-item-details {
        border-color: #287ABD;
    }
    
    .playback-item-details-name {
        font-size: 16px;
        font-weight: 300;
    }
    
    .playback-item-details-date {
        font-size: 12px;
        font-weight: 300;
        margin-top: 4px;
    }
    
    .playback-item-timeline {
        width: 240px;
        border: 1px solid #ddd;
        border-right-width: 0px;
        display: flex;
        padding: 0px 10px;
        flex-direction: column;
        justify-content: center;
    }
    
    .playback-item:focus .playback-item-timeline {
        border-color: #287ABD;
    }
    
    .playback-item-timeline-bar {
        width: 100%;
        height: 18px;
        border-radius: 4px;
        background-color: #ddd;
    }
    
    .playback-item-timeline-bar-progress {
        height: 18px;
        border-radius: 4px;
        background-image: linear-gradient(135deg, #287ABD 25%, #1a72ba 25%, #1a72ba 50%, #287ABD 50%, #287ABD 75%, #1a72ba 75%, #1a72ba 100%);
        background-size: 28.28px 28.28px;
    }
    
    .playback-item-timeline-time {
        font-size: 12px;
        font-weight: 300;
        margin-top: 3px;
        color: #666;
    }	
    
    .playback-item-actions {
        padding: 10px;
        border: 1px solid #ddd;
    }
    
    .playback-item:focus .playback-item-actions {
        border-color: #287ABD;
    }
    
    .playback-item-actions >>> .button {
        width: 120px;
        text-align: left;
        padding: 0px 10px;
    }
    
    .playback-item-actions >>> .button .fa {
        position: absolute;
        right: 10px;
        top: 8px;
    }
    
    </style>
    