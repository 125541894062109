<template>

<div class="modal">

	<com-incident />
	<com-chat />
	<com-logout />
	<com-flag />

	<com-adhoc v-if="isRoamingObserver" />
	<com-pause v-if="isRoamingObserver" />
	<com-announce v-if="isRoamingObserver" />
	<com-enforce-logout v-if="isRoamingObserver" />
	<com-enforce-refresh v-if="isRoamingObserver" />
	<com-reassign v-if="isRoamingObserver" />

</div>

</template>

<script>

import comIncident from './modal/Incident'
import comChat from './modal/Chat'
import comAdhoc from './modal/Adhoc'
import comLogout from './modal/Logout'
import comFlag from './modal/Flag'
import comPause from './modal/Pause'
import comAnnounce from './modal/Announce'
import comReassign from './modal/Reassign'
import comEnforceLogout from './modal/enforce/Logout'
import comEnforceRefresh from './modal/enforce/Refresh'

export default {

	components: {
		'com-incident': comIncident,
		'com-chat': comChat,
		'com-logout': comLogout,
		'com-pause': comPause,
		'com-adhoc': comAdhoc,
		'com-announce': comAnnounce,
		'com-flag': comFlag,
		'com-reassign': comReassign,
		'com-enforce-logout': comEnforceLogout,
		'com-enforce-refresh': comEnforceRefresh
	}

}

</script>