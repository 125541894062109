import _ from 'underscore'
import pusher from '@/service/pusher'

export default {

	namespaced: true,

	state: {

		circuits: [],
		current: false,

	},

	getters: {

		get: function(state) {

			return state.circuits

		},

		associated: function(state) {

			var associated = []

			_.each(state.circuits, function(circuit) {

				associated[circuit.identity] = circuit
					
			})

			return associated

		},

		current: function(state) {

			return state.current

		}

	},

	mutations: {

		current: function(state, value) {
		
			state.current = value

		},

		set: function(state, data) {

			state.circuits = data

		},

		add: function(state, data) {

			state.circuits.push(data)

		}

	},

	actions: {

		init: function(context, data) {

			if (data) {

				context.commit('set', data)

				_.each(data, function(circuit) {

					if (circuit.view && !context.getters['current']) context.commit('current', circuit.identity)

					if (context.rootGetters['session/is/roaming/observer']) {

						pusher.subscribe.circuit(circuit.identity)

					}

				})

			}

		}

	}

}