<template>

<div class="meeting" onboarding="meeting">

	<div class="meeting-room">

		<app-room-status v-if="scene.setup.status === 'connecting'" :type="$constants.status.loading" text="Connecting, please wait..." />

		<app-room-grid v-if="scene.setup.status === 'connected'" :pinsSpecial="true" :pinsCurrent="pinned" :pinsEnabled="pinned.length" :participantsCurrent="$_.keys(participants)" :cardsCurrent="$_.keys(cards)">

			<com-participant v-for="participant in participants" :expanded="scene.setup.expanded === participant.id" :key="participant.id" :person="participant" :speaking="participant.id === scene.setup.speaking" :local="participant.local" />

			<template v-for="card in cardsByType('file')">

				<com-file :onboarding="'card.' + card.id" :onboardingPrefix="'card.' + card.id" :key="card.id" :showNote="card.id === scene.setup.expanded || participants.length < 9" :expanded="card.id === scene.setup.expanded" :item="card" />

			</template>

			<template v-for="card in cardsByType('notepad')">

				<com-notepad :onboarding="'card.' + card.id" :onboardingPrefix="'card.' + card.id" :key="card.id" :canExpand="true" :item="card" :expanded="card.id === scene.setup.expanded" />

			</template>

		</app-room-grid>

	</div>
		
	<com-playback v-if="scene.setup.playback" :setup="scene.setup.playback" />

	<com-marksheet v-if="scene.setup.marksheet" :setup="scene.setup.marksheet" />

</div>

</template>

<script>

import comFile from './meeting/File'
import comParticipant from './meeting/Participant'
import comPlayback from './meeting/Playback'
import comMarksheet from './common/Marksheet'

export default {

	props: ['scene', 'initTime'],

	components: {
		'com-participant': comParticipant,
		'com-file': comFile,
		'com-marksheet': comMarksheet,
		'com-playback': comPlayback
	},

	computed: {

		pinned: function() {

			if (this.scene.setup.expanded) {

				return [this.scene.setup.expanded]

			} else {

				return this.scene.setup.pinned || []

			}

		},

		participants: function() {

			return this.scene.setup.participants

		},

		cards: function() {

			return this.scene.setup.cards

		}

	},

	methods: {

		cardsByType: function(type) {

            return this.$_.where(this.cards, {
                type: type
            })

		}

	}

}

</script>

<style scoped>

.meeting {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 1;
	display: flex;
	flex-direction: column;
}

.meeting-room {
	flex-shrink: 0;
	flex-grow: 1;
	min-height: 48px;
}

</style>