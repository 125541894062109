import constants from '@/constants'
import Vue from 'vue'

export default {

	namespaced: true,

	state: {

        data: {
			role: {
				examiner: 'Examiner'
			}
		}

	},

	getters: {

		get: function(state) {

			return state.data

        },

		role: function(state) {

			return function(id) {

				if (id === constants.role.candidate) return state.data.role.candidate
				if (id === constants.role.roleplayer) return state.data.role.roleplayer
				if (id === constants.role.examiner) return state.data.role.examiner
				if (id === constants.role.layexaminer) return state.data.role.layexaminer
				if (id === constants.role.observer) return state.data.role.observer

				return false

			}

		}

	},

	mutations: {

		set: function(state, data) {

			Vue.set(state, 'data', data)

		}

	},

	actions: {

		init: function(context, data) {

			context.commit('set', data)

		}

	}

}