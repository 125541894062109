import pusher from './pusher'
import store from './../store'
import constants from './../constants'

export default {

	init: function() {

		pusher.subscribe.monitor(store.getters['session/identity'])

	},

	log: function(event, params) {

		if (store.getters['session/mode'] === constants.mode.dashboard) return false

		if (store.getters['people/self'].role === constants.role.candidate) {

			pusher.emit.monitor(store.getters['session/identity'], event, params)

		}

	}

}