<template>

<div ref="wrapper" tabindex="0" class="test-wrapper">

	<div class="test">

		<div class="test-item is-video">

			<div class="test-item-about">

				<h1><i class="fa fa-video"></i> Position check</h1>

				<p>Use this tool to make sure you're well positioned and clearly seen. Try to position your face inside the dashed box.</p>

				<p>The tool will let you know if it thinks your face can't easily be seen, if you're too close or too far away, or if you're too far over to one side.</p>

				<div class="test-item-about-inputs" v-if="allVideo.length > 1">

					<div class="test-item-about-inputs-title">Select camera</div>

					<div class="test-item-about-inputs-options">

						<div class="test-item-about-inputs-options-item" v-for="input in allVideo" :key="input.deviceId">
							<div class="test-item-about-inputs-options-item-name">{{ input.label }}</div>
							<button class="test-item-about-inputs-options-item-check" :class="{'is-active': input.deviceId === currentVideo}" v-on:click="onVideoSelect(input.deviceId)"><i class="fa fa-check"></i></button>
						</div>

					</div>

				</div> 

			</div>

			<div class="test-item-content">

				<com-video />

			</div>

		</div>

		<div class="test-item is-audio">

			<div class="test-item-about">

				<h1><i class="fa fa-microphone"></i> Volume check</h1>

				<p>Use this tool to check your microphone volume by recording your voice and listening back.</p>
				
				<p>If too quiet or too loud you may consider adjusting the distance you are from the microphone or changing your device settings.</p>

				<div class="test-item-about-inputs" v-if="allAudio.length > 1">

					<div class="test-item-about-inputs-title">Select microphone</div>

					<div class="test-item-about-inputs-options">

						<div class="test-item-about-inputs-options-item" v-for="input in allAudio" :key="input.deviceId">
							<div class="test-item-about-inputs-options-item-name">{{ input.label }}</div>
							<button class="test-item-about-inputs-options-item-check" :class="{'is-active': input.deviceId === currentAudio}" v-on:click="onAudioSelect(input.deviceId)"><i class="fa fa-check"></i></button>
						</div>

					</div>

				</div> 

			</div>

			<div class="test-item-content">

				<com-audio />

			</div>

		</div>

	</div>

	<app-button class="test-close" v-on:click="onCloseClick">Proceed</app-button>

</div>

</template>

<script>

import comVideo from './../event/content/test/Video'
import comAudio from './../event/content/test/Audio'

export default {

	props: ['template', 'check'],

	components: {
		'com-video': comVideo,
		'com-audio': comAudio
	},

	computed: {

		allVideo: function() {

			return this.$store.getters['device/video/inputs']

		},

		allAudio: function() {

			return this.$store.getters['device/audio/inputs']

		},

		currentVideo: function() {

			return this.$store.getters['device/video/current']

		},

		currentAudio: function() {

			return this.$store.getters['device/audio/current']

		}

	},

	created: function() {

		this.$nextTick(function() {

			this.$refs.wrapper.focus()

		}.bind(this))

	},

	methods: {

		onCloseClick: function() {

			this.$emit('proceed')

		},

		onVideoSelect: function(id) {

			this.$store.commit('device/video/current', id)
			this.$store.dispatch('device/video')
		},

		onAudioSelect: function(id) {

			this.$store.commit('device/audio/current', id)
			this.$store.dispatch('device/audio')

		}

	}

}

</script>

<style scoped>

.test-wrapper {
	position: absolute;
	background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
	z-index: 150;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
}

.test-close {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: 10px;
	width: 240px;
	height: 44px!important;
}

.test {
	position: absolute;
	left: 10px;
	top: 10px;
	right: 10px;
	bottom: 64px;
	display: flex;
	flex-direction: column;
}

@media (max-height: 799px) {

	.test {
		flex-direction: row;
	}

}

.test-item {
	color: #333;
	border-radius: 4px;
	background-color: #fff;
	display: flex;
	flex-direction: row;
	border-radius: 4px;
	overflow: hidden;
}

@media (max-height: 799px) {

	.test-item {
		flex-direction: column;
		width: calc(50% - 5px);
	}

}

.test-item.is-video {
	flex-grow: 1;
	flex-basis: 0;
	border: 0px;
}

.test-item.is-audio {
	height: 250px;
	margin-top: 10px;
	border: 1px solid #ddd;
}

@media (max-height: 799px) {

	.test-item.is-audio {
		height: auto;
		margin-left: 10px;
		margin-top: 0px;
	}

}

.test-item-about {
	width: 300px;
	padding: 20px;
	border-right: 1px solid #ddd;
	overflow-y: auto;
}

@media (max-height: 799px) {

	.test-item-about {
		width: 100%;
		border-right: 0px;
	}

}

.test-item.is-video .test-item-about {
	border-radius: 4px 0px 0px 4px;
	border: 1px solid #ddd;
}

.test-item-about h1 {
	font-size: 24px;
	margin-bottom: 20px;
	position: relative;
	padding-left: 42px;
	color: #333;
}

.test-item-about h1 .fa {
	position: absolute;
	left: 0px;
	top: -6px;
	font-size: 16px;
	background-color: #287ABD;
	color: #fff;
	width: 32px;
	height: 32px;
	line-height: 32px;
	text-align: center;
	border-radius: 50%;
}

.test-item-about p {
	font-size: 16px;
	line-height: 20px;
	margin-bottom: 20px;
	color: #333;
}

.test-item-about-inputs {
	display: flex;
	flex-direction: column;
}

.test-item-about-inputs-title {
	font-size: 16px;
	font-weight: 500;
	color: #333;
	margin-bottom: 8px;
}

.test-item-about-inputs-options {
	display: flex;
	flex-direction: column;
}

.test-item-about-inputs-options-item {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.test-item-about-inputs-options-item-name {
	font-size: 16px;
	color: #333;
	padding: 10px 0px;
	flex-grow: 1;
	flex-basis: 0;
}

.test-item-about-inputs-options-item-check {
	width: 32px;
	height: 32px;
	border: 2px solid #ddd;
	border-radius: 4px;
	flex-shrink: 0;
	line-height: 28px;
	text-align: center;
	font-size: 16px;
	cursor: pointer;
	color: #eee;
}

.test-item-about-inputs-options-item-check.is-active {
	background-color: #287ABD;
	border-color: #287ABD;
	pointer-events: none;
	color: #fff;
}

.test-item-about-inputs-options-item-check:not(.is-active):focus {
    border: 2px solid #4285f4;
}

.test-item-about-inputs-options-item-check.is-active:focus {
	box-shadow: rgba(50, 50, 93, .1) 0 0 0 1px inset, rgba(50, 50, 93, .2) 0 6px 15px 0, rgba(0, 0, 0, .1) 0 2px 2px 0, rgba(50, 151, 211, .3) 0 0 0 4px;
}

.test-item-about-inputs-options-item-check:not(.is-active):hover {
	border-color: #999;
	color: #999;
}

.test-item-content {
	flex-grow: 1;
	flex-basis: 0;
}

.test-item.is-video .test-item-content {
	border-radius: 0px 4px 0px 0px;
	overflow: hidden;
}

@media (max-height: 799px) {

	.test-item.is-video .test-item-content {
		border-radius: 0px;
	}

}

</style>
