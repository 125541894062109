<template>

<app-page>

	<div class="init-prompt is-error" v-if="is.prompt.denied">

		<div class="init-prompt-icon"><i class="fa fa-exclamation-triangle"></i></div>

		<div class="init-promot-content">

			<div class="init-prompt-title" v-if="status.video == $constants.setup.status.blocked">Camera access required</div>
			<div class="init-prompt-title" v-if="status.audio == $constants.setup.status.blocked">Microphone access required</div>
			
			<div class="init-prompt-text">
				
				It appears the app has not been given permission to use your 
				<template v-if="status.video === $constants.setup.status.blocked">camera</template>
				<template v-if="status.audio === $constants.setup.status.blocked"><template v-if="status.video === $constants.setup.status.blocked"> and </template>microphone</template>. 
				To grant access you need to tell your browser to either ask or allow permission by clicking the lock next to the URL and changing the settings

			</div>

		</div>

	</div>

	<div class="init-prompt is-warning" v-if="is.prompt.permission">

		<div class="init-prompt-icon" v-if="is.requesting.video"><i class="fa fa-video"></i></div>
		<div class="init-prompt-icon" v-if="is.requesting.audio"><i class="fa fa-microphone"></i></div>
		<div class="init-prompt-icon" v-if="is.requesting.location"><i class="fa fa-map-marker"></i></div>

		<div class="init-promot-content">

			<div class="init-prompt-title" v-if="is.requesting.video">Allow camera</div>
			<div class="init-prompt-title" v-if="is.requesting.audio">Allow microphone</div>
			<div class="init-prompt-title" v-if="is.requesting.location">Allow geolocation</div>
			
			<div class="init-prompt-text">
				
				Your browser should now be asking you to allow access to your <template v-if="is.requesting.location">location</template><template v-if="is.requesting.video">camera</template><template v-if="is.requesting.audio">microphone</template> at the top left of the screen, please click the Allow button.

			</div>

		</div>

	</div>

	<div class="init-prompt is-error" v-if="is.prompt.connection">

		<div class="init-prompt-icon"><i class="fa fa-cloud-upload-alt"></i></div>

		<div class="init-promot-content">

			<div class="init-prompt-title">Can't connect</div>
			
			<div class="init-prompt-text">
				
				The app can't connect to the video chat server, it's possible your computer or network is blocking the connection. You could try refreshing and logging in again, if the problem persists try using a different wifi connection or momentarily turning off any VPNs or firewalls.

			</div>

		</div>

	</div>

	<div class="init-prompt is-warning" v-if="is.prompt.network">

		<div class="init-prompt-icon"><i class="fa fa-wifi"></i></div>

		<div class="init-prompt-content">

			<div class="init-prompt-title" v-if="networkStrength === 2">Weak quality</div>
			<div class="init-prompt-title" v-if="networkStrength !== 2">Poor quality</div>
			
			<div class="init-prompt-text">
				
				Your connection quality is <template v-if="networkStrength === 2">weak</template><template v-if="networkStrength !== 2">poor</template>. Make sure you're not currently downloading or streaming anything and try to sit nearer the router if you're on wifi.

			</div>

		</div>

	</div>

	<div class="init-prompt is-warning" v-if="is.prompt.onboarding">

		<div class="init-prompt-icon"><i class="fa fa-graduation-cap"></i></div>

		<div class="init-prompt-content">

			<div class="init-prompt-title">Walkthrough</div>
			
			<div class="init-prompt-text">
				
				Is this your first time using Osler Online? If so, why not take our Walkthrough and become familiar with things.<br/><br/>
				If you rather do this later, or are already familiar, then skip and you won't be prompted again. You can still take the Walkthrough anytime once logged in.
				
			</div>

		</div>

	</div>

	<div class="init" :class="{'is-loading': is.loading}">

		<div class="init-title" v-if="!is.complete">One moment, checking your setup...</div>
		<div class="init-title is-success" v-if="is.complete && !is.errored && !is.prompt.network">Setup check complete, proceeding to position and volume check...</div>
		<div class="init-title is-success" v-if="is.complete && !is.errored && is.prompt.network">Setup check complete, you may now proceed...</div>
		<div class="init-title is-fail" v-if="is.complete && is.errored">Some problems were identified, please fix before proceeding.</div>
		
		<div class="init-steps">

			<div class="init-steps-item" :class="{'is-requesting': is.requesting.video, 'is-waiting': is.requesting.video && !is.allowed.video, 'is-success': is.success.video, 'is-fail': is.fail.video}">
				
				<div class="init-steps-item-icon"><i class="fa fa-video"></i></div>

				<div class="init-steps-item-name">Camera</div>
				<div class="init-steps-item-status" v-if="status.video">{{ statusText[status.video] }}</div>

			</div>

			<div class="init-steps-item" :class="{'is-requesting': is.requesting.audio, 'is-waiting': is.requesting.audio && !is.allowed.audio, 'is-success':  is.success.audio, 'is-fail': is.fail.audio}">
				
				<div class="init-steps-item-icon"><i class="fa fa-microphone"></i></div>

				<div class="init-steps-item-name">Microphone</div>
				<div class="init-steps-item-status" v-if="status.audio">{{ statusText[status.audio] }}</div>

			</div>

			<div v-if="canGeolocate" class="init-steps-item" :class="{'is-requesting': is.requesting.location, 'is-waiting': is.requesting.location && !is.allowed.location, 'is-success':  is.success.location, 'is-warning': is.fail.location}">
				
				<div class="init-steps-item-icon"><i class="fa fa-map-marker"></i></div>

				<div class="init-steps-item-name">Location</div>
				<div class="init-steps-item-status" v-if="status.location">{{ statusText[status.location] }}</div>

			</div>

			<div class="init-steps-item" :class="{'is-requesting': is.requesting.twilio, 'is-success': is.success.twilio, 'is-fail': is.fail.twilio}">
				
				<div class="init-steps-item-icon"><i class="fa fa-cloud-upload-alt"></i></div>

				<div class="init-steps-item-name">Connection</div>
				<div class="init-steps-item-status" v-if="status.twilio">{{ statusText[status.twilio] }}</div>

			</div>

			<div class="init-steps-item" :class="{'is-requesting': is.requesting.network, 'is-warning': status.network === $constants.setup.status.weak || status.network === $constants.setup.status.bad, 'is-success': is.success.network, 'is-fail': is.fail.network}">
				
				<div class="init-steps-item-icon"><i class="fa fa-wifi"></i></div>

				<div class="init-steps-item-name">Quality</div>
				<div class="init-steps-item-status" v-if="status.network">{{ statusText[status.network] }}</div>

			</div>

			<div v-if="canOnboard" class="init-steps-item" :class="{'is-requesting': is.requesting.onboarding, 'is-success': is.success.onboarding, 'is-warning': is.fail.onboarding}">
				
				<div class="init-steps-item-icon"><i class="fa fa-graduation-cap"></i></div>

				<div class="init-steps-item-name">Walkthrough</div>
				<div class="init-steps-item-status" v-if="status.onboarding">{{ statusText[status.onboarding] }}</div>

			</div>

		</div>

		<div class="init-buttons" v-if="is.complete || is.prompt.onboarding || is.prompt.network">

			<app-button v-if="is.errored && is.complete" theme="red" class="init-buttons-proceed" v-on:click="onRefreshClick">Try again <i class="fa fa-sync-alt"></i></app-button>
			
			<app-button v-if="is.prompt.network" theme="blue" class="init-buttons-proceed" v-on:click="onNetworkProceed">Proceed <i class="fa fa-chevron-right"></i></app-button>
			
			<app-button v-if="is.prompt.onboarding && !is.success.onboarding" theme="yellow" class="init-buttons-proceed" v-on:click="onOnboardingTake">Yes, please <i class="fa fa-graduation-cap"></i></app-button>
			<app-button v-if="is.prompt.onboarding && !is.success.onboarding" theme="blue" class="init-buttons-proceed" v-on:click="onOnboardingSkip">No, thanks <i class="fa fa-chevron-right"></i></app-button>
			
			<app-button v-if="is.prompt.onboarding && is.success.onboarding" theme="blue" class="init-buttons-proceed" v-on:click="onOnboardingProceed">Proceed <i class="fa fa-chevron-right"></i></app-button>
			
		</div>

	</div>

	<com-test v-if="is.testing" :setup="true" v-on:proceed="onTested" />

</app-page>

</template>

<script>

import {connect} from 'twilio-video'
import comTest from './content/Test'

export default {

	components: {
		'com-test': comTest
	},

	data: function() {

		return {
			room: false,
			delay: 500,
			delayNetwork: 3000,
			statusText: {
				1: 'Checking',
				2: 'Not found',
				3: 'Asking permission',
				4: 'Permission denied',
				5: 'Checking',
				6: 'Unknown',
				7: 'OK',
				8: 'Requires camera',
				9: 'Requires microphone',
				10: 'Requires camera & microphone',
				11: 'Failed',
				12: 'OK',
				13: 'OK',
				14: 'Weak',
				15: 'Bad',
				16: 'Requires connection',
				17: 'Not completed',
				18: 'Requires geolocation'
			},
			status: {
				video: 0,
				audio: 0,
				location: 0,
				twilio: 0,
				network: 0,
				onboarding: 0
			},
			is: {
				complete: false,
				errored: false,
				testing: false,
				requesting: {
					video: false,
					audio: false,
					location: false,
					twilio: false,
					network: false,
					onboarding: false
				},
				prompt: {
					denied: false,
					permission: false,
					connection: false,
					network: false,
					onboarding: false
				},
				exists: {
					video: false,
					location: false,
					audio: false,
					network: false
				},
				allowed: {
					video: false,
					location: false,
					audio: false
				},
				success: {
					video: false,
					location: false,
					audio: false,
					twilio: false,
					network: false,
					onboarding: false
				},
				fail: {
					video: false,
					location: false,
					audio: false,
					twilio: false,
					network: false,
					onboarding: false
				}
			}
		}

	},

	created: function() {

		this.$store.dispatch('device/init').then(function() {

			// are there inputs
			if (this.$store.getters['device/audio/inputs'].length) this.is.exists.audio = true
			if (this.$store.getters['device/video/inputs'].length) this.is.exists.video = true
			if (navigator.geolocation) this.is.exists.location = true

			// do we already have access
			if (this.$store.getters['device/audio/current']) this.is.allowed.audio = true
			if (this.$store.getters['device/video/current']) this.is.allowed.video = true
			navigator.permissions.query({ name: 'geolocation' }).then(function(response) {

				if (response.state == 'granted') this.is.allowed.location = true

			}.bind(this))

			this.queueVideo()

		}.bind(this))

	},

	computed: {

		canOnboard: function() {

			return this.$store.getters['session/onboarding/check']

		},

		canGeolocate: function() {

			return this.$store.getters['event/geolocation/enabled'] && this.$isCandidate

		}

	},

	methods: {

		queueVideo: function() {

			if (this.is.exists.video) {

				this.is.requesting.video = true
				this.status.video = this.$constants.setup.status.requesting
				this.$_.delay(this.onVideoRequest, this.delay)

			} else {

				this.is.requesting.video = false
				this.is.fail.video = true
				this.status.video = this.$constants.setup.status.notexist

			}

		},

		queueAudio: function() {

			if (this.is.exists.audio) {

				this.is.requesting.audio = true
				this.status.audio = this.$constants.setup.status.requesting 
				this.$_.delay(this.onAudioRequest, this.delay)

			} else {

				this.is.fail.audio = true
				this.status.audio = this.$constants.setup.status.notexist

			}

		},

		queueLocation: function() {

			if (this.is.exists.location) {

				this.is.requesting.location = true
				this.status.location = this.$constants.setup.status.requesting 
				this.$_.delay(this.onLocationRequest, this.delay)

			} else {

				this.is.fail.location = true
				this.status.location = this.$constants.setup.status.notexist

				this.queueTwilio()

			}

		},

		queueOnboarding: function() {

			if (this.canOnboard) {

				this.status.onboarding = this.$constants.setup.status.checking

				var done = this.$store.getters['onboarding/taken']

				if (done) {

					this.status.onboarding = this.$constants.setup.status.complete
					this.is.success.onboarding = true
					this.status.onboarding = this.$constants.setup.status.ok

					this.onFinished()

				} else {

					this.status.onboarding = this.$constants.setup.status.notdone
					this.is.fail.onboarding = true
					this.is.prompt.onboarding = true

				}

			} else {

				this.onFinished()

			}

		},

		queueTwilio: function() {

			if (!this.is.success.video || !this.is.success.audio) {

				this.is.fail.twilio = true 
				this.is.fail.network = true 

				this.status.twilio = this.$constants.setup.status.notdone
				this.status.network = this.$constants.setup.status.notdone

				this.onFinished()
				
			} else {

				this.is.requesting.twilio = true
				this.status.twilio = this.$constants.setup.status.checking
				this.$_.delay(this.onTwilioRequest, this.delay)

			}

		},

		onVideoRequest: function() {

			this.status.video = (this.is.allowed.video) ? this.$constants.setup.status.requesting : this.$constants.setup.status.permission

			if (!this.is.allowed.video) this.is.prompt.permission = true

			this.$store.dispatch('device/video').then(function() {

				this.is.requesting.video = false 
				this.is.prompt.permission = false
				this.is.success.video = true

				this.status.video = this.$constants.setup.status.ok

				this.queueAudio()

			}.bind(this), function(e) {

				this.is.requesting.video = false 
				this.is.prompt.permission = false
				this.is.fail.video = true

				if (e.toString() === 'NotAllowedError: Permission denied') {

					this.status.video = this.$constants.setup.status.blocked

				} else {

					this.status.video = this.$constants.setup.status.unknown

				}

				this.queueAudio()

			}.bind(this))

		},

		onAudioRequest: function() {

			this.status.audio = (this.is.allowed.audio) ? this.$constants.setup.status.requesting : this.$constants.setup.status.permission

			if (!this.is.allowed.audio) this.is.prompt.permission = true

			this.$store.dispatch('device/audio').then(function() {

				this.is.requesting.audio = false 
				this.is.prompt.permission = false
				this.is.success.audio = true

				this.status.audio = this.$constants.setup.status.ok

				if (this.canGeolocate) {

					this.queueLocation()

				} else {

					this.queueTwilio()

				}

			}.bind(this), function(e) {

				this.is.requesting.audio = false 
				this.is.prompt.permission = false
				this.is.fail.audio = true

				if (e.toString() === 'NotAllowedError: Permission denied') {

					this.status.audio = this.$constants.setup.status.blocked

				} else {

					this.status.audio = this.$constants.setup.status.unknown

				}

				if (this.canGeolocate) {

					this.queueLocation()

				} else {

					this.queueTwilio()

				}

			}.bind(this))

		},

		onLocationRequest: function() {

			this.status.location = (this.is.allowed.location) ? this.$constants.setup.status.requesting : this.$constants.setup.status.permission

			if (!this.is.allowed.location) this.is.prompt.permission = true

			navigator.geolocation.getCurrentPosition(function() {

				this.is.requesting.location = false 
				this.is.prompt.permission = false
				this.is.success.location = true

				this.status.location = this.$constants.setup.status.ok

				this.queueTwilio()
				
			}.bind(this), function(e) {

				this.is.requesting.location = false 
				this.is.prompt.permission = false
				this.is.fail.location = true

				if (e.code === 1) {

					this.status.location = this.$constants.setup.status.blocked

				} else {

					this.status.location = this.$constants.setup.status.unknown

				}

				this.queueTwilio()

			}.bind(this))

		},

		onTwilioRequest: function() {

			var config = {
				name: 'devicecheck.' + this.$store.getters['session/identity'],
        region: 'ie1',
				dominantSpeaker: true,
				audio: true,
				video: true,
				preferredVideoCodecs: [
					{ codec: 'VP8', simulcast: true }
				],
        networkQuality: {
          local: 1,
          remote: 1
        },
        bandwidthProfile: {
          video: {
            mode: 'presentation',
            dominantSpeakerPriority: 'standard',
            trackSwitchOffMode: 'disabled',
            contentPreferencesMode: 'auto'
          }
        }
			}

			if (this.$store.getters['device/ios']) config.tracks = this.$store.getters['device/tracks']

			connect(this.$store.getters['session/token'], config).then(function(room) {

				this.room = room

				var hasWorkingAudio = false
				var hasWorkingVideo = false

				this.room.localParticipant.audioTracks.forEach(function(publication) {

					hasWorkingAudio = publication.isTrackEnabled

				}.bind(this))

				this.room.localParticipant.videoTracks.forEach(function(publication) {

					hasWorkingVideo = publication.isTrackEnabled

				}.bind(this))

				if (hasWorkingAudio && hasWorkingVideo) {

					this.is.success.twilio = true
					this.is.requesting.twilio = false
					this.status.twilio = this.$constants.setup.status.ok

					this.networkStrength = this.room.localParticipant.networkQualityLevel

					this.is.requesting.network = true 
					this.status.network = this.$constants.setup.status.checking

					this.room.localParticipant.on('networkQualityLevelChanged', this.onNetworkQualityLevelChange.bind(this))

					this.$_.delay(this.onDisconnect, this.delayNetwork)

				} else { 

					this.status.twilio = this.$constants.setup.status.failed
					this.status.network = this.$constants.setup.status.notdone
					this.is.fail.twilio = true
					this.is.fail.network = true
					this.is.requesting.twilio = false

					this.onDisconnect()

					this.onFinished()

				}


			}.bind(this), function() {

				this.status.twilio = this.$constants.setup.status.failed
				this.status.network = this.$constants.setup.status.notdone
				this.is.fail.twilio = true
				this.is.fail.network = true
				this.is.requesting.twilio = false

				this.onFinished()

			}.bind(this))

		},

		onNetworkQualityLevelChange: function(level) {

			this.networkStrength = level

		},

		onDisconnect: function() {

			if(!this.is.fail.twilio) {

				if (this.networkStrength !== null) this.$monitor.log('user/strength', {
					strength: this.networkStrength
				})

				this.room.localParticipant.removeListener('networkQualityLevelChanged', this.onNetworkQualityLevelChange.bind(this))

				this.room.disconnect()

				if(this.networkStrength === 5) {

					this.status.network = this.$constants.setup.status.strong

				} else if(this.networkStrength === 4) {

					this.status.network = this.$constants.setup.status.good

				} else if(this.networkStrength === 3) {

					this.status.network = this.$constants.setup.status.ok

				} else if(this.networkStrength === 2) {

					this.status.network = this.$constants.setup.status.weak
					this.is.prompt.network = true

				} else if(this.networkStrength === 1) {

					this.status.network = this.$constants.setup.status.poor
					this.is.prompt.network = true

				} else {

					this.status.network = this.$constants.setup.status.unknown
					this.is.prompt.network = true

				}
		
				this.is.success.network = true
				this.is.requesting.network = false

				this.room = false

				if (!this.is.prompt.network) this.queueOnboarding()
				
			}

		},

		onFinished: function() {

			if (this.is.fail.video || this.is.fail.audio) {

				this.is.prompt.denied = true
				this.is.errored = true

			} else if (this.is.fail.twilio) {

				this.is.prompt.connection = true
				this.is.errored = true

			} else {

				this.is.errored = false

			}

			this.is.complete = true

			if (!this.is.errored && !this.is.prompt.network) {

				this.$_.delay(this.onProceed, this.delay)

			} else {

				this.$monitor.log('user/check/fail', this.status)

			}

		},

		onRefreshClick: function() {

			this.$monitor.log('user/refresh')

			window.location.reload()

		},

		onOnboardingTake: function() {

			this.$store.commit('onboarding/active', true)

			this.status.onboarding = this.$constants.setup.status.ok
			this.is.success.onboarding = true
			this.is.fail.onboarding = false

		},

		onOnboardingSkip: function() {

			this.is.success.onboarding = true
			this.is.fail.onboarding = false
			this.is.prompt.onboarding = false

			this.$store.commit('onboarding/taken', true)

			this.onFinished()
			
		},

		onNetworkProceed: function() {

			this.is.prompt.network = false
			this.queueOnboarding()

		},

		onOnboardingProceed: function() {

			this.$store.commit('onboarding/taken', true)

			this.onFinished()

		},

		onProceed: function() {

			this.$monitor.log('user/check/pass')

			if (this.$isCandidate) {

				navigator.geolocation.getCurrentPosition(function(e) {

					this.$monitor.log('user/location', {
						accuracy: e.coords.accuracy,
						latitude: e.coords.latitude,
						longitude: e.coords.longitude
					})
						
				}.bind(this), function() {}, {
					enableHighAccuracy: true,
					maximumAge: 0
				})
					
			}

			this.is.testing = true

		},

		onTested: function() {

			this.$store.dispatch('device/detect').then(function() {
				
				this.$store.commit('device/initiated', true)

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.init {
	position: absolute;
	left: 50%;
	user-select: none;
	top: 50%;
	transform: translate(-50%, -50%);
}

.init.is-loading {
	background-image: url('data:image/gif;base64,R0lGODlhEAALAPQAACN9xv///0KPzUqU0DSHyvj6/P///9bm84274avN6F2f1ODs9r7Y7Ye336fL51qd1N3q9fr7/brW7DeJy0OQziuCyM3h8UCOzS2DyGGh1XSs2k+X0TCFyQAAAAAAAAAAACH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCwAAACwAAAAAEAALAAAFLSAgjmRpnqSgCuLKAq5AEIM4zDVw03ve27ifDgfkEYe04kDIDC5zrtYKRa2WQgAh+QQJCwAAACwAAAAAEAALAAAFJGBhGAVgnqhpHIeRvsDawqns0qeN5+y967tYLyicBYE7EYkYAgAh+QQJCwAAACwAAAAAEAALAAAFNiAgjothLOOIJAkiGgxjpGKiKMkbz7SN6zIawJcDwIK9W/HISxGBzdHTuBNOmcJVCyoUlk7CEAAh+QQJCwAAACwAAAAAEAALAAAFNSAgjqQIRRFUAo3jNGIkSdHqPI8Tz3V55zuaDacDyIQ+YrBH+hWPzJFzOQQaeavWi7oqnVIhACH5BAkLAAAALAAAAAAQAAsAAAUyICCOZGme1rJY5kRRk7hI0mJSVUXJtF3iOl7tltsBZsNfUegjAY3I5sgFY55KqdX1GgIAIfkECQsAAAAsAAAAABAACwAABTcgII5kaZ4kcV2EqLJipmnZhWGXaOOitm2aXQ4g7P2Ct2ER4AMul00kj5g0Al8tADY2y6C+4FIIACH5BAkLAAAALAAAAAAQAAsAAAUvICCOZGme5ERRk6iy7qpyHCVStA3gNa/7txxwlwv2isSacYUc+l4tADQGQ1mvpBAAIfkECQsAAAAsAAAAABAACwAABS8gII5kaZ7kRFGTqLLuqnIcJVK0DeA1r/u3HHCXC/aKxJpxhRz6Xi0ANAZDWa+kEAA7AAAAAAAAAAAA');
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

.init > * {
	opacity: 1;
	transition: opacity 100ms linear;
}

.init.is-loading > * {
	opacity: 0;
}

.init-steps {
	display: flex;
	flex-direction: row;
}

.init-steps-item {
	margin: 0px 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.init-steps-item-icon {
	font-size: 64px;
	background-color: #ddd;
	color: #999;
	width: 128px;
	height: 128px;
	border-radius: 50%;
	line-height: 128px;
	text-align: center;
	transition: all 100ms linear;
}

.init-steps-item.is-requesting .init-steps-item-icon {
	animation: requesting linear 1000ms infinite;
}

.init-steps-item.is-waiting .init-steps-item-icon {
	background-color: #FBB516;
	color: #fff;
}

.init-steps-item.is-success .init-steps-item-icon {
	background-color: #287ABD;
	color: #fff;
}

.init-steps-item.is-fail .init-steps-item-icon {
	background-color: #c62323;
	color: #fff;
}

.init-steps-item.is-warning .init-steps-item-icon {
	background-color: #FBB516;
	color: #fff;
}

@keyframes requesting {
	0% { transform: scale(1); }
	50% { transform: scale(1.1); }
	100% { transform: scale(1); }
}

.init-steps-item-name {
	text-align: center;
	font-size: 20px;
	font-weight: 400;
	margin-top: 10px;
	color: #999;
}

.init-steps-item-status {
	text-align: center;
	font-size: 16px;
	font-weight: 400;
	margin-top: 4px;
	color: #ccc;
}

.init-title {
	font-size: 24px;
	font-weight: 400;
	text-align: center;
	margin-bottom: 48px;
	color: #333;
}

.init-title.is-fail {
	color: #c62323;
}

.init-title.is-success {
	color: #287ABD;
}

.init-prompt {
	position: fixed;
	top: 20px;
	max-width: 480px;
	padding: 20px;
	border-radius: 4px;
	box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
	display: flex;
	flex-direction: row;
}

.init-prompt:before {
	content: ' ';
	width: 0;
	height: 0;
	position: absolute;
	top: -16px;
	border-style: solid;
	border-width: 0 16px 16px 16px;
}

.init-prompt.is-warning {
	background-color: #FBB516;
	color: #fff;
	right: 20px;
}

.init-prompt.is-warning:before {
	border-color: transparent transparent #FBB516 transparent;
	right: 16px;
}

.init-prompt.is-error {
	left: 20px;
	background-color: #c62323;
	color: #fff;
}

.init-prompt.is-error:before {
	left: 16px;
	border-color: transparent transparent #c62323 transparent
}

.init-prompt-icon {
	text-align: center;
	font-size: 64px;
	color: #fff;
	margin-right: 20px;
}

.init-prompt-title {
	font-size: 20px;
	font-weight: 500;
	margin-bottom: 10px;
}

.init-prompt-text {
	font-size: 16px;
	line-height: 20px;
	font-weight: 400;
}

.init-buttons {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	position: absolute;
	bottom: -80px;
}

.init-buttons >>> .button {
	margin: 0px 10px;
}

.init-buttons-proceed >>> .fa {
	margin-left: 10px;
}

</style>
