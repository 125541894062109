<template>
	
<div class="inventories" :class="{'is-sidebyside': isSideBySide}">

	<div class="inventories-tabs">
		
		<div class="inventories-tabs-item" v-for="inventory in inventories" :key="inventory.id" v-on:click="currentInventory = inventory.id" :class="{'is-active': currentInventory === inventory.id}">{{ inventory.name }}</div>

	</div>

	<template v-for="inventory in inventories">

		<div class="inventories-content" :key="inventory.id" v-if="currentInventory === inventory.id || isSideBySide">

			<app-shredded :data="{ type: 'inventory', identity: inventory.id}" showNote="true" v-if="inventory.pdf" />

			<img class="inventories-content-image" :src="inventory.image" v-if="inventory.image" />

			<video class="inventories-content-video" controls v-if="inventory.video">
				<source :src="inventory.video" type="video/mp4">
			</video>

		</div>

	</template>

</div>

</template>

<script>

export default {

	data: function() {

		return {
			currentInventory: false,
			station: false
		}

	},

	created: function() {

		this.station = this.$_.findWhere(this.$store.getters['schedule'], {
			type: this.$constants.schedule.type.station
		})

		this.currentInventory = this.station.inventory[0].id

	},

	computed: {

		inventories: function() {

			return this.station.inventory

		},

		isSideBySide: function() {

			return this.window.width >= 1110 && this.inventories.length === 2

		}

	}
	
}

</script>

<style scoped>

.inventories {
	height: 100%;
}

.inventories-tabs {
	display: flex;
}

.inventories-tabs-item {
	flex-grow: 1;
	flex-shrink: 0;
	height: 54px;
	line-height: 54px;
	color: #333;
	text-align: center;
	text-transform: capitalize;
	font-size: 18px;
	border-bottom: 4px solid #fff;
}

.inventories.is-sidebyside .inventories-tabs-item,
.inventories-tabs-item.is-active {
	border-bottom: 4px solid #287ABD;
}

.inventories.is-sidebyside .inventories-tabs-item {
	pointer-events: none;
}

.inventories.is-sidebyside .inventories-tabs-item:first-child {
	border-right: 1px solid #666;
}

.inventories-content {
	height: calc(100% - 54px)!important;
	background-color: #ddd;
}

.inventories.is-sidebyside .inventories-content {
	width: 50%;
	position: absolute;
	top: 54px;
}

.inventories.is-sidebyside .inventories-content:nth-child(2) {
	left: 0px;
	border-right: 1px solid #666;
}

.inventories.is-sidebyside .inventories-content:nth-child(3) {
	left: 50%;
}

.inventories-content-image {
	max-width: 100%;
	max-height: 100%;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.inventories-content-video {
	max-width: 100%;
	max-height: 100%;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

</style>