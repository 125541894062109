<template>

<div class="status">

	<div class="status-icon" :class="{'is-loading': type === $constants.status.loading}">

		<i class="fa fa-exclamation-triangle" v-if="type === $constants.status.errored"></i>

	</div>

	<div class="status-text">{{ text }}</div>

</div>

</template>

<script>

export default {

	props: ['text', 'type']

}

</script>

<style scoped>

.status {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	align-items: center;
}

.status-icon {
	font-size: 48px;
	height: 64px;
	line-height: 64px;
	text-align: center;
	color: #287ABD;
	width: 64px;
}

.status-icon.is-loading {
	background-image: url('data:image/gif;base64,R0lGODlhKwALAPEAAP///yN9xpO/4iN9xiH+GkNyZWF0ZWQgd2l0aCBhamF4bG9hZC5pbmZvACH5BAAKAAAAIf8LTkVUU0NBUEUyLjADAQAAACwAAAAAKwALAAACMoSOCMuW2diD88UKG95W88uF4DaGWFmhZid93pq+pwxnLUnXh8ou+sSz+T64oCAyTBUAACH5BAAKAAEALAAAAAArAAsAAAI9xI4IyyAPYWOxmoTHrHzzmGHe94xkmJifyqFKQ0pwLLgHa82xrekkDrIBZRQab1jyfY7KTtPimixiUsevAAAh+QQACgACACwAAAAAKwALAAACPYSOCMswD2FjqZpqW9xv4g8KE7d54XmMpNSgqLoOpgvC60xjNonnyc7p+VKamKw1zDCMR8rp8pksYlKorgAAIfkEAAoAAwAsAAAAACsACwAAAkCEjgjLltnYmJS6Bxt+sfq5ZUyoNJ9HHlEqdCfFrqn7DrE2m7Wdj/2y45FkQ13t5itKdshFExC8YCLOEBX6AhQAADsAAAAAAAAAAAA=');
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

.status-text {
	font-size: 18px;
	font-weight: 300;
}

</style>