import _ from 'underscore'

export default {

	namespaced: true,

	state: {

		streaming: [],
		quality: false,
		screensharing: false

	},

	getters: {

		screensharing: function(state) {

			return state.screensharing

		},

		streaming: function(state) {

			return state.streaming

		},

		quality: function(state) {

			return state.quality

		}

	},

	mutations: {

		reset: function(state) {

			state.streaming = []
			state.quality = false

		},

		screensharing: function(state, value) {

			state.screensharing = value

		},

		'streaming/add': function(state, value) {

			if (!_.contains(state.streaming, value)) state.streaming.push(value)

		},

		'streaming/remove': function(state, value) {

			state.streaming = _.without(state.streaming, value)

		},

		'quality': function(state, value) {

			state.quality = value

		}

	},

	actions: {

		init: function(context) {

			context.commit('reset')

		}

	}

}