<template>

<div class="station" :class="{'has-notes': notepad && isPreparation, 'is-preparing': isPreparation}" :data-briefs="schedule.brief.length">

	<div class="station-content">

		<div class="station-content-room" v-if="!isPreparation" :class="{'is-playback': is.preloading && $isActivePlayback}">

			<div class="station-warning is-active" v-if="!$isActivePlaying && $isActiveReady">Paused</div>

			<template v-if="$isActivePlaying">

				<div class="station-warning" v-if="isPrepareEnding" :class="{'is-active': isPrepareEnding}">{{ countdown(prepareEnd) }}</div>
				<div class="station-warning" v-if="isRoleplayStarting" :class="{'is-active': isRoleplayStarting}">Roleplay</div>
				<div class="station-warning" v-if="isRoleplayWarning" :class="{'is-active': isWarningActive}">{{ countdown(roleplayEnd) }}</div>
				<div class="station-warning" v-if="isRoleplayEnding" :class="{'is-active': isRoleplayEnding}">{{ countdown(roleplayEnd) }}</div>
				<div class="station-warning" v-if="isFeedbackStarting" :class="{'is-active': isFeedbackStarting}">Feedback</div>
				<div class="station-warning" v-if="isFeedbackEnding" :class="{'is-active': isFeedbackEnding}">{{ countdown(feedbackEnd) }}</div>

			</template>

			<app-room-status v-if="is.preloading && $isActivePlayback" :type="$constants.status.loading" text="Loading recording, please wait..." />

			<app-room v-on:connected="is.connected = true" v-on:disconnected="is.connected = false" v-show="!is.preloading || !$isActivePlayback" :exclude="exclude" :pinsDisabled="$isActivePlayback" v-on:canplay="onCanPlay" :item="schedule" :cards="cards" :pinsHidden="true" :pinsSpecial="!exclude.length" :pinsEditable="false" v-on:cardClosed="onCardClosed" />

		</div>

		<template v-if="isPreparation">

			<div class="station-preparation" :class="{'is-expanded': is.expanded === briefType}" v-for="briefType in schedule.brief" :key="briefType">

				<app-shredded class="station-preparation-text" :data="{type: (isCandidate) ? 'brief' : 'station', brief: briefType, identity: (isCandidate) ? schedule.identity : schedule.station}" :schedule="schedule.identity" :showNote="true" />

				<div class="station-preparation-label">
					<div class="station-preparation-label-text">{{ briefName(briefType) }} brief</div>
					<button class="station-preparation-label-expand" v-if="is.expanded !== briefType && schedule.brief.length > 1" v-on:click="is.expanded = briefType" v-tooltip="'Click to enlarge'"><i class="fa fa-expand-alt"></i></button>
					<button class="station-preparation-label-expand" v-if="is.expanded === briefType && schedule.brief.length > 1" v-on:click="is.expanded = false" v-tooltip="'Click to shrink'"><i class="fa fa-compress-alt"></i></button>
				</div>

			</div>

			<app-room-notepad v-if="notepad" :item="{title: '', subtitle: ''}" class="station-notes" />

		</template>

		<com-playback v-if="$isActivePlayback" />

		<com-marksheet v-if="$canSeeMarksheet && ($store.getters['session/lead/section'] !== $constants.lead.section.standardisation) && !isPreparation && (is.connected || $isActivePlayback)" />

	</div>
	
</div>

</template>

<script>

import comMarksheet from './station/Marksheet'
import comPlayback from './../sidebar/actions/Playback'

export default {

	components: {
		'com-marksheet': comMarksheet,
		'com-playback': comPlayback
	},

	data: function() {

		return {
			is: {
				expanded: false,
				preloading: true,
				skipping: false,
				connected: false
			},
			brief: {
				candidate: false,
				roleplayer: false,
				roleplayer_1: false,
				roleplayer_2: false,
				roleplayer_3: false,
				roleplayer_4: false,
				examiner: false,
				examiner_1: false,
				examiner_2: false,
				examiner_3: false,
				examiner_4: false,
				layexaminer: false,
				layexaminer_1: false,
				layexaminer_2: false,
				layexaminer_3: false,
				layexaminer_4: false,
            },
            notepad: false
		}

	},

	created: function() {

        this.$pubsub.$on('action.brief', this.onReadBrief.bind(this))
        this.$pubsub.$on('action.notepad', this.onToggleNotepad.bind(this))

        this.notepad = this.isCandidate && this.$store.getters['event/notes/enabled']

		if (this.$isCandidate) this.brief.candidate = true

	},

	beforeDestroy: function() {

		this.$pubsub.$off('action.brief', this.onReadBrief.bind(this))
        this.$pubsub.$off('action.notepad', this.onToggleNotepad.bind(this))

	},

	computed: {

		exclude: function() {

			return (this.isFeedback && !this.schedule.feedbackSettings.candidate) ? [this.schedule.candidate] : []

		},

		hasReadingTime: function() {

			return this.schedule.duration.preparation

		},

		cards: function() {

			var cards = []

			this.$_.each(this.brief, function(enabled, type) {

				if (enabled) {
					
					cards.push({
						closable: true,
						type: this.$constants.card.type.file,
						identity: type,
						expandable: true,
						title: type.replace('_', ' ') + ' brief',
						shredded: {
							type: (this.isCandidate) ? 'brief' : 'station',
							brief: type,
							identity: (this.isCandidate) ? this.schedule.identity : this.schedule.station
						}
					})

				}

			}.bind(this))
            
            if (this.notepad) {
                
                cards.push({
					closable: true,
                    type: this.$constants.card.type.notepad,
                    identity: 'notepad',
                    title: 'Your notes'
                })

            }

			this.$_.each(this.schedule.inventory, function(inventory) {

				var show = false

				if (inventory.availability.type === this.$constants.inventory.availability.always) {

					show = true

				} else if (inventory.availability.type === this.$constants.inventory.availability.time) {

					show = inventory.availability.timer <= this.roleplayElapsed

				} else if (inventory.availability.type === this.$constants.inventory.availability.trigger) {

					var isPermitted = false, revealed = false

					if (inventory.availability.permission === this.$constants.inventory.availability.permission.role) {

						isPermitted = this.$_.contains(inventory.availability.roles, this.$store.getters['people/self'].role)

					} else if (inventory.availability.permission === this.$constants.inventory.availability.permission.user) {

						isPermitted = this.$_.contains(inventory.availability.logins, this.$store.getters['session/identity'])

					} else if (inventory.availability.permission === this.$constants.inventory.availability.permission.lead) {

						isPermitted = this.isLead

					}

					revealed = this.$_.contains(this.$_.keys(this.schedule.triggered), inventory.id)

					if (isPermitted) {

						show = true

					} else {

						show = revealed

					}

					if (revealed) {

						if (this.schedule.triggered[inventory.id] !== this.$store.getters['session/identity']) {

							isPermitted = false

						}

					}

				}
				
				if (show) {

					cards.push({
						type: this.$constants.card.type.file,
						identity: inventory.id,
						observeIdentity: this.schedule.id + '-' + inventory.id,
						title: inventory.name,
						subtitle: 'Inventory',
						availability: inventory.availability,
						interaction: inventory.interaction,
						triggerer: this.schedule.triggered[inventory.id],
						revealed: revealed,
						image: inventory.image,
						video: inventory.video,
						shredded: (inventory.pdf) ? {
							type: 'inventory',
							identity: inventory.id
						} : false
					})
					
				}

			}.bind(this))

			return cards

		},

		schedule: function() {

			return this.$store.getters['schedule/active']

		},

		prepareEnd: function() {

			return this.schedule.roleplay

		},

		roleplayEnd: function() {

			return (this.schedule.feedback) ? this.schedule.feedback : this.schedule.end

		},

		feedbackEnd: function() {

			return this.schedule.end

		},

		isFeedback: function() {

			return this.schedule.feedback && this.time >= this.schedule.feedback && this.time <= this.schedule.end

		},

		isRoleplay: function() {

			return this.time >= this.schedule.roleplay && this.time <= this.roleplayEnd

		},

		isPreparation: function() {

			return this.time >= this.schedule.start && this.time <= this.schedule.roleplay && this.hasReadingTime

		},

		isPrepareEnding: function() {

			return this.countdown(this.prepareEnd, true) <= 5 && this.isPreparation && this.hasReadingTime

		},

		isRoleplayStarting: function() {

			var remaining = this.countdown(this.roleplayEnd, true)
			return this.isRoleplay && (remaining >= (this.roleplayEnd - this.schedule.roleplay) - 3 && remaining < this.roleplayEnd - this.schedule.roleplay) && this.hasReadingTime

		},

		isRoleplayEnding: function() {

			return this.countdown(this.roleplayEnd, true) <= 5 && this.isRoleplay

		},

		isFeedbackStarting: function() {

			var remaining = this.countdown(this.feedbackEnd, true)
			return this.isFeedback && (remaining >= (this.schedule.end - this.schedule.feedback) - 3 && remaining < this.schedule.end - this.schedule.feedback)

		},

		isFeedbackEnding: function() {

			return this.countdown(this.feedbackEnd, true) <= 5 && this.isFeedback

		},

		warningPoint: function() {

			return this.$store.getters['event/stations/warning']

		},

		isRoleplayWarning: function() {

			var remaining = this.countdown(this.roleplayEnd, true)
			return remaining <= this.warningPoint + 3 && remaining >= this.warningPoint - 3 && this.isRoleplay

		},

		isWarningActive: function() {

			var remaining = this.countdown(this.roleplayEnd, true)
			return remaining <= this.warningPoint + 1 && remaining >= this.warningPoint - 1 && this.isRoleplay

		},

		roleplayElapsed: function() {

			return this.time - this.schedule.roleplay

		}

	},

	methods: {

		onCardClosed: function(identity) {

			if (identity === 'notepad') {

				this.notepad = false

			} else {

				this.brief[identity] = false
				
			}

		},

		briefName: function(type) {

			if (this.isRoleplayer && type.indexOf('roleplayer_') === 0) {

				return 'Roleplayer'
				
			} else if (this.isExaminer && type.indexOf('examiner_') === 0) {

				return 'Examiner'
				
			} else if (this.isLayExaminer && type.indexOf('layexaminer_') === 0) {

				return 'Lay Examiner'

			} else {

				return type.replace('_', ' ')

			}

		},
		
		onCanPlay: function() {

			if (!this.schedule.playback.ready) {

				if (this.schedule.playback.position && !this.is.skipping) {

					this.is.skipping = true

					this.$pubsub.$emit('room.skip')

					this.schedule.start = this.$store.getters['time'] - this.schedule.playback.position
					this.schedule.roleplay = this.$store.getters['time'] - this.schedule.playback.position
					this.schedule.end = this.$store.getters['time'] - this.schedule.playback.position + this.schedule.duration.roleplay

					this.$pubsub.$emit('playback.skip', this.schedule.playback.position)

				} else {

					var difference = this.$store.getters['time'] - this.schedule.start - this.schedule.playback.position

					this.schedule.start += difference 
					this.schedule.roleplay += difference 
					this.schedule.end += difference 

					this.schedule.playback.ready = true

					this.is.preloading = false

				}

			}

			if ((this.schedule.playback.can.autoplay || this.is.skipping) && this.schedule.playback.ready) {

				// this.$pubsub.$emit('playback.play')
				// this.schedule.playback.playing = true
				
			}
			
		},

		onReadBrief: function(identity) {

			this.brief[identity] = !this.brief[identity]

        },
        
        onToggleNotepad: function() {

            this.notepad = !this.notepad

        }

	}

}

</script>

<style scoped>

.station {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 1;
}

.station-content {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 1;
	display: flex;
	flex-direction: column;
}

.station-content-room {
	flex-shrink: 0;
	flex-grow: 1;
	min-height: 48px;
}

.station-content-room.is-playback {
	background-color: #fff;
}

.station-warning {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 2;
	color: red;
	font-size: 64px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	font-weight: 700;
	z-index: 20;
	text-shadow: 0px 0px 30px rgba(0, 0, 0, 1);
	opacity: 0;
	transition: opacity 300ms ease-in-out;
	pointer-events: none;
}

.station-warning.is-active {
	opacity: 1;
}

.station.is-preparing .station-content {
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 10px;
}

.station.has-notes.is-preparing .station-content {
	padding-bottom: 210px;
}

.station.has-notes {
	padding-bottom: 210px;
}

.station.is-preparing[data-briefs="3"] .station-content,
.station.is-preparing[data-briefs="4"] .station-content {
	grid-template-columns: 1fr 1fr;
}

.station.is-preparing[data-briefs="5"] .station-content,
.station.is-preparing[data-briefs="6"] .station-content,
.station.is-preparing[data-briefs="7"] .station-content,
.station.is-preparing[data-briefs="8"] .station-content,
.station.is-preparing[data-briefs="9"] .station-content {
	grid-template-columns: 1fr 1fr 1fr;
}

.station-preparation {
	display: flex;
	flex-direction: column;
	border: 1px solid #ddd;
	border-radius: 4px;
	overflow: hidden;
	background-color: #fff;
	box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
	z-index: 1;
}

.station-preparation.is-expanded {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 2;
}

.station-preparation-text {
	width: 100%;
	flex-grow: 1;
	border-radius: 4px 4px 0px 0px;
	flex-basis: 0;
}

.station-preparation-label {
	flex-shrink: 0;
	text-align: left;
	font-size: 16px;
	font-weight: 400;
	color: #333;
	text-transform: capitalize;
	display: flex;
	justify-content: space-between;
}

.station-preparation-label-text {
	flex-grow: 1;
	padding: 10px;
}

.station-preparation-label-expand {
	flex-shrink: 0;
	font-size: 16px;
	width: 40px;
	height: 40px;
	color: #999;
}

.station-preparation-label-expand:focus {
	background-color: #287ABD;
	color: #fff;		
}

.station-preparation-label-expand:hover {
	color: #333;
}

.station-notes {
    position: absolute;
    z-index: 2;
    left: 0px;
    bottom: 0px;
    right: 0px;
	height: 200px;
}

</style>