<template>

<div class="directory">

	<input class="directory-search" ref="input" v-model="input.search" placeholder="Filter directory by name..." />

	<div class="directory-items" :class="{'is-empty': !filteredPeople.length, 'is-filtering': input.search}">

		<div v-for="person in filteredPeople" :key="person.identity" class="directory-item" :class="{'is-selected': isSelected(person.identity)}">

			<div class="directory-item-name">
				{{ person.name }} ({{ roleName(person) }}<template v-if="person.circuit">, {{circuitName(person)}}</template><template v-if="person.label">, {{ person.label }}</template>)
			</div>

			<div class="directory-item-select" v-if="(!isSelected(person.identity) && !multiple) || (multiple && (person.role !== $constants.role.candidate || !input.users.length || isSelected(person.identity) || !limitCandidates) && (canMultiple || isSelected(person.identity)))">
				<app-button class="directory-item-select-button" v-if="!isSelected(person.identity)" :small="true" v-on:click="onSelectClick(person.identity)">Select</app-button>
				<app-button class="directory-item-select-button" v-if="isSelected(person.identity)" :small="true" v-on:click="onRemoveClick(person.identity)">Remove</app-button>
			</div>

		</div>

	</div>
	
</div>

</template>

<script>

export default {

	props: ['multiple', 'people', 'hide', 'limitCandidates'],

	data: function() {

		return {

			input: {
				user: false,
				users: [],
				search: ''
			}

		}

	},

	created: function() {

		this.allPeople = this.people || this.$_.clone(this.$store.getters['people'])

		this.$nextTick(function() {

			this.$refs.input.focus()

		}.bind(this))

	},

	computed: {

		canMultiple: function() {

			return (this.multiple) ? !this.hasCandidate : false

		},

		hasCandidate: function() {

			if (!this.limitCandidates) return false

			var users = (this.multiple) ? this.input.users : [this.input.user]

			var hasCandidate = false

			this.$_.each(users, function(id) {

				if (this.$store.getters['people'][id].role === this.$constants.role.candidate) hasCandidate = true

			}.bind(this))

			return hasCandidate

		},

		filteredPeople: function() {

			var people = this.allPeople

			if (this.hide) {

				people = this.$_.filter(people, function(person) {

					return !this.$_.contains(this.hide, person.identity)

				}.bind(this))

			}

			if (this.input.search) {

				people = this.$_.filter(people, function(person) {

					return person.name.toLowerCase().indexOf(this.input.search.toLowerCase().trim()) >= 0

				}.bind(this))

			}

			return this.$_.sortBy(this.$_.sortBy(people, 'name'), 'role')

		}

	},

	methods: {

		isSelected: function(identity) {

			return (this.multiple) ? this.$_.contains(this.input.users, identity) : identity === this.input.user

		},

		roleName: function(person) {
		
			return this.$store.getters['language/role'](person.role)

		},

		circuitName: function(person) {

			return (this.$store.getters['circuits/associated'][person.circuit]) ? this.$store.getters['circuits/associated'][person.circuit].name : false

		},

		onSelectClick: function(identity) {

			if (this.multiple) { 

				this.input.users.push(identity) 
				this.$emit('change', this.input.users)
			
			} else {
				
				this.input.user = identity
				this.$emit('change', this.input.user)

			}

		},

		onRemoveClick: function(identity) {

			if (this.multiple) { 
				
				this.input.users.splice(this.input.users.indexOf(identity), 1)
				this.$emit('change', this.input.users)
			
			} else {
				
				this.input.user = false
				this.$emit('change', this.input.user)

			}

		}

	}

}

</script>

<style scoped>

.select {
	border: 1px solid #ddd;
	border-radius: 4px;
	font-size: 14px;
	line-height: 40px;
	padding: 3px 10px;
	text-align: left;
	background-color: #fff;
	margin-bottom: 10px;
	width: 100%;
}

.directory {
	overflow: hidden;
	margin-bottom: 20px;
	height: 250px;
}

.directory-items {
	height: 200px;
	overflow-y: scroll;
	border: 2px solid #ddd;
	border-top: 0px;
	border-radius: 0px 0px 4px 4px;
}

.directory-items:before {
	display: block;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-size: 24px;
	color: #ccc;
	font-weight: 400;
}

.directory-items.is-empty.is-filtering:before {
	content: 'No matching names found.'
}

.directory-items.is-empty:not(.is-filtering):before {
	content: 'No recipients found.'
}

.directory-item {
	display: flex;
	flex-direction: row;
}

.directory-item:nth-child(even) {
	background-color: #eee;
}

.directory-item.is-selected {
	background-color: #287ABDbf;
}

.directory-item-name {
	font-size: 14px;
	color: #000;
	flex-grow: 1;
	padding: 10px 10px;
	line-height: 20px;
}

.directory-item.is-selected .directory-item-name {
	color: #fff;
	font-weight: 400;
}

.directory-item-select {
	flex-shrink: 0;
	padding: 4px 10px;
}

.directory-item-select-button {
	border-radius: 4px;
	height: 32px;
	width: 120px;
}

.directory-search {
	border: 2px solid #ddd;
	border-radius: 4px 4px 0px 0px;
	font-size: 14px;
	line-height: 40px;
	padding: 3px 10px;
	text-align: left;
	background-color: #fff;
	width: 100%;
}

.directory-search:focus {
    border: 2px solid #4285f4;
}

.message-recipient {
	font-size: 14px;
	line-height: 14px;
	margin-bottom: 20px;
}

.message-textarea {
	border: 1px solid #ddd;
	border-radius: 4px;
	font-size: 14px;
	line-height: 20px;
	padding: 10px 10px;
	text-align: left;
	background-color: #fff;
	width: 100%;
	height: 216px;
}

p {
	font-size: 14px;
	line-height: 20px;
	font-weight: 300;
	margin-bottom: 10px;
}

</style>
