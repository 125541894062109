<template>

<div class="modal" v-if="active">

	<div onboarding="modal" class="modal-dialog">

		<div class="modal-head">

			<slot name="head"></slot>

		</div>

		<div class="modal-body">

			<slot></slot>

		</div>

		<div class="modal-buttons">

			<slot name="buttons"></slot>

		</div>

	</div>

</div>

</template>

<script>

export default {

	props: ['active']

}

</script>

<style scoped>

.modal {
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.8);
	z-index: 200;
	display: flex;
	user-select: none;
	justify-content: center;
	align-items: center;
}

.modal-dialog {
	border-radius: 4px;
	background-color: #fff;
	max-width: 640px;
	width: 100%;
	padding: 20px;
	display: flex;
	flex-direction: column;
}

.modal-body > *:last-child {
	margin-bottom: 0px;
}

.modal-buttons {
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-top: 20px;
}

.modal-buttons > * {
	margin: 0px 10px;
}

.modal-head h1 {
	font-size: 24px;
	font-weight: 500;
	margin-bottom: 20px;
}

</style>
