
import api from '@/service/api'
import monitor from '@/service/monitor'

export default {

	namespaced: true,

	state: {
		
		cache: {}

	},

	getters: {

	},

	mutations: {

	},

	actions: {

		cache: function(context, data) {

			return new Promise(function(resolve) {

				var identity = data.identity
				var type = data.type

				var url = ['shredded', type, identity]

				if (data.brief) url.push(data.brief)

				url = url.join('/')

				var extension = (context.rootGetters['event/shredding/enabled']) ? 'json' : 'pdf'

				var fullurl = api.base() + url + '.' + extension + '?key=' + context.rootGetters['session/key']

				var startTime = Date.now()

				window.fetch(fullurl).then(function(response) {

					var waitTime = Date.now() - startTime

					response.json().then(function(json) {

						monitor.log('user/api/ok', {
							endpoint: url + '.' + extension,
							duration: waitTime
						})

						resolve(json.data)
	
					}, function() {

						monitor.log('user/api/fail', {
							endpoint: url,
							duration: waitTime
						})
	
						resolve()
	
					})

				}, function() {

					var waitTime = Date.now() - startTime

					monitor.log('user/api/fail', {
						endpoint: url + '.' + extension,
						duration: waitTime
					})

					resolve()

				})

			})

		}

	}

}