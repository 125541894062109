import pusher from '@/service/pusher'
import constants from '../constants'

export default {

	namespaced: true,

	state: {

		people: {}

	},

	getters: {

		get: function(state) {

			return state.people

		},

		self: function(state, getters, rootState, rootGetters) {

			return (rootGetters['session/identity']) ? state.people[rootGetters['session/identity']] : false

		}	

	},

	mutations: {

		set: function(state, data) {

			state.people = data

		},

		update: function(state, data) {

			state.people[data.identity] = data

		},

		rename: function(state, data) {

			state.people[data.identity].name = data.name
			state.people[data.identity].reference = data.reference

		},

		empty: function(state, data) {

			state.people[data.identity].empty = 1

		}

	},

	actions: {

		init: function(context, data) {

			context.commit('set', data)

			pusher.on('user', 'people/update', function(data) {

				context.commit('update', data)

			})

			pusher.on('event', 'any/rename', function(data) {

				context.commit('rename', data)

			})

			pusher.on('event', 'people/empty', function(data) {

				context.commit('empty', data)

			})

			if(context.rootGetters['people/self'].role !== constants.role.candidate) {

				pusher.on('event', 'redacted/rename', function(data) {

					context.commit('rename', data)
	
				})

			}

			pusher.subscribe.observers('people')

			pusher.on('observers.people', 'update', function(data) {

				context.commit('update', data)

			})

		}

	}

}