<template>

<div class="timeline" :data-type="type" :style="{gridTemplateColumns: gridTemplateColumns}">

	<div tabindex="0" class="timeline-item" v-for="round in data" :key="round.id" v-on:keypress.enter="onStationClick(round)" v-on:click="onStationClick(round)">

		<div class="timeline-item-flags" v-if="type == 'status'">

			<i class="fa fa-flag is-system" v-if="round.system_flag" />
			<i class="fa fa-flag is-roleplayer" v-if="round.roleplayer_flag" />

		</div>

		<div class="timeline-item-visual">

			<div class="timeline-item-visual-type is-roleplay">

				<div v-for="(segment, index) in segments(round)" v-tooltip="tooltip(segment)" :key="index" :data-value="segment.value" :style="{width: segment.width + '%'}" />

			</div>

		</div>

		<div class="timeline-item-label" :class="{'is-lost': round.lost && type === 'status'}">
			<div class="timeline-item-label-name">{{ round.name }}</div>
			<div class="timeline-item-label-time" v-if="type === 'status'">{{ duration(round.lost) }}</div>
		</div>

	</div>

</div>

</template>

<script>

export default {

	props: ['data', 'type'],

	data: function() {

		return {
			timelinePerRow: 7,
			tooltips: {
				status: {
					1: 'Online',
					0: 'Offline'
				},
				strength: {
					0: 'Offline',
					1: 'Bad',
					2: 'Below average',
					3: 'Average',
					4: 'Good',
					5: 'Excellent'
				}
			}
		}

	},

	computed: {

		gridTemplateColumns: function() {

			return '1fr '.repeat((this.data.length <= this.timelinePerRow) ? this.data.length : this.timelinePerRow)

		}

	},

	methods: {

		onStationClick: function(station) {

			console.log(station)

			this.$pubsub.$emit('connections.station.toggle', {
				data: station
			})

		},

		duration: function(value) {

			if (!value) return '00:00'
		
			var minutes = Math.floor(value / 60)
			var seconds = value - (minutes * 60)

			return minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0')

		},

		tooltip: function(segment) {

			return this.tooltips[this.type][segment.value] + '<small>' + this.duration(segment.duration) + '</small>'

		},

		segments: function(round) {

			var segments = [], gap

			if(round[this.type].length) {

				this.$_.each(round[this.type], function(item, index) {

					if(item.time < round.end) {
						
						if(index === 0 && round.start !== item.time) {

							gap = item.time - round.start

							segments.push({
								value: 0,
								duration: gap,
								width: (100 / round.duration) * gap
							})
						}

						if (round[this.type].length > index + 1) {

							gap = round[this.type][index + 1].time - item.time

						} else {

							gap = round.end - item.time

						}

						segments.push({
							value: item.value,
							duration: gap,
							width: (100 / round.duration) * gap
						})

					}

				}.bind(this))

			} else {

				if (round.end < this.time) {

					segments.push({
						value: 0,
						duration: round.duration,
						width: 100
					})
					
				}

			}

			return segments

		}

	}

}

</script>

<style scoped>

.timeline {
	display: grid!important;
	width: 100%;
}

.timeline-item {
	display: flex;
	flex-direction: column;
	padding: 10px 5px 5px 6px;
	cursor: pointer;
	height: 74px;
}

.timeline-item:nth-child(even) {
	background-color: #eee;
}

.timeline-item:hover,
.timeline-item:focus {
	background-color: #ccc;
}

.timeline-item-flags {
	position: absolute;
	z-index: 2;
	font-size: 12px;
	top: -4px;
	color: #d34545;
}

.timeline-item-flags .fa {
	margin-right: 2px;
}

.timeline-item-flags .is-system {
	color: #d34545;
}

.timeline-item-flags .is-roleplayer {
	color: #ffbe19;
}

.timeline-item-visual {
	display: flex;
	flex-direction: row;
	height: 40px;
	z-index: 1;
}

.timeline-item-visual-type {
	display: flex;
	flex-direction: row;
	height: 40px;
	align-items: center;
	overflow: hidden;
}

.timeline-item-visual-type.is-roleplay {
	width: calc(100% - 0px);
}

.timeline-item-visual-type > div {
	height: 40px;
	border-left: 1px solid #fff;
	border-radius: 8px;
	z-index: 2;
	min-width: 3px;
}

.timeline-item-visual-type > div:first-child {
	border-left: 0px!important;
}

.timeline-item:nth-child(even) .timeline-item-visual-type > div {
	border-left: 1px solid #eee;
}

.timeline[data-type="status"] .timeline-item-visual-type > div[data-value="0"] {
	background-color: #dd0000;
}

.timeline[data-type="status"] .timeline-item-visual-type > div[data-value="1"] {
	background-color: #21ca21;
}

.timeline[data-type="strength"] .timeline-item-visual-type > div[data-value="0"] {
	background-color: #dd0000;
	height: 1px;
	z-index: 1;
}

.timeline[data-type="strength"] .timeline-item-visual-type > div[data-value="1"] {
	background-color: #d34545;
	height: 8px;
}

.timeline[data-type="strength"] .timeline-item-visual-type > div[data-value="2"] {
	background-color: #e68213;
	height: 16px;
}

.timeline[data-type="strength"] .timeline-item-visual-type > div[data-value="3"] {
	background-color: #f1cf5e;
	height: 24px;
}

.timeline[data-type="strength"] .timeline-item-visual-type > div[data-value="4"] {
	background-color: #7fd17f;
	height: 32px;
}

.timeline[data-type="strength"] .timeline-item-visual-type > div[data-value="5"] {
	background-color: #21ca21;
	height: 40px;
}

.timeline-item-label {
	text-align: center;
	font-size: 13px;
    margin-top: 4px;
    font-weight: 400;
	display: flex;
    color: #999;
	width: 100%;
}

.timeline-item-label-name {
	overflow: hidden;
	flex-grow: 1;
	white-space: nowrap;
	text-align: left;
	max-width: 74px;
}

.timeline-item-label-time {
	flex-shrink: 0;
	flex-grow: 1;
	text-align: right;
}

.timeline-item-label.is-lost {
	color: #dd0000;
}

</style>