<template>

    <div class="playback" onboarding="playback">
    
        <div class="playback-timeline">
            
            <div ref="timeline" class="playback-timeline-bar">
    
                <div class="playback-timeline-bar-item is-progress" :style="{width: progressWidth}"></div>
                <div class="playback-timeline-bar-item is-full"></div>
    
            </div>
    
        </div>
    
        <app-actions-icons class="playback-actions">
    
            <app-actions-icon :shortcut="['shift', 's']" :class="{'is-disabled': !setup.paused}" icon="play" name="Start playback" />
            <app-actions-icon :shortcut="['shift', 'p']" :class="{'is-disabled': setup.paused}" icon="pause" name="Pause playback" />
    
        </app-actions-icons>
    
    </div>
    
    </template>
    
    <script>
    
    export default {

        props: ['setup'],
    
        computed: {
    
            progressWidth: function() {
    
                return this.setup.progress.toString() + '%'
    
            },
    
            hoverWidth: function() {
    
                return false
    
            },
    
            canPlay: function() {
    
                return true
    
            },
    
            canPause: function() {
    
                return true
    
            },
    
            canSkipBackward: function() {
    
                return true
    
            },
    
            canSkipForward: function() {
    
                return true
    
            },
    
            canSkip: function() {
    
                return true
    
            },
    
            canStop: function() {
    
                return true
    
            }
    
        }
    
    }
    
    </script>
    
    <style scoped>
    
    .playback {
        margin-top: 10px;
        display: flex;
    }
    
    .playback-timeline {
        background-color: #fff;
        border: 1px solid #ddd;
        padding: 10px;
        border-radius: 4px 0px 0px 4px;
        border-right-width: 0px;
        flex-grow: 1;
    }
    
    .playback-timeline-bar {
        width: 100%;
        height: 22px;
    }
    
    .playback-timeline.no-skip {
        pointer-events: none;
    }
    
    .playback-timeline.is-disabled {
        pointer-events: none;
        opacity: 0.35;
    }
    
    .playback-timeline-bar-tooltip {
        width: 1px;
    }
    
    .playback-timeline-bar-item {
        position: absolute;
        left: 0px;
        top: 0px;
        bottom: 0px;
        border-radius: 4px;
        cursor: pointer;
    }
    
    .playback-timeline-bar-item.is-disabled {
        pointer-events: none;
    }
    
    .playback-timeline-bar-item.is-progress {
        z-index: 3;
        background-color: #287ABD;
    }
    
    .playback-timeline-bar-item.is-viewed {
        z-index: 2;
        background-color: #999;
    }
    
    .playback-timeline-bar-item.is-full {
        z-index: 1;
        width: 100%;
        background-color: #ddd;
    }
    
    .playback-actions {
        margin-top: 0px!important;
    }
    
    .playback-actions >>> .icon {
        border-radius: 0px;
        margin-right: 0px;
        border-right-width: 0px;
        flex-shrink: 0;
        line-height: 44px!important;
    }
    
    .playback-actions >>> .icon:nth-child(3) {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border-right-width: 1px;
    }
    
    </style>
    