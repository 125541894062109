<template>

<div class="schedule">

	<div class="schedule-item schedule-item-head">

		<div class="schedule-item-name" v-if="isEarly">Begins in {{ hours }} hour<template v-if="hours > 1">s</template></div>
		<div class="schedule-item-name" v-if="!isEarly">Schedule</div>

	</div>

	<app-scroll class="schedule-scroll">

		<div v-for="(item, index) in structure" :key="index" class="schedule-item" :class="{'is-break': item.type === $constants.schedule.type.break, 'is-next': isNext(item.identity) || isCurrent(item.identity), 'is-completed': isCompleted(item.identity)}">
		
			<div class="schedule-item-name">{{ item.name }}</div>
			<div class="schedule-item-status">{{ itemStatus(item) }}</div>

			<div class="schedule-item-check fa fa-check-circle" v-if="isCompleted(item.identity)"></div>

			<div class="schedule-item-check fa fa-coffee" v-if="!isCompleted(item.identity) && item.type === $constants.schedule.type.break"></div>

		</div>

	</app-scroll>

</div>

</template>

<script>

export default {

computed: {

		structure: function() {

			if (this.isActive) {

				return this.$_.filter(this.$store.getters['structure'], function(structure) {

					return this.$_.contains(structure.circuit, this.isActive.circuit) || structure.circuit.length === 0

				}.bind(this))

			} else {

				return this.$store.getters['structure']

			}

		},

		hours: function() {

			return Math.round((this.structure[0].start - this.time) / 3600)

		}

	},

	methods: {

		itemStatus: function(item) {

			if (this.isNext(item.identity) && !this.isEarly) {

				var difference = item.start - this.time
				var remaining

				if (difference > 60) {

					remaining = Math.floor(difference / 60) + ' minute' + ((Math.floor(difference / 60) === 1) ? '' : 's')

				} else {

					remaining = difference + ' second' + ((difference === 1) ? '' : 's')

				}

				return 'Begins in ' + remaining

			} else if (this.isCompleted(item.identity)) {

				return 'Completed'

			} else if (this.isCurrent(item.identity)) {

				return 'In progress'

			} else {

				return this.$moment.unix(item.start).utc().tz('Europe/London').format('h:mma')

			}

		},

		isNext: function(identity) {

			var next = this.$_.find(this.$store.getters['structure'], function(item) {

				return item.start > this.time

			}.bind(this))

			return (next) ? next.identity === identity : false

		},

		isCurrent: function(identity) {

			return this.$store.getters['structure/associated'][identity].end > this.time && this.$store.getters['structure/associated'][identity].start < this.time

		},

		isCompleted: function(identity) {
		
			return this.$store.getters['structure/associated'][identity].end < this.time

		}

	}

}

</script>

<style scoped>

.schedule {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	border-radius: 4px;
	user-select: none;
	margin-top: 10px;
	background-color: #fff;
	box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
}

.schedule-scroll {
	overflow: hidden;
	flex-grow: 1;
	border-radius: 0px 0px 4px 4px;
	border: 1px solid #ddd;
	border-top: 0px;
}

.schedule-scroll.has-online {
	border-radius: 0px;
	border-bottom-width: 0px;
}

.schedule-item {
	color: #333;
	padding: 10px;
	height: 56px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	border-bottom: 1px solid #ddd;
}

.schedule-item.is-break {
	background-color: #f1f1f1;
}

.schedule-item:last-child {
	border-radius: 0px 0px 4px 4px;
	border-bottom: 0px;
}

.schedule-item-head {
	border-top: 0px;
	background-color: #287ABD;
	color: #fff;
	border-color: #287ABD;
	height: 40px;
}

.schedule-item-name {
	font-size: 16px;
	font-weight: 300;
	margin-bottom: 4px;
}

.schedule-item-head .schedule-item-name {
	margin-bottom: 0px;
	font-weight: 400;
}

.schedule-item-status {
	font-size: 12px;
	font-weight: 300;
}

.schedule-item.is-completed {
	color: #999;
}

.schedule-item-check {
	position: absolute;
	right: 10px;
	top: 50%;
	margin-top: -12px;
	font-size: 24px;
	color: #10ab6b;
}

.schedule-item-check.fa-coffee {
	color: #287ABD;
	font-size: 20px;
	margin-top: -8px;
}

.schedule-item-countdown {
	font-size: 16px;
	line-height: 36px;
	font-weight: 300;
	color: #666;
	position: absolute;
	right: 10px;
	top: 10px;
}

.schedule-item.is-online {
	height: auto;
	border-radius: 0px 0px 4px 4px;
	border: 1px solid #ddd;
}

</style>
