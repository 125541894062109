<template>

<com-card>

	abc

</com-card>

</template>

<script>

import comCard from './Card'

export default {

	components: {

		'com-card': comCard

	}

}

</script>

<style scoped>

</style>