<template>

<app-page class="page">

	<h1 v-if="!section">Hello, {{ firstname }}!<small>Choose an option below.</small></h1>

	<com-menu v-if="!section" v-on:change="onSectionChange" />

	<com-launch v-if="!section" />

	<com-availability v-if="section === $constants.dashboard.section.availability" v-on:close="section = false" />
	<com-profile v-if="section === $constants.dashboard.section.profile" v-on:close="section = false" />
	<com-schedule v-if="section === $constants.dashboard.section.schedule" v-on:close="section = false" />

	<com-foot />

	<com-logout />

</app-page>

</template>

<script>

import comMenu from './dashboard/Menu'
import comLaunch from './dashboard/Launch'
import comFoot from './dashboard/Foot'
import comAvailability from './dashboard/Availability'
import comProfile from './dashboard/Profile'
import comSchedule from './dashboard/Schedule'
import comLogout from './shared/modal/Logout'

export default {

	components: {

		'com-menu': comMenu,
		'com-foot': comFoot,
		'com-launch': comLaunch,
		'com-availability': comAvailability,
		'com-schedule': comSchedule,
		'com-profile': comProfile,
		'com-logout': comLogout

	},

	data: function() {

		return {
			section: false
		}

	},

	computed: {

		firstname: function() {

			return this.$store.getters['user/firstname']

		}

	},

	methods: {

		onSectionChange: function(value) {

			this.section = value

		}

	}

}

</script>

<style scoped>

.page {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	display: flex;
	flex-direction: column;
	overflow: visible!important;
	transition: all 100ms linear;
	margin-left: 0px;
	justify-content: center;
	align-items: center;
}

.page h1 {
	font-size: 24px;
	text-align: center;
	font-weight: 500;
	color: #333;
	margin-bottom: 30px;
}

.page small {
	font-size: 16px;
	font-weight: 400;
	display: block;
	margin-top: 4px;
}

</style>