<template>

<div tabindex="0" ref="wrapper" class="observer">

	<com-circuits v-if="isExam && !is.joining" />

	<app-waiting v-if="is.joining && !is.playback" text="One moment, requesting to join room..." />
	<app-waiting v-if="is.joining && is.playback" text="One moment, loading playback..." />

	<div class="observer-content" ref="content" v-if="!is.joining" v-on:scroll="onContentScroll">
		<com-rollcall v-if="section === $constants.observer.rollcall" /> 
		<com-connections v-if="section === $constants.observer.connections" /> 
		<com-chaperones v-if="section === $constants.observer.chaperones" v-on:join="onJoin" />
		<com-marking v-if="section === $constants.observer.marking" />
		<com-schedule v-if="section === $constants.observer.schedule" v-on:join="onJoin" v-on:playback="onPlayback" />
		<com-incidents v-if="section === $constants.observer.incidents && canIncidents" />
		<com-adhocs v-if="section === $constants.observer.adhocs && canAdhoc" />
	</div>

</div>

</template>

<script>

import comCircuits from './observer/Circuits'
import comRollcall from './observer/Rollcall'
import comSchedule from './observer/Schedule'
import comConnections from './observer/Connections'
import comIncidents from './observer/Incidents'
import comChaperones from './observer/Chaperones'
import comMarking from './observer/Marking'
import comAdhocs from './observer/Adhocs'

export default {

	components: {
		'com-circuits': comCircuits,
		'com-rollcall': comRollcall,
		'com-schedule': comSchedule,
		'com-chaperones': comChaperones,
		'com-connections': comConnections,
		'com-marking': comMarking,
		'com-adhocs': comAdhocs,
		'com-incidents': comIncidents
	},

	data: function() {

		return {
			is: {
				joining: false,
				playback: false
			}
		}

	},

	created: function() {

		this.$pubsub.$on('observer.section', this.onSectionChange.bind(this))
		this.$pubsub.$on('observer.watch', this.onPlayback.bind(this))
		this.$pubsub.$on('observer.join', this.onJoin.bind(this))

	},

	beforeDestroy: function() {

		this.$pubsub.$off('observer.section', this.onSectionChange.bind(this))

	},

	watch: {

		section: function() {

			this.$nextTick(function() {

				this.$refs.wrapper.focus()

			}.bind(this))

		}

	},

	computed: {

		section: function() {

			return this.$store.getters['session/observer/section']

		},

		canIncidents: function() {

			return this.hasPermission(this.$constants.observer.permission.incidents)

		},

		canAdhoc: function() {

			return this.hasPermission(this.$constants.observer.permission.adhoc)

		},

		circuits: function() {

			return this.$store.getters['circuits']

		}

	},

	methods: {

		onContentScroll: function(e) {

			if (!e.target) return false

			if (this.section === this.$constants.observer.schedule && e.target.scrollTop) this.$store.commit('session/observer/scroll', e.target.scrollTop)

		},

		onJoin: function(identity) {

			this.is.joining = true
			this.is.playback = false

			this.$store.commit('settings/video', false)
			this.$store.commit('settings/audio', false)

			this.$api.request('room/join', {
				room: identity
			}).then(function() {

				this.is.joining = false

			}.bind(this), function() {

				this.is.joining = false

			}.bind(this))

		},

		onPlayback: function(identity) {

			this.is.joining = true
			this.is.playback = true

			this.$store.commit('settings/video', false)
			this.$store.commit('settings/audio', false)

			this.$api.request('playback/start', {
				identity: identity
			}).then(function(json) {

				this.$store.commit('schedule/add', this.$util.copy(json.schedule))
				
				this.is.joining = false

			}.bind(this), function() {

				this.is.joining = false

			}.bind(this))

		},

		onSectionChange: function(section) {

			this.$store.commit('session/observer/section', section)

			this.$nextTick(function() {

				if (!this.$refs.content) return false

				if (this.section === this.$constants.observer.schedule) this.$refs.content.scrollTop = this.$store.getters['session/observer/scroll']

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.observer {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 1;
	display: flex;
	flex-direction: column;
}

.observer-content {
	flex-grow: 1;
	box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
	z-index: 1;
	overflow-y: scroll;
	display: flex;
	flex-direction: column;
	padding: 10px;
	border: 1px solid #ddd;
	border-radius: 0px 4px 4px 4px;
	background-color: #fff;
}

</style>
