import Vue from 'vue'
import pusher from '@/service/pusher'
import api from '@/service/api'

export default {

	namespaced: true,

	state: {

        questions: [],
        sheets: {
			saved: {},
			session: {},
			candidates: {}
		},
		examiners: {},
		width: 256,
		height: 512

	},

	getters: {

		get: function(state) {

			return state.questions

        },

		width: function(state) {

			return state.width

        },

		height: function(state) {

			return state.height

        },
        
        questions: function(state) {

            return state.questions

        },
        
        examiners: function(state) {

            return state.examiners

        },

        saved: function(state) {

            return state.sheets.saved

        },

        session: function(state) {

            return state.sheets.session

        },

        candidates: function(state) {

            return state.sheets.candidates

        }

	},

	mutations: {

		set: function(state, data) {

			if (data.questions) state.questions = data.questions 
            if (data.examiners) state.examiners = data.examiners 
            Vue.set(state, 'sheets', data.sheets)

        },

		width: function(state, value) {

			state.width = value

        },

		height: function(state, value) {

			state.height = value

		},
        
        marks: function(state, data) {

            Vue.set(state.sheets.saved, data.identity, data.marks)
            Vue.set(state.sheets.session, data.identity, data.marks)

        },
        
        session: function(state, data) {

            Vue.set(state.sheets.session, data.identity, data.marks)

        },
        
        examiners: function(state, data) {

            Vue.set(state, 'examiners', data)

        }

	},

	actions: {

		init: function(context, data) {

			if (data) context.commit('set', data)

			// listen for new ad-hoc stations
			pusher.on('user', 'marking/add', function(data) {

				api.request('marking/sheet', {
					identity: data.identity
				}).then(function(json) {

					if (json.saved !== undefined) Vue.set(context.state.sheets.saved, json.identity, json.saved)
					if (json.session !== undefined) Vue.set(context.state.sheets.session, json.identity, json.session)
					if (json.candidates !== undefined) Vue.set(context.state.sheets.candidates, json.identity, json.candidates)
					if (json.questions !== undefined) Vue.set(context.state.questions, json.identity, json.questions)
					if (json.examiners !== undefined) Vue.set(context.state.examiners, json.identity, json.examiners)

				})

			}.bind(this))

			// listen for refresh
			pusher.on('user', 'marking/refresh', function() {

				api.request('marking/refresh').then(function(json) {
					
					context.commit('set', json)

				})

			}.bind(this))

			// listen for refresh
			pusher.on('user', 'marking/save', function() {

				api.request('marking/refresh').then(function(json) {
					
					context.commit('set', json)

				})

			}.bind(this))

		}

	}

}