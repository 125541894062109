<template>

<div class="schedule">

	<div class="schedule-head">Upcoming Schedule</div>

	<div class="schedule-list">

		<div class="schedule-item" v-for="item in schedule" :key="item.id">

			<div class="schedule-item-icon">
				<i class="fa fa-user-friends" v-if="item.type == $constants.dashboard.type.exam" />
				<i class="fa fa-pen" v-if="item.type == $constants.dashboard.type.marking" />
				<i class="fa fa-chalkboard-teacher" v-if="item.type == $constants.dashboard.type.calibration" />
			</div>

			<div class="schedule-item-text">
				<div class="schedule-item-text-title">{{ item.name }}</div>
				<div class="schedule-item-text-meeting" v-if="item.meeting">{{ item.meeting }}</div>
				<div class="schedule-item-text-date">{{ item.date }}</div>
			</div>

		</div>

	</div>

	<div class="schedule-buttons">

		<app-button v-on:click="$emit('close')">Close</app-button>

	</div>

</div>

</template>

<script>

export default {

	computed: {

		schedule: function() {

			return this.$store.getters['user/schedule']

		}

	}

}

</script>

<style scoped>

.schedule {
	background-color: #fff;
	width: 742px;
	display: block;
	padding: 20px;
	border: 1px solid #ddd;
	border-radius: 4px;
	align-items: center;
	box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
	user-select: none;
	max-height: calc(100vh - 200px);
	overflow: auto;
}

.schedule-list {
	border: 1px solid #ddd;
	border-radius: 4px;
}

.schedule-head {
	font-size: 24px;
	font-weight: 500;
	text-align: center;
	margin-bottom: 16px;
}

.schedule-buttons {
	display: flex;
	justify-content: center;
	margin-top: 20px;
}

.schedule-buttons >>> .button {
	width: 150px;
}

.schedule-item {
	display: flex;
	text-align: left;
	padding: 20px;
	color: #333;
	width: 100%;
	border-bottom: 1px solid #ddd;
	align-items: center;
}

.schedule-item:last-child {
	border-bottom: 0px;
}

.schedule-item-icon {
	font-size: 24px;
	width: 32px;
	text-align: center;
}

.schedule-item-text {
	margin-left: 20px;
	flex-grow: 1;
}

.schedule-item-text-title {
	font-size: 16px;
	margin-bottom: 4px;
	font-weight: 500;
}

.schedule-item-text-meeting {
	font-size: 16px;
	margin-bottom: 4px;
}

.schedule-item-text-date {
	font-size: 14px;
	color: #666;
}

</style>