import pusher from '@/service/pusher'

export default {

	namespaced: true,

	state: {

		incidents: []

	},

	getters: {

		get: function(state) {

			return state.incidents

		}

	},

	mutations: {

		set: function(state, data) {

			state.incidents = data

		},

		add: function(state, data) {

			state.incidents.unshift(data)

		}

	},

	actions: {

		init: function(context, data) {

			context.commit('set', data)

			if (context.rootGetters['session/is/observer'] && !context.rootGetters['people/self'].chaperone) {

				pusher.subscribe.observers('incidents')

				pusher.on('observers.incidents', 'add', function(data) {

					context.commit('add', data)

				})

			}

		}

	}

}