<template>

<div class="launch" v-if="schedule.length">

	<button v-on:click="onLaunchClick(item)" v-for="item in schedule" :key="item.id" class="launch-item" v-tooltip="'Click to launch'" tabindex="0">

		<div class="launch-item-icon">
			<i class="fa fa-user-friends" v-if="item.type == $constants.dashboard.type.exam" />
			<i class="fa fa-pen" v-if="item.type == $constants.dashboard.type.marking" />
			<i class="fa fa-chalkboard-teacher" v-if="item.type == $constants.dashboard.type.calibration" />
		</div>

		<div class="launch-item-text">
			<div class="launch-item-text-title">{{ item.name }}</div>
			<div class="launch-item-text-date">{{ item.date }}</div>
		</div>

		<i class="fa fa-caret-right launch-item-caret" />

	</button>

</div>

</template>

<script>

export default {

	computed: {

		schedule: function() {

			return this.$_.filter(this.$store.getters['user/schedule'], function(item) {

				return item.active

			})

		}

	},

	methods: {

		onLaunchClick: function(item) {

			window.open(item.launch)

		}

	}

}

</script>

<style scoped>

.launch {
	background-color: #fff;
	width: 640px;
	margin-top: 20px;
	display: block;
	border: 1px solid #ddd;
	border-radius: 4px;
	align-items: center;
	box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
}

.launch-item {
	display: flex;
	text-align: left;
	padding: 20px;
	color: #333;
	width: 100%;
	border-bottom: 1px solid #ddd;
	align-items: center;
}

.launch-item-icon {
	font-size: 24px;
	width: 32px;
	text-align: center;
}

.launch-item-text {
	margin-left: 20px;
	flex-grow: 1;
}

.launch-item-text-title {
	font-size: 16px;
	margin-bottom: 4px;
	font-weight: 500;
}

.launch-item-text-date {
	font-size: 14px;
}

.launch-item-caret {
	font-size: 24px;
}

.launch-item:hover {
	background-color: #287ABD;
	border-color: solid #287ABD;
	color: #fff;
}

.launch-item:focus {
    box-shadow: rgba(50, 50, 93, .1) 0 0 0 1px inset, rgba(50, 50, 93, .2) 0 4px 4px 0, rgba(0, 0, 0, .1) 0 2px 2px 0, rgba(50, 151, 211, .3) 0 0 0 4px;
    z-index: 10;
}

</style>