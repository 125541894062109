<template>
    
<div v-shortkey="['esc']" v-on:shortkey="onEscapeKey" tabindex="0" class="marking" ref="panel" v-if="is.expanded" :style="styleMarking" :class="{'is-active': is.expanded}">

	<div class="marking-buttons">

		<button class="marking-buttons-item" :class="{'is-saved': isSaved && is.observeSheet && !is.questionExpanded , 'is-changed': isChanged && is.observeSheet}" v-if="(!isActive && is.observeSheet && is.expanded && !is.loading) || (is.expanded && !currentSheet && canChange && canClose)" v-on:click="onExpandClick" v-tooltip.left="'Close marking panel'"><i class="fa fa-remove"></i></button>
		<button class="marking-buttons-item" :class="{'is-saved': isSaved}" v-if="!is.observeSheet && !is.questionExpanded && is.expanded && currentSheet && canChange && !isRoamingExaminer" v-on:click="onBackClick" v-tooltip.left="'View all candidates'"><i class="fa fa-caret-left"></i></button>
		<button class="marking-buttons-item" :class="{'is-saved': isSaved, 'is-changed': isChanged}" v-if="!is.observeSheet && !is.questionExpanded && is.expanded && currentSheet && isRoamingExaminer" v-on:click="onBackClick" v-tooltip.left="'View all examiners'"><i class="fa fa-caret-left"></i></button>
		<button class="marking-buttons-item" :class="{'is-saved': isSaved}" v-if="is.questionExpanded && is.expanded && currentSheet" v-on:click="onCollapseClick" v-tooltip.left="'Back to questions'"><i class="fa fa-caret-left"></i></button>

	</div>

	<div class="marking-content">

		<template v-if="!is.loading">

			<template v-for="identity in candidates">

				<com-sheet v-on:expand="is.questionExpanded = $event" v-on:collapse="is.questionExpanded = false" :expanded="is.questionExpanded" :examiner="sheetExaminer(identity)" :candidate="sheetCandidate(identity)" :identity="identity" :key="identity" :validSave="validSave[identity]" :session="sheets.session[identity]" :saved="sheets.saved[identity]" v-if="currentSheet === identity" v-on:saved="onSheetSaved" v-on:change="onSheetChanged" />

			</template>

			<template v-for="identity in examiners">

				<com-sheet v-on:expand="is.questionExpanded = $event" v-on:collapse="is.questionExpanded = false" :expanded="is.questionExpanded" :examiner="identity" :candidate="sheetCandidate(identity)" :identity="identity" :key="identity" :validSave="validSave[identity]" :session="sheets.session[identity]" :saved="sheets.saved[identity]" v-if="currentSheet === identity" />

			</template>

			<com-candidates :validSave="validSave" :session="sheets.session" :saved="sheets.saved" v-if="!currentSheet && !isRoamingExaminer" v-on:change="onCandidateChange" />

			<com-examiners v-on:change="onExaminerChange" :validSave="validSave" :session="sheets.session" :saved="sheets.saved" v-if="!currentSheet && isRoamingExaminer" />

		</template>

		<div class="marking-loading" v-if="is.loading">

			<i class="fa fa-spinner fa-spin" />

		</div>

	</div>

	<div class="marking-resize" v-tooltip="'Resize marking'" v-on:mousedown="onResizeDown" v-if="is.expanded && !is.loading"><i class="fa fa-arrows-h"></i></div>

</div>

</template>

<script>

import Vue from 'vue'
import comSheet from './marking/Sheet'
import comCandidates from './marking/Candidates'
import comExaminers from './marking/Examiners'

export default {

    components: {
		'com-sheet': comSheet,
		'com-candidates': comCandidates,
		'com-examiners': comExaminers
	},

	data: function() {

		return {
			currentSheet: false,
			observeIdentity: false,
			sheets: {
				saved: {},
				session: {}
			},
			is: {
				observeSheet: false,
				loading: false,
				resizing: false,
				questionExpanded: false,
				expanded: false
			},
			view: 'candidates'
		}

	},

	created: function() {

		this.sheets.saved = JSON.parse(JSON.stringify(this.$store.getters['marking/saved']))
		this.sheets.session = JSON.parse(JSON.stringify(this.$store.getters['marking/session']))

		if (this.isStation) {

			this.currentSheet = (this.currentSchedule.candidate && !this.isRoamingExaminer) ? this.currentSchedule.identity : false
			this.observeIdentity = this.currentSchedule.identity

			if (this.isRoamingExaminer) this.is.loading = true

			this.expand()

		}	

		this.$pubsub.$on('marking.toggle', this.onExpandClick.bind(this))
		
		window.addEventListener('mousemove', this.onResizeMove.bind(this))
		window.addEventListener('mouseup', this.onResizeUp.bind(this))

	},

	beforeDestroy: function() {

		this.$pubsub.$off('marking.toggle', this.onExpandClick.bind(this))
		
		window.removeEventListener('mousemove', this.onResizeMove.bind(this))
		window.removeEventListener('mouseup', this.onResizeUp.bind(this))

	},

	watch: {

		observeIdentity: function() {

			this.onObserveMarksheet()

		},

		currentSchedule: function(n, o) {

			if (n) {

				if (n.identity !== o.identity) {

					this.unexpand()

				}

			} else {

				if (this.isSaved || this.isRoamingExaminer) this.unexpand()

			}

		},

		isRoleplaying: function(n) {

			if (n) {
				
				this.currentSheet = (this.currentSchedule.candidate && !this.isRoamingExaminer) ? this.currentSchedule.identity : false
				this.observeIdentity = this.currentSchedule.identity

				if (this.isRoamingExaminer) this.is.loading = true

				this.expand()

			}

		}

	},

	computed: {

		candidates: function() {

			var candidates = []

			if (this.currentSheet || !this.isRoamingExaminer) {

				this.$_.each(this.$store.getters['marking/candidates'], function(candidate) {

					candidates.push(candidate.identity)

				}.bind(this))
				
			}

			return candidates


		},

		examiners: function() {

			var examiners = []

			if (this.isActive && this.isRoamingExaminer) {

				this.$_.each(this.$_.keys(this.sheets.session), function(examiner) {

					examiners.push(examiner)

				}.bind(this))
				
			}

			return examiners


		},

		canChange: function() {

			return !this.isRoleplaying || !this.currentSchedule.candidate

		},

		canClose: function() {

			return !this.isRoleplaying 

		},

		isRoleplaying: function() {

			if (this.currentSchedule) {

				if (this.currentSchedule.type === this.$constants.schedule.type.station) {

					return this.time >= this.currentSchedule.roleplay && this.$isActiveReady

				} else {

					return false

				}

			} else {

				return false

			}

		},

		currentSchedule: function() {

			return this.$store.getters['schedule/active']

		},

		isChanged: function() {

			if (this.currentSheet) {

				return this.validSave[this.currentSheet] && !this.$_.isEqual(this.sheets.saved[this.currentSheet], this.sheets.session[this.currentSheet])

			} else {

				return false

			}

		},

		isSaved: function() {

			if (this.currentSheet) {

				return this.validSave[this.currentSheet] && this.$_.isEqual(this.sheets.saved[this.currentSheet], this.sheets.session[this.currentSheet])

			} else {

				return false

			}

		},

		validSave: function() {

			var valid = {}

			this.$_.each(this.sheets.saved, function(sheet, schedule) {

				var total = 0
				var answered = 0

				this.$_.each(this.$store.getters['marking/questions'][schedule], function(question) {

					if (question.required) {

						total++

						if (sheet[question.identity] !== null) {

							answered++

						}

					}

				}.bind(this))

				Vue.set(valid, schedule, total === answered)

			}.bind(this))

			return valid

		},

		styleMarking: function() {

			var width = this.$store.getters['marking/width']

			return {
				width: width.toString() + 'px',
				right: (0 - width).toString() + 'px',
			}

		}

	},

	methods: {

		onEscapeKey: function() {

			if (this.questionExpanded) {

				this.onCollapseClick()

			} else if (!this.is.observeSheet && !this.is.questionExpanded && this.is.expanded && this.currentSheet && this.isRoamingExaminer) {

				this.onBackClick()

			} else if (!this.is.observeSheet && !this.is.questionExpanded && this.is.expanded && this.currentSheet && this.canChange && !this.isRoamingExaminer) {

				this.onBackClick()

			} else if ((!this.isActive && this.is.observeSheet && this.is.expanded && !this.is.loading) || (this.is.expanded && !this.currentSheet && this.canChange && this.canClose)) {

				this.onExpandClick()

			}

		},

		onExaminerChange: function(examiner) {

			this.currentSheet = examiner

		},

		onObserveLoad: function() {

			this.$api.request('marking/observe', {
				schedule: this.observeIdentity,
				examiner: this.is.observeSheet
			}).then(function(json) {

				this.$store.commit('marking/set', json)

				this.sheets.saved = JSON.parse(JSON.stringify(this.$store.getters['marking/saved']))
				this.sheets.session = JSON.parse(JSON.stringify(this.$store.getters['marking/session']))

				if (this.isActive && this.examiners.length === 1) {

					this.currentSheet = this.examiners[0]
					this.is.observeSheet = this.examiners[0]

				}

				this.is.loading = false

				this.$nextTick(function() {
					
					if (this.$refs.panel) this.$refs.panel.focus()

				}.bind(this))

			}.bind(this))

		},

		onObserveMarksheet: function() {

			if (this.isRoamingExaminer && this.observeIdentity) {
			
				this.$pusher.subscribe.marksheet(this.observeIdentity)

				this.$pusher.on('marksheet.' + this.observeIdentity, 'save', function() {

					this.$pubsub.$emit('marksheet.save')

					this.onObserveLoad()

				}.bind(this))
				
			}

		},

		onResizeDown: function() {

			this.is.resizing = true

		},

		onResizeMove: function(e) {

			if (this.is.resizing) {

				var width = this.window.width - e.x
				if (width > 512) width = 512
				if (width < 256) width = 256

				this.$store.commit('marking/width', width)

			}

		},

		onResizeUp: function() {

			this.is.resizing = false

		},

		sheetCandidate: function(identity) {

			return (this.isActive && this.isRoamingExaminer) ? this.isActive.candidate : this.$store.getters['marking/candidates'][identity].candidate

		},

		sheetExaminer: function() {

			return this.is.observeSheet

		},

		unexpand: function() {

			this.is.expanded = false

			if (this.isRoamingExaminer) {

				this.sheets.saved = {}
				this.sheets.session = {}

			}

		},

		expand: function(examiner) {

			this.is.expanded = true

			this.is.observeSheet = examiner || false
			
			if (this.isRoamingExaminer) {

				this.onObserveLoad()

			}

		},

		onExpandClick: function(e) {

			e = e || {}

			if (!this.is.expanded) {

				this.currentSheet = false
				this.observeIdentity = false
				this.is.observeSheet = false

			}

			if (this.isRoamingExaminer) {

				if (e.schedule) {

					if (e.schedule !== this.observeIdentity) {
						
						this.is.loading = true

					} 

					this.observeIdentity = e.schedule
					this.currentSheet = e.schedule

					this.expand(e.examiner)

				} else {

					this.unexpand()

					if (this.observeIdentity) {
						
						this.$pusher.unsubscribe.marksheet(this.observeIdentity)

						this.observeIdentity = false

					}
						
				}

			} else {

				this.is.expanded = !this.is.expanded

			}

		},

		onBackClick: function() {

			this.currentSheet = false

		},

		onCollapseClick: function() {

			this.is.questionExpanded = false
			
		},

		onSheetSaved: function(e) {

			Vue.set(this.sheets.saved, e.schedule, e.sheet)

			this.$store.commit('marking/marks', {
                identity: e.schedule,
                marks: e.sheet
            })

		},

		onSheetChanged: function(e) {

			Vue.set(this.sheets.session, e.schedule, e.sheet)

			this.$store.commit('marking/session', {
                identity: e.schedule,
                marks: e.sheet
            })

		},

		onCandidateChange: function(identity) {

			this.currentSheet = identity

		}

	}
	
}

</script>

<style scoped>

.marking-resize {
	position: absolute;
	z-index: 3;
	top: 50%;
	left: -20px;
	width: 20px;
	height: 40px;
	margin-top: -20px;
	background-color: #89b3d5;
	color: #fff;
	line-height: 40px;
	text-align: center;
	border-radius: 10px 0px 0px 10px;
	cursor: ew-resize;
}

.marking {
	position: fixed;
	top: 0px;
	bottom: 54px;
	z-index: 2;
	transition: right 300ms ease-in-out;
	user-select: none;
}

.marking.is-active {
    right: 0px!important;
}

.marking-buttons {
	position: absolute;
	left: -45px;
	width: 45px;
	height: 45px;
	top: 0px;
	display: flex;
	align-items: flex-end;
	flex-direction: column;
}

.marking-buttons-item {
	width: 45px;
	height: 45px;
    background-color: #2A7ABB;
	line-height: 45px;
	color: #fff;
	font-size: 20px;
	text-align: center;
	cursor: pointer;
	transition: all 100ms linear;
}

.marking:not(.is-active) .marking-buttons-item {
    background-color: #287ABD;
}

.marking-buttons-item:not(.is-active):focus,
.marking-buttons-item:not(.is-active):hover {
    background-color: #56a0dc;
}

.marking:not(.is-active) .marking-buttons-item:not(.is-active):focus,
.marking:not(.is-active) .marking-buttons-item:not(.is-active):hover {
    background-color: #2A7ABB;
}

.marking-buttons-item.is-saved {
    background-color: #38b56a;
}

.marking-buttons-item.is-saved:hover {
    background-color: #48cc7d;
}

.marking-buttons-item.is-changed {
    background-color: #ce9921;
}

.marking-buttons-item.is-changed:hover {
    background-color: #b18219;
}

.marking-content {
	width: 100%;
	height: 100%;
}

.marking-loading {
	width: 100%;
	user-select: none;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
    background-color: #287ABD;
	transition: background-color 100ms linear;
    flex-direction: column;
	color: #fff;
	font-size: 32px;
}

</style>