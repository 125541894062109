<template>
	
<div class="foot">

	<div class="foot-actions">

		<app-actions-icon v-if="canMessage" icon="comments" name="Direct messages" v-on:click="onChat" :counter="unreadCount" />

		<app-actions-icon v-if="hasAnnouncements" icon="bullhorn" name="Announcements" v-on:click="onAnnouncement" />

		<app-actions-icon v-if="canReport" icon="exclamation-triangle" name="Report incident" v-on:click="onIncident" />

		<div class="divide"></div>

		<app-actions-icon v-if="canAnnounce" icon="broadcast-tower" name="Make announcement to user(s)" v-on:click="onAnnounce" />

		<div class="divide" v-if="canAnnounce"></div>

		<app-actions-icon icon="sync-alt" name="Refresh" v-on:click="onRefresh" />

	</div>

	<div class="foot-status" v-html="liveStatus" :class="{'is-ending': isLiveEnding}"></div>

	<slot></slot>
	
	<div class="foot-text" v-if="!hideCredit">
		designed by <b>Professional Role Players</b>
	</div>

	<div class="foot-clock" v-if="!hideClock">{{ time | formatDate('HH:mm') }}</div>

</div>

</template>

<script>

export default {

	props: ['hideClock', 'hideCredit'],

	computed: {

		canReport: function() {

			return this.$_.contains(this.$store.getters['event/assistant/content'], this.$constants.assistant.content.incidents)

		},

		canMessage: function() {

			return this.$_.contains(this.$store.getters['event/assistant/content'], this.$constants.assistant.content.messaging)

		},

		canAnnounce: function() {

			return this.$_.contains(this.$store.getters['event/assistant/content'], this.$constants.assistant.content.announcements) && this.hasPermission(this.$constants.observer.permission.announce)
		},
		
		hasAnnouncements: function() {

			return this.$store.getters['announcements'].length

		},

		unreadCount: function() {

			var count = 0 

			this.$_.each(this.$store.getters['chats'], function(chat) {

				count += this.$_.filter(this.$store.getters['messages'], function(message) {

					return message.time > chat.read && message.user !== this.$store.getters['session/identity'] && message.chat === chat.identity

				}.bind(this)).length

			}.bind(this)) 

			return count 

		}

	},

	methods: {

		onAnnouncement: function() {

			this.$pubsub.$emit('panel.announcements')

		},
		
		onAnnounce: function() {

			this.$pubsub.$emit('modal.announce')
			
		},

		onChat: function() {

			this.$pubsub.$emit('panel.chats')

		},

		onIncident: function() {

			this.$pubsub.$emit('modal.incident')

		},

		onRefresh: function() {

			window.location.reload()

		}

	}

}

</script>

<style scoped>

.foot {
	display: flex;
	flex-direction: row;
	height: 54px;
	background-color: #FBB516;
}

.foot-actions {
	margin-top: 0px!important;
	flex-shrink: 0;
	display: flex;
	flex-direction: row;
	padding: 5px 10px;
	border-right: 1px solid rgba(255, 255, 255, 0.25);
}

.foot-status {
	line-height: 54px;
	font-size: 14px;
	font-weight: 300;
	color: #343434;
	padding-left: 20px;
	padding-right: 20px;
}

.foot-status.is.ending {
	background-color: #cc3939;
	color: #fff;
}

.foot-clock {
	height: 54px;
	line-height: 54px;
	font-weight: 500;
	font-size: 14px;
	padding: 0px 10px;
	margin-left: 20px;
	border-left: 1px solid rgba(255, 255, 255, 0.25);
	width: 80px;
	text-align: center;
}

.foot-text {
	line-height: 54px;
	flex-grow: 1;
	font-size: 14px;
	text-align: right;
	font-weight: 300;
	color: #343434;
}

.foot-text b {
	font-weight: 500;
	color: #000;
}

.divide {
	width: 1px;
	background-color: rgba(255, 255, 255, 0.25);
	height: 54px;
	margin-right: 14px;
	margin-left: 10px;
	margin-top: -5px;
}

</style>