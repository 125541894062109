import '@/assets/reset.css'
import '@/assets/tooltip.css'

import Vue from 'vue'
import App from './App.vue'

import panZoom from 'vue-panzoom'
import VTooltip from 'v-tooltip'
import shortKey from 'vue-shortkey'

import _ from 'underscore'
import moment from 'moment'
import 'moment-timezone'

import store from './store'
import constants from './constants'
import util from './util'

import pubsub from './service/pubsub'
import monitor from './service/monitor'
import api from './service/api'
import pusher from './service/pusher'
import chaperone from './service/chaperone'

import injection from './mixin/injection'
import mWindow from './mixin/window'
import time from './mixin/time'
import log from './mixin/log'
import shortcuts from './mixin/shortcuts'

// intercept console messages for device check logging
console.stdlog = console.log.bind(console);
console.stdinfo = console.info.bind(console);
console.stdwarn = console.warn.bind(console);
console.stderror= console.error.bind(console);

window.onerror = function(error) {
	pubsub.$emit('console', error)
}

console.log = function(){
	pubsub.$emit('console', arguments)
    console.stdlog.apply(console, arguments)
}

console.info = function(){
	pubsub.$emit('console', arguments)
    console.stdinfo.apply(console, arguments)
}

console.warn = function(){
	pubsub.$emit('console', arguments)
    console.stdwarn.apply(console, arguments)
}

console.error = function(){
	pubsub.$emit('console', arguments)
    console.stderror.apply(console, arguments)
}

Vue.config.productionTip = false

var requireComponentGlobal = require.context('./components/common', true, /[A-Z]\w+\.(vue|js)$/)

requireComponentGlobal.keys().forEach(function(filename) {

	var componentConfig = requireComponentGlobal(filename)
	var componentName = 'app' + filename.replace(/\//g, '-').replace('.vue', '').replace('.', '').toLowerCase()

	Vue.component(
		componentName,
		componentConfig.default
	)

})

function updateTime() {

	store.commit('time/current', moment().utc().tz('Europe/London').unix())
	setTimeout(updateTime, 250)

}

setTimeout(updateTime, 500)

Vue.filter('round', function (value, decimals, force) {

	force = force || false

	return ((value % 1 !== 0 || force) && value !== undefined) ? value.toFixed(decimals) : value

})

Vue.filter('nth', function (value) {

	var remainder = value % 10

	if (remainder === 1 && value !== 11) return value.toString() + 'st'
	if (remainder === 2 && value !== 12) return value.toString() + 'nd'
	if (remainder === 3 && value !== 13) return value.toString() + 'rd'

	return value.toString() + 'th'

})

Vue.filter('abs', function (value) {

	return Math.abs(value)

})

Vue.filter('nl2br', function (value) {

	return (value) ? value.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br/>$2') : value

})

Vue.filter('capitalise', function (value) {

	return value.charAt(0).toUpperCase() + value.slice(1)

})

Vue.filter('formatDate', function (value, format) {

	return (value) ? moment.unix(value).utc().tz('Europe/London').format(format) : ''

})

Vue.filter('timeStamp', function (value) {

	if (!value) return '00:00'
		
	var minutes = Math.floor(value / 60)
	var seconds = value - (minutes * 60)

	return minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0')

})

Vue.filter('fromNow', function (value) {

	return (value) ? moment.unix(value).utc().tz('Europe/London').from(moment.unix(store.getters['time/true']).utc().tz('Europe/London')) : ''

})

Vue.filter('prettyNumber', function (value) {

	return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

})

Vue.mixin(injection)
Vue.mixin(time)
Vue.mixin(log)
Vue.mixin(shortcuts)
Vue.mixin(mWindow)

Vue.use(VTooltip)
Vue.use(panZoom)
Vue.use(shortKey, { prevent: ['input', 'textarea'] })

new Vue({
	_,
	store,
	api,
	pusher,
	constants,
	chaperone,
	util,
	moment,
	monitor,
	pubsub,
	render: h => h(App)
}).$mount('#app')