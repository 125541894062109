import Vue from 'vue'
import Vuex from 'vuex'
import cookies from 'js-cookie'

import modSettings from './store/settings'
import modTime from './store/time'
import modRoom from './store/room'
import modDevice from './store/device'
import modNotes from './store/notes'
import modMarking from './store/marking'
import modSession from './store/session'
import modSchedule from './store/schedule'
import modEvent from './store/event'
import modOnline from './store/online'
import modChats from './store/chats'
import modPeople from './store/people'
import modMessages from './store/messages'
import modIncidents from './store/incidents'
import modOverview from './store/overview'
import modStations from './store/stations'
import modCircuits from './store/circuits'
import modChaperone from './store/chaperone'
import modAnnouncements from './store/announcements'
import modShredded from './store/shredded'
import modStructure from './store/structure'
import modLanguage from './store/language'
import modOnboarding from './store/onboarding'
import modPlayback from './store/playback'
import modFlags from './store/flags'
import modUser from './store/user'

Vue.use(Vuex)

export default new Vuex.Store({

	modules: {
		language: modLanguage,
		settings: modSettings,
		circuits: modCircuits,
		time: modTime,
		room: modRoom,
		structure: modStructure,
        device: modDevice,
        notes: modNotes,
		marking: modMarking,
		session: modSession,
		schedule: modSchedule,
		event: modEvent,
		online: modOnline,
		chats: modChats,
		people: modPeople,
		messages: modMessages,
		incidents: modIncidents,
		overview: modOverview,
		stations: modStations,
		playback: modPlayback,
		chaperone: modChaperone,
		announcements: modAnnouncements,
		shredded: modShredded,
		onboarding: modOnboarding,
		flags: modFlags,
		user: modUser
	},

	state: {
		key: ''
	},

	getters: {

		key: function(state) {

			return state.key

		},

		user: function(state, getters) {

			return getters['user/get']

		},

		flags: function(state, getters) {

			return getters['flags/get']

		},

		language: function(state, getters) {

			return getters['language/get']

		},

		announcements: function(state, getters) {

			return getters['announcements/get']

		},

		chaperone: function(state, getters) {

			return getters['chaperone/get']

		},

		session: function(state, getters) {

			return getters['session/get']

		},

		schedule: function(state, getters) {

			return getters['schedule/get']

		},

		circuits: function(state, getters) {

			return getters['circuits/get']

		},

		time: function(state, getters) {

			return getters['time/current']

        },

		structure: function(state, getters) {

			return getters['structure/get']

        },
        
		marking: function(state, getters) {

			return getters['marking/get']

		},
        
		playback: function(state, getters) {

			return getters['playback/get']

		},
        
		event: function(state, getters) {

			return getters['event/get']

		},
        
		online: function(state, getters) {

			return getters['online/get']

		},

		chats: function(state, getters) {

			return getters['chats/get']

		},
		
		people: function(state, getters) {

			return getters['people/get']

		},
		
		messages: function(state, getters) {

			return getters['messages/get']

		},

		incidents: function(state, getters) {

			return getters['incidents/get']

		},

		overview: function(state, getters) {

			return getters['overview/get']

		},
	
		stations: function(state, getters) {

			return getters['stations/get']

		}

	},

	mutations: {

		key: function(state, value) {

			state.key = value

		}

	},

	actions: {

		init: function(context, event) {

			if(cookies.get(event) !== undefined) context.commit('key', cookies.get(event))
				
		},

		cookie: function(context, e) {

			if (e.key === undefined) {

				cookies.remove(e.event)
				context.commit('key', undefined)

			} else {

				cookies.set(e.event, e.key)
				context.commit('key', e.key)

			}
				
		}

	}

})
