<template>
	
<div class="item" :class="{'is-active': active}" v-on:click="$emit('click')">

	<div class="item-icon"><i class="fa" :class="{['fa-' + icon]: true}" /></div>

	<div class="item-text">{{ text }}</div>

</div>

</template>

<script>

export default {

	props: ['text', 'icon', 'active']
	
}

</script>

<style scoped>

.item {
	display: flex;
	flex-direction: row;
	width: 100%;
	padding: 10px 20px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.25);
	cursor: pointer;
}

.item.is-active {
	background-color: #11426a;
}

.item-icon {
	width: 40px;
	height: 40px;
	flex-shrink: 0;
	line-height: 40px;
	font-size: 24px;
	margin-right: 10px;
	color: #fff;
}

.item-text {
	line-height: 40px;
	flex-grow: 1;
	color: #fff;
	text-align: left;
	font-size: 18px;
}

</style>