<template>

<div class="schedule">

	<div v-for="(item, index) in schedule" :key="index" class="schedule-item" :class="{'is-break': item.type === $constants.schedule.type.break, 'is-current': isCurrent(item.identity), 'is-next': isNext(item.identity) || isCurrent(item.identity), 'is-completed': isCompleted(item.identity)}">
		
		<div class="schedule-item-name">{{ item.name }}</div>
		<div class="schedule-item-status">{{ itemStatus(item) }}</div>

		<div class="schedule-item-check fa fa-check-circle" v-if="isCompleted(item.identity)"></div>

		<div class="schedule-item-check fa fa-coffee" v-if="!isCompleted(item.identity) && item.type === $constants.schedule.type.break"></div>
		<div class="schedule-item-check fa fa-broadcast-tower is-current" v-if="isCurrent(item.identity)"></div>

	</div>

</div>

</template>

<script>

export default {

	computed: {

		schedule: function() {

			return this.$store.getters['schedule']

		},

		hours: function() {

			return Math.round((this.$store.getters['schedule/start'] - this.time) / 3600)

		}

	},

	methods: {

		itemStatus: function(item) {

			if (this.isNext(item.identity) && !this.isEarly) {

				var difference = item.start - this.time
				var remaining

				if (difference > 60) {

					remaining = Math.floor(difference / 60) + ' minute' + ((Math.floor(difference / 60) === 1) ? '' : 's')

				} else if (difference > 0) {

					remaining = difference + ' second' + ((difference === 1) ? '' : 's')

				} else {

					remaining = 'a moment'

				}

				return (remaining === 'a moment') ? 'Begins once unlocked by an observer' : 'Begins in ' + remaining 

			} else if (this.isCompleted(item.identity)) {

				return 'Completed'

			} else if (this.isCurrent(item.identity)) {

				return 'In progress'

			} else {

				return this.$moment.unix(item.start).utc().tz('Europe/London').format('h:mma')

			}

		},

		isNext: function(identity) {

			return this.$store.getters['schedule/next/identity'] === identity

		},

		isCurrent: function(identity) {

			return this.$store.getters['schedule/active/identity'] === identity

		},

		isCompleted: function(identity) {
		
			return this.$_.contains(this.$store.getters['schedule/completed'], identity)

		}

	}

}

</script>

<style scoped>

.schedule {
	display: flex;
	flex-direction: column;
}

.schedule-item {
	color: #333;
	padding: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	border-bottom: 1px solid #ddd;
}

.schedule-item.is-break {
	background-color: #f1f1f1;
}

.schedule-item-head {
	border-top: 0px;
	background-color: #287ABD;
	color: #fff;
	border-color: #287ABD;
	height: 40px;
}

.schedule-item-name {
	font-size: 18px;
	font-weight: 300;
	margin-bottom: 4px;
}

.schedule-item-head .schedule-item-name {
	margin-bottom: 0px;
	font-weight: 400;
}

.schedule-item-status {
	font-size: 14px;
	font-weight: 300;
}

.schedule-item.is-current {
	background-color: #FBB516;
	color: #fff;
	border-color: #FBB516;
}

.schedule-item.is-current .schedule-item-name {
	font-weight: 400;
}

.schedule-item.is-completed {
	color: #999;
}

.schedule-item-check {
	position: absolute;
	right: 20px;
	top: 50%;
	margin-top: -16px;
	font-size: 32px;
	color: #10ab6b;
}

.schedule-item.is-current .schedule-item-check {
	color: #fff;
}

.schedule-item-check.fa-coffee {
	color: #287ABD;
	font-size: 20px;
	margin-top: -8px;
}

.schedule-item-countdown {
	font-size: 16px;
	line-height: 36px;
	font-weight: 300;
	color: #666;
	position: absolute;
	right: 10px;
	top: 10px;
}

.schedule-item.is-online {
	height: auto;
	border-radius: 0px 0px 4px 4px;
	border: 1px solid #ddd;
}

</style>
