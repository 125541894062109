<template>

<button v-shortkey="shortcut" v-on:shortkey="onClick" class="icon" v-tooltip="tooltip" :disabled="disabled" v-on:click="onClick" :class="{'has-options': hasOptions, 'is-loading': loading, 'is-highlighted': highlight, 'is-user': user, 'is-disabled': disabled, 'is-expanded': expanded, 'with-text': text, 'bounce': counter || (bounce && !loading)}">

	<span class="fa-stack icon-stack" v-if="slash">
		<i class="fa-stack-1x" :class="{[iconClass]: true}"></i>
		<i class="fas fa-slash fa-stack-1x" v-if="slash"></i>
	</span>

	<div class="icon-user" v-if="user"><i class="fa fa-user"></i></div>

	<i :class="{[iconClass]: true}" class="icon-icon" v-if="!slash"></i>

	<div class="icon-initial" v-if="initial">{{ initial }}</div>

	<div class="icon-counter" v-if="counter">{{ counter }}</div>

	<div class="icon-flagged" v-if="flagged"><i class="fas fa-exclamation-circle"></i></div>

    <div class="icon-text">{{ text }}</div>

	<div class="icon-options" v-if="hasOptions">

		<div :onboarding="'button.' + option.value" class="icon-options-item" v-for="option in options" :key="option.value" v-on:click="$emit('click', option.value)" :class="{'is-highlighted': option.highlight}">{{ option.text }}</div>

	</div>

</button>

</template>

<script>

export default {

	props: ['shortcut', 'icon', 'name', 'expanded', 'slash', 'highlight', 'options', 'flagged', 'initial', 'counter', 'bounce',  'user', 'loading', 'text', 'disabled'],

	computed: {

		tooltip: function() {

			var tooltip = this.name

			if (this.shortcut) tooltip += '<small>' + this.shortcut.join(' + ') + '</small>'

			return tooltip

		},

		hasOptions: function() {

			return (this.options) ? this.options.length > 1 : false

		},

		iconClass: function() {

			return 'fas fa-' + this.icon

		}

	},

	methods: {

		onClick: function() {

			if (this.options) {

				if (!this.hasOptions) this.$emit('click', this.options[0].value)

			} else {

				this.$emit('click')

			}

		}

	}

}

</script>

<style scoped>

.icon:focus {
	box-shadow: rgba(50, 50, 93, .1) 0 0 0 1px inset, rgba(50, 50, 93, .2) 0 4px 4px 0, rgba(0, 0, 0, .1) 0 2px 2px 0, rgba(50, 151, 211, .3) 0 0 0 4px;
	z-index: 10;
}

.icon.is-expanded .icon-options,
.icon:focus .icon-options,
.icon:hover .icon-options {
	display: block;
}

.icon.has-options:focus,
.icon.has-options:hover {
	border-bottom-left-radius: 0px!important;
	border-bottom-right-radius: 0px!important;
}

.icon.is-highlighted {
	background-color: #FBB516;
	color: #287abd;
}

.icon-options {
	position: absolute;
	left: -1px;
	top: 43px;
	background-color: #5196cf;
	border-radius: 0px 4px 4px 4px;
	padding: 5px 0px;
	display: none;
}

.icon-options-item {
	font-size: 14px;
	white-space: nowrap;
	line-height: 32px;
	padding: 0px 10px;
	color: #fff;
	font-weight: 500;
	text-align: left;
	text-transform: capitalize;
}

.icon-options-item:focus,
.icon-options-item:hover {
	background-color: #287ABD;
}

.icon-options-item.is-highlighted {
	background-color: #FBB516;
	color: #287abd;
}

.icon {
	border: 1px solid #ddd;
	width: 44px;
	cursor: pointer;
	text-align: center;
	font-size: 24px;
	color: #287ABD;
	height: 44px;
	border-radius: 4px;
	line-height: 42px;
	background-color: white;
	margin-right: 4px;
	z-index: 2;
}

.icon-stack {
	display: block;
	height: 100%!important;
	width: 100%!important;
	line-height: 44px!important;
}

.icon-stack > .fas {
	width: 100%!important;
	height: 100%!important;
	top: 0px!important;
}

.icon.is-disabled {
	pointer-events: none;
	opacity: 0.35;
}

.icon.with-text {
    width: 130px;
    text-align: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 10px;
}

.icon.is-loading {
	background-image: url('data:image/gif;base64,R0lGODlhEAALAPQAACN9xv///0KPzUqU0DSHyvj6/P///9bm84274avN6F2f1ODs9r7Y7Ye336fL51qd1N3q9fr7/brW7DeJy0OQziuCyM3h8UCOzS2DyGGh1XSs2k+X0TCFyQAAAAAAAAAAACH+GkNyZWF0ZWQgd2l0aCBhamF4bG9hZC5pbmZvACH5BAALAAAAIf8LTkVUU0NBUEUyLjADAQAAACwAAAAAEAALAAAFLSAgjmRpnqSgCuLKAq5AEIM4zDVw03ve27ifDgfkEYe04kDIDC5zrtYKRa2WQgAh+QQACwABACwAAAAAEAALAAAFJGBhGAVgnqhpHIeRvsDawqns0qeN5+y967tYLyicBYE7EYkYAgAh+QQACwACACwAAAAAEAALAAAFNiAgjothLOOIJAkiGgxjpGKiKMkbz7SN6zIawJcDwIK9W/HISxGBzdHTuBNOmcJVCyoUlk7CEAAh+QQACwADACwAAAAAEAALAAAFNSAgjqQIRRFUAo3jNGIkSdHqPI8Tz3V55zuaDacDyIQ+YrBH+hWPzJFzOQQaeavWi7oqnVIhACH5BAALAAQALAAAAAAQAAsAAAUyICCOZGme1rJY5kRRk7hI0mJSVUXJtF3iOl7tltsBZsNfUegjAY3I5sgFY55KqdX1GgIAIfkEAAsABQAsAAAAABAACwAABTcgII5kaZ4kcV2EqLJipmnZhWGXaOOitm2aXQ4g7P2Ct2ER4AMul00kj5g0Al8tADY2y6C+4FIIACH5BAALAAYALAAAAAAQAAsAAAUvICCOZGme5ERRk6iy7qpyHCVStA3gNa/7txxwlwv2isSacYUc+l4tADQGQ1mvpBAAIfkEAAsABwAsAAAAABAACwAABS8gII5kaZ7kRFGTqLLuqnIcJVK0DeA1r/u3HHCXC/aKxJpxhRz6Xi0ANAZDWa+kEAA7AAAAAAAAAAAA');
	background-repeat: no-repeat;
	background-position: 50% 50%;
	pointer-events: none;
}

.icon.is-loading > * {
	visibility: hidden;
}

.icon.is-loading,
.icon:hover {
	border-color: #287ABD;
	background-color: #287ABD;
	color: #ffffff;
}

.icon-initial {
	color: #fff;
	font-size: 12px;
	font-weight: bold;
	position: absolute;
	height: 44px;
	width: 44px;
	line-height: 48px;
	text-align: center;
	top: 0px;
	left: 0px;
}

.icon:hover .icon-initial {
	color: #287ABD;
}

.icon-flagged {
	position: absolute;
	height: 16px;
	border-radius: 4px;
	font-size: 16px;
	font-weight: 400;
	color: #ca3434;
	background-color: #fff;
	border-radius: 8px;
	line-height: 16px;
	right: -4px;
	top: -4px;
	z-index: 2;
}

.icon-counter {
	position: absolute;
	height: 16px;
	border-radius: 4px;
	background-color: #ca3434;
	color: #fff;
	min-width: 16px;
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	padding: 0px 4px;
	right: -4px;
	top: -4px;
	z-index: 2;
}

.icon-text {
    font-size: 14px;
    font-weight: 400;
    margin-left: 10px;
}

.icon.is-user .icon-icon {
	font-size: 18px;
}

.icon-user .fa {
	font-size: 12px;
	position: absolute;
	left: 4px;
	top: 4px;
}

.bounce {
	animation: bounce 1.5s;
	z-index: 20;
	animation-iteration-count: infinite;
}

@keyframes bounce {
	0%,
	25%,
	50%,
	75%,
	100% {
		transform: translateY(0);
	}
	40% {
		transform: translateY(-16px);
	}
	60% {
		transform: translateY(-8px);
	}
}

</style>
