export default {

	computed: {

		time: function() {

			return this.$store.getters['time']

		},

		trueTime: function() {

			return this.$store.getters['time/true']

		}

	},

	methods: {

		countdown: function(deadline, asSeconds, trueTime, noMoment) {

			trueTime = trueTime || false
			asSeconds = asSeconds || false
			noMoment = noMoment || false

			var difference = deadline - ((trueTime) ? this.trueTime : this.time)

			if (difference < 0) difference = 0

			if (asSeconds) return difference

			var hours = Math.floor(difference / 3600)
			var minutes = Math.floor((difference - (hours * 3600)) / 60)
			var seconds = difference - (minutes * 60) - (hours * 3600)

			if (difference === 0 && !noMoment && this.$store.getters['session/mode'] === this.$constants.mode.assistant) {

				return 'a moment' 

			} else if(hours) {

				return hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0')
				
			} else {

				return minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0')
				
			}

		}

	}

}