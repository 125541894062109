import _ from 'underscore'
import Pusher from 'pusher-js'
import constants from '@/constants'
import store from '@/store'
import api from './api'
import util from './../util'

// ensure pusher requests include credentials
Pusher.Runtime.createXHR = function () {
	var xhr = new XMLHttpRequest()
	xhr.withCredentials = true
	return xhr
}

// capture pusher logs
Pusher.log = (msg) => {
	console.log(msg)
}

// pusher instance
var instance = false

// pusher channels
var channels = {}

// fallback polling endpoints
var fallbacks = {}

// api root
var authEndpoint = document.getElementById('app').getAttribute('data-api')

// pusher clusters
const dataData = util.fetchClusterDataSync();
const pusherClusters = dataData.pusherClusters;
const pusherClusterKeys = dataData.pusherClusterKeys;
const currentPusherCluster = pusherClusters.eu;

let failoverInterval;

function startFailoverCheck(cluster, resolve, reject) {
	failoverInterval = setInterval(() => {

		let failoverData = util.triggerFailover();
		console.warn(formatMessageWithTimestamp('Current cluster: ' + cluster));
		console.warn(formatMessageWithTimestamp(failoverData.message));

		// If the failover is off but there was a completed reload then remove all the cookies
		// This will move the cluster back to eu and reload the page automatically
		// Or if the failover is on but the pusher_reload_completed cookie is not set up
		if ((!failoverData.isFailover && cluster !== 'eu' && util.getCookie('pusher_had_failover'))
			|| (failoverData.isFailover && cluster === 'eu')
		) {
			// Delete all the pusher failover cookies
			util.deletePusherFailoverCookies();

			// Try re-connecting pusher to the new cluster
			def.handleConnectionFailure(cluster, resolve, reject, failoverData.isFailover);
		}

	}, 10000);
}

function formatMessageWithTimestamp(message) {
	const now = new Date();
	const options = {
		year: 'numeric',
		month: 'numeric',
		day: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
		second: 'numeric',
		hour12: true
	};
	const timestamp = now.toLocaleDateString(undefined, options) + ' ' + now.toLocaleTimeString(undefined, options);
	return `[${timestamp}] ${message}`;
}

var def = {

	// initiate pusher instance
	init: async function(key, cluster = currentPusherCluster, triggerFailover = false) {

		if (store.getters['session/mode'] === constants.mode.dashboard) return false

		return new Promise(function(resolve, reject) {

			let currentCluster = pusherClusters[cluster] ?? currentPusherCluster;

			const pusherData = def.getClusterAndKeyAfterReload(key, currentCluster);
			currentCluster = pusherData.currentCluster
			key = pusherData.key

			instance = new Pusher(key, {
				cluster: currentCluster,  // Use cluster,
				authEndpoint: authEndpoint + 'pusher/auth?cluster=' + currentCluster,
				auth: {
					headers: {
						Authorization: store.getters['session/key']
					},
				}
			})

			// listen to status change to handle unavailability
			instance.connection.bind("state_change", function (states) {

				// force cluster fail-over - hardcoded testing the fail-over
				if (triggerFailover && (currentCluster === 'eu' || currentCluster === 'mt1')) {
					states.current = 'failed';
				}

				if (states.previous === 'connecting' && ['unavailable', 'disconnected', 'failed'].includes(states.current)) {
					util.deletePusherFailoverCookies();
					def.handleConnectionFailure(cluster, resolve, reject);
				} else if (states.current === 'connected') {
					resolve(true);
					def.setSuccessFailoverCookies(currentCluster, triggerFailover)
				}
			})

			// Start failover check
			startFailoverCheck(currentCluster, resolve, reject);
		})

	},

	setSuccessFailoverCookies(currentCluster, triggerFailover) {

		util.setCookie('pusher_cluster', currentCluster, 1);

		// trigger the reload only for non-eu cluster since that means it was a failover
		// also needs to be triggered if the failover was enabled and then disabled
		if (currentCluster === 'eu' && !triggerFailover && !util.getCookie('pusher_had_failover')) {
			return false
		}

		// cookie to tell the code it was a failover and a reload is needed
		util.setCookie('pusher_reload', true, 1);

		// reload is needed and no reload completed, will trigger the reload
		if (util.getCookie('pusher_reload') && !util.getCookie('pusher_reload_completed')) {
			util.setCookie('pusher_reload_completed', true, 1);
			util.setCookie('pusher_had_failover', true, 1);
			location.reload();
		}
	},

	getClusterAndKeyAfterReload(key, currentCluster)  {
		// If there was a cluster failover reload - then use the cluster and key from the cookie
		if (util.getCookie('pusher_reload') && util.getCookie('pusher_reload_completed')) {
			currentCluster = util.getCookie('pusher_cluster')
			key = pusherClusterKeys[currentCluster]
		}

		return {
			key: key,
			currentCluster: currentCluster,
		}
	},

	clearFailoverInterval(currentCluster) {
		if (currentCluster === 'ap4') {
			clearInterval(failoverInterval);
		}
	},

	handleConnectionFailure: function (currentCluster, resolve, reject, triggerFailover = false) {
		const nextCluster = this.getNextCluster(currentCluster);

		if (nextCluster) {
			this.init(pusherClusterKeys[nextCluster], nextCluster, triggerFailover).then(resolve).catch(() => {
				this.handleConnectionFailure(nextCluster, resolve, reject); // Recursive call for the next cluster
			});
		} else {
			reject(); // If all clusters fail, reject the promise
		}
	},

	getNextCluster: function (currentCluster) {
		// Loop through the cluster keys based on the current cluster
		const clusterValues = Object.values(pusherClusters);
		let nextIndex = clusterValues.indexOf(currentCluster) + 1;

		if (nextIndex >= clusterValues.length) {
			nextIndex = 0; // start again with the EU cluser?
		}

		return clusterValues[nextIndex];
	},

	// test cluster without overriding default instance
	test: function(key, cluster) {

		return new Promise(function(resolve) {

			var test = new Pusher(key, {
				cluster: cluster,
				authEndpoint: authEndpoint + 'pusher/auth',
				auth: {
					headers: {
						Authorization: store.getters['session/key']
					}
				}
			})

			// listen to status change to determine success
			test.connection.bind("state_change", function (states) {

				if (states.previous === 'connecting' && states.current === 'unavailable') {

					resolve(false)

				} else if (states.previous === 'connecting' && states.current === 'disconnected') {

					resolve(false)

				} else if (states.current === 'failed') {

					resolve(false)

				} else if (states.previous === 'connecting' && states.current === 'connected') {

					resolve(true)

				}

			})

		})

	},

	connected: function() {

		return instance.connection.state === 'connected'

	},

	subscribe: {

		presence: function(identity) {

			return this.do('presence-person', identity, 'presence')

		},

		server: function() {

			return this.do('server', 'global', 'server')

		},

		event: function(identity) {

			return this.do('event', identity, 'event')

		},

		observers: function(identity, name) {

			return this.do('observers', identity, name)

		},

		user: function(identity, name) {

			return this.do('user', identity, name)

		},

		inventory: function(identity) {

			return this.private('inventory', identity)

		},

		monitor: function(identity) {

			return this.private('monitor', identity)

		},

		room: function(identity, name) {

			return this.do('room', identity, name)

		},

		schedule: function(identity, name) {

			return this.do('schedule', identity, name)

		},

		circuit: function(identity, name) {

			return this.do('circuit', identity, name)

		},

		chat: function(identity, name) {

			return this.do('chat', identity, name)

		},

		marksheet: function(identity, name) {

			return this.do('marksheet', identity, name)

		},

		do: function(prefix, identity, name) {

			if (!instance) return false

			name = name || prefix + '.' + identity
			if (!channels[name]) {
				channels[name] = instance.subscribe(prefix + '.' + identity)
			}

			return channels[name]

		},

		private: function(prefix, identity, name) {

			if (!instance) return false

			name = 'private-' + prefix + '.' + identity
			if (!channels[name]) channels[name] = instance.subscribe('private-' + prefix + '.' + identity)

			return channels[name]

		}

	},

	unsubscribe: {

		marksheet: function(identity) {

			return this.do('marksheet', identity)

		},

		inventory: function(identity) {

			return this.private('inventory', identity)

		},

		do: function(prefix, identity, name) {

			if (!instance) return false

			name = name || prefix + '.' + identity

			if (channels[name]) {
				channels[name] = instance.unsubscribe(prefix + '.' + identity)
				delete channels[name]

			}

		},

		private: function(prefix, identity, name) {

			if (!instance) return false

			name = 'private-' + prefix + '.' + identity

			if (channels[name]) {

				instance.unsubscribe('private-' + prefix + '.' + identity)
				delete channels[name]

			}

		}

	},

	emit: {

		monitor: function(identity, event, data) {

			return this.do('monitor', identity, event, data)

		},

		inventory: function(identity, event, data) {

			return this.do('inventory', identity, event, data)

		},

		do: function(prefix, identity, event, data) {

			var name = 'private-' + prefix + '.' + identity
			data = data || {}
			channels[name].trigger('client-' + event, data)

		}

	},

	trigger: function(channel, event, params) {

		if(!instance) return false

		instance.trigger(channel, event, params)

	},

	on: function(channel, event, handler, fallback) {

		// bind pusher channel
		channels[channel].bind(event, handler)

		// remember polling fallback in case pusher is down
		if (fallback) def.fallbacks.add(fallback, handler)

		return channels[channel]

	},

	onPrivate: function(channel, event, handler) {

		channel = channel.join('.')
		channels['private-' + channel].bind('client-' + event, handler)

		return channels[channel]

	},

	offPrivate: function(channel, event, handler) {

		channel = channel.join('.')
		channels['private-' + channel].unbind('client-' + event, handler)

		return channels[channel]

	},

	// fallback handling
	fallbacks: {

		// add fallback
		add: function(fallback, handler) {

			console.log('pusher/fallbacks/add', fallback.endpoint)

			// create fallback object with defaults
			fallbacks[fallback.endpoint] = {
				interval: fallback.interval || 60,
				immediate: fallback.immediate || false,
				timer: false,
				last: 0,
				handler: handler
			}

		},

		// start fallback polling
		start: function() {

			console.log('pusher/fallbacks/start')

			// initiate each fallback
			_.each(fallbacks, function(fallback, endpoint) {

				// set last poll time to one minute ago to avoid unncessary duplicates
				fallback.last = store.getters['time/raw'] - 60

				// if immediate call now
				if (fallback.immediate) {

					def.fallbacks.poll(endpoint)

				// otherwise queue up with a timeout
				} else {

					def.fallbacks.queue(endpoint)

				}

			})

		},

		// queue a fallback call
		queue: function(endpoint) {

			console.log('pusher/fallbacks/queue', endpoint, fallbacks[endpoint].interval)

			fallbacks[endpoint].timer = _.delay(function() { def.fallbacks.poll(endpoint) }, fallbacks[endpoint].interval * 1000)

		},

		// poll a fallback endpoint
		poll: function(endpoint) {

			console.log('pusher/fallbacks/poll', endpoint)

			// poll the endpoint
			api.request('poll/' + endpoint, {
				last: fallbacks[endpoint].last
			}).then(function(json) {

				// update last poll time
				fallbacks[endpoint].last = store.getters['time/raw']

				// pass any responses to handler
				_.each(json.items, function(item) {

					fallbacks[endpoint].handler(item)

				})

				// requeue fallback after success
				def.fallbacks.queue(endpoint)

			}, function() {

				// requeue fallback after fail
				def.fallbacks.queue(endpoint)

			})

		},

		// stop all fallbacks
		stop: function() {

			console.log('pusher/fallbacks/stop')

			_.each(fallbacks, function(fallback) {

				clearTimeout(fallback.timer)

			})

		}

	}

}

export default def
