<template>
	
<div class="resize" v-tooltip="'Resize marksheet'" v-on:mousedown="onDown">

	<i class="fa fa-arrows-v"></i>

</div>

</template>

<script>	

export default {

	data: function() {

		return {
			is: {
				resizing: false
			}
		}

	},

	created: function() {

		window.addEventListener('mousemove', this.onMove.bind(this))
		window.addEventListener('mouseup', this.onUp.bind(this))

	},

	beforeDestroy: function() {

		window.removeEventListener('mousemove', this.onMove.bind(this))
		window.removeEventListener('mouseup', this.onUp.bind(this))

	},

	methods: {

		onDown: function() {

			this.is.resizing = true

		},

		onMove: function(e) {

			if (this.is.resizing) {

				this.$store.commit('marking/height', this.window.height - e.clientY - 64)

			}

		},

		onUp: function() {

			this.is.resizing = false

		}

	}

}

</script>

<style scoped>

.resize {
	position: absolute;
	z-index: 3;
	top: 0px;
	left: 50%;
	width: 80px;
	height: 30px;
	margin-left: -40px;
	background-color: #89b3d5;
	color: #fff;
	line-height: 30px;
	font-size: 16px;
	text-align: center;
	border-radius: 0px 0px 10px 10px;
	cursor: ns-resize;
}

</style>