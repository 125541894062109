<template>

<div class="chat">

	<app-scroll class="chat-messages" ref="messages" v-on:scroll="onScroll">

		<div v-for="message in messages" :key="message.identity" class="chat-messages-item">

			<p><b>{{ username(message.user) }}:</b> {{ message.content }}</p>

			<small>{{ message.time | fromNow() }} &middot; {{ message.time | formatDate('h:mma') }}</small>

		</div>

	</app-scroll>

	<div class="chat-compose">

		<input class="chat-compose-input" v-on:contextmenu="$allowContext" :disabled="is.loading" placeholder="Enter message..." type="text" v-model="compose" v-on:keypress.enter="onSubmit" />
		<app-button :disabled="!compose" :loading="is.loading" class="chat-compose-button" :small="true" v-on:click.native="onSubmit">Send</app-button>

	</div>

</div>

</template>

<script>

export default {

	data: function() {

		return {

			compose: '',

			is: {
				loading: false
			},

			scrollX: 0,
			bottomX: 0

		}

	},

	watch: {

		messages: function() {

			this.$nextTick(function () {

				if (this.scrollX >= this.bottomX) {

					this.$refs.messages.$children[0].$refs.scrollElement.scrollTop = this.$refs.messages.$children[0].$refs.scrollElement.scrollHeight

				}

				this.bottomX = this.$refs.messages.$children[0].$refs.scrollElement.scrollHeight - this.$refs.messages.$children[0].$refs.scrollElement.clientHeight
				this.bottomX = (this.bottomX < 0) ? 0 : this.bottomX

			}.bind(this))

		}

	},

	computed: {

		schedule: function() {

			return this.$store.getters['schedule/active'].identity

		},

		messages: function() {

			var messages = this.$_.where(this.$store.getters['messages'], {
				schedule: this.schedule
			})

			return messages

		}

	},

	methods: {

		onScroll: function(e) {

			this.scrollX = e

		},

		username: function(identity) {

			return this.$store.getters['people'][identity].name

		},

		onSubmit: function() {

			if (this.compose) {

				this.is.loading = true

				this.$api.request('messages/send', {
					schedule: this.schedule,
					content: this.compose
				}).then(function() {

					this.is.loading = false
					this.compose = ''

				}.bind(this), function() {

					this.is.loading = false

				}.bind(this))

			}

		}

	}

}

</script>

<style scoped>

.chat {
	display: flex;
	flex-direction: column;
	padding: 0px!important;
	border-radius: 4px;
	min-height: 64px;
	flex-grow: 1;
	border: 1px solid #ddd;
	background-color: #fff;
	margin-top: 10px;
}

.chat-messages {
	flex-grow: 1;
}

.chat-messages-item {
	padding: 7px 10px;
}

.chat-messages-item p {
	font-size: 14px;
	line-height: 20px;
	font-weight: 300;
	color: #333;
}

.chat-messages-item p b {
	font-weight: 500;
}

.chat-messages-item small {
	font-size: 10px;
	font-weight: 300;
}

.chat-messages-item:nth-child(even) {
	background-color: #efefef;
}

.chat-messages-empty {
	font-size: 24px;
	font-weight: 400;
	position: absolute;
	left: 50%;
	color: #999;
	top: 50%;
	transform: translate(-50%, -50%);
}

.chat-compose {
	flex-shrink: 0;
	height: 32px;
	border-top: 1px solid #ddd;
	line-height: 32px;
	display: flex;
	flex-direction: row;
}

.chat-compose-input {
	flex-grow: 1;
	height: 32px;
	line-height: 32px;
	flex-basis: 0;
	margin-right: 10px;
	padding-left: 10px;
	max-width: 150px;
	font-size: 14px;
	color: #666;
	font-weight: 400;
}

.chat-compose-input:focus {
    border: 2px solid #4285f4;
}

</style>
