<template>

<div class="schedule">

	<input v-model="search" type="text" placeholder="Quick search by candidate or examiner reference..." class="schedule-search" />

	<div class="schedule-day" v-for="(day, index) in days" :key="index">

		<div class="schedule-day-title" v-if="days.length > 1">{{ day.date | formatDate('Do MMMM') }}</div>

		<div tabindex="0" class="schedule-round" v-for="(round, time) in rounds(day.value)" :key="time" :data-initial="circuitInitial" v-on:keypress.enter="toggleExpand(time)" v-on:click="toggleExpand(time)" :class="{'has-colour': $colour, 'is-expanded': $_.contains(expanded, time), 'is-done': allCompleted(round)}" :style="{borderColor: $colour}">

			<div class="schedule-round-head">

				<div class="schedule-round-head-time">
					{{ time | formatDate('HH:mm:ss') }}
					<template v-if="roundIndex(round)">- Round {{ roundIndex(round) }} of {{ totalRounds }}</template>
				</div>

				<div class="schedule-round-head-info" v-if="allCompleted(round)">
				
					<div v-if="canPlayback && roundStations(round)" class="schedule-round-head-info-item" v-tooltip="'Total playback videos ready'"><i class="fa fa-play-circle" /><span>{{ roundPlayback(round) }}</span></div>

				</div>

			</div>

			<div class="schedule-round-body">

				<div tabindex="0" class="schedule-round-item" v-tooltip.top-end="tooltip(item)" v-for="(item, index) in round" :key="index" v-on:keypress.enter.stop="onScheduleClick(item)" v-on:click="onScheduleClick(item)" :class="{'is-completed': isCompleted(item), 'is-active': isCurrent(item), 'has-playback': hasPlayback(item.identity), 'is-break': item.type === $constants.schedule.type.break}">

					<div class="schedule-round-item-icon fa fa-users" v-if="item.type === $constants.schedule.type.meeting && (item.format === $constants.meeting.format.standard || !item.format)"></div>
					<div class="schedule-round-item-icon fas fa-chalkboard-teacher" v-if="item.type === $constants.schedule.type.meeting && item.format === $constants.meeting.format.webinar"></div>
					<div class="schedule-round-item-icon fa fa-coffee" v-if="item.type === $constants.schedule.type.break && item.format == $constants.interval.format.standard"></div>
					<div class="schedule-round-item-icon fa fa-hourglass-half" v-if="item.type === $constants.schedule.type.break && item.format == $constants.interval.format.activity"></div>
					<div class="schedule-round-item-icon fa fa-graduation-cap" v-if="item.type === $constants.schedule.type.station && !isEmpty(item.candidate)"></div>
					<div class="schedule-round-item-icon fa fa-user-slash" v-if="item.type === $constants.schedule.type.station && isEmpty(item.candidate)"></div>

					<div class="schedule-round-item-name">{{ item.name }}</div>

					<div class="schedule-round-item-status" v-if="!isCompleted(item)">{{ itemStatus(item) }}</div>

					<div class="schedule-round-item-status" v-if="isCompleted(item) && item.type === $constants.schedule.type.station">
						<div v-for="(reference, index) in itemSummary(item)" :key="index">{{ reference }}</div>
					</div>

					<div class="schedule-round-item-people" :class="{'is-current': isCurrent(item)}" v-if="item.type === $constants.schedule.type.station && !isCompleted(item)">
						<div class="schedule-round-item-people-item" :class="{'is-online': isOnline(item.candidate), 'is-ready': isReady(item.candidate), 'is-offline': isOffline(item.candidate)}">{{ $language.role.c }}</div>
						<div v-for="id in item.roleplayer" :key="id" class="schedule-round-item-people-item" :class="{'is-online': isOnline(id), 'is-ready': isReady(id), 'is-offline': isOffline(id)}">{{ $language.role.r }}</div>
						<div v-for="id in item.examiner" :key="id" class="schedule-round-item-people-item" :class="{'is-online': isOnline(id), 'is-ready': isReady(id), 'is-offline': isOffline(id)}">{{ $language.role.e }}</div>
						<div v-for="id in item.layexaminer" :key="id" class="schedule-round-item-people-item" :class="{'is-online': isOnline(id), 'is-ready': isReady(id), 'is-offline': isOffline(id)}">{{ $language.role.l }}</div>
					</div>

					<div class="schedule-round-item-check is-green fa fa-check-circle" v-if="isCompleted(item) && !hasPlayback(item.identity)"></div>
					<div class="schedule-round-item-check fa fa-broadcast-tower" v-if="isCurrent(item)"></div>
					<div class="schedule-round-item-check is-blue fa fa-play-circle" v-if="isCompleted(item) && hasPlayback(item.identity)"></div>

				</div>

			</div>

		</div>

	</div>

</div>

</template>

<script>

export default {

	data: function() {

		return {
			is: {
				joining: false
			},
			search: '',
			expanded: []
		}

	},

	computed: {

		circuitInitial: function() {

			return this.$store.getters['circuits/current'] ? this.$store.getters['circuits/associated'][this.$store.getters['circuits/current']].initial : 'A'

		},

		totalRounds: function() {

			return this.$store.getters['stations'].length

		},

		canPlayback: function() {

			return this.hasPermission(this.$constants.observer.permission.playback) && this.$store.getters['event/playback'].enabled

		},

		days: function() {

			return this.$store.getters['event'].days

		},

		people: function() {

			return this.$_.pluck(this.$_.filter(this.$store.getters['people'], function(item) {
				return item.circuit === this.$store.getters['circuits/current'] 
			}.bind(this)), 'identity')

		},

		schedule: function() {

			var schedule

			schedule = this.$_.filter(this.$store.getters['overview'], function(item) {

				if (item.circuit) {

					return item.circuit === this.$store.getters['circuits/current']

				} else if(this.$isEventMarking || this.$isEventCalibration) {

					return true

				} else {

					return this.$_.intersection(item.participants, this.people).length

				}

			}.bind(this))

			if (this.search) {

				var term = this.search.toLowerCase()

				var users = this.$_.filter(this.$store.getters['people'], function(person) {

					return person.reference.toLowerCase().indexOf(term) >= 0 || person.name.toLowerCase().indexOf(term) >= 0

				})

				users = this.$_.pluck(users, 'identity')

				schedule = this.$_.filter(schedule, function(item) {

					return this.$_.intersection(item.participants, users).length || this.$_.intersection(item.examiner, users).length || this.$_.intersection(item.layexaminer, users).length || this.$_.contains(users, item.candidate)

				}.bind(this))

			}

			return schedule

		}

	},

	methods: {
		
		hasPlayback: function(id) {

			return this.canPlayback && this.$_.contains(this.$store.getters['playback/ready'], id)

		},

		rounds: function(day) {

			var rounds = {}

			this.$_.each(this.schedule, function(item) {

				if (item.day === day) {

					rounds[item.start] = rounds[item.start] || []

					rounds[item.start].push(item)

				}

			})

			return rounds

		},

		toggleExpand: function(round) {

			if (this.$_.contains(this.expanded, round)) {

				this.expanded.splice(this.expanded.indexOf(round), 1)

			} else {

				this.expanded.push(round)

			}

		},

		roundIndex: function(round) {

			var index = false

			this.$_.each(round, function(item) {
				
				if (item.type === this.$constants.schedule.type.station) {
					
					index = item.round
				}

			}.bind(this))

			return index

		},

		roundStations: function(round) {

			var total = 0

			this.$_.each(round, function(item) {
				
				if (item.type === this.$constants.schedule.type.station) total++

			}.bind(this))

			return total

		},

		roundPlayback: function(round) {

			var total = 0

			this.$_.each(round, function(item) {
				
				if (this.$_.contains(this.$store.getters['playback/ready'], item.identity)) total++

			}.bind(this))

			return total


		},

		allCompleted: function(round) {

			var total = 0

			this.$_.each(round, function(item) {

				if (this.isCompleted(item)) total++

			}.bind(this))

			return total === round.length

		},

		tooltip: function(schedule) {

			if (schedule.type !== this.$constants.schedule.type.station) return false

			var tooltip = []
			var presence = ''

			this.$_.each(schedule.participants, function(participant) {

				presence = 'is-offline'

				if (this.isOnline(participant)) presence = 'is-online'
				if (this.isReady(participant)) presence = 'is-ready'

				tooltip.push('<div class="schedule-tooltip-participants-item ' + presence + '">' + this.$store.getters['people'][participant].name + '<small>' + this.$store.getters['language/role'](this.$store.getters['people'][participant].role) + '</small></div>')

			}.bind(this))
			
			return {
				content: tooltip.join(''),
				classes: ['schedule-tooltip']
			}

		},

		isOnline: function(person) {

			return this.$_.contains(this.$store.getters['online'], person)

		},

		isReady: function(person) {

			return this.$_.contains(this.$store.getters['online/ready'], person)

		},

		isOffline: function(person) {

			return !this.$_.contains(this.$store.getters['online'], person)

		},

		isEmpty: function(person) {

			return (person) ? this.$store.getters['people'][person].empty : false

		},

		onScheduleClick: function(item) {

			if (this.isCompleted(item) && this.hasPlayback(item.identity)) {

				this.$emit('playback', item.identity)

			} else if (item.type !== this.$constants.schedule.type.break && !this.isCompleted(item)) {

				this.$emit('join', item.identity)

			}

		},

		itemSummary: function(item) {

			var summary = []

			summary.push(this.$store.getters['people'][item.candidate].reference)

			this.$_.each(item.examiner, function(id) {

				summary.push(this.$store.getters['people'][id].reference)

			}.bind(this))

			return summary

		},

		itemStatus: function(item) {

			if (this.isNext(item)) {

				var difference = item.start - this.time
				var remaining

				if (difference > 60) {

					remaining = Math.floor(difference / 60) + ' minute' + ((Math.floor(difference / 60) === 1) ? '' : 's')

				} else {

					remaining = difference + ' second' + ((difference === 1) ? '' : 's')

				}

				return 'Begins in ' + remaining

			} else if (this.isCompleted(item)) {

				return 'Completed'

			} else if (this.isCurrent(item)) {

				return 'In progress'

			} else {

				var minutes = Math.floor((item.end - item.start) / 60)

				return 'Duration: ' + minutes + ' minute' + ((minutes === 1) ? '' : 's')

			}

		},

		isNext: function() {

			return false

		},

		isCurrent: function(item) {

			return item.start < this.time && item.end > this.time

		},

		isCompleted: function(item) {
		
			return item.end < this.time

		}

	}

}

</script>

<style scoped>

.schedule {
	display: flex;
	flex-direction: column;
}

.schedule-search {
	border: 2px solid #ddd;
	padding: 10px;
	margin-bottom: 10px;
	border-radius: 4px;
	height: 32px;
	font-size: 14px;
	margin-left: 1px;
}

.schedule-search:focus {
    border: 2px solid #4285f4;
}

.schedule-day {
	display: flex;
	flex-direction: column;
}

.schedule-day-title {
	border-radius: 4px;
	margin-bottom: 10px;
	background-color: #287ABD;
	color: #fff;
	padding: 10px;
	font-size: 16px;
	font-weight: 400;
}

.schedule-round {
	margin: 0px 10px 10px 0px;
	width: 100%;
}

.schedule-round:before {
	content: attr(data-initial);
	color: rgba(0, 0, 0, 0.75);
	font-size: 14px;
	font-weight: 400;
	width: 32px;
	text-align: center;
	line-height: 32px;
	position: absolute;
	left: -32px;
	top: 0px;
	z-index: 3;
}

.schedule-round.is-done {
	cursor: pointer;
}

.schedule-round.is-done:not(.is-expanded) {
	height: 32px;
	opacity: 0.5;
}

.schedule-round.is-done:not(.is-expanded) .schedule-round-body {
	display: none;
}

.schedule-round.is-done:focus,
.schedule-round.is-done:hover {
	opacity: 1;
}

.schedule-round.has-colour {
	border-left: 32px solid transparent;
	border-radius: 4px 0px 0px 4px;
}

.schedule > *:last-child {
	margin-bottom: 0px;
}

.schedule-round-head {
	background-color: #287ABD;
	color: #fff;
	height: 32px;
	font-size: 16px;
	line-height: 32px;
	padding: 0px 10px;
	border-radius: 4px;
	margin-left: 1px;
	display: flex;
	justify-content: space-between;
}

.schedule-round:focus .schedule-round-head {
	background-color: #205f93;
}

.schedule-round-head-info-item {
	display: flex;
	height: 32px;
	align-items: center;
}

.schedule-round-head-info-item .fa {
	font-size: 16px;
}

.schedule-round-head-info-item span {
	font-size: 13px;
	line-height: 18px;
	display: block;
	padding-top: 2px;
	margin-left: 4px;
}

.schedule-round-body {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	overflow: hidden;
	background-color: #fff;
}

.schedule-round.has-colour .schedule-round-body {
	border-radius: 0px 0px 4px 0px;
	border-left: 0px;
}

.schedule-round-item {
	background-color: #fff;
	font-size: 16px;
	line-height: 20px;
	padding: 6px 10px;
	cursor: pointer;
	padding-left: 46px;
	border: 1px solid #ddd;
	width: calc(33% - 1px);
	border-radius: 4px;
	margin: 1px 0px 0px 1px;
}

.schedule-round-item:nth-child(3n) {
	width: calc(34% - 1px);
}

.schedule-round-item.is-break {
	cursor: initial;
	pointer-events: none;
}

.schedule-round-item.is-complete {
	cursor: initial;
}

.schedule-round-item.is-active {
	background-color: #FBB516;
	color: #fff;
	border-color: #FBB516;
}

.schedule-round-item:nth-child(3n) {
	border-right: 0px;
}

.schedule-round-item:focus,
.schedule-round-item:hover {
	border-color: #287ABD;
}

.schedule-round-item-icon {
	position: absolute;
	left: 10px;
	top: 50%;
	margin-top: -10px;
	font-size: 20px;
}

.schedule-round-item-name {
	font-size: 16px;
	font-weight: 300;
}

.schedule-round-item.is-active .schedule-round-item-name { 
	font-weight: 400;
}

.schedule-round-head {
	font-weight: 400;
}

.schedule-round-item-status {
	font-size: 12px;
	font-weight: 300;
	display: flex;
}

.schedule-round-item-status div:after {
	content: '\b7';
	color: #999;
	padding: 0px 3px;
}

.schedule-round-item-status div:last-child:after {
	display: none;
}

.schedule-round-item.is-completed:not(.has-playback) {
	color: #999;
}

.schedule-round-item-people {
	position: absolute;
	right: 10px;
	top: 50%;
	margin-top: -6px;
	font-size: 16px;
	color: #23c64f;
	display: flex;
	flex-direction: row;
}

.schedule-round-item-people.is-current {
	right: 54px;
}

.schedule-round-item-people-item {
	width: 16px;
	height: 16px;
	border-radius: 8px;
	margin-left: 2px;
	background-color: red;
	line-height: 16px;
	color: #fff;
	text-align: center;
	font-size: 12px;
	font-weight: 500;
}

.schedule-round-item-people-item.is-online {
	background-color: #f5c638;
}

.schedule-round-item-people-item.is-ready {
	background-color: #1ed43f;
}

.schedule-round-item-people-item.is-offline {
	background-color: #c62323;
}

.schedule-round-item-check {
	position: absolute;
	right: 10px;
	top: 50%;
	margin-top: -12px;
	font-size: 24px;
	color: #287ABD;
}

.schedule-round-item-check.is-blue {
	color: #287ABD;
}

.schedule-round-item-check.is-green {
	color: #23c6a3;
}

.schedule-round-item.is-active .schedule-round-item-check {
	color: #fff;
}

</style>