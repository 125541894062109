import pusher from '@/service/pusher'
import _ from 'underscore'

export default {

	namespaced: true,

	state: {

		online: [],
		ready: []

	},

	getters: {

		get: function(state) {

			return state.online

		},

		ready: function(state) {

			return state.ready

		}

	},

	mutations: {

		set: function(state, data) {

			state.online = data

		},

		add: function(state, value) {

			if (!_.contains(state.online)) state.online.push(value)

		},

		ready: function(state, value) {

			if (!_.contains(state.online)) state.online.push(value)
			if (!_.contains(state.ready)) state.ready.push(value)

		},

		remove: function(state, value) {

			var online = []

			_.each(state.online, function(id) {

				if (id !== value) online.push(id)

			})

			state.online = online

			var ready = []

			_.each(state.ready, function(id) {

				if (id !== value) ready.push(id)

			})

			state.ready = ready

		}

	},

	actions: {

		init: function(context, data) {

			context.commit('add', context.rootGetters['session/identity'])

			_.each(data.online, function(id) {

				context.commit('add', id)

			})

			_.each(data.ready, function(id) {

				context.commit('ready', id)

			})

		},

		presence: function(context) {

			return new Promise(function(resolve, reject) {

				// subscribe to presence channel
				var presence = pusher.subscribe.presence(context.rootGetters['event/identity'] + '.' + context.rootGetters['session/identity'])

				presence.bind('pusher:subscription_succeeded', function() {
					
					resolve()

				})
				
				presence.bind('pusher:subscription_error', function() {
					
					reject()

				})

				pusher.on('event', 'presence', function(data) {

					_.each(data.online, function(id) {

						context.commit('add', id)

					})

					_.each(data.offline, function(id) {

						context.commit('remove', id)

					})

					_.each(data.ready, function(id) {

						context.commit('ready', id)

					})

				})

			})

		}

	} 

}