import Vue from 'vue'

export default {

	namespaced: true,

	state: {

		data: []

	},

	getters: {

		get: function(state) {

			return state.data

		}

	},

	mutations: {

		set: function(state, data) {

			Vue.set(state, 'data', data)

		}

	},

	actions: {

		init: function(context, data) {

			context.commit('set', data)

		}

	}

}