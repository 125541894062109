<template>
	
<div class="menu">

	<div class="menu-summary">

		<div class="menu-summary-name">{{ name }}</div>
		<div class="menu-summary-role"><template v-if="circuitName">{{ circuitName }}, </template>{{ role }}</div>

	</div>

	<div class="menu-sections">

		<com-item v-if="hasSchedule" text="Schedule" icon="clock" :active="section === $constants.assistant.content.schedule" v-on:click="$emit('sectionChange', $constants.assistant.content.schedule)" />
		<com-item v-if="hasMarksheets" text="Marksheets" icon="marker" :active="section === $constants.assistant.content.marksheets" v-on:click="$emit('sectionChange', $constants.assistant.content.marksheets)" />
		<com-item v-if="hasMarking" text="Marking" icon="marker" :active="section === $constants.assistant.content.marking" v-on:click="$emit('sectionChange', $constants.assistant.content.marking)" />
		<com-item v-if="hasBriefs" text="Briefs" icon="file-alt" :active="section === $constants.assistant.content.briefs" v-on:click="$emit('sectionChange', $constants.assistant.content.briefs)" />
		<com-item v-if="hasInventory" text="Inventory" icon="photo-video" :active="section === $constants.assistant.content.inventories" v-on:click="$emit('sectionChange', $constants.assistant.content.inventories)" />
		<com-item v-if="hasIncidents" text="Incidents" icon="exclamation-triangle" :active="section === $constants.assistant.content.incidents" v-on:click="$emit('sectionChange', $constants.assistant.content.incidents)" />

	</div>

	<div class="menu-foot">

		<com-item text="Logout" icon="sign-out-alt" v-on:click="$emit('logoutClick')" />

	</div>

</div>

</template>

<script>

import comItem from './menu/Item'

export default {

	props: ['section'],

	components: {
		'com-item': comItem
	},

	computed: {

		name: function() {

			return this.$store.getters['people/self'].name || 'No name'

		},

		role: function() {

			return this.$store.getters['language/role'](this.$store.getters['people/self'].role)

		},

		circuitName: function() {

			return (!this.$store.getters['people/self'].circuit) ? false : this.$store.getters['circuits/associated'][this.$store.getters['people/self'].circuit].name

		},

		hasInventory: function() {

			var station = this.$_.findWhere(this.$store.getters['schedule'], {
				type: this.$constants.schedule.type.station
			})

			return (this.isExaminer || this.isLayExaminer) && this.$_.contains(this.$store.getters['event/assistant/content'], this.$constants.assistant.content.inventories) && station.inventory.length

		},

		hasIncidents: function() {

			return this.isRoamingObserver && this.$_.contains(this.$store.getters['event/assistant/content'], this.$constants.assistant.content.incidents) && this.hasPermission(this.$constants.observer.permission.incidents)

		},

		hasSchedule: function() {

			return this.$_.contains(this.$store.getters['event/assistant/content'], this.$constants.assistant.content.schedule)

		},

		hasMarksheets: function() {

			return (this.isExaminer || this.isLayExaminer) && this.$_.contains(this.$store.getters['event/assistant/content'], this.$constants.assistant.content.marksheets)

		},

		hasMarking: function() {

			return this.$_.contains(this.$store.getters['event/assistant/content'], this.$constants.assistant.content.marksheets) && this.hasPermission(this.$constants.observer.permission.marking)

		},

		hasBriefs: function() {

			return (this.isExaminer || this.isLayExaminer) && this.$_.contains(this.$store.getters['event/assistant/content'], this.$constants.assistant.content.briefs)

		}

	}
	
}

</script>

<style scoped>

.menu {
	width: 240px;
	height: 100%;
	position: absolute;
	left: 0px;
	top: 0px;
	background-color: #195485;
	z-index: 2;
	display: flex;
	flex-direction: column;
	left: -240px;
}

.menu-summary {
	padding: 20px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}

.menu-summary-name {
	font-weight: 400;
	font-size: 18px;
	margin-bottom: 4px;
	color: #fff;
}

.menu-summary-role {
	font-size: 14epx;
	color: #fff;
}

.menu-sections {
	flex-grow: 1;
}

</style>