<template>

<div class="schedule">

	<div class="schedule-tabs" v-if="circuits.length > 1">
		
		<div class="schedule-tabs-item" v-for="circuit in circuits" :key="circuit.identity" v-on:click="onCircuitClick(circuit.identity)" :class="{'is-active': currentCircuit === circuit.identity}">
			
			<span class="schedule-tabs-item-colour" v-if="circuit.colour" :style="{backgroundColor: circuit.colour}"></span>{{ circuit.name }}

		</div>

	</div>

	<div class="schedule-content">

		<div class="schedule-round" v-for="(round, time) in rounds" :key="time" v-on:click="toggleExpand(time)" :class="{'has-colour': $colour, 'is-expanded': $_.contains(expanded, time), 'is-done': allCompleted(round)}" :style="{borderColor: $colour}">

			<div class="schedule-round-head">

				<div class="schedule-round-head-text">{{ time | formatDate('HH:mm:ss') }}</div>

				<div class="schedule-round-head-actions" v-if="canLock && !isCompleted(round[0]) && !isCurrent(round[0])">

					<div class="schedule-round-head-actions-button is-play" :class="{'is-loading': is.loading === time}" v-if="isScheduleActive(time)" v-on:click="onProgressClick(time)"><i class="fa fa-unlock"></i></div>
					<div class="schedule-round-head-actions-button is-pause" :class="{'is-loading': is.loading === time}" v-if="!isScheduleActive(time)" v-on:click="onProgressClick(time)"><i class="fa fa-lock"></i></div>

				</div>

			</div>

			<div class="schedule-round-body">

				<div class="schedule-round-item" v-tooltip.top-end="tooltip(item)" v-for="(item, index) in round" :key="index" :class="{'is-completed': isCompleted(item), 'is-active': isCurrent(item), 'is-break': item.type === $constants.schedule.type.break}">

					<div class="schedule-round-item-icon fa fa-users" v-if="item.type === $constants.schedule.type.meeting"></div>
					<div class="schedule-round-item-icon fa fa-coffee" v-if="item.type === $constants.schedule.type.break"></div>
					<div class="schedule-round-item-icon fa fa-graduation-cap" v-if="item.type === $constants.schedule.type.station"></div>

					<div class="schedule-round-item-name">{{ item.name }}</div>
					<div class="schedule-round-item-status">{{ itemStatus(item) }}</div>

					<div class="schedule-round-item-people" :class="{'is-current': isCurrent(item)}" v-if="item.type === $constants.schedule.type.station && isDeliveryOnline && !isCompleted(item)">
						<div class="schedule-round-item-people-item" :class="{'is-online': isOnline(item.candidate), 'is-ready': isReady(item.candidate), 'is-offline': isOffline(item.candidate)}">C</div>
						<div v-for="id in item.roleplayer" :key="id" class="schedule-round-item-people-item" :class="{'is-online': isOnline(id), 'is-ready': isReady(id), 'is-offline': isOffline(id)}">{{ $language.role.r }}</div>
						<div v-for="id in item.examiner" :key="id" class="schedule-round-item-people-item" :class="{'is-online': isOnline(id), 'is-ready': isReady(id), 'is-offline': isOffline(id)}">{{ $language.role.e }}</div>
						<div v-for="id in item.layexaminer" :key="id" class="schedule-round-item-people-item" :class="{'is-online': isOnline(id), 'is-ready': isReady(id), 'is-offline': isOffline(id)}">{{ $language.role.l }}</div>
					</div>

					<div class="schedule-round-item-check fa fa-check-circle" v-if="isCompleted(item)"></div>
					<div class="schedule-round-item-check fa fa-broadcast-tower is-current" v-if="isCurrent(item)"></div>

				</div>

			</div>

		</div>

	</div>

</div>

</template>

<script>

export default {

	data: function() {

		return {
			is: {
				loading: false
			},
			expanded: []
		}

	},

	computed: {

		canLock: function() {

			return this.hasPermission(this.$constants.observer.permission.progression) 

		},

		circuits: function() {

			return this.$_.filter(this.$store.getters['circuits'], function(circuit) {

				return circuit.view === true

			})

		},

		currentCircuit: function() {

			return this.$store.getters['circuits/current']

		},

		rounds: function() {

			var rounds = {}

			this.$_.each(this.schedule, function(item) {

				rounds[item.start] = rounds[item.start] || []

				rounds[item.start].push(item)

			})

			return rounds

		},

		schedule: function() {

			return this.$_.filter(this.$store.getters['overview'], function(item) {
				return item.circuit === this.currentCircuit || item.circuit === false
			}.bind(this))

		},

		fullRounds: function() {

			var rounds = {}

			this.$_.each(this.fullSchedule, function(item) {

				rounds[item.start] = rounds[item.start] || []

				rounds[item.start].push(item)

			})

			return rounds

		},

		fullSchedule: function() {

			return this.$store.getters['overview']

		}

	},

	methods: {

		toggleExpand: function(round) {

			if (this.$_.contains(this.expanded, round)) {

				this.expanded.splice(this.expanded.indexOf(round), 1)

			} else {

				this.expanded.push(round)

			}

		},

		allCompleted: function(round) {

			var total = 0

			this.$_.each(round, function(item) {

				if (this.isCompleted(item)) total++

			}.bind(this))

			return total === round.length

		},

		onCircuitClick: function(identity) {

			this.$store.commit('circuits/current', identity)

		},

		isScheduleActive: function(time) {
		
			var schedule = this.$_.findWhere(this.$store.getters['overview'], {
				start: parseInt(time),
				circuit: this.$store.getters['circuits/current']
			})

			return schedule.active

		},

		tooltip: function(schedule) {

			if (schedule.type !== this.$constants.schedule.type.station) return false

			var tooltip = []
			var presence = ''

			this.$_.each(schedule.participants, function(participant) {

				presence = 'is-offline'

				if (this.isOnline(participant)) presence = 'is-online'
				if (this.isReady(participant)) presence = 'is-ready'

				if (!this.isDeliveryOnline) presence = 'is-no-presence'

				tooltip.push('<div class="schedule-tooltip-participants-item ' + presence + '">' + this.$store.getters['people'][participant].name + '<small>' + this.$store.getters['language/role'](this.$store.getters['people'][participant].role) + '</small></div>')

			}.bind(this))
			
			return {
				content: tooltip.join(''),
				trigger: 'hover click',
				classes: ['schedule-tooltip']
			}

		},

		isOnline: function(person) {

			return this.$_.contains(this.$store.getters['online'], person)

		},

		isReady: function(person) {

			return this.$_.contains(this.$store.getters['online/ready'], person)

		},

		isOffline: function(person) {

			return !this.$_.contains(this.$store.getters['online'], person)

		},

		itemStatus: function(item) {

			if (this.isNext(item)) {

				var difference = item.start - this.time
				var remaining

				if (difference > 60) {

					remaining = Math.floor(difference / 60) + ' minute' + ((Math.floor(difference / 60) === 1) ? '' : 's')

				} else {

					remaining = difference + ' second' + ((difference === 1) ? '' : 's')

				}

				return 'Begins in ' + remaining

			} else if (this.isCompleted(item)) {

				return 'Completed'

			} else if (this.isCurrent(item)) {

				return 'In progress'

			} else {

				var minutes = Math.floor((item.end - item.start) / 60)

				return 'Duration: ' + minutes + ' minute' + ((minutes === 1) ? '' : 's')

			}

		},

		isNext: function() {

			return false

		},

		isCurrent: function(item) {

			var previousInactive = false

			this.$_.each(this.schedule, function(previous) {

				if (!previous.active && item.start > previous.start) previousInactive = true

			})

			return item.start < this.time && item.end > this.time && item.active && !previousInactive

		},

		isCompleted: function(item) {

			var previousInactive = false

			this.$_.each(this.schedule, function(previous) {

				if (!previous.active && item.start > previous.start) previousInactive = true

			})
		
			return item.end < this.time && item.active && !previousInactive

		},

		onProgressClick: function(time) {

			if (!this.is.loading) {

				this.is.loading = time

				var schedule = this.$_.pluck((this.$isObservingMultipleCircuits) ? this.fullRounds[time] : this.rounds[time], 'identity')

				this.$api.request('observer/progress', {
					schedule: schedule,
					active: this.isScheduleActive(time)
				}).then(function() {

					this.is.loading = false

				}.bind(this), function() {

					this.is.loading = false

				}.bind(this))

			}

		}

	}

}

</script>

<style scoped>

.schedule-tabs {
	display: flex;
	width: 100%;
}

.schedule-tabs-item {
	flex-grow: 1;
	flex-shrink: 0;
	height: 54px;
	line-height: 54px;
	color: #333;
	text-align: center;
	text-transform: capitalize;
	font-size: 18px;
	border-bottom: 4px solid #fff;
	display: flex;
	align-items: center;
	cursor: pointer;
	justify-content: center;
}

.schedule-tabs-item.is-active {
	border-bottom: 4px solid #287ABD;
}

.schedule-tabs-item-colour {
	width: 16px;
	height: 16px;
	display: inline-block;
	margin-right: 6px;
	border-radius: 50%;
}

.schedule {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
}

.schedule-content {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	width: 100%;
	padding: 15px;
}

.schedule-round {
	margin: 0px 10px 10px 0px;
	width: 100%;
}

.schedule-round.is-done {
	cursor: pointer;
}

.schedule-round.is-done:not(.is-expanded) {
	height: 40px;
	overflow: hidden;
	opacity: 0.5;
}

.schedule-round.is-done:hover {
	opacity: 1;
}

.schedule-round.has-colour {
	border-left: 32px solid transparent;
	border-radius: 4px 0px 0px 4px;
}

.schedule > *:last-child {
	margin-bottom: 0px;
}

.schedule-round-head {
	background-color: #287ABD;
	color: #fff;
	height: 40px;
	font-size: 16px;
	line-height: 40px;
	padding: 0px 10px;
	display: flex;
	border-radius: 4px 4px 0px 0px;
}

.schedule-round.has-colour .schedule-round-head {
	border-radius: 0px 4px 0px 0px;
}

.schedule-round.is-done .schedule-round-head {
	border-radius: 0px 4px 4px 0px;
}

.schedule-round-head-text {
	flex-grow: 1;
}

.schedule-round-head-actions {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	padding: 4px 0px;
}

.schedule-round-head-actions-button {
	width: 32px;
	cursor: pointer;
	height: 32px;
	border-radius: 50%;
	line-height: 32px;
	color: #fff;
	text-align: center;
	margin-left: 4px;
	font-size: 16px;
	background-repeat: no-repeat;
	background-position: 50% 50%;
}

.schedule-round-head-actions-button.is-play {
	background-color: #1ed43f;
}

.schedule-round-head-actions-button.is-loading.is-play {
	background-color: #fff;
	background-image: url('data:image/gif;base64,R0lGODlhEAALAPQAAP///yN9xt7r9tbm8+zz+SiAxyN9xkqU0JO/4nWt28Pb70COzWKi1pnD5Hmv3Mbd70OQziZ/xmak1+nx+N3q9fX4+1OZ0uDs9vP3+7/Z7qzO6dHj8vD1+gAAAAAAAAAAACH+GkNyZWF0ZWQgd2l0aCBhamF4bG9hZC5pbmZvACH5BAALAAAAIf8LTkVUU0NBUEUyLjADAQAAACwAAAAAEAALAAAFLSAgjmRpnqSgCuLKAq5AEIM4zDVw03ve27ifDgfkEYe04kDIDC5zrtYKRa2WQgAh+QQACwABACwAAAAAEAALAAAFJGBhGAVgnqhpHIeRvsDawqns0qeN5+y967tYLyicBYE7EYkYAgAh+QQACwACACwAAAAAEAALAAAFNiAgjothLOOIJAkiGgxjpGKiKMkbz7SN6zIawJcDwIK9W/HISxGBzdHTuBNOmcJVCyoUlk7CEAAh+QQACwADACwAAAAAEAALAAAFNSAgjqQIRRFUAo3jNGIkSdHqPI8Tz3V55zuaDacDyIQ+YrBH+hWPzJFzOQQaeavWi7oqnVIhACH5BAALAAQALAAAAAAQAAsAAAUyICCOZGme1rJY5kRRk7hI0mJSVUXJtF3iOl7tltsBZsNfUegjAY3I5sgFY55KqdX1GgIAIfkEAAsABQAsAAAAABAACwAABTcgII5kaZ4kcV2EqLJipmnZhWGXaOOitm2aXQ4g7P2Ct2ER4AMul00kj5g0Al8tADY2y6C+4FIIACH5BAALAAYALAAAAAAQAAsAAAUvICCOZGme5ERRk6iy7qpyHCVStA3gNa/7txxwlwv2isSacYUc+l4tADQGQ1mvpBAAIfkEAAsABwAsAAAAABAACwAABS8gII5kaZ7kRFGTqLLuqnIcJVK0DeA1r/u3HHCXC/aKxJpxhRz6Xi0ANAZDWa+kEAA7AAAAAAAAAAAA');
}

.schedule-round-head-actions-button.is-pause {
	background-color: #c62323;
}

.schedule-round-head-actions-button.is-loading.is-pause {
	background-color: #fff;
	background-image: url('data:image/gif;base64,R0lGODlhEAALAPQAAP///yN9xt7r9tbm8+zz+SiAxyN9xkqU0JO/4nWt28Pb70COzWKi1pnD5Hmv3Mbd70OQziZ/xmak1+nx+N3q9fX4+1OZ0uDs9vP3+7/Z7qzO6dHj8vD1+gAAAAAAAAAAACH+GkNyZWF0ZWQgd2l0aCBhamF4bG9hZC5pbmZvACH5BAALAAAAIf8LTkVUU0NBUEUyLjADAQAAACwAAAAAEAALAAAFLSAgjmRpnqSgCuLKAq5AEIM4zDVw03ve27ifDgfkEYe04kDIDC5zrtYKRa2WQgAh+QQACwABACwAAAAAEAALAAAFJGBhGAVgnqhpHIeRvsDawqns0qeN5+y967tYLyicBYE7EYkYAgAh+QQACwACACwAAAAAEAALAAAFNiAgjothLOOIJAkiGgxjpGKiKMkbz7SN6zIawJcDwIK9W/HISxGBzdHTuBNOmcJVCyoUlk7CEAAh+QQACwADACwAAAAAEAALAAAFNSAgjqQIRRFUAo3jNGIkSdHqPI8Tz3V55zuaDacDyIQ+YrBH+hWPzJFzOQQaeavWi7oqnVIhACH5BAALAAQALAAAAAAQAAsAAAUyICCOZGme1rJY5kRRk7hI0mJSVUXJtF3iOl7tltsBZsNfUegjAY3I5sgFY55KqdX1GgIAIfkEAAsABQAsAAAAABAACwAABTcgII5kaZ4kcV2EqLJipmnZhWGXaOOitm2aXQ4g7P2Ct2ER4AMul00kj5g0Al8tADY2y6C+4FIIACH5BAALAAYALAAAAAAQAAsAAAUvICCOZGme5ERRk6iy7qpyHCVStA3gNa/7txxwlwv2isSacYUc+l4tADQGQ1mvpBAAIfkEAAsABwAsAAAAABAACwAABS8gII5kaZ7kRFGTqLLuqnIcJVK0DeA1r/u3HHCXC/aKxJpxhRz6Xi0ANAZDWa+kEAA7AAAAAAAAAAAA');
}

.schedule-round-head-actions-button.is-loading i {
	opacity: 0;
}

.schedule-round-body {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	border-radius: 0px 0px 4px 4px;
	overflow: hidden;
	border: 1px solid #ddd;
	background-color: #fff;
}

.schedule-round.has-colour .schedule-round-body {
	border-radius: 0px 0px 4px 0px;
	border-left: 0px;
}

.schedule-round-item {
	background-color: #fff;
	font-size: 16px;
	line-height: 20px;
	padding: 6px 10px;
	cursor: pointer;
	padding-left: 46px;
	width: 33%;
}

.schedule-round-item:nth-child(3n) {
	width: 34%;
}

.schedule-round-item:nth-child(odd) {
	background-color: #efefef;
}

.schedule-round-item.is-break,
.schedule-round-item.is-completed {
	cursor: initial;
	pointer-events: none;
}

.schedule-round-item.is-active {
	background-color: #FBB516;
	color: #fff;
	border-color: #FBB516;
}

.schedule-round-item:nth-child(3n) {
	border-right: 0px;
}

.schedule-round-item:hover {
	background-color: #eee;
}

.schedule-round-item.is-active:hover {
	background-color: #FBB516;
	color: #fff;
	border-color: #FBB516;
}

.schedule-round-item-icon {
	position: absolute;
	left: 10px;
	top: 50%;
	margin-top: -10px;
	font-size: 20px;
}

.schedule-round-item-name {
	font-size: 16px;
	font-weight: 300;
}

.schedule-round-item.is-active .schedule-round-item-name { 
	font-weight: 400;
}

.schedule-round-head {
	font-weight: 400;
}

.schedule-round-item-status {
	font-size: 12px;
	font-weight: 300;
}

.schedule-round-item.is-completed {
	color: #999;
}

.schedule-round-item-people {
	position: absolute;
	right: 10px;
	top: 50%;
	margin-top: -6px;
	font-size: 16px;
	color: #23c64f;
	display: flex;
	flex-direction: row;
}

.schedule-round-item-people.is-current {
	right: 54px;
}

.schedule-round-item-people-item {
	width: 16px;
	height: 16px;
	border-radius: 8px;
	margin-left: 2px;
	background-color: red;
	line-height: 16px;
	color: #fff;
	text-align: center;
	font-size: 12px;
	font-weight: 500;
}

.schedule-round-item-people-item.is-online {
	background-color: #f5c638;
}

.schedule-round-item-people-item.is-ready {
	background-color: #1ed43f;
}

.schedule-round-item-people-item.is-offline {
	background-color: #c62323;
}

.schedule-round-item-check {
	position: absolute;
	right: 10px;
	top: 50%;
	margin-top: -12px;
	font-size: 24px;
	color: #287ABD;
}

.schedule-round-item.is-active .schedule-round-item-check {
	color: #fff;
}

</style>