<template>

<div v-shortkey="['esc']" v-on:shortkey="onCloseClick" tabindex="0" ref="panel" class="chat" v-if="is.active" :class="{'is-active': is.active}">

	<div class="chat-head">

		<template v-if="chat">{{ chatname(chat, true) }}</template>
		<template v-if="!chat">Your messages</template>

		<button class="chat-close" v-tooltip="'Close chat'" v-on:click="onCloseClick"><i class="fa fa-remove"></i></button>

	</div>

	<div class="chat-participants" v-show="participants.length > 2">

		<template class="chat-participants-item" v-for="(participant, index) in participants">
			<template v-if="index === participants.length - 1">and </template>
			<template v-if="participant.identity === $store.getters['session/identity']">You</template>
			<template v-if="participant.identity !== $store.getters['session/identity']">{{ participant.name }}</template>
			<template v-if="index < participants.length - 1">, </template>
			<template v-if="index === participants.length - 1">.</template>
		</template>

	</div>

	<app-scroll class="chat-body" :class="{'is-empty': !chats.length}" v-show="!chat">

		<div tabindex="0" v-for="chat in chats" :key="chat.identity" class="chat-body-item" v-on:keypress.enter="onChatClick(chat)" v-on:click="onChatClick(chat)">

			<div class="chat-body-item-name">{{ chatname(chat) }}</div>
			<div class="chat-body-item-time" v-if="chat.status === $constants.chat.status.active">{{ chat.recent | fromNow() }}</div>
			<div class="chat-body-item-time" v-if="chat.status === $constants.chat.status.finished">Chat has ended.</div>
			<div class="chat-body-item-counter" v-if="chatcount(chat)">{{ chatcount(chat) }}</div>

		</div>

	</app-scroll>

	<app-scroll class="chat-messages-wrapper" ref="messages" v-show="chat">

		<div v-for="message in messages" :key="message.identity" class="chat-messages-item" :class="{'is-system': !message.user}">

			<p>
				<b v-if="message.user">{{ username(message.user) }}</b> 
				{{ content(message.content) }}
			</p>

			<small v-if="message.user">
				{{ message.time | fromNow() }} &middot; {{ message.time | formatDate('h:mma') }} 
				<div v-if="message.user === $store.getters['session/identity'] || chat.participants.length > 2" class="chat-messages-item-read" :class="{[readclass(message)]: true}" v-tooltip="readtip(message)" />
			</small>

		</div>

	</app-scroll>

	<div class="chat-foot" v-if="!chat">

		<app-button theme="white" v-on:click.native="onStartClick">Start chat</app-button>

	</div>

	<div class="chat-foot" v-if="chat && !isChatFinished">

		<textarea ref="input" class="chat-foot-compose" v-on:contextmenu="$allowContext" :disabled="is.loading" placeholder="Enter message..." v-model="compose" v-on:keypress.enter="onSubmit"></textarea>

		<app-button :disabled="!compose" theme="white" :loading="is.loading" class="chat-compose-button" v-on:click.native="onSubmit">Send</app-button>

	</div>

	<div class="chat-actions" v-if="chat && canGroupChat && !isChatFinished">

		<button class="chat-actions-item" :class="{'is-loading': is.actioning === 'add'}" v-on:click="onChatAddClick" v-if="hasPermission(this.$constants.observer.permission.groupchat)">Add user</button>
		<button class="chat-actions-item" :class="{'is-loading': is.actioning === 'leave'}" v-on:click="onChatLeaveClick" v-if="participants.length > 2">Leave chat</button>
		<button class="chat-actions-item" :class="{'is-loading': is.actioning === 'end'}" v-on:click="onChatEndClick">End chat</button>

	</div>

</div>

</template>

<script>

export default {

	data: function() {

		return {
			selectedChat: false,
			compose: '',
			is: {
				actioning: false,
				loading: false,
				active: false,
				direct: false
			},
			interval: false
		}

	},

	watch: {

		chats: {

			deep: true,

			handler: function() {

				if (this.selectedChat && !this.chat) this.selectedChat = false

			}

		},

		messages: function() {

			if (this.chat) {

				this.$nextTick(function () {

					this.$refs.messages.$children[0].$refs.scrollElement.scrollTop = this.$refs.messages.$children[0].$refs.scrollElement.scrollHeight

				}.bind(this))

				this.$api.request('chat/read', {
					chat: this.chat.identity,
					time: this.$store.getters['time']
				})

				this.$store.commit('chats/read', {
					chat: this.chat.identity,
					time: this.$store.getters['time']
				})
				
			}

		}

	},

	computed: {

		canGroupChat: function() {

			return this.hasPermission(this.$constants.observer.permission.groupchat) || this.isRoamingObserver

		},

		isChatFinished: function() {

			return (this.chat) ? this.chat.status === this.$constants.chat.status.finished : false

		},

		participants: function() {

			if (this.chat) {

				var participants = []

				this.$_.each(this.chat.participants, function(identity) {

					participants.push(this.$store.getters['people'][identity])

				}.bind(this))

				return participants

			} else {

				return []

			}

		},

		chat: function() {

			return (this.selectedChat) ? this.$_.findWhere(this.chats, {
				identity: this.selectedChat
			}) : false

		},

		chats: function() {

			return this.$_.sortBy(this.$store.getters['chats'], 'recent').reverse()

		},

		messages: function() {

			return this.$_.sortBy(this.$_.where(this.$store.getters['messages'], {
				chat: this.chat.identity
			}), 'time')

		}

	},

	created: function() {

		this.$pubsub.$on('panel.chats', this.onOpen.bind(this))
		this.$pubsub.$on('panel.chats.open', this.onOnlyOpen.bind(this))
		this.$pubsub.$on('panel.chats.chat', this.onChatClick.bind(this))
		this.$pubsub.$on('panel.chats.direct', this.onDirectOpen.bind(this))
		this.interval = setInterval(function() {
			this.$forceUpdate()
		}.bind(this), 1000)

	},

	beforeDestroy: function() {

		this.$pubsub.$off('panel.chats', this.onOpen.bind(this))
		this.$pubsub.$off('panel.chats.open', this.onOnlyOpen.bind(this))
		this.$pubsub.$off('panel.chats.chat', this.onChatClick.bind(this))
		this.$pubsub.$off('panel.chats.direct', this.onDirectOpen.bind(this))
		clearInterval(this.interval);

	},

	methods: {

		onChatAddClick: function() {

			this.$pubsub.$emit('modal.chat', {
				chat: this.selectedChat
			})

		},

		onChatLeaveClick: function() {

			this.is.actioning = 'leave'

			this.$api.request('chat/leave', {
				chat: this.chat.identity
			}).then(function() {

				this.is.actioning = false

			}.bind(this), function() {

				this.is.actioning = false

			}.bind(this))

		},

		onChatEndClick: function() {

			this.is.actioning = 'end'

			this.$api.request('chat/end', {
				chat: this.chat.identity
			}).then(function() {

				this.is.actioning = false

			}.bind(this), function() {

				this.is.actioning = false

			}.bind(this))

		},

		readtotal: function(message) {

			var readTotal = 0

			this.$_.each(this.chat.reads, function(value, key) {

				if (value >= message.time && key !== this.$store.getters['session/identity']) readTotal++

			}.bind(this))

			return readTotal

		},

		readclass: function(message) {

			var readTotal = this.readtotal(message)

			if (readTotal === 0) return 'is-none'
			else if (readTotal < this.chat.participants.length - 1) return 'is-some'
			else if (readTotal === this.chat.participants.length - 1) return 'is-all'

		},

		readtip: function(message) {

			var readTotal = this.readtotal(message)

			if (this.chat.participants.length === 2) {

				return (readTotal) ? 'Read' : 'Not read'

			} else {

				var seen = []

				this.$_.each(this.chat.reads, function(value, key) {

					if (value >= message.time && key !== this.$store.getters['session/identity']) seen.push(this.$store.getters['people'][key].name)

				}.bind(this))

				return (seen.length) ? 'Read by ' + seen.join(', ') : 'Not read'

			}

		},

		username: function(identity) {

			return (identity === this.$store.getters['session/identity']) ? 'You' : this.$store.getters['people'][identity].name

		},

		content: function(content) {

			var matches = content.match(/{{([a-zA-Z0-9]{8})}}/)

			if (matches !== null) {

				var username = this.$store.getters['people'][matches[1]].name || 'Unnamed'

				content = content.replace(matches[0], username)

			}

			return content

		},

		chatname: function(chat, asHead) {

			asHead = asHead || false

			var name = []

			if (chat.name) return chat.name

			if (!asHead || chat.participants.length === 2) {

				this.$_.each(chat.participants, function(identity) {

					if (identity !== this.$store.getters['session/identity']) {

						name.push(this.$store.getters['people'][identity].name)

					}

				}.bind(this))

				if (name.length > 6) {

					var total = name.length

					name = name.slice(0, 6)

					var difference = total - name.length

					name.push('and ' + difference.toString() + ' other' + ((difference > 1) ? 's' : ''))

				}
				
			} else {

				name.push('Multiple people')

			}

			return name.join(', ')

		},

		chatcount: function(chat) {

			return this.$_.filter(this.$store.getters['messages'], function(message) {

				return message.time > chat.read && message.user !== this.$store.getters['session/identity'] && message.chat === chat.identity

			}.bind(this)).length

		},

		onOpen: function() {

			this.is.direct = false
			this.selectedChat = false
			this.is.active = !this.is.active

			this.$nextTick(function() {

				if (this.is.active) this.$refs.panel.focus()
        this.$refs.messages.$children[0].$refs.scrollElement.scrollTop = this.$refs.messages.$children[0].$refs.scrollElement.scrollHeight

			}.bind(this))

		},

		onOnlyOpen: function() {

			this.is.active = true

		},

		onDirectOpen: function(chat) {

			this.is.active = true
			this.is.direct = true

			this.onChatClick(chat)

			this.$nextTick(function() {

				if (this.is.active) this.$refs.panel.focus()
        this.$refs.messages.$children[0].$refs.scrollElement.scrollTop = this.$refs.messages.$children[0].$refs.scrollElement.scrollHeight

			}.bind(this))

		},

		onChatClick: function(chat) {

			this.compose = ''
			this.selectedChat = chat.identity

			this.$api.request('chat/read', {
				chat: this.chat.identity,
				time: this.$store.getters['time']
			})

			this.$store.commit('chats/read', {
				chat: this.chat.identity,
				time: this.$store.getters['time']
			})

			this.$nextTick(function() {

				this.$refs.input.focus()
        this.$refs.messages.$children[0].$refs.scrollElement.scrollTop = this.$refs.messages.$children[0].$refs.scrollElement.scrollHeight

			}.bind(this))

		},

		onCloseClick: function() {

			if (this.chat) {

				this.$api.request('chat/read', {
					chat: this.chat.identity,
					time: this.$store.getters['time']
				})

				this.$store.commit('chats/read', {
					chat: this.chat.identity,
					time: this.$store.getters['time']
				})

				if (this.is.direct) {
					
					this.is.active = false

				} else {

					this.selectedChat = false

				}

			} else {

				this.is.active = false

			}

		},

		onStartClick: function() {

			this.$pubsub.$emit('modal.chat')

		},

		onSubmit: function() {

			if (this.compose) {

				this.is.loading = true

				this.$api.request('messages/send', {
					chat: this.chat.identity,
					content: this.compose
				}).then(function() {

					this.is.loading = false
					this.compose = ''
          this.$refs.messages.$children[0].$refs.scrollElement.scrollTop = this.$refs.messages.$children[0].$refs.scrollElement.scrollHeight

				}.bind(this), function() {

					this.is.loading = false

				}.bind(this))

			}

		}

	}

}

</script>

<style scoped>

.chat {
	position: fixed;
	left: -256px; 
	top: 0px;
	height: calc(100% - 54px);
	width: 256px;
	display: flex;
	flex-direction: column;
	background-color: #287ABD;
	justify-content: flex-start;
	z-index: 10;
	transition: left 300ms ease-in-out;
}

.chat.is-active {
	left: 0px;
}

.chat-head {
	flex-shrink: 0;
	padding: 10px;
	padding-right: 40px;
	font-size: 24px;
	color: #fff;
	height: 48px;
	font-weight: 300;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.chat-participants {
	padding: 0px 10px 8px 10px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	display: flex;
	flex-wrap: wrap;
	color: #fff;
	flex-shrink: 0;
	font-size: 12px;
	font-weight: 300;
	line-height: 16px;
}

.chat-participants-item {
	font-weight: 400;
}

.chat-messages-wrapper {
	flex-grow: 1;
}

.chat-messages.is-empty:before {
	content: 'There are no messages.';
	font-size: 14px;
	color: #fff;
	font-weight: 400;
	width: calc(100% - 20px);
	padding: 10px;
	position: absolute;
	text-align: center;
	top: 50%;
	transform: translateY(-50%);
}

.chat-messages-item {
	padding: 10px;
}

.chat-messages-item p {
	font-size: 16px;
	line-height: 20px;
	font-weight: 300;
	color: #fff;
}

.chat-messages-item.is-system p {
	font-size: 14px;
	text-align: center;
	font-weight: 500;
	padding: 10px 0px;
}

.chat-messages-item p b {
	font-weight: 500;
	font-size: 16px;
	line-height: 14px;
	margin-bottom: 2px;
	display: block;
}

.chat-messages-item-read {
	border-radius: 50%;
	width: 10px;
	height: 10px;
	border: 2px solid #fff;
	margin-left: 10px;
	overflow: hidden;
}

.chat-messages-item-read.is-none {
	border-color: #c62323;
}

.chat-messages-item-read.is-some {
	border-color: #f5c638;
}

.chat-messages-item-read.is-some:before {
	display: block;
	width: 3px;
	top: 0px;
	height: 6px;
	background-color: #f5c638;
	content: '';
	position: absolute;
	left: 0px;
	top: 0px;
}

.chat-messages-item-read.is-all {
	border-color: #1ed43f;
	background-color: #1ed43f;
}

.chat-messages-item small {
	font-size: 12px;
	font-weight: 300;
	margin-top: 6px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	color: #fff;
}

.chat-messages-item:nth-child(even) {
	background-color: rgba(0, 0, 0, 0.1);
}

.chat-body {
	flex-grow: 1;
}

.chat-body.is-empty:before {
	content: 'You have no active chats.';
	font-size: 14px;
	color: #fff;
	font-weight: 400;
	width: calc(100% - 20px);
	padding: 10px;
	position: absolute;
	text-align: center;
	top: 50%;
	transform: translateY(-50%);
}

.chat-body-item {
	padding: 10px;
	cursor: pointer;
}

.chat-body-item:focus,
.chat-body-item:hover {
	background-color: rgba(0, 0, 0, 0.25);
}

.chat-body-item:nth-child(even) {
	background-color: rgba(0, 0, 0, 0.1);
}

.chat-body-item-name {
	font-size: 20px;
	font-weight: 400;
	color: #fff;
	line-height: 24px;
	margin-bottom: 2px;
}

.chat-body-item-time {
	font-size: 14px;
	font-weight: 300;
	color: #fff;
}

.chat-body-item-counter {
	position: absolute;
	height: 16px;
	border-radius: 4px;
	background-color: red;
	color: #fff;
	min-width: 16px;
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	padding: 0px 4px;
	right: 10px;
	top: 14px;
	z-index: 2;
}

.chat-foot {
	flex-shrink: 0;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	padding: 10px;
}

.chat-foot >>> .button {
	width: 100%;
}

.chat-foot >>> .button:focus {
	box-shadow: rgba(255, 255, 255, 0.5) 0 0 0 1px inset, rgb(255 255 255 / 50%) 0 2px 2px 0, rgba(0, 0, 0, .1) 0 4px 4px 0, rgb(255 255 255 / 50%) 0 0 0 4px;
}

.chat-foot-compose {
	width: 100%;
	background-color: #fff;
	border-radius: 4px;
	line-height: 20px;
	height: 74px;
	font-size: 14px;
	color: #333;
	font-weight: 400;
	padding: 7px 10px;
	margin-bottom: 4px;
}

.chat-foot-compose:focus {
	box-shadow: rgba(255, 255, 255, 0.5) 0 0 0 1px inset, rgb(255 255 255 / 50%) 0 2px 2px 0, rgba(0, 0, 0, .1) 0 4px 4px 0, rgb(255 255 255 / 50%) 0 0 0 4px;
}

.chat-close {
	position: absolute;
	right: 4px;
	height: 40px;
	width: 40px;
	top: 4px;
	line-height: 40px;
	color: #fff;
	font-size: 24px;
	text-align: center;
	cursor: pointer;
}

.chat-close:focus {
	border-radius: 50%;
	box-shadow: rgba(255, 255, 255, 0.5) 0 0 0 1px inset, rgb(255 255 255 / 50%) 0 4px 4px 0, rgba(0, 0, 0, .1) 0 2px 2px 0, rgb(255 255 255 / 50%) 0 0 0 4px;
}

.chat-actions {
	flex-shrink: 0;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	padding: 5px 10px;
	display: flex;
}

.chat-actions-item {
	flex-grow: 1;
	flex-shrink: 0;
	text-align: center;
	font-size: 12px;
	color: #fff;
	border-radius: 4px;
	padding: 5px;
	cursor: pointer;
}

.chat-actions-item:focus,
.chat-actions-item:hover {
	background-color: rgba(0, 0, 0, 0.1);
}

</style>