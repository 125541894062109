<template>

    <app-page class="page">

        <div class="page-inner" onboarding="dashboard">
    
            <h1>Hello, Chris!<small>Choose an option below.</small></h1>
        
            <com-menu />
        
            <com-launch />
        
            <com-foot />

        </div>
    
    </app-page>
    
    </template>
    
    <script>
    
    import comMenu from './dashboard/Menu'
    import comLaunch from './dashboard/Launch'
    import comFoot from './dashboard/Foot'
    
    export default {
    
        components: {
    
            'com-menu': comMenu,
            'com-foot': comFoot,
            'com-launch': comLaunch
    
        }
    
    }
    
    </script>
    
    <style scoped>
    
    .page {
        position: absolute;
        left: 0px;
        top: 0px;
        right: 0px;
        bottom: 0px;
        display: flex;
        flex-direction: column;
        overflow: visible!important;
        transition: all 100ms linear;
        margin-left: 0px;
        justify-content: center;
        align-items: center;
    }
    
    .page-inner {
        padding: 20px;
    }
    
    .page h1 {
        font-size: 24px;
        text-align: center;
        font-weight: 500;
        color: #333;
        margin-bottom: 30px;
    }
    
    .page small {
        font-size: 16px;
        font-weight: 400;
        display: block;
        margin-top: 4px;
    }
    
    </style>