<template>

<div class="shredded" ref="wrapper">

	<iframe :src="embedURL" class="shredded-embed" v-if="is.loaded && !is.shredded"></iframe>

	<template v-if="is.shredded">

		<panZoom class="shredded-panzoom" v-if="is.loaded" v-on:init="onInitiated" :options="options">

			<div class="shredded-wrapper" :style="styleWrapper">

				<div class="shredded-page" v-for="(page, index) in pages" :key="index" :style="{width: page.size.x + 'px', height: page.size.y + 'px'}">

					<div class="shredded-piece" v-for="(part, index) in page.parts" :key="index" :style="{width: page.segment.x + 'px', height: page.segment.y + 'px', backgroundImage: 'url(' + part + ')'}"></div>

				</div>

			</div>

		</panZoom>

		<div class="is-zoom shredded-controls" v-if="showNote">
			<button class="shredded-controls-button" v-tooltip="'Zoom in'" v-on:click="onZoomIn"><i class="fa fa-search-plus"></i></button>
			<button class="shredded-controls-button" v-tooltip="'Zoom out'" v-on:click="onZoomOut"><i class="fa fa-search-minus"></i></button>
		</div>

		<div class="is-scroll shredded-controls" v-if="showNote">
			<button class="shredded-controls-button" v-tooltip="'Scroll up'" v-on:click="onScrollUp"><i class="fa fa-chevron-up"></i></button>
			<button class="shredded-controls-button" v-tooltip="'Scroll down'" v-on:click="onScrollDown"><i class="fa fa-chevron-down"></i></button>
		</div>

		<div class="shredded-controls is-reset">
			<button class="shredded-controls-button" v-tooltip="'Reset position'" v-on:click="onReset"><i class="far fa-dot-circle"></i></button>
		</div>

		<button class="shredded-note" v-if="is.helping && showNote" v-on:click="onHelpClick" v-tooltip="'Click to close'">
			Tip: you can drag the page to pan, use the arrow keys or mouse wheel to scroll up or down, or double click to zoom. <i class="fa fa-remove"></i>
		</button>

	</template>

</div>

</template>

<script>

export default {

	props: ['showNote', 'embed', 'data', 'initialZoom'],

	data: function() {

		return {
			pages: [],
			is: {
				helping: true,
				loaded: false,
				shredded: false
			},
			panzoom: false,
			options: {
				disableKeyboardInteraction: true,
				initialZoom: 0.4,
				maxZoom: 1,
				minZoom: 0.4,
				smoothScroll: true,
				zoomSpeed: 0.065,
				bounds: true,
				beforeWheel: function(e) {

					if (e.deltaY > 0) {

						this.onScrollDown()

					} else if(e.deltaY < 0) {

						this.onScrollUp()

					}
					
					return true

				}.bind(this)
			},
			size: {
				x: 0,
				y: 0
			}
		}

	},

	computed: {

		embedURL: function() {

			if (this.embed) return this.embed

			var url = ['shredded', this.data.type, this.data.identity]

			if (this.data.brief) url.push(this.data.brief)

			url = url.join('/')

			var extension = (this.$store.getters['event/shredding/enabled']) ? 'json' : 'pdf'

			url = this.$api.base() + url + '.' + extension + '?key=' + this.$store.getters['key']

			return url

		},

		styleWrapper: function() {

			return {
				width: this.size.x.toString() + 'px',
				height: (this.size.y * 1.25).toString() + 'px'
			}

		}

	},

	watch: {

		showNote: function() {

			this.$_.delay(function() {

				if (this.panzoom) this.panzoom.smoothMoveTo((this.showNote) ? (this.$refs.wrapper.offsetWidth / 2) - (this.size.x * 0.2) : 10, (this.showNote) ? 25 : 10)

			}.bind(this), 600)

		}

	},

	created: function() {

		this.is.shredded = this.$store.getters['event/shredding/enabled']

		if (this.embed) this.is.loaded = true

		this.options.initialZoom = this.initialZoom || 0.4

		this.$store.dispatch('shredded/cache', this.data).then(this.onLoaded.bind(this), this.onFailed.bind(this))

	},

	methods: {

		onReset: function() {

			this.options.initialZoom = 0.4
			this.panzoom.zoomAbs(0, 0, 0.4)
			this.panzoom.moveTo((this.showNote || this.$refs.wrapper.offsetWidth > 400) ? (this.$refs.wrapper.offsetWidth / 2) - (this.size.x * 0.2) : 10, (this.showNote) ? 25 : 10)
			
		},

		onInitiated: function(el) {

			this.panzoom = el

			this.panzoom.moveTo((this.showNote || this.$refs.wrapper.offsetWidth > 400) ? (this.$refs.wrapper.offsetWidth / 2) - (this.size.x * 0.2) : 10, (this.showNote) ? 25 : 10)

		},

		onLoaded: function(json) {

			if (this.is.shredded) {

				this.pages = json.pages
				this.size = json.size

			}

			this.is.loaded = true

		},

		onFailed: function() {

		},

		onHelpClick: function() {

			this.is.helping = false

		},

		onScrollUp: function() {

			this.panzoom.moveBy(0, 100, true)

		},

		onScrollDown: function() {

			this.panzoom.moveBy(0, -100, true)

		},

		onZoomIn: function() {

			this.options.initialZoom += 0.1
			this.options.initialZoom = (this.options.initialZoom > this.options.maxZoom) ? this.options.maxZoom : this.options.initialZoom
			this.panzoom.smoothZoomAbs(this.$refs.wrapper.offsetWidth / 2, this.$refs.wrapper.offsetHeight / 2, this.options.initialZoom)

		},

		onZoomOut: function() {

			this.options.initialZoom -= 0.1
			this.options.initialZoom = (this.options.initialZoom < this.options.minZoom) ? this.options.minZoom : this.options.initialZoom
			this.panzoom.smoothZoomAbs(this.$refs.wrapper.offsetWidth / 2, this.$refs.wrapper.offsetHeight / 2, this.options.initialZoom)

		}

	}

}

</script>

<style scoped>

.shredded {
	background-color: #ddd;
	flex-grow: 1;
	flex-basis: 0;
	overflow: hidden;
	height: 100%;
}

.shredded-embed {
	width: 100%;
	height: 100%;
}

.shredded-panzoom {
	width: 100%;
	height: 100%;
}

.shredded-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.shredded-page {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	background-color: #fff;
	margin-bottom: 25px;
}

.shredded-page:last-child {
	margin-bottom: 0px;
}

.shredded-piece {
	background-size: cover;
}

.shredded-note {
	position: absolute;
	left: 10px;
	bottom: 10px;
	z-index: 2;
	background-color: #287ABD;
	width: 240px;
	color: #fff;
	font-size: 14px;
	padding-right: 34px;
	line-height: 18px;
	font-weight: 300;
	text-align: left;
	padding: 10px;
	border-radius: 4px;
	cursor: pointer;
}

.shredded-note:hover {
	background-color: #205f93;
}

.shredded-note .fa {
	position: absolute;
	right: 3px;
	top: 4px;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	width: 24px;
}

.shredded-controls {
	position: absolute;
	right: 10px;
	z-index: 2;
	display: flex;
	flex-direction: column;
}

.shredded-controls.is-zoom {
	top: 10px;
}

.shredded-controls.is-scroll {
	bottom: 10px;
}

.shredded-controls.is-reset {
	top: 10px;
	left: 10px;
	right: auto;
}

.shredded-controls-button {
	width: 40px;
	height: 40px;
	line-height: 40px;
	color: #fff;
	background-color: #287ABD;
	border-radius: 4px;
	font-size: 24px;
	text-align: center;
	cursor: pointer;
}

.shredded-controls-button:focus,
.shredded-controls-button:hover {
	background-color: #1b649e;
}

.shredded-controls-button:first-child {
	margin-bottom: 2px;
}

</style>
