<template>

<div tabindex="0" class="user">

	<div class="user-head">
			
		<div class="user-head-name">{{ profile.user.name }}</div>

		<div class="user-head-actions">
			<button class="user-head-actions-item" v-on:click="onLogClick">View log <i class="fa fa-list-alt" /></button>
			<button class="user-head-actions-item" v-on:click="expanded = !expanded" v-if="!expanded">Expand <i class="fa fa-caret-down" /></button>
			<button class="user-head-actions-item" v-on:click="expanded = !expanded" v-if="expanded">Collapse <i class="fa fa-caret-up" /></button>
		</div>
			
	</div>

	<div class="user-body">

		<div class="user-content">
				
			<div tabindex="0" class="user-content-item">

				<div class="user-content-item-content is-connection" :data-strength="strength">

					<i class="fa fa-ban" v-if="!profile.data.online" />

					<span class="user-content-item-content-empty" v-if="profile.data.online && !profile.data.strength">
					
						Connection strength pending
					
					</span>

					<template v-if="profile.data.online && profile.data.strength">
					
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>

					</template>
					
				</div>

				<div class="user-content-item-label">Connection</div>

			</div>

			<div tabindex="0" class="user-content-item" data-span="2">

					<div class="user-content-item-content">
					
						<div class="user-content-item-content-metrics" data-size="2">

							<div class="user-content-item-content-metrics-item">
								<div class="user-content-item-content-metrics-item-value">{{ formatUptime }}</div>
								<div class="user-content-item-content-metrics-item-label">Current uptime</div>
							</div>

							<div class="user-content-item-content-metrics-item">
								<div class="user-content-item-content-metrics-item-value">{{ profile.data.sessions.total }}</div>
								<div class="user-content-item-content-metrics-item-label">Total sessions</div>
							</div>

						</div>	
					
					</div>
					
					<div class="user-content-item-label">Sessions</div>

				</div>

				<div tabindex="0" class="user-content-item" data-span="4">

					<div class="user-content-item-content">
					
						<div class="user-content-item-content-metrics" data-size="4">

							<div class="user-content-item-content-metrics-item">
								<div class="user-content-item-content-metrics-item-value">{{ profile.data.rooms.disconnects }}</div>
								<div class="user-content-item-content-metrics-item-label">Disconnects</div>
							</div>

							<div class="user-content-item-content-metrics-item">
								<div class="user-content-item-content-metrics-item-value">{{ duration(profile.data.rooms.lost) }}</div>
								<div class="user-content-item-content-metrics-item-label">Time lost</div>
							</div>

							<div class="user-content-item-content-metrics-item" :data-status="(profile.data.rooms.system_flags) ? 'fail' : false">
								<div class="user-content-item-content-metrics-item-value">{{ profile.data.rooms.system_flags }}</div>
								<div class="user-content-item-content-metrics-item-label">Re-run threshold</div>
							</div>

							<div class="user-content-item-content-metrics-item" :data-status="(profile.data.rooms.roleplayer_flags) ? 'amber' : false">
								<div class="user-content-item-content-metrics-item-value">{{ profile.data.rooms.roleplayer_flags }}</div>
								<div class="user-content-item-content-metrics-item-label">Flagged</div>
							</div>

						</div>	
					
					</div>
					
					<div class="user-content-item-label">Stations</div>

				</div>
				
				<div class="user-content-item is-split">

					<button class="user-content-item-content is-check" :disabled="profile.data.check.count === 0" :class="{'is-disabled': profile.data.check.count === 0}" v-on:click="onCheckClick" v-tooltip="(profile.data.geolocation) ? 'Click to view device check history' : false" :data-status="(profile.data.check.passed) ? 'pass' : 'fail'">
					
						<div class="user-content-item-label">Device check</div> 
						<i class="fa fa-check" v-if="profile.data.check.count === 1" />
						<i class="fa fa-times" v-if="profile.data.check.count === 0" />
						<div class="user-content-item-bubble" v-if="profile.data.check.count > 1">{{ profile.data.check.count }}</div>
					
					</button>

					<button class="user-content-item-content is-location" :disabled="!profile.data.geolocation" :class="{'is-disabled': !profile.data.geolocation}" v-on:click="onLocationClick" v-tooltip="(profile.data.geolocation) ? 'Click to view location' : false" :data-status="(profile.data.geolocation) ? 'pass' : 'fail'">
					
						<div class="user-content-item-label">Geolocation</div> 
						<i class="fa fa-check" v-if="profile.data.geolocation" />
						<i class="fa fa-times" v-if="!profile.data.geolocation" />
					
					</button>

				</div>

				<div tabindex="0" class="user-content-item" data-span="8" v-if="expanded">

					<div class="user-content-item-content">

						<com-timeline :data="profile.data.rooms.timeline" type="status" />
					
					</div>
					
					<div class="user-content-item-label">Online status</div>

				</div>

				<div tabindex="0" class="user-content-item" data-span="4" v-if="expanded">

					<div class="user-content-item-content">
					
						<div class="user-content-item-content-metrics" data-size="4">

							<div class="user-content-item-content-metrics-item">
								<div class="user-content-item-content-metrics-item-value">{{ profile.data.server.logins.average }}<small>ms</small></div>
								<div class="user-content-item-content-metrics-item-label">Avg. login</div>
							</div>

							<div class="user-content-item-content-metrics-item">
								<div class="user-content-item-content-metrics-item-value">{{ profile.data.server.briefs.average }}<small>ms</small></div>
								<div class="user-content-item-content-metrics-item-label">Avg. brief</div>
							</div>

							<div class="user-content-item-content-metrics-item">
								<div class="user-content-item-content-metrics-item-value">{{ profile.data.server.endpoints.average }}<small>ms</small></div>
								<div class="user-content-item-content-metrics-item-label">Avg. endpoint</div>
							</div>

							<div class="user-content-item-content-metrics-item">
								<div class="user-content-item-content-metrics-item-value">{{ profile.data.server.rate }}<small>%</small></div>
								<div class="user-content-item-content-metrics-item-label">Success rate</div>
							</div>

						</div>	
					
					</div>
					
					<div class="user-content-item-label">Server</div>

				</div>

				<div tabindex="0" class="user-content-item" v-if="expanded">

					<div class="user-content-item-content">
					
						<div class="user-content-item-content-metrics">

							<div class="user-content-item-content-metrics-item">
								<div class="user-content-item-content-metrics-item-value">{{ profile.data.rooms.average }}<small>ms</small></div>
								<div class="user-content-item-content-metrics-item-label">Avg. join</div>
							</div>

						</div>	
					
					</div>
					
					<div class="user-content-item-label">Rooms</div>

				</div>

				<div tabindex="0" class="user-content-item" data-span="3" v-if="expanded">

					<div class="user-content-item-content">
					
						<div class="user-content-item-content-metrics" data-size="4">

							<div class="user-content-item-content-metrics-item">
								<div class="user-content-item-content-metrics-item-value">{{ profile.data.actions.requests }}</div>
								<div class="user-content-item-content-metrics-item-label">Requests</div>
							</div>

							<div class="user-content-item-content-metrics-item">
								<div class="user-content-item-content-metrics-item-value">{{ profile.data.actions.refreshes }}</div>
								<div class="user-content-item-content-metrics-item-label">Refreshes</div>
							</div>

							<div class="user-content-item-content-metrics-item">
								<div class="user-content-item-content-metrics-item-value">{{ profile.data.actions.logouts }}</div>
								<div class="user-content-item-content-metrics-item-label">Logouts</div>
							</div>

							<div class="user-content-item-content-metrics-item">
								<div class="user-content-item-content-metrics-item-value">{{ profile.data.actions.exits }}</div>
								<div class="user-content-item-content-metrics-item-label">Exits</div>
							</div>

						</div>	
					
					</div>
					
					<div class="user-content-item-label">Actions</div>

				</div>

				<div tabindex="0" class="user-content-item" data-span="8" v-if="expanded">

					<div class="user-content-item-content">

						<com-timeline :data="profile.data.rooms.timeline" type="strength" />
					
					</div>
					
					<div class="user-content-item-label">Connection strength</div>

				</div>

			</div>

		</div>

</div>

</template>

<script>

import comTimeline from './user/Timeline'

export default {

	props: ['profile'],

	components: {
		'com-timeline': comTimeline
	},

	data: function() {

		return {
			expanded: false
		}

	},

	methods: {

		duration: function(value) {

			if (!value) return '00:00'
		
			var minutes = Math.floor(value / 60)
			var seconds = value - (minutes * 60)

			return minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0')

		},

		onLogClick: function() {

			this.$pubsub.$emit('connections.log.toggle', {
				data: this.profile
			})

		},

		onLocationClick: function() {

			this.$pubsub.$emit('connections.location.toggle', {
				data: this.profile
			})

		},

		onCheckClick: function() {

			this.$pubsub.$emit('connections.check.toggle', {
				data: this.profile
			})

		}

	},

	computed: {

		strength: function() {

			if (this.profile.data.online && !this.profile.data.strength) {

				return 3

			} else {

				return this.profile.data.strength

			}

		},

		formatUptime: function() {

			if (!this.profile.data.sessions.uptime) return '00:00:00'

			var difference = this.time - this.profile.data.sessions.uptime
			
			var hours = Math.floor(difference / 3600)
			var minutes = Math.floor((difference - (hours * 3600)) / 60)
			var seconds = difference - (minutes * 60) - (hours * 3600)

			return hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0')

		}

	}

}

</script>

<style scoped>

.user {
	background-color: #fff;
	display: flex;
	flex-direction: column;
	margin-bottom: 10px;
}

.user-head {
	background-color: #287ABD;
	display: flex;
	border-radius: 4px;
	overflow: hidden;
	margin-bottom: 10px;
}

.user:focus .user-head {
	background-color: #205f93;
}

.user-head-name {
	font-size: 16px;
	color: #fff;
	font-weight: 400;
	padding: 0px 10px;
	flex-grow: 1;
	line-height: 32px;
}

.user-head-actions {
	flex-shrink: 0;
	display: flex;
}

.user-head-actions-item {
	color: #fff;
	font-size: 14px;
	padding: 0px 10px;
	font-weight: 400;
	line-height: 32px;
	border-left: 1px solid #fff;
	cursor: pointer;
	min-width: 100px;
	text-align: left;
	padding-right: 32px;
}

.user-head-actions-item:focus,
.user-head-actions-item:hover {
	background-color: rgba(0, 0, 0, 0.2);
}

.user-head-actions-item .fa {
	margin-left: 4px;
	position: absolute;
	right: 10px;
	top: 9px;
}

.user-content {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
	grid-gap: 10px;
}

.user-content-item {
	min-height: 90px;
	display: flex;
	flex-direction: column;
	transition: all 100ms linear;
}

.user-content-item-content {
	flex-grow: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid #ddd;
	border-radius: 4px 4px 0px 0px;
}

.user-content-item-content-empty {
	font-size: 14px;
	line-height: 18px;
	font-weight: 400;
	text-align: center;
	color: #333;
}

.user-content-item:focus .user-content-item-content {
	border-color: #205f93;
}

.user-content-item-content.is-disabled {
	pointer-events: none;
}

.user-content-item-label {
	text-align: center;
	line-height: 20px;
	flex-shrink: 0;
	font-size: 12px;
	color: #666;
	font-weight: 500;
	border: 1px solid #ddd;
	border-top: 0px;
	border-radius: 0px 0px 4px 4px;
}

.user-content-item:focus .user-content-item-label {
	border-color: #205f93;
}

.user-content-item[data-span="2"] {
	grid-column: span 2;
}

.user-content-item[data-span="3"] {
	grid-column: span 3;
}

.user-content-item[data-span="4"] {
	grid-column: span 4;
}

.user-content-item[data-span="8"] {
	grid-column: span 8;
}

.user-content-item.is-split {
	border: 0px;
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 10px;
	grid-template-rows: 1fr 1fr;
}

.user-content-item.is-split .user-content-item-content {
	border-radius: 4px;
	display: flex;
	justify-content: flex-start;
	padding: 0px 8px;
}

.user-content-item.is-split .user-content-item-label {
	flex-grow: 1;
	border: 0px;
	font-size: 12px;
	text-align: left;
}

.user-content-item.is-split .fa {
	flex-shrink: 0;
	width: 20px;
	text-align: center;
	flex-shrink: 0;
}

.user-content-item-content.is-metric {
	font-size: 24px;
	font-weight: 500;
	text-align: center;
	color: #333;
}

.user-content-item-bubble {
	width: 20px;
	height: 20px;
	border-radius: 4px;
	background-color: #fff;
	text-align: center;
	line-height: 20px;
	color: #333;
	font-size: 12px;
	flex-shrink: 0;
}

.user-content-item-content[data-status="fail"],
.user-content-item-content[data-status="fail"] .user-content-item-label {
	background-color: #d34545;
	font-weight: 400;
	border-width: 0px;
	color: #fff;
	cursor: pointer;
}

.user-content-item-content[data-status="fail"]:focus,
.user-content-item-content[data-status="fail"]:hover,
.user-content-item-content[data-status="fail"]:focus .user-content-item-label,
.user-content-item-content[data-status="fail"]:hover .user-content-item-label {
	background-color: #dd0000;
}

.user-content-item-content[data-status="fail"] .user-content-item-bubble {
	color: #d34545;
}

.user-content-item-content[data-status="pass"],
.user-content-item-content[data-status="pass"] .user-content-item-label {
	background-color: #21ca21;
	color: #fff;
	font-weight: 400;
	border-width: 0px;
	cursor: pointer;
}

.user-content-item-content[data-status="pass"] .user-content-item-bubble {
	color: #21ca21;
}

.user-content-item-content[data-status="pass"]:focus,
.user-content-item-content[data-status="pass"]:hover,
.user-content-item-content[data-status="pass"]:focus .user-content-item-label,
.user-content-item-content[data-status="pass"]:hover .user-content-item-label {
	background-color: #1cad1c;
}

.user-content-item-content.is-connection {
	border-width: 0px;
	font-size: 32px;
	color: #fff;
}

.user-content-item-content.is-connection > div {
	width: 8px;
	background-color: #fff;
	height: 40px;
	margin: 0px 1px;
}

.user-content-item-content.is-connection > div:nth-child(1) {
	height: 8px;
	margin-top: 32px;
}

.user-content-item-content.is-connection > div:nth-child(2) {
	height: 16px;
	margin-top: 24px;
}

.user-content-item-content.is-connection > div:nth-child(3) {
	height: 24px;
	margin-top: 16px;
}

.user-content-item-content.is-connection > div:nth-child(4) {
	height: 32px;
	color: #fff;
	margin-top: 8px;
}

.user-content-item-content.is-connection[data-strength="0"] {
	background-color: #dd0000;
}

.user-content-item-content.is-connection[data-strength="1"] {
	background-color: #d34545;
}

.user-content-item-content.is-connection[data-strength="2"] {
	background-color: #e68213;
}

.user-content-item-content.is-connection[data-strength="3"] {
	background-color: #f1cf5e;
}

.user-content-item-content.is-connection[data-strength="4"] {
	background-color: #7fd17f;
}

.user-content-item-content.is-connection[data-strength="5"] {
	background-color: #21ca21;
}

.user-content-item-content.is-connection[data-strength="1"] > div:nth-child(2) {
	opacity: 0.25;
}

.user-content-item-content.is-connection[data-strength="1"] > div:nth-child(3),
.user-content-item-content.is-connection[data-strength="2"] > div:nth-child(3) {
	opacity: 0.25;
}

.user-content-item-content.is-connection[data-strength="1"] > div:nth-child(4),
.user-content-item-content.is-connection[data-strength="2"] > div:nth-child(4),
.user-content-item-content.is-connection[data-strength="3"] > div:nth-child(4) {
	opacity: 0.25;
}

.user-content-item-content.is-connection[data-strength="1"] > div:nth-child(5),
.user-content-item-content.is-connection[data-strength="2"] > div:nth-child(5),
.user-content-item-content.is-connection[data-strength="3"] > div:nth-child(5),
.user-content-item-content.is-connection[data-strength="4"] > div:nth-child(5) {
	opacity: 0.25;
}

.user-content-item-content-metrics {
	display: grid;
	width: 100%;
}

.user-content-item-content-metrics[data-size="2"] {
	grid-template-columns: 1fr 1fr;
}

.user-content-item-content-metrics[data-size="3"] {
	grid-template-columns: 1fr 1fr 1fr;
}

.user-content-item-content-metrics[data-size="4"] {
	grid-template-columns: 1fr 1fr 1fr 1fr;
}

.user-content-item-content-metrics-item {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.user-content-item-content-metrics-item-value {
	font-size: 24px;
	font-weight: 500;
	text-align: center;
	color: #333;
}

.user-content-item-content-metrics-item-value small {
	font-size: 12px;
	color: #999;
	margin-left: 2px;
}

.user-content-item-content-metrics-item-label {
	font-size: 14px;
	margin-top: 4px;
	font-weight: 400;
	color: #999;
}

.user-content-item-content-metrics-item[data-status="fail"] .user-content-item-content-metrics-item-label,
.user-content-item-content-metrics-item[data-status="fail"] .user-content-item-content-metrics-item-value,
.user-content-item-content-metrics-item[data-status="fail"] .user-content-item-content-metrics-item-value small {
	color: #d34545;
}

.user-content-item-content-metrics-item[data-status="amber"] .user-content-item-content-metrics-item-label,
.user-content-item-content-metrics-item[data-status="amber"] .user-content-item-content-metrics-item-value,
.user-content-item-content-metrics-item[data-status="amber"] .user-content-item-content-metrics-item-value small {
	color: #ffbe19;
}

</style>