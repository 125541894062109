<template>

<com-card class="file" :expanded="expanded" :hidePin="true" v-on:play="onPlayClick" :playing="is.playing" v-on:pause="onPauseClick" :controls="false" :closable="item.closable" v-on:close="$emit('close')" :class="{'is-image': item.image, 'is-video': item.video}" v-on:trigger="$emit('trigger')" v-on:expand="$emit('expand')" :type="$constants.card.type.file" :revealed="item.revealed" :triggerer="item.triggerer" :availability="item.availability" :interaction="item.interaction" :expandable="!$isInStandardisation || expandable" :title="item.title" :subtitle="item.subtitle">

	<iframe class="file-iframe" :src="item.iframe" v-if="item.iframe" />

	<app-shredded :data="item.shredded" :showNote="showNote" v-if="item.shredded" />

	<img class="file-image" :src="item.image" v-if="item.image" />

	<video ref="video" preload="auto" class="file-video" :controls="false" v-on:canplay="onVideoCanPlay" v-on:play="onVideoPlay" v-on:pause="onVideoPause" v-on:seeking="onVideoSeek" v-on:seeked="onVideoSeeked" v-on:timeupdate="onVideoUpdate" v-if="item.video">
		<source :src="item.video" type="video/mp4">
	</video>

	<div class="file-avatar" v-if="item.avatar" :style="{backgroundImage: 'url(' + item.avatar + ')'}" />

	<div class="file-video-muted" v-tooltip="'Click to unmute'" v-if="isMuted" v-on:click="onUnmuteClick">

		<i class="fa fa-microphone-slash"></i>

	</div>

	<template v-slot:actions v-if="item.video && isInteracting">

		<com-controls v-on:play="onPlayClick" v-on:pause="onPauseClick" v-on:skip="onSkipClick" :is-playing="is.playing" :is-seeking="is.seeking" :position="video.position" :duration="video.duration" />

	</template>

</com-card>

</template>

<script>

import comCard from './Card'
import comControls from './file/Controls'

export default {

	props: ['item', 'showNote', 'expanded', 'expandable', 'closable'],

	components: {
		'com-card': comCard,
		'com-controls': comControls
	},

	data: function() {

		return {
			lastUpdate: 0,
			is: {
				playing: false
			},
			video: {
				position: 0,
				duration: 0
			},
			muted: false
		}

	},

	computed: {

		isMuted: function() {

			return this.muted

		},

		isObserving: function() {

			return (this.item.interaction) ? this.item.interaction === this.$constants.inventory.interaction.observe : false

		},

		isInteracting: function() {

			return (this.isObserving) ? this.isPermitted : true

		},

		isUsuallyPermitted: function() {

			var isPermitted = false

			if (this.item.availability) {

				if (this.item.availability.permission === this.$constants.inventory.availability.permission.role) {

					isPermitted = this.$_.contains(this.item.availability.roles, this.$store.getters['people/self'].role)

				} else if (this.item.availability.permission === this.$constants.inventory.availability.permission.user) {

					isPermitted = this.$_.contains(this.item.availability.logins, this.$store.getters['session/identity'])

				} else if (this.item.availability.permission === this.$constants.inventory.availability.permission.lead) {

					isPermitted = this.isLead

				}

			} 

			return isPermitted

		},

		triggerer: function() {

			return this.item.triggerer

		},

		isPermitted: function() {

			var isPermitted = this.isUsuallyPermitted

			if (this.item.triggerer !== undefined && this.item.triggerer !== this.$store.getters['session/identity']) {

				isPermitted = false

			}

			return isPermitted

		}

	},

	watch: {

		triggerer: function(n, o) {

			if (!n && o === this.$store.getters['session/identity']) {

				this.$refs.video.pause()
				this.$pusher.emit.inventory(this.item.observeIdentity, 'pause')

			}

		}

	},

	created: function() {

		if (this.isObserving) {

			this.$pusher.subscribe.inventory(this.item.observeIdentity).bind('pusher:subscription_succeeded', function() {

				this.$pusher.onPrivate(['inventory', this.item.observeIdentity], 'play', this.onObservePlay)
				this.$pusher.onPrivate(['inventory', this.item.observeIdentity], 'pause', this.onObservePause)
				this.$pusher.onPrivate(['inventory', this.item.observeIdentity], 'seek', this.onObserveSeek)
				this.$pusher.onPrivate(['inventory', this.item.observeIdentity], 'update', this.onObserveUpdate)

				if (this.isUsuallyPermitted) {

					this.$pusher.onPrivate(['inventory', this.item.observeIdentity], 'ping', this.onObservePing)
					
				}
				
				if (!this.isPermitted) {
					
					this.$pusher.emit.inventory(this.item.observeIdentity, 'ping')

				}

			}.bind(this))

		}


	},

	beforeDestroy: function() {

		if (this.isObserving) {

			this.$pusher.offPrivate(['inventory', this.item.observeIdentity], 'play', this.onObservePlay)
			this.$pusher.offPrivate(['inventory', this.item.observeIdentity], 'pause', this.onObservePause)
			this.$pusher.offPrivate(['inventory', this.item.observeIdentity], 'seek', this.onObserveSeek)
			this.$pusher.offPrivate(['inventory', this.item.observeIdentity], 'update', this.onObserveUpdate)

			if (this.isUsuallyPermitted) {

				this.$pusher.offPrivate(['inventory', this.item.observeIdentity], 'ping', this.onObservePing)

			}

			if (this.isPermitted) {

				this.$refs.video.pause()
				this.$pusher.emit.inventory(this.item.observeIdentity, 'pause')

			}
		
			this.$pusher.unsubscribe.inventory(this.item.observeIdentity)

		}

	},

	methods: {

		onUnmuteClick: function() {

			this.$refs.video.muted = false
			this.muted = false

		},

		onObservePing: function() {

			this.lastUpdate = this.$moment().unix()
			
			if (this.item.video) {

				this.$pusher.emit.inventory(this.item.observeIdentity, 'update', {
					time: this.$refs.video.currentTime,
					paused: this.$refs.video.paused
				})

				this.video.position = this.$refs.video.currentTime

			}

		},

		onPlayClick: function() {

			this.$refs.video.play()

			this.is.playing = true

		},

		onPauseClick: function() {

			this.$refs.video.pause()

			this.is.playing = false

		},

		onSkipClick: function(time) {

			this.$refs.video.currentTime = time

		},
		
		onVideoCanPlay: function(e) {

			this.video.duration = e.target.duration 

			this.muted = e.target.muted

		},

		onVideoUpdate: function(e) {

			if (this.isPermitted) {

				if (this.lastUpdate < this.$moment().unix() - 10) {

					this.lastUpdate = this.$moment().unix()

					this.$pusher.emit.inventory(this.item.observeIdentity, 'update', {
						time: e.target.currentTime,
						paused: e.target.paused
					})

				}

				this.video.position = e.target.currentTime

			}

		},

		onObserveUpdate: function(e) {

			this.checkStatus(e)
			if (Math.abs(e.time - this.$refs.video.currentTime) > 5) this.$refs.video.currentTime = e.time

		},

		onVideoPlay: function(e) {

			this.muted = e.target.muted

			if (this.isPermitted) {

				this.lastUpdate = this.$moment().unix()

				this.$pusher.emit.inventory(this.item.observeIdentity, 'play', {
					time: e.target.currentTime,
					paused: e.target.paused
				})

			}

		},

		onObservePlay: function(e) {

			this.$refs.video.play()
			if (e.time) this.$refs.video.currentTime = e.time

		},

		onVideoPause: function(e) {

			this.muted = e.target.muted

			if (this.isPermitted) {

				this.lastUpdate = this.$moment().unix()

				this.$pusher.emit.inventory(this.item.observeIdentity, 'pause', {
					time: e.target.currentTime,
					paused: e.target.paused
				})

			}

		},

		onObservePause: function(e) {

			this.$refs.video.pause()
			if (e.time) this.$refs.video.currentTime = e.time

		},

		onVideoSeek: function(e) {

			this.muted = e.target.muted

			this.is.seeking = true

			if (this.isPermitted) {

				this.lastUpdate = this.$moment().unix()

				this.$pusher.emit.inventory(this.item.observeIdentity, 'seek', {
					time: e.target.currentTime,
					paused: e.target.paused
				})

			}

		},

		onVideoSeeked: function() {
		
			this.is.seeking = false

		},

		onObserveSeek: function(e) {

			this.checkStatus(e)
			this.$refs.video.currentTime = e.time

		},

		checkStatus: function(e) {

			if (e.paused) {

				if (!this.$refs.video.paused) this.$refs.video.pause()

			} else {

				if (this.$refs.video.paused) this.$refs.video.play()

			}

		}

	}

}

</script>

<style scoped>

.file {
	background-color: #fff;
}

.file.is-image >>> .card-body,
.file.is-video >>> .card-body {
	background-color: #000;
	cursor: pointer;
}

.file-iframe {
	width: 100%;
	height: 100%;
}

.file-image {
	max-width: 100%;
	max-height: 100%;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.file-video {
	width: auto;
	z-index: 1;
	display: block;
	height: 100%;
	max-height: 100%;
    left: 50%;
    position: absolute;
	max-width: 100%;
    transform: translateX(-50%);
}

.file-avatar {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 2;
	background-position: 50% 50%;
	background-size: contain;
	background-color: #000;
	background-repeat: no-repeat;
}

.file-video-muted {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0%;
	top: 0px;
	z-index: 2;
	background-color: rgba(0, 0, 0, 0.25);
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.file-video-muted .fa {
	color: #fff;
	font-size: 64px;
}

</style>