<template>

<div class="online">

	<template v-if="current.online">

		There {{ auxiliary }} 
		<template v-if="examinerCount">{{ examinerCount }} <template v-if="examinerCount === 1">{{ $language.role.examiner }}</template><template v-if="examinerCount > 1">{{ $language.role.examiners }}</template></template>
		<template v-if="examinerCount && observerCount"> and </template>
		<template v-if="observerCount">{{ observerCount }} {{ observerNoun }} watching</template>
		<template v-if="!count">no {{ $language.role.observers }} watching</template>.

	</template>
	
	<template v-if="current.chaperoned">

		You are being observed by a chaperone.

	</template>
	
</div>

</template>

<script>

export default {

	props: ['current'],

	computed: {

		count: function() {

			return this.examinerCount + this.observerCount

		},

		observerCount: function() {

			return this.current.observers

		},

		examinerCount: function() {

			return this.current.examainers

		},

		observerNoun: function() {

			return (this.observerCount === 1) ? this.$language.role.observer : this.$language.role.observers

		},

		auxiliary: function() {

			return (this.count === 1) ? 'is' : 'are'

		}

	}

}

</script>

<style scoped>

.online {
	font-size: 12px;
	line-height: 14px;
	font-weight: 300;
	user-select: none;
	color: #333;
}

</style>
