<template>
	
<div class="questions">

	<com-question :onboarding="'marksheet.questions.' + question.identity" v-for="question in questions" :item="question" :locked="locked" :questions="questions" :standardisation="standardisation" :values="sheet" :value="sheet[question.identity]" :key="question.identity" v-on:change="$emit('change', $event)" />

</div>

</template>

<script>	

import comQuestion from './questions/Question'

export default {

	props: ['sheet', 'locked', 'questions', 'standardisation'],

	components: {
		'com-question': comQuestion
	}

}

</script>

<style scoped>

.questions {
	flex-grow: 1;
	overflow: auto;
	display: flex;
	flex-direction: column;
}

</style>