import pusher from '@/service/pusher'

export default {

	namespaced: true,

	state: {

		person: false,
		talking: false,
		chaperone: false,
		list: []

	},

	getters: {

		get: function(state) {

			return state.person

		},

		list: function(state) {

			return state.list

		},

		person: function(state) {

			return state.person

		},

		talking: function(state) {

			return state.talking

		},

		chaperone: function(state) {

			return state.chaperone

		}

	},

	mutations: {

		set: function(state, data) {

			state.person = data

		},

		list: function(state, data) {

			state.list = data

		},

		person: function(state, data) {

			state.person = data

		},

		talking: function(state, data) {

			state.talking = data

		},

		chaperone: function(state, data) {

			state.chaperone = data

		}

	},

	actions: {

		init: function(context, data) {

			context.commit('list', data)

			pusher.on('user', 'chaperone', function(data) {

				context.commit('chaperone', data.identity)

			})

		}

	}

}