<template>
	
<div class="question" :class="{'is-locked': locked}">

	<div class="question-text" v-html="item.text" />

	<com-toggle :standardisation="standardisationValues" :item="item" :value="value" v-if="isToggle" v-on:change="$emit('change', $event)" />
	<com-dropdown :standardisation="standardisationValues" :disabled="disabled" :min="min" :max="max" :item="item" :value="value" v-if="isDropdown" v-on:change="$emit('change', $event)" />

</div>

</template>

<script>	

import comToggle from './question/Toggle'
import comDropdown from './question/Dropdown'

export default {

	props: ['item', 'value', 'questions', 'locked', 'values', 'standardisation'],

	components: {
		'com-toggle': comToggle,
		'com-dropdown': comDropdown
	},

	computed: {

		standardisationValues: function() {

			var values = []

			this.$_.each(this.standardisation, function(answers, key) {

				values.push({
					examiner: key,
					value: answers[this.item.identity]
				})

			}.bind(this))

			return values

		},

		isToggle: function() {

			return this.$_.contains([
				this.$constants.marking.question.type.toggle,
				this.$constants.marking.question.type.pbf,
				this.$constants.marking.question.type.epbf,
				this.$constants.marking.question.type.cpfc,
				this.$constants.marking.question.type.cpbbfc,
				this.$constants.marking.question.type.ucbpc
			], this.item.type)

		},

		isDropdown: function() {

			return this.$_.contains([
				this.$constants.marking.question.type.dropdown
			], this.item.type)

		},

		linkedMark: function() {

			var mark = false

			this.$_.each(['1','2','3'], function(index) {

				if (this.item.link === this.$constants.marking.question.link.domain.feedback[index]) {

					mark = this.getMarkByLink(this.$constants.marking.question.link.domain.mark[index]) 

				}

			}.bind(this))

			return mark

		},

		disabled: function() {

			return this.linkedMark === null || this.linkedMark === this.$constants.marking.mark.clearpass

		},

		min: function() {

			return (this.linkedMark === this.$constants.marking.mark.clearfail || this.linkedMark === this.$constants.marking.mark.fail) ? 1 : 0

		},

		max: function() {

			if (this.linkedMark === this.$constants.marking.mark.pass) { 

				/* var ownLength = (this.value === null) ? 0 : this.value.length

				var total = 0

				this.$_.each(['1','2','3'], function(index) {

					var mark = this.getMarkByLink(this.$constants.marking.question.link.domain.feedback[index])

					total += (mark) ? mark.length : 0

				}.bind(this))

				total -= ownLength

				return (total > 4) ? 0 : 4 - total*/

				return 4

			} else {

				return 4

			}

		}

	},

	methods: {

		getMarkByLink: function(type) {

			var link = this.$_.find(this.questions, function(question) {
						
				return question.link === type

			}.bind(this))

			return (link) ? this.values[link.identity] : false

		}

	}

}

</script>

<style scoped>

.question {
	display: grid;
	grid-gap: 10px;
	padding: 20px;
	grid-template-columns: 1fr;
	border-bottom: 1px solid #eee;
}

.question.is-locked {
	pointer-events: none;
}

.question:last-child {
	border-bottom: 0px;
}

.question-text {
    font-size: 16px;
    line-height: 20px;
    color: #333;
    font-weight: 400;
    margin-bottom: 10px;
}

.question-text >>> p,
.question-text >>> ul {
	margin-bottom: 15px;
}

.question-text >>> li {
	margin-bottom: 15px;
}

.question-text >>> li:last-child,
.question-text >>> p:last-child,
.question-text >>> ul:last-child {
	margin-bottom: 0px;
}

</style>