<template>

<div class="rollcall">

	<template v-for="(rollcall, index) in rollcalls">

	<div tabindex="0" class="rollcall-item" :key="index" v-if="peopleType(rollcall.type).length" :data-initial="circuitInitial" :class="{'has-colour': $colour}" :style="{borderColor: $colour}">

		<div class="rollcall-item-head">{{ rollcall.text }}</div>

		<div class="rollcall-item-people">

			<div tabindex="0" class="rollcall-item-person" v-for="(person, index) in peopleType(rollcall.type)" :key="index">

				<i class="rollcall-item-person-status fa" :class="{'is-online fa-hourglass-half': isOnline(person.identity) && !isReady(person.identity), 'is-ready fa-check-circle': isReady(person.identity), 'is-offline fa-ban': isOffline(person.identity)}" />

				<div class="rollcall-item-person-name">{{ emptyCheck(person.name, 'No name') }} <small>{{ emptyCheck(person.label, 'No Label') }}</small></div>
				
				<button v-if="person.role === $constants.role.candidate" v-tooltip="'Click to set as ID verified'" class="rollcall-item-person-flag" :class="{'is-active': isFlagVerified(person.identity), 'is-loading': is.flaggingVerified === person.identity}" v-on:click="onFlagVerifiedClick(person.identity)">

					<i class="fa fa-id-card-alt"></i>

				</button>

				<button v-if="(person.role !== $constants.role.observer || person.chaperone) && hasPermission($constants.observer.permission.release) " v-tooltip="'Click to set as free to leave'" class="rollcall-item-person-flag" :class="{'is-active': isFlagFinished(person), 'is-loading': is.flaggingFinished === person.identity}" v-on:click="onFlagFinishedClick(person.identity)">

					<i class="fa fa-door-open"></i>

				</button>

			</div>

		</div>

	</div>

	</template>

</div>

</template>

<script>

export default {

	data: function() {

		return {

			rollcalls: [],
			is: {
				flaggingFinished: false,
				flaggingVerified: false
			}

		}

	},

	created: function() {

		this.rollcalls = []

		if(this.$isExam) {

			this.rollcalls.push({
				text: this.$language.role.candidates,
				type: 'candidates'
			})

			this.rollcalls.push({
				text: 'Chaperones',
				type: 'chaperones'
			})

		}

		this.rollcalls.push({
			text: this.$language.role.roleplayers,
			type: 'roleplayers'
		})

		this.rollcalls.push({
			text: this.$language.role.examiners,
			type: 'examiners'
		})

		this.rollcalls.push({
			text: this.$language.role.layexaminers,
			type: 'layexaminers'
		})

		this.rollcalls.push({
			text: 'Observers',
			type: 'observers'
		})

	},

	computed: {

		circuitInitial: function() {

			return this.$store.getters['circuits/current'] ? this.$store.getters['circuits/associated'][this.$store.getters['circuits/current']].initial : false

		},

		people: function() {

			return this.$_.filter(this.$store.getters['people'], function(item) {
				return item.circuit === this.$store.getters['circuits/current'] || item.circuit === false
			}.bind(this))

		},

		candidates: function() {

			var people = []

			this.$_.each(this.people, function(person) {

				if (person.role === this.$constants.role.candidate && !person.empty) {

					people.push(person)

				}

			}.bind(this))

			return this.$_.sortBy(people, 'name')

		},

		observers: function() {

			var people = []

			this.$_.each(this.people, function(person) {

				if (person.role === this.$constants.role.observer && !person.chaperone) {

					people.push(person)

				}

			}.bind(this))

			return this.$_.sortBy(people, 'name')

		},

		chaperones: function() {

			var people = []

			this.$_.each(this.people, function(person) {

				if (person.role === this.$constants.role.observer && person.chaperone) {

					people.push(person)

				}

			}.bind(this))

			return this.$_.sortBy(people, 'name')

		},

		examiners: function() {

			var people = []

			this.$_.each(this.people, function(person) {

				if (person.role === this.$constants.role.examiner) {

					people.push(person)

				}

			}.bind(this))

			return this.$_.sortBy(people, 'label')

		},

		layexaminers: function() {

			var people = []

			this.$_.each(this.people, function(person) {

				if (person.role === this.$constants.role.layexaminer) {

					people.push(person)

				}

			}.bind(this))

			return this.$_.sortBy(people, 'label')

		},

		roleplayers: function() {

			var people = []

			this.$_.each(this.people, function(person) {

				if (person.role === this.$constants.role.roleplayer) {

					people.push(person)

				}

			}.bind(this))

			return this.$_.sortBy(people, 'label')

		}

	},

	methods: {

		emptyCheck: function(text, defaultText) {

			return text || defaultText

		},

		onFlagVerifiedClick: function(person) {

			this.is.flaggingVerified = person

			this.$api.request('flag/verified', {
				identity: person
			}).then(function() {

				this.is.flaggingVerified = false

			}.bind(this), function() {

				this.is.flaggingVerified = false

			}.bind(this))

		},

		onFlagFinishedClick: function(person) {

			this.is.flaggingFinished = person

			this.$api.request('login/verify', {
				identity: person
			}).then(function(data) {

				this.$store.commit('people/update', data.person)

				this.is.flaggingFinished = false

			}.bind(this), function() {

				this.is.flaggingFinished = false

			}.bind(this))

		},

		peopleType: function(type) {

			return this[type]

		},

		isOnline: function(person) {

			return this.$_.contains(this.$store.getters['online'], person) 

		},

		isOffline: function(person) {

			return !this.$_.contains(this.$store.getters['online'], person)

		},

		isReady: function(person) {

			return this.$_.contains(this.$store.getters['online/ready'], person)

		},

		isFlagVerified: function(identity) {

			return this.$hasFlagged('verified', identity)

		},

		isFlagFinished: function(person) {

			return person.verified

		}

	}

}

</script>

<style scoped>

.rollcall {
	display: flex;
	flex-direction: column;
	margin-bottom: 10px;
	width: 100%;
}

.rollcall-item {
	display: flex;
	flex-direction: column;
	margin: 0px 10px 10px 0px;
}

.rollcall-item.has-colour {
	border-left: 32px solid transparent;
	border-radius: 4px 0px 0px 4px;
}

.rollcall-item:before {
	content: attr(data-initial);
	color: rgba(0, 0, 0, 0.75);
	font-size: 14px;
	font-weight: 400;
	width: 32px;
	text-align: center;
	line-height: 32px;
	position: absolute;
	left: -32px;
	top: 0px;
	z-index: 3;
}

.rollcall-item-head {
	background-color: #287ABD;
	color: #fff;
	height: 32px;
	font-size: 16px;
	line-height: 32px;
	padding: 0px 10px;
	border-radius: 4px;
	margin-left: 1px;
	font-weight: 400;
}

.rollcall-item:focus .rollcall-item-head {
	background-color: #205f93;
}

.rollcall-item-people {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.rollcall-item.has-colour .rollcall-item-people {
	border-left: 0px;
}

.rollcall-item-person {
	background-color: #fff;
	display: flex;
	flex-direction: row;
	font-size: 16px;
	line-height: 20px;
	border-radius: 4px;
	align-items: center;
	width: calc(25% - 1px);
	min-width: 200px;
	padding-left: 10px;
	border: 1px solid #ddd;
	margin: 1px 0px 0px 1px;
}

.rollcall-item:focus .rollcall-item-person {
	border-color: #205f93;
}

.rollcall-item-person:focus,
.rollcall-item-person:hover {
	border-color: #287ABD;
}

.rollcall-item-person-name {
	font-size: 16px;
	font-weight: 300;
	flex-grow: 1;
	padding: 6px 10px;
}

.rollcall-item-person-name small {
	display: block;
	font-size: 12px;
	font-weight: 300;
	line-height: 12px;
}

.rollcall-item-person-flag {
	flex-shrink: 0;
	width: 32px;
	line-height: 44px;
	font-size: 16px;
	text-align: center;
	cursor: pointer;
	color: #ccc;
}

.rollcall-item-person-flag.is-loading {
	background-image: url('data:image/gif;base64,R0lGODlhEAALAPQAAP///yN9xt7r9tbm8+zz+SiAxyN9xkqU0JO/4nWt28Pb70COzWKi1pnD5Hmv3Mbd70OQziZ/xmak1+nx+N3q9fX4+1OZ0uDs9vP3+7/Z7qzO6dHj8vD1+gAAAAAAAAAAACH+GkNyZWF0ZWQgd2l0aCBhamF4bG9hZC5pbmZvACH5BAALAAAAIf8LTkVUU0NBUEUyLjADAQAAACwAAAAAEAALAAAFLSAgjmRpnqSgCuLKAq5AEIM4zDVw03ve27ifDgfkEYe04kDIDC5zrtYKRa2WQgAh+QQACwABACwAAAAAEAALAAAFJGBhGAVgnqhpHIeRvsDawqns0qeN5+y967tYLyicBYE7EYkYAgAh+QQACwACACwAAAAAEAALAAAFNiAgjothLOOIJAkiGgxjpGKiKMkbz7SN6zIawJcDwIK9W/HISxGBzdHTuBNOmcJVCyoUlk7CEAAh+QQACwADACwAAAAAEAALAAAFNSAgjqQIRRFUAo3jNGIkSdHqPI8Tz3V55zuaDacDyIQ+YrBH+hWPzJFzOQQaeavWi7oqnVIhACH5BAALAAQALAAAAAAQAAsAAAUyICCOZGme1rJY5kRRk7hI0mJSVUXJtF3iOl7tltsBZsNfUegjAY3I5sgFY55KqdX1GgIAIfkEAAsABQAsAAAAABAACwAABTcgII5kaZ4kcV2EqLJipmnZhWGXaOOitm2aXQ4g7P2Ct2ER4AMul00kj5g0Al8tADY2y6C+4FIIACH5BAALAAYALAAAAAAQAAsAAAUvICCOZGme5ERRk6iy7qpyHCVStA3gNa/7txxwlwv2isSacYUc+l4tADQGQ1mvpBAAIfkEAAsABwAsAAAAABAACwAABS8gII5kaZ7kRFGTqLLuqnIcJVK0DeA1r/u3HHCXC/aKxJpxhRz6Xi0ANAZDWa+kEAA7AAAAAAAAAAAA');
	background-repeat: no-repeat;
	background-position: 50% 50%;
	pointer-events: none;
}

.rollcall-item-person-flag.is-loading > * {
	visibility: hidden;
}

.rollcall-item-person-flag:focus,
.rollcall-item-person-flag:hover {
	color: #666;
}

.rollcall-item-person-flag.is-active {
	color: #287ABD;
}

.rollcall-item-person-flag.is-active:focus,
.rollcall-item-person-flag.is-active:hover {				
	color: #205f93;
}

.rollcall-item-person-status {
	width: 16px;
	height: 16px;
	display: block;
	font-size: 16px;
	line-height: 16px;
	text-align: center;
	border-radius: 8px;
	color: rgba(0, 0, 0, 0.75);
}

.rollcall-item-person-status.is-online {
	color: #f5c638;
}

.rollcall-item-person-status.is-ready {
	color: #1ed43f;
}

.rollcall-item-person-status.is-offline {
	color: #c62323;
}

</style>