<template>
	
<div class="dropdown" :class="{'is-disabled': disabled, 'is-maxed': answer.length === max}">

	<button class="dropdown-item" :class="{'is-active': isSelected(option.value)}" v-for="(option, index) in options" :key="index" v-on:click="onClick(option.value)">
		{{ option.text }}
	</button>

</div>

</template>

<script>	

export default {

	props: ['item', 'value', 'disabled', 'min', 'max'],

	data: function() {

		return {
			answer: false
		}

	},

	computed: {

		isMulti: function() {

			return this.item.multi

		},

		options: function() {

			return this.item.options

		}

	},

	watch: {

		disabled: function() {

			this.answer = (this.isMulti) ? [] : null

			this.pushChange()

		},

		max: function() {

			/*this.answer = (this.isMulti) ? [] : null

			this.pushChange()*/

		},

		min: function() {

			/* if (n < o) this.answer = (this.isMulti) ? [] : null

			this.pushChange()*/

		},
        
        value: function() {

			this.applyValue()

        }

    },

    created: function() {

		this.applyValue()

    },

	methods: {

		pushChange: function() {

			var answer = this.answer

			if (this.isMulti && this.$_.isArray(answer)) {

				answer = (answer.length) ? answer.join(',') : null

			}

			this.$emit('change', {
				identity: this.item.identity,
				value: answer
			})

		},

		applyValue: function() {

			if (this.isMulti) {

				this.answer = (this.value === null) ? [] : this.value.toString().split(',')

				this.answer = this.answer.map(function (x) { 
					return parseInt(x); 
				})

			} else {

				this.answer = this.value

			}

		},

		isSelected: function(value) {

			if (this.isMulti) {

				return this.$_.contains(this.answer, parseInt(value))

			} else {

				return parseInt(value) === this.answer

			}

		},

		onClick: function(value) {

			if (this.isRoamingObserver) return false

			if (this.isMulti) {

				if (this.$_.contains(this.answer, value)) {

					this.answer.splice(this.answer.indexOf(value), 1)

				} else if(this.answer.length + 1 <= this.max) {

					this.answer.push(value)

				} else if(this.max === undefined) {

					this.answer.push(value)

				}

			} else {

				this.answer = (this.isSelected(value)) ? null : parseInt(value)

			}

			this.pushChange()

		}

	}

}

</script>

<style scoped>

.dropdown {
	display: grid;
	grid-template-columns: 1fr;
}

.dropdown.is-disabled {
	opacity: 0.5;
	pointer-events: none;
}

.dropdown.is-maxed .dropdown-item:not(.is-active) {
	opacity: 0.5;
	pointer-events: none;
}

.dropdown-item {
	font-size: 16px;
	font-weight: 400;
	border: 1px solid #ccc;
	border-bottom-width: 0px;
	text-align: left;
	padding: 10px;
	tex-align: left;
}

.dropdown-item:first-child {
	border-radius: 4px 4px  0px 0px;
}

.dropdown-item:last-child {
	border-bottom-width: 1px;
	border-radius: 0px 0px 4px 4px;
}

.dropdown-item:hover {
	background-color: #eee;
}

.dropdown-item.is-active {
	border-color: #287ABD;
	background-color: #287ABD;
	color: #fff;
}

</style>