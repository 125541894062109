<template>
    
<com-card class="notepad" :expanded="expanded" :closable="item.closable" v-on:close="$emit('close')" :type="$constants.card.type.notepad" v-on:expand="$emit('expand')" :expandable="canExpand" :title="item.title" :subtitle="item.subtitle">

    <div class="notepad-empty" v-if="!notes && !is.focussed">Write some notes...</div>

    <textarea v-on:contextmenu="$allowContext" class="notepad-input" v-on:focus="is.focussed = true" v-on:blur="is.focussed = false" v-model="notes" v-on:keyup="onChange" v-on:change="onChange"></textarea>

</com-card>

</template>

<script>

import cookies from 'js-cookie'
import comCard from './Card'

export default {

	props: ['item', 'canExpand', 'expanded'],

	components: {

		'com-card': comCard

	},

    data: function() {

        return {
            is: {
                focussed: false
            },
            notes: ''
        }

    },

    created: function() {

        this.notes = cookies.get('notes/' + this.isActive.identity, '')

    },

    methods: {

        onChange: function() {

            cookies.set('notes/' + this.isActive.identity, this.notes)

        }

    }

}

</script>

<style scoped>

.notepad {
	background-color: #fff;
}

.notepad-empty {
    font-size: 32px;
    font-weight: 500;
    color: #ccc;
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    text-align: center;
    padding: 10px;
}

.notepad-input {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    font-size: 14px;
    color: #333;
    line-height: 18px;
    padding: 10px;
    resize: none;
}

.notepad-input:focus {
    border: 2px solid #4285f4;
	border-radius: 4px 4px 0px 0px;
}

</style>