<template>

<div class="page">

	<slot></slot>

</div>

</template>

<script>

export default {

}

</script>

<style scoped>

.page {
	position: fixed;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

</style>
