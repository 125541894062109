<template>
	
<div class="marksheets">

	<template v-for="identity in markingSchedule">

		<com-sheet :candidate="sheetCandidate(identity)" :identity="identity" :key="identity" :validSave="validSave[identity]" :session="sheets.session[identity]" :saved="sheets.saved[identity]" v-if="currentSheet === identity" v-on:saved="onSheetSaved" v-on:change="onSheetChanged" />

	</template>

	<com-candidates :validSave="validSave" :session="sheets.session" :saved="sheets.saved" v-if="!currentSheet" v-on:change="onCandidateChange" />

</div>

</template>

<script>

import Vue from 'vue'

import comCandidates from './marksheets/Candidates'
import comSheet from './marksheets/Sheet'

export default {
	
	components: {
		'com-candidates': comCandidates,
		'com-sheet': comSheet
	},

	data: function() {

		return {
			currentSheet: false,
			sheets: {
				saved: {},
				session: {}
			}
		}

	},

	created: function() {

		this.sheets.saved = JSON.parse(JSON.stringify(this.$store.getters['marking/saved']))
		this.sheets.session = JSON.parse(JSON.stringify(this.$store.getters['marking/session']))

		if (this.isRoleplaying) {

			this.currentSheet = this.isActive.identity

		}	

		this.$pubsub.$on('assistant.back', this.onBack.bind(this))
		this.$pubsub.$on('assistant.marking', this.onMarking.bind(this))

	},

	beforeDestroy: function() {

		this.$pubsub.$off('assistant.back', this.onBack.bind(this))

	},

	watch: {

		currentSheet: function(n) {

			this.$emit('sheetChange', n)

		},

		isRoleplaying: function(n) {

			if (n) {
				
				this.currentSheet = this.isActive.identity

			} else {

				if (this.isSaved) this.currentSheet = false

			}

		}

	},	

	computed: {

		isRoleplaying: function() {

			return (this.isStation) ? this.time >= this.isActive.roleplay && this.time <= this.isActive.end : false

		},

		markingSchedule: function() {

			var schedule = []

			this.$_.each(this.$store.getters['schedule'], function(item) {

				if (item.type === this.$constants.schedule.type.station) {

					schedule.push(item.identity)

				}

			}.bind(this))

			return schedule


		},

		isSaved: function() {

			if (this.currentSheet) {

				return this.validSave[this.currentSheet] && this.$_.isEqual(this.sheets.saved[this.currentSheet], this.sheets.session[this.currentSheet])

			} else {

				return false

			}

		},

		validSave: function() {

			var valid = {}

			this.$_.each(this.sheets.saved, function(sheet, schedule) {

				var total = 0
				var answered = 0

				this.$_.each(this.$store.getters['marking/questions'][schedule], function(question) {

					if (question.required) {

						total++

						if (sheet[question.identity] !== null) {

							answered++

						}

					}

				}.bind(this))

				Vue.set(valid, schedule, total === answered)

			}.bind(this))

			return valid

		}

	},

	methods: {

		sheetCandidate: function(identity) {

			return (this.$store.getters['schedule/associated'][identity]) ? this.$store.getters['schedule/associated'][identity].candidate : false

		},

		onMarking: function(value) {

			this.currentSheet = value

		},

		onBack: function() {

			this.currentSheet = false

		},

		onSheetSaved: function(e) {

			Vue.set(this.sheets.saved, e.schedule, e.sheet)

			this.$store.commit('marking/marks', {
                identity: e.schedule,
                marks: e.sheet
            })

		},

		onSheetChanged: function(e) {

			Vue.set(this.sheets.session, e.schedule, e.sheet)

		},

		onCandidateChange: function(identity) {

			this.currentSheet = identity

		}

	}

}

</script>

<style scoped>

</style>