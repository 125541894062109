<template>

    <app-page>
    
        <div class="login-prompt is-auth" onboarding="login">
    
            <div class="auth-logo prp-loading"></div>
    
            <input type="text" ref="name" class="auth-input" v-model="inputs.name" placeholder="Enter your username" />
    
            <input type="password" class="auth-input" v-model="inputs.code" placeholder="Enter your access pin" />
    
            <app-button>Login</app-button>
                
            <a class="login-statement" href="https://www.rcgp.org.uk/terms-conditions/accessibility-statement" target="_blank">accessibility statement</a>
    
        </div>
    
    </app-page>
    
    </template>
    
    <script>
    
    export default {
    
        data: function() {
    
            return {
                inputs: {
                    name: '',
                    code: '',
                    event: 'dashboard'
                }
            }
    
        }
    
    }
    
    </script>
    
    <style scoped>
    
    .login-prompt {
        width: 320px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #fff;
        padding: 20px;
        border: 1px solid #ddd;
        border-radius: 4px;
        box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
    }
    
    .login-prompt.is-session {
        opacity: 0;
    }
    
    .auth-logo {
        animation-name: none;
        margin-bottom: 10px;
    }
    
    .auth-input {
        border: 2px solid #ddd;
        border-radius: 4px;
        font-size: 14px;
        line-height: 38px;
        padding: 0px 10px;
        text-align: center;
        background-color: #fff;
        margin-bottom: 10px;
        width: 100%;
    }
    
    .auth-input:focus {
        outline: none;
        border: 2px solid #4285f4;
    }
    
    .auth-error {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 10px;
        font-weight: 400;
        color: red;
    }
    
    .login-prompt.is-browser h1 {
        font-size: 24px;
        margin-bottom: 20px;
    }
    
    .login-prompt.is-browser p {
        font-size: 16px;
        line-height: 20px;
        color: #333;
        margin-bottom: 20px;
    }
    
    .login-prompt.is-browser a {
        display: block;
        border-radius: 4px;
        font-size: 14px;
        line-height: 40px;
        font-weight: 400;
        cursor: pointer;
        padding: 0px 20px;
        text-transform: uppercase;
        background-color: #287ABD;
        color: #fff;
    }
    
    .login-prompt.is-browser a:hover {
        background-color: #1b649e;
    }
    
    .login-statement {
        position: absolute;
        bottom: -40px;
        font-size: 12px;
        color: #428dca;
        font-weight: 400;
    }
    
    .login-statement:hover,
    .login-statement:focus {
        color: #287ABD;
        text-decoration: underline;
    }
    
    </style>
    