<template>

<app-page class="check">

	<com-welcome v-if="stage === 'welcome'" :template="template" :check="check" v-on:proceed="onWelcomeProceed" />
	<com-device v-if="stage === 'device'" :template="template" :check="check" :silent="silent" :pusher="pusher" v-on:log="onSaveLogs" v-on:strength="progress.strength = $event" v-on:onboarded="progress.onboarded = $event" v-on:passed="progress.passed = $event" v-on:failed="progress.failed = $event" v-on:location="progress.location = $event" v-on:proceed="onDeviceProceed" />
	<com-test v-if="stage === 'test'" :template="template" :check="check" v-on:proceed="onTestProceed" />
	<com-feedback v-if="stage === 'feedback'" :template="template" :check="check" :progress="progress" :attempt="attempt" v-on:proceed="onFeedbackProceed" />
	<com-success v-if="stage === 'success'" :template="template" :check="check" />
	<com-failed v-if="stage === 'failed'" :template="template" :check="check" />
	<com-finished v-if="stage === 'finished'" :template="template" :check="check" :progress="progress" />

	<div class="check-loading prp-loading" v-if="is.loading" />

</app-page>

</template>

<script>

import Vue from 'vue'
import { detect } from 'detect-browser'

import comWelcome from './check/Welcome'
import comDevice from './check/Device'
import comTest from './check/Test'
import comFeedback from './check/Feedback'
import comSuccess from './check/Success'
import comFailed from './check/Failed'
import comFinished from './check/Finished'

export default {

	components: {
		'com-welcome': comWelcome,
		'com-device': comDevice,
		'com-test': comTest,
		'com-feedback': comFeedback,
		'com-success': comSuccess,
		'com-failed': comFailed,
		'com-finished': comFinished
	},

	data: function() {

		return {
			is: {
				loading: true
			},
			progress: {
				onboarded: false,
				location: false,
				passed: false,
				failed: false,
				device: {
					browser: '',
					os: '',
					window: ''
				},
				strength: false
			},
			logs: [],
			stage: '',
			key: '',
			attempt: false,
			template: false,
			pusher: false,
			silent: [],
			check: false
		}

	},

	watch: {

		progress: {

			deep: true,

			handler: function() {

				if (this.attempt) this.$api.request('check/update', {
					attempt: this.attempt.identity,
					data: this.progress
				})

			}

		},

		stage: {

			handler: function() {

				if (this.attempt) this.onSaveLogs()
			}

		}

	},

	created: function() {

		var url = window.location.href.split('/')

		if(url.length === 5) {

			this.key = url[url.length - 1]

		}

		this.load()

		this.$pubsub.$on('console', this.onConsole.bind(this))

	},

	computed: {

		canFeedback: function() {

			return this.template.features.feedback === 1 || (this.template.features.feedback === 2 && this.progress.passed) || (this.template.features.feedback === 3 && this.progress.failed)

		}

	},

	methods: {

		onSaveLogs: function() {
		
			this.$api.request('check/log', {
				attempt: this.attempt.identity,
				log: this.logs
			})

		},

		onConsole: function(e) {

			this.logs.push(e)

		},

		onWelcomeProceed: function(data) {

			Vue.set(this, 'attempt', data)

			this.stage = 'device'
			
			var browser = detect()

			this.progress.device.window = this.window.width.toString() + 'x' + this.window.height.toString()
			this.progress.device.browser = browser.name + ' ' + browser.version
			this.progress.device.os = browser.os

			this.$api.request('check/update', {
				attempt: this.attempt.identity,
				data: this.progress
			})

		},

		onDeviceProceed: function() {

			this.$api.request('check/update', {
				attempt: this.attempt.identity,
				data: this.progress
			}).then(function() {
			
				this.onSaveLogs()
			
			}.bind(this))

			if (this.template.features.quality && this.progress.passed) {

				this.stage = 'test'

			} else if (this.canFeedback) {

				this.stage = 'feedback'

			} else {

				this.stage = (this.progress.passed) ? 'success' : 'failed'

			}

		},

		onTestProceed: function() {

			if (this.canFeedback) {

				this.stage = 'feedback'

			} else {

				this.stage = (this.progress.passed) ? 'success' : 'failed'

			}

		},

		onFeedbackProceed: function() {

			this.stage = 'finished'

		},

		load: function() {

			if (!this.key) return false

			this.$api.request('check/init', {
				check: this.key
			}).then(function(json) {

				Vue.set(this, 'template', json.template)
				Vue.set(this, 'check', json.check)
				Vue.set(this, 'pusher', json.pusher)
				Vue.set(this, 'silent', json.silent)

				this.$emit('onboarding', json.template.features.walkthrough)

				this.is.loading = false

				this.stage = 'welcome'

			}.bind(this), function() {

				// 

			})

		}

	}

}

</script>

<style scoped>

.check {
	display: flex;
	justify-content: center;
	align-items: center;
}

</style>