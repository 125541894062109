<template>

<div class="connections">

	<div class="connections-loading prp-loading" v-if="is.loading" />
	<div class="connections-failed" v-if="is.failed"><i class="fa fa-exclamation-triangle" /><span>Sorry, data could not be loaded.</span><small>If problem persists, please contact tech support.</small></div>

	<com-user v-for="profile in candidates" :key="profile.id" :profile="profile" />

</div>

</template>

<script>

import comUser from './connections/User'

export default {

	components: {
		'com-user': comUser
	},

	data: function() {

		return {
			is: {
				loading: true,
				failed: false
			},
			users: [],
			timer: false,
			closed: false
		}

	},

	created: function() {

		this.update()

	},

	beforeDestroy: function() {

		this.closed = true

		clearTimeout(this.timer)
		
	},

	watch: {

		currentCircuit: function() {

			this.is.loading = true
			this.update()

		}

	},

	computed: {

		currentCircuit: function() {

			return this.$store.getters['circuits/current']

		},

		candidates: function() {

			return this.$_.filter(this.users, function(item) {

				return item.circuit === this.$store.getters['circuits/current'] || item.circuit === false

			}.bind(this))

		}

	},

	methods: {

		update: function() {

			clearTimeout(this.timer)

			var apiCircuit = this.$store.getters['circuits/current']

			this.$api.request('monitor/dashboard', {
				circuit: this.$store.getters['circuits/current']
			}).then(function(json) {

				this.users = json.users

				if (!this.closed && apiCircuit === this.$store.getters['circuits/current']) this.timer = this.$_.delay(this.update.bind(this), 60000)

				this.is.loading = false

			}.bind(this), function() {

				this.is.failed = true
				this.is.loading = false

			}.bind(this))

		},

	}

}

</script>

<style scoped>

.connections {
	display: flex;
	flex-direction: column;
	min-height: 100%;
}

.connections-loading {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-left: -32px;
	margin-top: -32px;
}

.connections-failed {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 64px;
	display: flex;
	flex-direction: column;
	align-items: center;
	color: #c62323;
}

.connections-failed span {
	font-size: 24px;
	font-weight: 400;
	margin-top: 20px;
}

.connections-failed small {
	font-size: 14px;
	margin-top: 4px;
	line-height: 20px;
	color: #333;
}

</style>