export default {

	data: function() {

		return {

			$prefix: []

		}

	},

	methods: {

		$log: function() {

			var props = this.$_.clone((this.$_.isArray(this.$prefix)) ? this.$prefix : [this.$prefix])

			this.$_.each(arguments, function(arg) {

				props.push(arg)

			})

			console.log.apply(null, props)

		}

	}

}