import _ from 'underscore'

export default {

	namespaced: true,

	state: {

		messages: []

	},

	getters: {

		get: function(state) {

			return state.messages

		}

	},

	mutations: {

		set: function(state, data) {

			state.messages = data

		},

		add: function(state, data) {

			if (!_.findWhere(state.messages, {
				identity: data.identity
			})) state.messages.push(data)

		}

	},

	actions: {

		init: function(context, data) {

			context.commit('set', data)

		}

	}

}