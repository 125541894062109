<template>

<div class="icons" :onboarding="onboarding">

	<slot></slot>

</div>

</template>

<script>

export default {

	props: ['onboarding']

}

</script>

<style scoped>

.icons {
	margin-top: 10px;
	display: flex;
	width: auto;
	flex-direction: row;
}

.icons:focus {
	box-shadow: rgba(50, 50, 93, .1) 0 0 0 1px inset, rgba(50, 50, 93, .2) 0 4px 4px 0, rgba(0, 0, 0, .1) 0 2px 2px 0, rgba(50, 151, 211, .3) 0 0 0 4px;
}

</style>
