<template>

<div class="waiting">

	<div class="waiting-icon fa fa-coffee"></div>

	<div class="waiting-text">{{ isActive.name }}</div>

	<div class="waiting-countdown">Ends in {{ remainingText }}</div>
	
</div>

</template>

<script>

export default {

	computed: {

		remainingText: function() {

			return this.countdown(this.isActive.end)

		}

	}

}

</script>

<style scoped>

.waiting {
	height: 100%;
	display: flex;
	flex-direction: column;
	color: #333;
	align-items: center;
	justify-content: center;
	border: 1px solid #ddd;
	border-radius: 4px;
	background-color: #fff;
	overflow: hidden;
	box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
}

.waiting-icon {
	font-size: 64px;
	margin-bottom: 20px;
	color: #287ABD;
}

.waiting-text {
	font-size: 24px;
	font-weight: 300;
	text-align: center;
}

.waiting-text small {
	font-size: 14px;
	margin-top: 4px;
	display: block;
}

.waiting-countdown {
	margin-top: 10px;
	font-size: 32px;
	font-weight: 400;
}

</style>
