<template>
	
<div class="toggle">

	<button class="toggle-item" :class="{'is-active': isSelected(option.value)}" v-for="(option, index) in options" :key="index" v-on:click="onClick(option.value)">
		{{ option.text }}
		<com-standardisation :standardisation="standardisation" :selected="value" :value="option.value" />
	</button>

</div>

</template>

<script>	

import comStandardisation from './Standardisation'

export default {

	props: ['item', 'value', 'standardisation'],

	components: {
		'com-standardisation': comStandardisation
	},

	data: function() {

		return {
			answer: false
		}

	},

	computed: {

		isMulti: function() {

			return this.item.multi

		},

		options: function() {

			return this.item.options

		}

	},

	watch: {

        answer: function() {

			if (!this.isRoamingObserver) {

				var answer = this.answer

				if (this.isMulti && this.$_.isArray(answer)) {

					answer = (answer.length) ? answer.join(',') : null

				}

				this.$emit('change', {
					identity: this.item.identity,
					value: answer
				})
					
			}

        },
        
        value: function() {

			this.applyValue()

        }

    },

    created: function() {

		this.applyValue()

    },

	methods: {

		applyValue: function() {

			if (this.isMulti) {

				this.answer = (this.value === null) ? [] : this.value.toString().split(',')

				this.answer = this.answer.map(function (x) { 
					return parseInt(x); 
				})

			} else {

				this.answer = this.value

			}

		},

		isSelected: function(value) {

			if (this.isMulti) {

				return this.$_.contains(this.answer, parseInt(value))

			} else {

				return parseInt(value) === this.answer

			}

		},

		onClick: function(value) {

			if (this.isRoamingObserver) return false

            this.answer = (this.isSelected(value)) ? null : parseInt(value)

		}

	}

}

</script>

<style scoped>

.toggle {
	display: grid;
	grid-gap: 10px;
	grid-auto-columns: minmax(0, 1fr);
	grid-auto-flow: column;
}

.toggle-item {
	flex-grow: 1;
	flex-shrink: 0;
	font-size: 16px;
	font-weight: 500;
	border-radius: 4px;
	border: 1px solid #333;
	padding: 20px;
}

.toggle-item:hover {
	border-color: #287ABD;
	color: #287ABD;
}

.toggle-item.is-active {
	border-color: #287ABD;
	background-color: #287ABD;
	color: #fff;
}

</style>