<template>

<div class="feedback">

	<i class="success-icon fa fa-times-circle is-failed" v-if="!progress.passed" />
	<i class="success-icon fa fa-check-circle" v-if="progress.passed" />

	<div class="success-text">{{ template.text[(progress.passed) ? 'passed' : 'failed'] }}</div>

	<template v-for="(input, index) in template.inputs" >

		<input class="feedback-input" :placeholder="input.placeholder" type="text" v-model="model[index]" v-if="input.type === 1 && isVisible(input)" :key="index" />
		<textarea class="feedback-input" :placeholder="input.placeholder" v-model="model[index]" v-if="input.type === 2 && isVisible(input)" :key="index" />

	</template>

	<app-button theme="blue" :disabled="!isValid" :loading="is.loading" v-on:click="onSubmitClick">Submit</app-button>

</div>

</template>

<script>

import Vue from 'vue'

export default {

	props: ['template', 'attempt', 'progress', 'check'],

	data: function() {

		return {
			is: {
				loading: false
			},
			model: {}
		}

	},

	computed: {
		
		isValid: function() {

			var valid = 0

			this.$_.each(this.model, function(value, index) {

				if (value || !this.isVisible(this.template.inputs[index])) valid++

			}.bind(this))

			return valid === this.template.inputs.length

		}

	},

	created: function() {

		this.$_.each(this.template.inputs, function(value, key) {

			Vue.set(this.model, key, '')

		}.bind(this))

	},

	methods: {

		isVisible: function(input) {

			if (this.template.features.feedback === 1) {

				if (input.visibility === 0) {

					return true

				} else if (input.visibility === 1) {

					return this.progress.passed

				} else if (input.visibility === 2) {

					return this.progress.failed

				}		

			} else {

				return true

			}

		},

		onSubmitClick: function() {

			this.is.loading = true

			this.$api.request('check/feedback', {
				data: this.model,
				attempt: this.attempt.identity
			}).then(function() {

				this.$emit('proceed')

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.success-icon {
	color: #6db350;
	font-size: 96px;
}

.success-icon.is-failed {
	color: #c62323;
}

.success-text {
	font-size: 16px;
	line-height: 20px;
	font-weight: 400;
	text-align: center;
	color: #333;
	margin-top: 40px;
	margin-bottom: 40px;
}

.feedback {
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 100%;
	max-width: 480px;
	align-items: center;
	background-color: #fff;
	padding: 40px 20px;
	border: 1px solid #ddd;
	border-radius: 4px;
}

.feedback-text {
	font-size: 16px;
	line-height: 20px;
	font-weight: 400;
	text-align: center;
	color: #333;
	margin-bottom: 20px;
}

.feedback-input {
	background-color: #eee;
	border-radius: 4px;
	font-size: 16px;
	line-height: 20px;
	padding: 0px 10px;
	min-height: 40px;
	width: 100%;
	margin-bottom: 20px;
}

textarea.feedback-input {
	padding: 8px 10px;
	min-height: 160px;
}

</style>