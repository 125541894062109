<template>

<div class="foot" onboarding="foot">

	<com-actions class="foot-actions" />

	<div class="foot-text">
		designed by <b>Professional Role Players</b>
	</div>

	<div class="foot-clock" onboarding="foot.clock">{{ time | formatDate('HH:mm') }}</div>

</div>

</template>

<script>

import comActions from './foot/Actions'

export default {

	components: {
		'com-actions': comActions
	}

}

</script>

<style scoped>

.foot {
	position: fixed;
	left: 0px;
	width: 100%;
	bottom: 0px;
	background-color: #FBB516;
	height: 54px;
	z-index: 100;
	display: flex;
	flex-direction: row;
	transition: background-color 100ms linear;
}

.foot-clock {
	height: 54px;
	line-height: 54px;
	font-weight: 500;
	font-size: 14px;
	padding: 0px 10px;
	margin-left: 20px;
	border-left: 1px solid rgba(255, 255, 255, 0.25);
	width: 80px;
	text-align: center;
}

.foot-actions {
	margin-top: 0px!important;
	flex-grow: 1;
	padding: 5px 10px;
}

.foot-text {
	line-height: 54px;
	font-size: 14px;
	font-weight: 300;
	color: #343434;
}

.foot-text b {
	font-weight: 500;
	color: #000;
}

</style>
