
import pusher from '@/service/pusher'
import api from '@/service/api'

export default {

	namespaced: true,

	state: {

        schedule: [],
		ready: [],
		tracking: [],
		from: 0,
		to: 0

	},

	getters: {

		get: function(state) {

			return state.schedule

        },
		
		tracking: function(state) {

			return state.tracking

		},
		
		ready: function(state) {

			return state.ready

		},
		
		from: function(state) {

			return state.from

		},
		
		to: function(state) {

			return state.to

		}

	},

	mutations: {

		set: function(state, data) {

            state.schedule = data.schedule
            state.ready = data.ready
			state.tracking = data.tracking
			state.from = data.from
			state.to = data.to

        }

	},

	actions: {

		init: function(context, data) {

			if (data) context.commit('set', data)

			// listen for refresh
			pusher.on('user', 'playback/refresh', function() {

				api.request('playback/refresh').then(function(json) {
					
					context.commit('set', json)

				})

			}.bind(this))

		}

	}

}