<template>

<div class="sidebar" onboarding="sidebar">

	<div class="sidebar-logo"></div>

	<com-participant onboarding="sidebar.self" :person="sidebar.self" class="sidebar-user" :local="true" />

	<com-actions v-if="sidebar.actions" :role="sidebar.self.role" :actions="sidebar.actions" :initTime="initTime" />
	<com-schedule v-if="sidebar.schedule" :scroll="sidebar.scroll ? sidebar.scroll.schedule : 'top'" :schedule="sidebar.schedule" :initTime="initTime" />
	<com-current v-if="sidebar.current" :current="sidebar.current" :initTime="initTime" />
	<com-standardisation v-if="sidebar.standardisation" :standardisation="sidebar.standardisation" :initTime="initTime" />
	
	<com-chat v-if="sidebar.chat" :chat="sidebar.chat" :initTime="initTime" />

</div>

</template>

<script>

import comParticipant from './meeting/Participant'
import comSchedule from './sidebar/Schedule'
import comActions from './sidebar/Actions'
import comCurrent from './sidebar/Current'
import comStandardisation from './sidebar/Standardisation'
import comChat from './sidebar/Chat'

export default {

	props: ['sidebar', 'initTime'],

	components: {
		'com-participant': comParticipant,
		'com-schedule': comSchedule,
		'com-actions': comActions,
		'com-current': comCurrent,
		'com-standardisation': comStandardisation,
		'com-chat': comChat
	}

}

</script>

<style scoped>

.sidebar {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 256px;
	padding: 10px;
	bottom: 54px;
	display: flex;
	flex-direction: column;
	z-index: 2;
	transition: left 300ms ease-in-out;
}

.sidebar-logo {
	background-image: url(~@/assets/logo.png);
	background-position: 50% 50%;
	background-size: 210.5px 85px;
	background-repeat: no-repeat;
	height: 85px;
	flex-shrink: 0;
}

@media (max-height: 799px) {

	.sidebar-logo {
		display: none;
	}

}

.sidebar-user {
	position: relative!important;
	flex-shrink: 0!important;
	transition: none!important;
}

.sidebar-user >>> .stream {
	border-radius: 4px 4px 0px 0px;
	overflow: hidden;
}

.sidebar-user:not(.is-expanded) >>> .stream {
	height: 177px!important;
	border-radius: 4px 4px 0px 0px;
	overflow: hidden;
}

</style>
