<template>

<div class="chat" onboarding="sidebar.chat">

	<app-scroll class="chat-messages" ref="messages" v-on:scroll="onScroll">

		<div v-for="message in messages" :key="message.id" :onboarding="'sidebar.chat.' + message.id" class="chat-messages-item">

			<p><b>{{ message.name }}:</b> {{ message.content }}</p>

			<small>{{ (message.time + initTime) | fromNow() }} &middot; {{ (message.time + initTime) | formatDate('h:mma') }}</small>

		</div>

	</app-scroll>

	<div class="chat-compose" onboarding="sidebar.chat.compose">

		<input class="chat-compose-input" v-on:contextmenu="$allowContext" :disabled="is.loading" placeholder="Enter message..." type="text" v-model="compose" />
		<app-button :disabled="!compose" :loading="is.loading" class="chat-compose-button" :small="true">Send</app-button>

	</div>

</div>

</template>

<script>

export default {

	props: ['chat', 'initTime'],

	data: function() {

		return {

			compose: '',

			is: {
				loading: false
			},

			scrollX: 0,
			bottomX: 0

		}

	},

	watch: {

		messages: function() {

			this.$nextTick(function () {

				if (this.scrollX >= this.bottomX) {

					this.$refs.messages.$children[0].$refs.scrollElement.scrollTop = this.$refs.messages.$children[0].$refs.scrollElement.scrollHeight

				}

				this.bottomX = this.$refs.messages.$children[0].$refs.scrollElement.scrollHeight - this.$refs.messages.$children[0].$refs.scrollElement.clientHeight
				this.bottomX = (this.bottomX < 0) ? 0 : this.bottomX

			}.bind(this))

		}

	},

	computed: {

		messages: function() {

			return this.chat.messages

		}

	},

	methods: {

		onScroll: function(e) {

			this.scrollX = e

		}

	}

}

</script>

<style scoped>

.chat {
	display: flex;
	flex-direction: column;
	padding: 0px!important;
	border-radius: 4px;
	min-height: 64px;
	flex-grow: 1;
	border: 1px solid #ddd;
	background-color: #fff;
	margin-top: 10px;
}

.chat-messages {
	flex-grow: 1;
}

.chat-messages-item {
	padding: 7px 10px;
}

.chat-messages-item p {
	font-size: 14px;
	line-height: 20px;
	font-weight: 300;
	color: #333;
}

.chat-messages-item p b {
	font-weight: 500;
}

.chat-messages-item small {
	font-size: 10px;
	font-weight: 300;
}

.chat-messages-item:nth-child(even) {
	background-color: #efefef;
}

.chat-messages-empty {
	font-size: 24px;
	font-weight: 400;
	position: absolute;
	left: 50%;
	color: #999;
	top: 50%;
	transform: translate(-50%, -50%);
}

.chat-compose {
	padding-left: 10px;
	flex-shrink: 0;
	height: 32px;
	border-top: 1px solid #ddd;
	line-height: 32px;
	display: flex;
	flex-direction: row;
}

.chat-compose-input {
	flex-grow: 1;
	height: 32px;
	line-height: 32px;
	flex-basis: 0;
	max-width: 150px;
	font-size: 14px;
	color: #666;
	font-weight: 400;
}

</style>
