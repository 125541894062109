<template>

<div class="adhocs">

	<div class="adhocs-empty" v-if="!adhocs.length && !is.loading && !is.failed">There are no ad-hocs here.</div>

	<div class="adhocs-loading prp-loading" v-if="is.loading" />
	<div class="connections-failed" v-if="is.failed"><i class="fa fa-exclamation-triangle" /><span>Sorry, data could not be loaded.</span><small>If problem persists, please contact tech support.</small></div>

	<div class="adhocs-toggle" v-if="!is.loading && !is.failed">

		<button class="adhocs-toggle-item" :class="{'is-active': subsection === 'approved'}" v-on:click="subsection = 'approved'">Scheduled<span>{{ counts.approved }}</span></button>
		<button class="adhocs-toggle-item is-red" :class="{'is-active': subsection === 'threshold'}" v-on:click="subsection = 'threshold'">Recommended<span>{{ counts.threshold }}</span></button>
		<button class="adhocs-toggle-item is-amber" :class="{'is-active': subsection === 'flagged'}" v-on:click="subsection = 'flagged'">Flagged<span>{{ counts.flagged }}</span></button>

	</div>

	<com-schedule v-for="(adhoc, index) in adhocs" :key="index" :item="adhoc" :type="subsection" />

</div>

</template>

<script>

import comSchedule from './adhocs/Schedule'

export default {

	components: {

		'com-schedule': comSchedule

	},

	data: function() {

		return {
			is: {
				loading: true,
				failed: false
			},
			subsection: 'approved',
			data: {
				approved: [],
				threshold: [],
				flagged: []
			},
			timer: false,
			closed: false
		}

	},

	created: function() {

		this.update()

	},

	beforeDestroy: function() {

		this.closed = true

		clearTimeout(this.timer)
		
	},

	watch: {

		currentCircuit: function() {

			this.is.loading = true
			this.update()

		}

	},

	computed: {

		currentCircuit: function() {

			return this.$store.getters['circuits/current']

		},

		counts: function() {

			var counts = {
				approved: 0,
				threshold: 0,
				flagged: 0
			}

			var ignore = this.$_.pluck(this.$_.filter(this.$store.getters['flags'], function(flag) {

				return this.$_.contains([
					this.$constants.flag.approved,
					this.$constants.flag.hide
				], flag.type)

			}.bind(this)), 'attach')

			this.$_.each(counts, function(count, key) {

				counts[key] = this.$_.filter(this.data[key], function(item) {

					return !this.$_.contains(ignore, item.identity) && item.circuit === this.$store.getters['circuits/current']

				}.bind(this)).length

			}.bind(this))

			return counts

		},

		adhocs: function() {

			var ignore = this.$_.pluck(this.$_.filter(this.$store.getters['flags'], function(flag) {

				return this.$_.contains([
					this.$constants.flag.approved,
					this.$constants.flag.hide
				], flag.type)

			}.bind(this)), 'attach')

			return this.$_.filter(this.data[this.subsection], function(item) {

				return !this.$_.contains(ignore, item.identity) && item.circuit === this.$store.getters['circuits/current']

			}.bind(this))

		}

	},

	methods: {

		update: function() {

			clearTimeout(this.timer)

			var apiCircuit = this.$store.getters['circuits/current']
			
			this.$api.request('adhoc/dashboard', {
				circuit: this.$store.getters['circuits/current']
			}).then(function(json) {

				this.is.loading = false

				this.data.approved = json.approved
				this.data.threshold = json.threshold
				this.data.flagged = json.flagged

				if (!this.closed && apiCircuit === this.$store.getters['circuits/current']) this.timer = this.$_.delay(this.update.bind(this), 60000)

			}.bind(this), function() {

				this.is.failed = true
				this.is.loading = false

			}.bind(this))

		},

	}

}

</script>

<style scoped>

.adhocs {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	flex-grow: 1;
	justify-content: flex-start;
}

.adhocs-loading {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-left: -32px;
	margin-top: -32px;
}

.adhocs-empty {
	position: absolute;
	left: 50%;
	top: 50%;
	font-size: 24px;
	color: #ddd;
	font-weight: 500;
	text-align: center;
	transform: translate(-50%, -50%);
}

.adhocs-toggle {
	display: grid;
	width: 100%;
	grid-template-columns: 1fr 1fr 1fr;
	margin-bottom: 10px;
}

.adhocs-toggle-item {
	border: 1px solid #ddd;
	height: 42px;
	line-height: 40px;
	font-size: 14px;
	font-weight: 300;
	text-align: center;
	cursor: pointer;
}

.adhocs-toggle-item:nth-child(1) {
	border-radius: 4px 0px 0px 4px;
}

.adhocs-toggle-item:nth-child(2) {
	border-width: 1px 0px;
}

.adhocs-toggle-item:nth-child(3) {
	border-radius: 0px 4px 4px 0px;
}

.adhocs-toggle-item.is-active {
	background-color: #287ABD;
    border-color: #287ABD;
    color: #fff;
}

.adhocs-toggle-item:not(.is-active):focus,
.adhocs-toggle-item:not(.is-active):hover {
	background-color: #eee;
}

.adhocs-toggle-item.is-active:focus {
	box-shadow: rgba(50, 50, 93, .1) 0 0 0 1px inset, rgba(50, 50, 93, .2) 0 4px 4px 0, rgba(0, 0, 0, .1) 0 2px 2px 0, rgba(50, 151, 211, .3) 0 0 0 4px;
}

.adhocs-toggle-item span {
	background-color: #287ABD;
	color: #fff;
	border-radius: 4px;
	padding: 2px 4px;
	margin-left: 8px;
}

.adhocs-toggle-item.is-amber span {
	background-color: #ffbe19;
}

.adhocs-toggle-item.is-red span {
	background-color: #d34545;
}

.adhocs-toggle-item.is-amber.is-active {
	background-color: #ffbe19;
    border-color: #ffbe19;
    color: #fff;
}

.adhocs-toggle-item.is-red.is-active {
	background-color: #d34545;
    border-color: #d34545;
    color: #fff;
}

.adhocs-toggle-item.is-active span {
	background-color: #fff;
    color: #333;
}

.connections-failed {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 64px;
	display: flex;
	flex-direction: column;
	align-items: center;
	color: #c62323;
}

.connections-failed span {
	font-size: 24px;
	font-weight: 400;
	margin-top: 20px;
}

.connections-failed small {
	font-size: 14px;
	margin-top: 4px;
	line-height: 20px;
	color: #333;
}

</style>