<template>

<div class="availability">

	<div class="availability-head">Update Profile</div>

	<div class="availability-form">

		<div class="availability-input">
			<div class="availability-input-label">First name</div>
			<input type="text" class="availability-input" :disabled="saving" vv-on:contextmenu="$allowContext" v-model="model.firstname" placeholder="Your firstname" />
		</div>

		<div class="availability-input">
			<div class="availability-input-label">Last name</div>
			<input type="text" class="availability-input" :disabled="saving" vv-on:contextmenu="$allowContext" v-model="model.lastname"  placeholder="Your lastname" />
		</div>

		<div class="availability-input">
			<div class="availability-input-label">E-mail address</div>
			<input type="text" class="availability-input" :disabled="saving" v-on:contextmenu="$allowContext" v-model="model.email" placeholder="Your e-mail address" />
		</div>


	</div>

	<div class="availability-buttons">

		<app-button v-if="changed || !saved" :loading="saving" :disabled="!changed || !valid" v-on:click="onSaveClick">Save</app-button>
		<app-button v-if="!changed && saved" theme="green">Saved</app-button>
		<app-button v-if="changed" :disabled="saving" theme="plain" v-on:click="$emit('close')">Cancel</app-button>
		<app-button v-if="(saved && !changed) || !changed" theme="plain" v-on:click="$emit('close')">Close</app-button>

	</div>

</div>

</template>

<script>

import Vue from 'vue'
import { validationMixin } from 'vuelidate'
import { email, required } from 'vuelidate/lib/validators'

export default {

	mixins: [validationMixin],

	data: function() {

		return {
			saving: false,
			saved: false,
			changed: false,
			model: {
				firstname: '',
				lastname: '',
				email: ''
			},
			original: {}
		}

	},

	validations: {
		model: {
			firstname: {
				required
			},
			lastname: {
				required
			},
			email: {
				email
			}
		}
	},

	created: function() {

		this.reset()

	},

	computed: {

		valid: function() {

			return !this.$v.$invalid

		}

	},

	watch: {

		model: {

			deep: true,

			handler: function() {

				this.changed = !this.$_.isEqual(this.model, this.original)

				this.$v.$touch()

			}

		}

	},

	methods: {

		reset: function() {

			this.model.firstname = this.$store.getters['user/firstname']
			this.model.lastname = this.$store.getters['user/lastname']
			this.model.email = this.$store.getters['user/email']

			Vue.set(this, 'original', this.$util.copy(this.model))

			this.changed = !this.$_.isEqual(this.model, this.original)

		},

		onSaveClick: function() {

			this.saving = true

			this.$api.request('user/profile', this.model).then(function(json) {

				this.$store.dispatch('user/init', json.user)
				
				this.reset()

				this.saving = false
				this.saved = true

			}.bind(this), function() {

				this.saving = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.availability {
	background-color: #fff;
	width: 742px;
	display: block;
	padding: 20px;
	border: 1px solid #ddd;
	border-radius: 4px;
	align-items: center;
	box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
	user-select: none;
	max-height: calc(100vh - 200px);
	overflow: auto;
}

.availability-head {
	font-size: 24px;
	font-weight: 500;
	text-align: center;
	margin-bottom: 24px;
}

.availability-input {
	display: grid;
	grid-template-columns: 120px auto;
}

.availability-input-label {
	font-size: 14px;
	font-weight: 400;
	line-height: 40px;
}

.availability-input input {
	border: 2px solid #ddd;
	border-radius: 4px;
	font-size: 14px;
	line-height: 20px;
	padding: 7px 10px;
	text-align: left;
	background-color: #fff;
	margin-bottom: 10px;
	width: 100%;
}

.availability-input input:focus {
    border: 2px solid #4285f4;
}

.availability-buttons {
	display: flex;
	justify-content: center;
	margin-top: 20px;
}

.availability-buttons >>> .button {
	width: 150px;
}

</style>