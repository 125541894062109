<template>

<div class="brief">

	<app-shredded class="brief-shredded" :data="{type: 'calibration', brief: 'examiner_1', identity: schedule.identity}" :schedule="schedule.identity" :showNote="true" />

	<div class="brief-label">
		<div class="brief-label-text">Examiner brief</div>
		<button class="brief-label-close" v-on:click="$emit('close')" v-tooltip="'Click to close'"><i class="fa fa-times"></i></button>
	</div>
	
</div>

</template>

<script>

export default {

	props: ['value'],

	computed: {

		schedule: function() {

			return (this.$isLeadExaminer && !this.isActive && this.$isEventMarking) ? {identity: this.value} : this.$store.getters['schedule'][0]

		}

	}

}

</script>

<style scoped>

.brief {
	display: flex;
	flex-direction: column;
	border: 1px solid #ddd;
	border-radius: 4px;
	overflow: hidden;
	background-color: #fff;
	box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
	z-index: 1000;
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
}

.brief-shredded {
	width: 100%;
	flex-grow: 1;
	border-radius: 4px 4px 0px 0px;
	flex-basis: 0;
}

.brief-label {
	flex-shrink: 0;
	text-align: left;
	font-size: 16px;
	font-weight: 400;
	color: #333;
	text-transform: capitalize;
	display: flex;
	justify-content: space-between;
}

.brief-label-text {
	flex-grow: 1;
	padding: 10px;
}

.brief-label-close {
	flex-shrink: 0;
	font-size: 16px;
	width: 40px;
	height: 40px;
	color: #999;
}

.brief-label-close:focus {
	background-color: #287ABD;
	color: #fff;		
}

.brief-label-close:hover {
	color: #333;
}

</style>