<template>

<div class="stream">

	<div class="stream-recording" v-if="recording">

		<video v-show="!is.loading" ref="video" preload="auto" v-on:canplay="onCanPlay" :src="recording" />

	</div>

	<div class="stream-hidden" v-if="audioOnly && !avatar"><i class="fa fa-phone"></i></div>

	<div class="stream-avatar" :style="{backgroundImage: 'url(' + avatar + ')'}" v-if="avatar"></div>

</div>

</template>

<script>

export default {

	props: ['recording', 'avatar', 'audioOnly'],

	data: function() {

		return {
			is: {
				loading: true
			}
		}

	},

	created: function() {

		this.$pubsub.$on('playback.play', this.onPlay)
		this.$pubsub.$on('playback.skip', this.onSkip)
		this.$pubsub.$on('playback.pause', this.onPause)

	},

	beforeDestroy: function() {

		this.$refs.video.removeAttribute('src')
		this.$refs.video.load()

		this.$pubsub.$off('playback.play', this.onPlay)
		this.$pubsub.$off('playback.skip', this.onSkip)
		this.$pubsub.$off('playback.pause', this.onPause)

	},

	methods: {

		onPlay: function() {

			this.$refs.video.play()

		},

		onPause: function() {

			this.$refs.video.pause()

		},

		onSkip: function(value) {
			
			this.is.loading = true

			this.$refs.video.currentTime = value.toString()

		},

		onCanPlay: function() {

			this.$emit('canplay')

			this.is.loading = false

		}

	}

}

</script>

<style scoped>

.stream {
	width: 100%;
	height: 100%;
	background-color: #000;
	background-image: url('data:image/gif;base64,R0lGODlhKwALAPEAAAAAAP///3x8fP///yH+GkNyZWF0ZWQgd2l0aCBhamF4bG9hZC5pbmZvACH5BAAKAAAAIf8LTkVUU0NBUEUyLjADAQAAACwAAAAAKwALAAACMoSOCMuW2diD88UKG95W88uF4DaGWFmhZid93pq+pwxnLUnXh8ou+sSz+T64oCAyTBUAACH5BAAKAAEALAAAAAArAAsAAAI9xI4IyyAPYWOxmoTHrHzzmGHe94xkmJifyqFKQ0pwLLgHa82xrekkDrIBZRQab1jyfY7KTtPimixiUsevAAAh+QQACgACACwAAAAAKwALAAACPYSOCMswD2FjqZpqW9xv4g8KE7d54XmMpNSgqLoOpgvC60xjNonnyc7p+VKamKw1zDCMR8rp8pksYlKorgAAIfkEAAoAAwAsAAAAACsACwAAAkCEjgjLltnYmJS6Bxt+sfq5ZUyoNJ9HHlEqdCfFrqn7DrE2m7Wdj/2y45FkQ13t5itKdshFExC8YCLOEBX6AhQAADsAAAAAAAAAAAA=');
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

.stream-recording {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 1;
}

.stream-recording {
	z-index: 2;
}

.stream >>> video {
	width: auto;
	z-index: 1;
	display: block;
	height: 100%;
	pointer-events: none;
	max-height: 100%;
    left: 50%;
    position: absolute;
	max-width: 100%;
    transform: translateX(-50%);
}

.stream-hidden {
	font-size: 96px;
	color: #fff;
}

.stream-avatar {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	background-color: #000;
	z-index: 1000;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: contain;
}

</style>