<template>
    
<div class="marking" onboarding="panel.marksheet" :class="{'is-active': panel.active}">

	<div class="marking-buttons">

		<div class="marking-buttons-item" v-if="panel.back" :class="{'is-saved': panel.saved}"><i class="fa fa-caret-left"></i></div>

	</div>

	<div class="marking-content">

		<div class="sheet" :class="{'is-incomplete': !panel.valid, 'is-complete': panel.valid, 'is-saved': panel.saved && !panel.changed}">

		<div class="sheet-head">

			<div class="sheet-head-title">Mark Sheet</div>

			<div class="sheet-head-status" onboarding="panel.marksheet.status">
				<div class="sheet-head-status-item is-completed" v-if="!panel.valid">{{ panel.remaining }} left</div>
				<div class="sheet-head-status-item is-completed" v-if="panel.valid"><i class="fa fa-check"></i></div>
			</div>

		</div>

		<div class="sheet-context" onboarding="panel.marksheet.context">

			<div onboarding="panel.marksheet.context.date">{{ panel.date }}</div>
			<div onboarding="panel.marksheet.context.reference">{{ $language.role.candidate }} #{{ panel.reference }}</div>

		</div>

		<app-scroll class="sheet-body">

			<com-question v-for="question in panel.questions" :question="question" :key="question.identity" />

		</app-scroll>

		<div class="sheet-foot" onboarding="panel.marksheet.foot">

			<app-button :disabled="!panel.valid || (!panel.changed && panel.saved)" :loading="panel.saving" theme="white" class="sheet-foot-button">{{ panel.button }}</app-button>
			<app-button v-if="panel.saved && panel.changed" theme="plain" class="sheet-foot-button">Reset changes</app-button>

		</div>

	</div>

	</div>

</div>

</template>

<script>

import comQuestion from './marksheet/Question'

export default {

	props: ['panel'],

	components: {
		'com-question': comQuestion
	}

}

</script>

<style scoped>

.marking {
	position: fixed;
	right: -256px;
	top: 0px;
	width: 256px;
	bottom: 54px;
	z-index: 2;
	transition: right 300ms ease-in-out;
}

.marking.is-active {
    right: 0px;
}

.marking-buttons {
	position: absolute;
	left: -45px;
	width: 45px;
	height: 45px;
	top: 0px;
	display: flex;
	align-items: flex-end;
	flex-direction: column;
}

.marking-buttons-item {
	width: 45px;
	height: 45px;
    background-color: #2A7ABB;
	line-height: 45px;
	color: #fff;
	font-size: 20px;
	text-align: center;
	cursor: pointer;
	transition: all 100ms linear;
}

.marking:not(.is-active) .marking-buttons-item {
    background-color: #287ABD;
}

.marking-buttons-item:not(.is-active):hover {
    background-color: #56a0dc;
}

.marking:not(.is-active) .marking-buttons-item:not(.is-active):hover {
    background-color: #2A7ABB;
}

.marking-buttons-item.is-saved {
    background-color: #38b56a;
}

.marking-buttons-item.is-saved:hover {
    background-color: #48cc7d;
}

.marking-content {
	width: 100%;
	height: 100%;
}


.sheet {
	width: 256px;
	height: 100%;
	display: flex;
    background-color: #287ABD;
	transition: background-color 100ms linear;
    flex-direction: column;
}

.sheet.is-saved {
    background-color: #27a258;
}

.sheet-head {
    color: #fff;
    font-size: 24px;
    font-weight: 300;
    flex-shrink: 0;
	padding: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row;
    align-items: center;
}

.sheet-head-title {
    flex-grow: 1;
}

.sheet-head-status {
    flex-shrink: 0px;
    display: flex;
    flex-direction: row;
}

.sheet-head-status-item {
    background-color: #fff;
    color: #287ABD;
    font-size: 14px;
    font-weight: 500;
    height: 24px;
	white-space: nowrap;
    line-height: 25px;
	padding: 0px 5px;
    text-align: center;
    transition: all 100ms linear;
}

.sheet-head-status-item.is-completed {
    border-radius: 4px;
}

.sheet.is-incomplete .sheet-head-status-item.is-completed {
    color: #cc3939;
}

.sheet.is-complete .sheet-head-status-item.is-completed {
    color: #27a258;
}

.sheet-context {
    padding: 10px;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    color: #fff;
    font-weight: 400;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    justify-content: space-between;
}

.sheet-body {
    flex-grow: 1;
    flex-basis: 0;
}

.sheet-foot {
	padding: 10px;
    flex-shrink: 0;
}

.sheet-foot-button {
    width: 100%;
}

.sheet-foot-button.theme-plain {
	color: #fff!important;
	margin-top: 4px;
}

.sheet.is-saved .sheet-foot-button {
    color: #27a258;
}



</style>