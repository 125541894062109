<template>

<div class="success">

	<i class="success-icon fa fa-times-circle" />

	<div class="success-text">{{ template.text.failed }}</div>

	<app-button class="success-button" theme="red" v-on:click="onRefreshClick">Try again <i class="fa fa-sync-alt"></i></app-button>

</div>

</template>

<script>

export default {

	props: ['template', 'check'],

	methods: {

		onRefreshClick: function() {

			window.location.reload()

		}

	}

}

</script>

<style scoped>

.success {
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 100%;
	max-width: 480px;
	align-items: center;
	background-color: #fff;
	padding: 40px 20px;
	border: 1px solid #ddd;
	border-radius: 4px;
}

.success-icon {
	color: #c62323;
	font-size: 96px;
}

.success-text {
	font-size: 16px;
	line-height: 20px;
	font-weight: 400;
	text-align: center;
	color: #333;
	margin: 40px 0px;
}

.success-button  >>> .fa {
	margin-left: 10px;
}

</style>