<template>

<div tabindex="0" ref="sidebar" class="sidebar">

	<div class="sidebar-logo"></div>

	<app-room-participant v-if="!isChaperoning || isTalking || is.expanded || isChaperoneGroup" :showScreenshare="true" :person="localPerson" :stream="localStream" v-on:pin="onPin" class="sidebar-user" :pin="localPin" :local="true" :forced="isTalking" />

	<app-room-participant v-if="(isChaperoning || isChaperoned) && !isChaperoneGroup && chaperonePerson" v-on:expand="onChaperoneExpand" :expanded="is.expanded" :expandable="true" :person="chaperonePerson" :stream="chaperoneStream" class="sidebar-user is-chaperone" :class="{'is-expanded': is.expanded, 'is-talking': isTalking}" />

	<com-actions-user />
	
	<com-actions-observer v-if="!isActive" />
	<com-actions-lead v-if="!isActive" />

	<com-schedule v-if="(!isActive || $isInterval || $isEventFinished) && schedule.length" />
	<com-structure v-if="isChaperoneGroup && !$isEventFinished" />
	<com-current v-if="isActive && !$isInterval && !isChaperoneGroup" />
	<com-details v-if="isActive && !$isInterval && !isChaperoneGroup" />
	<com-standardisation v-if="$isInStandardisation" />
	
	<div class="sidebar-chaperone" v-if="isChaperoneGroup && !$isEventFinished">

		<com-online :chaperone="true" />

	</div>

	<com-chat v-if="isMeeting" />

</div>

</template>

<script>

import comChat from './sidebar/Chat'
import comSchedule from './sidebar/Schedule'
import comOnline from './sidebar/Online'
import comActionsUser from './sidebar/actions/User'
import comActionsLead from './sidebar/actions/Lead'
import comActionsObserver from './sidebar/actions/Observer'
import comCurrent from './sidebar/Current'
import comStructure from './sidebar/Structure'
import comDetails from './sidebar/Details'
import comStandardisation from './sidebar/Standardisation'

export default {

	components: {
		'com-chat': comChat,
		'com-schedule': comSchedule,
		'com-structure': comStructure,
		'com-current': comCurrent,
		'com-details': comDetails,
		'com-actions-user': comActionsUser,
		'com-online': comOnline,
		'com-standardisation': comStandardisation,
		'com-actions-observer': comActionsObserver,
		'com-actions-lead': comActionsLead
	},

	data: function() {

		return {
			is: {
				pinning: false,
				expanded: false
			}
		}

	},

	watch: {

		isChaperoning: function(n) {

			if (!n) {
				
				this.is.expanded = false
				this.$chaperone.stopTalking()

			}

		},

		isTalking: function() {

			this.is.expanded = false

		},

		activeIdentity: function() {

			this.$nextTick(function() {

				this.$refs.sidebar.focus()

			}.bind(this))

		}

	},

	created: function() {

		this.$nextTick(function() {

			this.$refs.sidebar.focus()

		}.bind(this))

	},

	computed: {

		activeIdentity: function() {

			return (this.isActive) ? this.isActive.identity : false

		},

		chaperoneStream: function() {

			return this.$store.getters['chaperone/person']

		},

		chaperonePerson: function() {

			return this.$store.getters['people'][this.$store.getters['chaperone/chaperone']]

		},

		isChaperoned: function() {

			return this.isCandidate && this.isTalking && !this.isMeeting && (!this.isStation || this.isPreparation)


		},

		isChaperoning: function() {

			return this.isChaperone && (!this.isRoamingChaperone || this.isTalking) && !this.isMeeting && (!this.isStation || this.isPreparation || this.isAudioStation)

		},

		localPin: function() {

			if (this.isActive && !this.$isActivePlayback) {

				return {
					enabled: (this.isActive.pinned.length < 2 || this.$_.contains(this.isActive.pinned, this.localPerson.identity)) && this.isActive.participants.length > 1,
					active: this.$_.contains(this.isActive.pinned, this.localPerson.identity),
					loading: this.is.pinning,
					editable: this.isMeeting && this.isLead,
					hidden: this.isStation
				}

			} else {

				return {
					enabled: false,
					active: false,
					loading: false,
					editable: false,
					hidden: false
				}

			}

		},

		localPerson: function() {

			return this.$store.getters['people/self']

		},

		localStream: function() {

			if ((!this.isObserver || this.isParticipating || this.isTalking) && !this.$isActivePlayback) {

				return {
					identity: this.$store.getters['session/identity']
				}

			} else {

				return false

			}

		},

		schedule: function() {

			return this.$store.getters['schedule']

		}

	},

	methods: {

		onChaperoneExpand: function() {

			this.is.expanded = !this.is.expanded

		},

		onPin: function() {

			if (!this.is.pinning) {

				this.is.pinning = true

				this.$api.request('room/pin', {
					room: this.isActive.identity,
					participant: this.$store.getters['session/identity']
				}).then(function() {

					this.is.pinning = false

				}.bind(this), function() {

					this.is.pinning = false

				}.bind(this))

			}

		}

	}

}

</script>

<style scoped>

.sidebar {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 256px;
	padding: 10px;
	bottom: 54px;
	display: flex;
	flex-direction: column;
	z-index: 2;
	transition: left 300ms ease-in-out;
}

.sidebar-logo {
	background-image: url(~@/assets/logo.png);
	background-position: 50% 50%;
	background-size: 210.5px 85px;
	background-repeat: no-repeat;
	height: 85px;
	flex-shrink: 0;
}

@media (max-height: 799px) {

	.sidebar-logo {
		display: none;
	}

}

.sidebar-user {
	position: relative!important;
	flex-shrink: 0!important;
	transition: none!important;
}

.sidebar-user.is-expanded {
	position: fixed!important;
	left: 256px;
	top: 10px;
	bottom: 64px;
	right: 10px;
	z-index: 100;
}

.sidebar-user.is-chaperone.is-talking:not(.is-expanded) {
	margin-top: 10px;
}

.sidebar-user:not(.is-expanded) >>> .participant-status {
	height: 177px!important;
}

.sidebar-user >>> .stream {
	border-radius: 4px 4px 0px 0px;
	overflow: hidden;
}

.sidebar-user:not(.is-expanded) >>> .stream {
	height: 177px!important;
	border-radius: 4px 4px 0px 0px;
	overflow: hidden;
}

.sidebar-chaperone {
	padding: 10px;
	height: auto;
	display: flex;
	color: #333;
	flex-direction: column;
	justify-content: center;
	border: 1px solid #ddd;
	background-color: #fff;
	margin-top: 10px;
	text-align: center;
	border-radius: 4px;
	box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
}

</style>
