<template>

<div class="meeting">

	<app-room :item="schedule" />
	
</div>

</template>

<script>

export default {

	computed: {

		schedule: function() {

			return this.$store.getters['schedule/active']

		}

	}

}

</script>

<style scoped>

.meeting {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 1;
}

</style>