<template>

<div class="meeting">

	<div class="meeting-content">

		<div class="meeting-content-room">

			<div class="meeting-warning" v-if="isMeetingEnding" :class="{'is-active': isMeetingEnding}">{{ countdown(meetingEnd) }}</div>

			<app-room :pinsSpecial="$isInStandardisation" :item="schedule" :cards="cards" :pinsEditable="isLead" :forceLocal="onlyLocal" :showLocalPinned="true" v-on:cardClosed="onCardClosed" />

		</div>
	
		<com-marksheet v-if="isStandardising" :marksheet="schedule.meta.marksheet" />

	</div>

</div>

</template>

<script>

import comMarksheet from './station/Marksheet'

export default {

	components: {
		'com-marksheet': comMarksheet
	},

	data: function() {

		return {
			brief: {
				candidate: false,
				roleplayer: false,
				roleplayer_1: false,
				roleplayer_2: false,
				roleplayer_3: false,
				roleplayer_4: false,
				examiner: false,
				examiner_1: false,
				examiner_2: false,
				examiner_3: false,
				examiner_4: false,
				layexaminer: false,
				layexaminer_1: false,
				layexaminer_2: false,
				layexaminer_3: false,
				layexaminer_4: false,
            }
		}

	},

	created: function() {

        this.$pubsub.$on('action.brief', this.onReadBrief.bind(this))

	},

	beforeDestroy: function() {

		this.$pubsub.$off('action.brief', this.onReadBrief.bind(this))

	},

	computed: {

		isStandardising: function() {

			return this.schedule.meta.marksheet && (this.$isExaminer || this.$isLeadExaminer)

		},

		onlyLocal: function() {

			return this.schedule.participants.length === 1 && this.cards.length === 0

		},

		schedule: function() {

			return this.$store.getters['schedule/active']

		},

		meetingEnd: function() {

			return this.schedule.end

		},

		isMeetingEnding: function() {

			return this.countdown(this.schedule.end, true) <= 10

		},

		cards: function() {

			var cards = []

			if (this.$isInStandardisation) {

				this.$_.each(this.brief, function(enabled, type) {

					if (enabled) {
						
						cards.push({
							closable: true,
							type: this.$constants.card.type.file,
							identity: type,
							expandable: true,
							title: type.replace('_', ' ') + ' brief',
							shredded: {
								type: 'calibration',
								brief: type,
								identity: this.schedule.identity
							}
						})

					}

				}.bind(this))
				
			}

			this.$_.each(this.schedule.inventory, function(inventory) {
				
				var show = false

				if (inventory.availability.type === this.$constants.inventory.availability.always) {

					show = true

				} else if (inventory.availability.type === this.$constants.inventory.availability.time) {

					show = inventory.availability.timer <= this.roleplayElapsed

				} else if (inventory.availability.type === this.$constants.inventory.availability.trigger) {

					var isPermitted = false, revealed = false

					if (inventory.availability.permission === this.$constants.inventory.availability.permission.role) {

						isPermitted = this.$_.contains(inventory.availability.roles, this.$store.getters['people/self'].role)

					} else if (inventory.availability.permission === this.$constants.inventory.availability.permission.user) {

						isPermitted = this.$_.contains(inventory.availability.logins, this.$store.getters['session/identity'])

					} else if (inventory.availability.permission === this.$constants.inventory.availability.permission.lead) {

						isPermitted = this.isLead

					}

					revealed = this.$_.contains(this.$_.keys(this.schedule.triggered), inventory.id)

					if (isPermitted) {

						show = true

					} else {

						show = revealed

					}

					if (revealed) {

						if (this.schedule.triggered[inventory.id] !== this.$store.getters['session/identity']) {

							isPermitted = false

						}

					}

				}
				
				if (show) {

					cards.push({
						type: this.$constants.card.type.file,
						identity: inventory.id,
						observeIdentity: this.schedule.id + '-' + inventory.id,
						title: inventory.name,
						subtitle: 'Inventory',
						avatar: inventory.avatar,
						availability: inventory.availability,
						interaction: inventory.interaction,
						triggerer: this.schedule.triggered[inventory.id],
						revealed: revealed,
						image: inventory.image,
						video: inventory.video,
						shredded: (inventory.pdf) ? {
							type: 'inventory',
							identity: inventory.id
						} : false
					})
					
				}

			}.bind(this))

			return cards

		},

	},

	methods: {

		onCardClosed: function(identity) {

			if (this.brief[identity]) {

				this.brief[identity] = false
				
			}

		},

		onReadBrief: function(identity) {

			this.brief[identity] = !this.brief[identity]

        }

	}

}

</script>

<style scoped>

.meeting {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 1;
}

.meeting-content {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 1;
	display: flex;
	flex-direction: column;
}

.meeting-content-room {
	flex-shrink: 0;
	flex-grow: 1;
	min-height: 160px;
}

.meeting-warning {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	height: 100%;
	z-index: 1;
	color: red;
	font-size: 64px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	font-weight: 700;
	z-index: 20;
	text-shadow: 0px 0px 30px rgba(0, 0, 0, 1);
	opacity: 0;
	transition: opacity 300ms ease-in-out;
	pointer-events: none;
}

.meeting-warning.is-active {
	opacity: 1;
}


</style>