import pusher from '@/service/pusher'

export default {

	namespaced: true,

	state: {

		announcements: []

	},

	getters: {

		get: function(state) {

			return state.announcements

		}

	},

	mutations: {

		set: function(state, data) {

			state.announcements = data

		},

		add: function(state, data) {

			state.announcements.push(data)

		}

	},

	actions: {

		init: function(context, data) {

			context.commit('set', data)

			pusher.on('event', 'announcement', function(data) {

				context.commit('add', data)

			}, {
				endpoint: 'announcements',
				immediate: true,
				interval: 60
			})

			pusher.on('user', 'announcement', function(data) {

				context.commit('add', data)

			})

		}

	}

}