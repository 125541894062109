<template>

    <div class="menu has-availability">
    
        <button class="menu-item" tabindex="0" v-tooltip="'View your exam schedule'">
            
            <div class="menu-item-icon"><i class="fas fa-calendar-alt" /></div>
            <div class="menu-item-text">View Schedule</div>
            
        </button>
    
        <button class="menu-item" tabindex="0" v-tooltip="'Edit your profile'">
            
            <div class="menu-item-icon"><i class="fas fa-user-edit" /></div>
            <div class="menu-item-text">Edit Profile</div>
            
        </button>
    
        <button class="menu-item" tabindex="0" v-tooltip="'Update your availability'">
            
            <div class="menu-item-icon"><i class="fas fa-calendar-check" /></div>
            <div class="menu-item-text">Set Availability</div>
            
        </button>
    
    </div>
    
    </template>
    
    <script>
    
    export default {
    
    }
    
    </script>
    
    <style scoped>
    
    .menu {
        display: grid;
        grid-gap: 20px;
        width: 640px;
        grid-template-columns: 1fr 1fr;
    }
    
    .menu.has-availability {
        grid-template-columns: 1fr 1fr 1fr;
    }
    
    .menu-item {
        background-color: #fff;
        padding: 20px;
        height: 200px;
        display: block;
        border: 1px solid #ddd;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
    }
    
    .menu-item:hover {
        background-color: #287ABD;
        border: 1px solid #287ABD;
    }
    
    .menu-item:focus {
        box-shadow: rgba(50, 50, 93, .1) 0 0 0 1px inset, rgba(50, 50, 93, .2) 0 4px 4px 0, rgba(0, 0, 0, .1) 0 2px 2px 0, rgba(50, 151, 211, .3) 0 0 0 4px;
        z-index: 10;
    }
    
    .menu-item-icon {
        font-size: 96px;
        color: #287ABD;
        height: 96px;
        margin-bottom: 20px;
    }
    
    .menu-item-text {
        color: #333;
        font-size: 16px;
        font-weight: 400;
    }
    
    .menu-item:hover .menu-item-icon,
    .menu-item:hover .menu-item-text {
        color: #fff;
    }
    
    </style>