<template>

<div class="playback">

	<div class="playback-empty" v-if="!playback.length">You have no candidates to mark.</div>

	<div tabindex="0" class="playback-item" v-for="item in playback" :key="item.identity">

		<div class="playback-item-status" :data-status="sheetStatus(item)">

			{{ textStatus(item) }}

		</div>

		<div class="playback-item-details">

			<div class="playback-item-details-name">#{{ candidateReference(item.candidate) }}</div>
			<div class="playback-item-details-date">{{ item.start | formatDate('HH:mma, Do MMMM') }}</div>

		</div>

		<div class="playback-item-timeline">

			<div class="playback-item-timeline-bar">
				<div class="playback-item-timeline-bar-progress" :style="{width: stylePosition(item)}"></div>
			</div>

			<div class="playback-item-timeline-time">{{ textPosition(item.identity) }}</div>

		</div>

		<div class="playback-item-actions">

			<app-button :small="true" :disabled="!canView(item.identity)" v-on:click="onPlayClick(item.identity)" :loading="is.loading === item.identity">{{ textButton(item.identity) }}<i class="fa fa-chevron-right"></i></app-button>

		</div>

	</div>

</div>

</template>

<script>

export default {

	data: function() {

		return {
			is: {
				loading: false
			}
		}

	},

	computed: {

		sheets: function() {

			return {
				saved: this.$store.getters['marking/saved'],
				session: this.$store.getters['marking/session']
			}

		},

		playback: function() {

			return this.$store.getters['playback']

		},

		tracking: function() {

			return this.$store.getters['playback/tracking']

		}

	},

	methods: {

		candidateReference: function(id) {

			return (this.$store.getters['people'][id]) ? this.$store.getters['people'][id].reference : '?'

		},

		canView: function(id) {

			return this.tracking[id].can.view

		},

		sheetStatus: function(item) {

			if (this.$hasFlagged('absent', item.identity)) {

				return this.$constants.marksheet.status.absent

			} else {

				var sheet = this.sheets.saved[item.identity]

				var isSaved = this.$validateSheet(this.$store.getters['marking/questions'][item.identity], sheet)

				if (item.start > this.time) {

					return this.$constants.marksheet.status.locked
					
				} else if (isSaved) {

					if(this.$_.isEqual(this.sheets.saved[item.identity], this.sheets.session[item.identity])) {

						return this.$constants.marksheet.status.saved

					} else {

						return this.$constants.marksheet.status.changed

					}

				} else {

					if (item.end > this.time) {

						return this.$constants.marksheet.status.active

					} else {

						return this.$constants.marksheet.status.unsaved

					}

				}
				
			}

		},
		
		textStatus: function(item) {

			return this.$constants.marksheet.statusLabel[this.sheetStatus(item)]

		},

		stylePosition: function(item) {

			if (this.tracking[item.identity].position) {

				return ((100 / item.duration.roleplay) * this.tracking[item.identity].position).toString() + '%'

			} else if (this.tracking[item.identity].views) {

				return '100%'

			} else {

				return '0%'

			}

		},

		textPosition: function(id) {

			if (this.tracking[id].position) {

				var mins = Math.floor(this.tracking[id].position / 60)

				return (mins) ? mins.toString() + ' minutes viewed' : '< 1 minute viewed'

			} else if (this.tracking[id].views) {

				return 'Viewed ' + this.tracking[id].views.toString() + ' time' + ((this.tracking[id].views > 1) ? 's' : '')

			} else {

				return 'Not viewed'

			}

		},

		textButton: function(id) {

			return (this.$store.getters['playback/tracking'][id].position) ? 'Resume' : 'Start'

		},

		onPlayClick: function(id) {
			
			this.is.loading = id

			this.$api.request('playback/start', {
				identity: id
			}).then(function(json) {
			
				this.is.loading = false

				this.$store.commit('schedule/add', this.$util.copy(json.schedule))

			}.bind(this), function() {

				this.is.loading = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.playback {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	flex-grow: 1;
	justify-content: flex-start;
}

.playback-empty {
	position: absolute;
	left: 50%;
	top: 50%;
	font-size: 24px;
	color: #ddd;
	font-weight: 500;
	text-align: center;
	transform: translate(-50%, -50%);
}

.playback-item {
	margin: 0px 10px 10px 0px;
	overflow: hidden;
	width: 100%;
	display: flex;
	flex-direction: row;
	border-radius: 4px;
}

.playback-item-status {
	color: #fff;
	padding: 10px;
	font-weight: 400;
	font-size: 16px;
	height: 54px;
	display: flex;
	align-items: center;
	width: 120px;
}

.playback-item-status[data-status="2"] {
	background-color: #27a258;
}

.playback-item-status[data-status="1"] {
	background-color: #1c6aa9;
	color: #5ea9e6;
}

.playback-item-status[data-status="3"] {
	background-color: #cc3939;
}

.playback-item-status[data-status="5"] {
	background-color: #ce9921;
}

.playback-item-status[data-status="4"] {
	background-color: #56aef5;
}

.playback-item-status[data-status="6"] {
	background-color: #6d38a1;
}

.playback-item-details {
	border: 1px solid #ddd;
	border-width: 1px 0px;
	padding: 10px;
	height: 54px;
	display: flex;
	flex-direction: column;
	justify-items: center;
	flex-grow: 1;
}

.playback-item:focus .playback-item-details {
	border-color: #287ABD;
}

.playback-item-details-name {
	font-size: 16px;
    font-weight: 300;
}

.playback-item-details-date {
	font-size: 12px;
    font-weight: 300;
	margin-top: 4px;
}

.playback-item-timeline {
	width: 240px;
	border: 1px solid #ddd;
	border-right-width: 0px;
	display: flex;
	padding: 0px 10px;
	flex-direction: column;
	justify-content: center;
}

.playback-item:focus .playback-item-timeline {
	border-color: #287ABD;
}

.playback-item-timeline-bar {
	width: 100%;
	height: 18px;
	border-radius: 4px;
	background-color: #ddd;
}

.playback-item-timeline-bar-progress {
	height: 18px;
	border-radius: 4px;
	background-image: linear-gradient(135deg, #287ABD 25%, #1a72ba 25%, #1a72ba 50%, #287ABD 50%, #287ABD 75%, #1a72ba 75%, #1a72ba 100%);
	background-size: 28.28px 28.28px;
}

.playback-item-timeline-time {
	font-size: 12px;
	font-weight: 300;
	margin-top: 3px;
	color: #666;
}	

.playback-item-actions {
	padding: 10px;
	border: 1px solid #ddd;
}

.playback-item:focus .playback-item-actions {
	border-color: #287ABD;
}

.playback-item-actions >>> .button {
	width: 120px;
	text-align: left;
	padding: 0px 10px;
}

.playback-item-actions >>> .button .fa {
	position: absolute;
	right: 10px;
	top: 8px;
}

</style>
