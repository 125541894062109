<template>

    <app-modal :active="true">
    
        <template v-slot:head>
            <h1>Marking Conflict</h1>
        </template>
    
        <textarea onboarding="modal.input" class="textarea" placeholder="(optional) Add any further details relating to your flag..." />
    
        <template v-slot:buttons>
            <app-button onboarding="modal.button">Confirm</app-button>
            <app-button theme="plain">Cancel</app-button>
        </template>
    
    </app-modal>
    
    </template>
    
    <script>
    
    export default {
    
    }
    
    </script>
    
    <style scoped>
    
    .textarea {
        border: 2px solid #ddd;
        border-radius: 4px;
        font-size: 14px;
        line-height: 20px;
        padding: 7px 10px;
        text-align: left;
        background-color: #fff;
        margin-bottom: 20px;
        width: 100%;
        height: 106px;
    }
    
    .textarea:focus {
        border: 2px solid #4285f4;
    }
    
    </style>
    