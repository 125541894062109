<template>
    
<div class="candidates">

    <app-scroll class="candidates-body">

		<div class="candidates-item" :class="{'is-inactive': item.status === 3}" v-for="item in candidates" :key="item.identity" v-on:click="onCandidateClick(item.identity)">

			<div class="candidates-item-text">
				<div class="candidates-item-text-name">Candidate #{{ item.reference }}</div>
				<div class="candidates-item-text-time">{{ item.time | formatDate('h:mma') }}</div>
			</div>

			<div class="candidates-item-status" :class="{'is-changed': item.status === 4, 'is-unsaved': item.status === 2, 'is-saved': item.status === 1, 'is-future': item.status === 3, 'is-active': item.status === 5}">
				{{ item.label }}
			</div>

		</div>

    </app-scroll>

</div>

</template>

<script>

export default {

	props: ['saved', 'session', 'validSave'],

	computed: {

        date: function() {

			return this.$store.getters['event'].date
			
		},

		candidates: function() {

			var candidates = []
			var previousInactive = false

			this.$_.each(this.$store.getters['schedule'], function(schedule) {

				if (schedule.type === this.$constants.schedule.type.station) {

					if (!schedule.active) previousInactive = true

					var status = 0
					var label = ''

					if (schedule.start > this.time || !schedule.active || previousInactive) {

						label = 'Not started'
						status = 3

					} else if (this.validSave[schedule.identity]) {

						if(this.$_.isEqual(this.saved[schedule.identity], this.session[schedule.identity])) {

							label = 'Completed & saved'
							status = 1

						} else {

							label = 'Unsaved changes'
							status = 4

						}

					} else {

						if (schedule.end > this.time) {

							label = 'In progress'
							status = 5

						} else {

							label = 'Not saved'
							status = 2

						}

					}

					candidates.push({
						identity: schedule.identity,
						reference: this.$store.getters['people'][schedule.candidate].reference,
						time: schedule.start,
						status: status,
						label: label
					})

				}

			}.bind(this))

			return candidates

		}

	},

	methods: {

		onCandidateClick: function(identity) {

			this.$emit('change', identity)

		}

	}

}

</script>

<style scoped>

.candidates {
	display: flex;
    flex-direction: column;
}

.candidates-item {
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	padding: 20px;
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
	user-select: none;
}

.candidates-item.is-inactive {
	pointer-events: none;
}

.candidates-item:hover {
	background-color: rgba(0, 0, 0, 0.1);
	border-color: transparent;
}

.candidates-item-text {
	color: #333;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	flex-basis: 0;
}

.candidates-item.is-inactive .candidates-item-text {
	opacity: 0.25;
}

.candidates-item-text-name {
	font-size: 18px;
	font-weight: 400;
}

.candidates-item-text-time {
	font-size: 14px;
	font-weight: 300;
	opacity: 0.75;
}

.candidates-item-status {
	color: #fff;
	border-radius: 4px; 
	padding: 5px 10px;
	font-weight: 400;
	font-size: 14px;
}

.candidates-item-status.is-saved {
	background-color: #27a258;
}

.candidates-item-status.is-future {
	background-color: #666;
	color: #fff;
}

.candidates-item-status.is-unsaved {
	background-color: #cc3939;
}

.candidates-item-status.is-changed {
	background-color: #ce9921;
}

.candidates-item-status.is-active {
	background-color: #56aef5;
}

</style>