import pusher from '@/service/pusher'
import _ from 'underscore'
import Vue from 'vue'

export default {

	namespaced: true,

	state: {

		chats: []

	},

	getters: {

		get: function(state) {

			return state.chats

		}

	},

	mutations: {

		set: function(state, data) {

			state.chats = data

		},

		add: function(state, data) {

			if (!_.findWhere(state.chats, {
				identity: data.identity
			})) state.chats.push(data)

		},

		remove: function(state, data) {

			var exists = _.findIndex(state.chats, {
				identity: data.identity
			})

			if (exists >= 0) state.chats.splice(exists, 1)

		},

		update: function(state, data) {

			_.each(state.chats, function(chat) {

				if (chat.identity === data.identity) {

					Vue.set(chat, 'reads', data.reads)
					Vue.set(chat, 'recent', data.recent)
					Vue.set(chat, 'participants', data.participants)
					Vue.set(chat, 'status', data.status)

				}

			})

		},

		read: function(state, data) {

			_.each(state.chats, function(chat) {

				if (chat.identity === data.chat) {

					chat.read = data.time

				}

			})

		}

	},

	actions: {

		init: function(context, data) {

			context.commit('set', data)

			// add existing chats
			_.each(context.getters['get'], function(chat) {

				pusher.subscribe.chat(chat.identity)

				pusher.on('chat.' + chat.identity, 'message', function(data) {

					context.commit('messages/add', data, {
						root: true
					})

				})

			})

			// listen for updated chats
			pusher.on('user', 'chat/update', function(data) {

				context.commit('update', data)

			})

			// listen for leaving chats
			pusher.on('user', 'chat/leave', function(data) {

				context.commit('remove', data)

			})

			// listen for new chats
			pusher.on('user', 'chat', function(data) {

				context.commit('add', data)

				if (data.message) {

					context.commit('messages/add', data.message, {
						root: true
					})

				}

				pusher.subscribe.chat(data.identity)

				pusher.on('chat.' + data.identity, 'message', function(data) {

					context.commit('messages/add', data, {
						root: true
					})

				})

			})

		}

	}

}