<template>

<div class="grid" :class="{[classSize]: true, [classPin]: true}">

	<slot></slot>

</div>

</template>

<script>

export default {

	props: ['participantsCurrent', 'cardsCurrent', 'pinsCurrent', 'pinsLoading', 'pinsEnabled', 'pinsLimit', 'pinsEditable', 'pinsSpecial', 'pinsHidden'],

	computed: {

		count: function() {

			return {
				total: this.participants.length + this.cards.length,
				participants: this.participants.length,
				cards: this.cards.length,
				pinned: this.pinned.length
			}

		},

		participants: function() {

			return (this.participantsCurrent) ? this.participantsCurrent : []

		},

		cards: function() {

			return (this.cardsCurrent) ? this.cardsCurrent : []

		},

		pinned: function() {

			return (this.pinsEnabled) ? this.pinsCurrent : []

		},

		size: function() {

			var pinSize = (this.pinSizeX * this.pinSizeY) - 1
			var adjustedTotal = this.count.total + (this.count.pinned * pinSize)
			var size = Math.ceil(Math.sqrt(adjustedTotal))

			if (this.count.total === 2 && this.count.pinned === 0 && size === 2) size = 1.5
			if (this.pinned.length === 0 && this.count.total < 7 && size === 3) size = 2.5
			if (this.pinned.length === 1 && size < 3) size = 3
			if (this.pinned.length === 2 && size === 3) size = 4

			return size

		},

		pinSizeDefault: function() {

			return 2

		},

		pinSizeX: function() {

			if (this.pinned.length === 1 && this.pinsSpecial && this.count.total > 6) {

				return 5
				
			} else if (this.pinned.length === 1 && this.pinsSpecial && this.count.total <= 6) {

				return 5

			} else if (this.pinned.length === 2 && this.pinsSpecial) {

				return 2

			} else {

				return this.pinSizeDefault

			}

		},

		pinSizeY: function() {

			if (this.pinned.length === 1 && this.pinsSpecial && this.count.total === 1) {

				return 5
				
			} else if (this.pinned.length === 1 && this.pinsSpecial && this.count.total === 2) {

				return 4

			} else if (this.pinned.length === 1 && this.pinsSpecial && this.count.total <= 6) {

				return 4

			} else if (this.pinned.length === 1 && this.pinsSpecial && this.count.total <= 12) {

				return 3

			} else if (this.pinned.length === 2 && this.count.total > 2 && this.pinsSpecial) {

				return 3

			} else if (this.pinned.length === 2 && this.count.total === 2 && this.pinsSpecial) {

				return 4

			} else {

				return this.pinSizeDefault

			}

		},

		classSize: function() {

			return 'size-' + this.size.toString().replace('.', '_')

		},

		classPin: function() {

			return 'pin-' + this.pinSizeX.toString() + '-' + this.pinSizeY.toString()

		}

	},

	created: function() {

		this.$prefix = 'grid'

		this.$nextTick(function() {

			this.index()
			this.status()

		})
		
	},

	watch: {

		pinsLimit: function() {

			this.$nextTick(function() {

				this.status()

			})

		},

		pinsSpecial: function() {

			this.$nextTick(function() {

				this.status()

			})

		},

		pinsEditable: function() {

			this.$nextTick(function() {

				this.status()

			})

		},

		pinsLoading: function() {

			this.$nextTick(function() {

				this.status()

			})

		},

		pinsEnabled: function() {

			this.$nextTick(function() {

				this.index()
				this.status()

			})

		},

		count: {

			deep: true,

			handler: function() {

				this.$nextTick(function() {

					this.index()
					this.status()

				})

			}

		}

	},

	methods: {

		status: function() {

			var enabled

			this.$_.each(this.$slots.default, function(child) {

				enabled = this.pinsEnabled 

				if (this.pinsLimit <= this.pinned.length) {

					enabled = (this.$_.contains(this.pinned, child.key)) ? true : false

				}

				child.componentInstance.$children[0].pin = {
					enabled: enabled,
					loading: this.pinsLoading === child.key,
					active: this.$_.contains(this.pinned, child.key),
					editable: this.pinsEditable,
					hidden: this.pinsHidden
				} 

			}.bind(this))

		},

		index: function() {

			var grid = []

			for (var i=1; i <= this.size * this.size; i++) {

				grid.push(false)

			}

			var identities = []

			this.$_.each(this.pinned, function(key) {

				this.$_.each(this.$slots.default, function(child) {

					if (child.key === key) identities.push(child.key)

				}.bind(this))

			}.bind(this))

			this.$_.each(this.$slots.default, function(child) {

				if (!this.$_.contains(identities, child.key)) identities.push(child.key)

			}.bind(this))

            var identityIndex = {}
            var identityWidth = {}

			this.$_.each(identities, function(identity, identitiesIndex) {

				this.$_.each(grid, function(taken, index) {

					if (!taken && !identityIndex[identity]) {

						if (this.$_.contains(this.pinned, identity)) {

							// valid column
							if (index % this.pinSizeX === 0 && index % this.size <= this.pinSizeX) {

								// valid row
								if ((index % this.size) <= this.pinSizeY) {

									for (var k = 0; k < this.pinSizeX; k++) {

										for (var j = 0; j < this.pinSizeY; j++) {

											grid[index + k + (j * this.size)] = true
											identityIndex[identity] = index + 1

										}

									}


								}

							}

						} else {

							grid[index] = true
							identityIndex[identity] = index + 1

						}

					}

                }.bind(this))
                
                if (identitiesIndex === identities.length - 1) {

                    identityWidth[identity] = (this.size * this.size) - identityIndex[identity] + 1

                }

			}.bind(this))

			this.$_.each(this.$slots.default, function(child) {

				child.componentInstance.$children[0].index = identityIndex[child.key]
				child.componentInstance.$children[0].width = identityWidth[child.key] || false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.grid {
	width: 100%;
	height: 100%;
}

.grid >>> .card {
	left: 0%;
	top: 0%;
	transition: all 600ms ease-in-out;
}

.grid.size-1 >>> .card {
	width: calc(100% - 10px);
	height: calc(100% - 10px);
}

.grid.size-1_5 >>> .card {
	width: calc(50% - 10px);
	height: calc(100% - 10px);
}

.grid.size-2 >>> .card {
	width: calc(50% - 10px);
	height: calc(50% - 10px);
}

.grid.size-2 >>> .card.width-2 {
    width: calc(100% - 10px);
}

.grid.size-2_5 >>> .card {
	width: calc(33.3% - 10px);
	height: calc(50% - 10px);
}

.grid.size-3 >>> .card {
	width: calc(33.3% - 10px);
	height: calc(33.3% - 10px);
}

.grid.size-3 >>> .card.width-2 {
	width: calc(66.6% - 10px);
}

.grid.size-3 >>> .card.width-3 {
    width: calc(100% - 10px);
}

.grid.size-3.pin-2-2 >>> .card.is-pinned {
	width: calc(66.6% - 10px);
	height: calc(66.6% - 10px);
}

.grid.size-3.pin-3-2 >>> .card.is-pinned {
	width: calc(100% - 10px);
	height: calc(66.6% - 10px);
}

.grid.size-3.pin-3-3 >>> .card.is-pinned {
	width: calc(100% - 10px);
	height: calc(100% - 10px);
}

.grid.size-4 >>> .card {
	width: calc(25% - 10px);
	height: calc(25% - 10px);
}

.grid.size-4 >>> .card.width-2 {
	width: calc(50% - 10px);
}

.grid.size-4 >>> .card.width-3 {
	width: calc(75% - 10px);
}

.grid.size-4 >>> .card.width-4 {
    width: calc(100% - 10px);
}

.grid.size-4.pin-2-2 >>> .card.is-pinned {
	width: calc(50% - 10px);
	height: calc(50% - 10px);
}

.grid.size-4.pin-4-2 >>> .card.is-pinned {
	width: calc(100% - 10px);
	height: calc(50% - 10px);
}

.grid.size-4.pin-3-3 >>> .card.is-pinned {
	width: calc(75% - 10px);
	height: calc(75% - 10px);
}

.grid.size-4.pin-4-3 >>> .card.is-pinned {
	width: calc(100% - 10px);
	height: calc(75% - 10px);
}

.grid.size-4.pin-3-2 >>> .card.is-pinned {
	width: calc(75% - 10px);
	height: calc(50% - 10px);
}

.grid.size-4.pin-2-3 >>> .card.is-pinned {
	width: calc(50% - 10px);
	height: calc(75% - 10px);
}

.grid.size-4.pin-2-4 >>> .card.is-pinned {
	width: calc(50% - 10px);
	height: calc(100% - 10px);
}

.grid.size-4.pin-4-4 >>> .card.is-pinned {
	width: calc(100% - 10px);
	height: calc(100% - 10px);
}

.grid.size-5 >>> .card {
	width: calc(20% - 10px);
	height: calc(20% - 10px);
}

.grid.size-5 >>> .card.width-2 {
	width: calc(40% - 10px);
}

.grid.size-5 >>> .card.width-3 {
	width: calc(60% - 10px);
}

.grid.size-5 >>> .card.width-4 {
	width: calc(80% - 10px);
}

.grid.size-5 >>> .card.width-5 {
    width: calc(100% - 10px);
}

.grid.size-5.pin-2-2 >>> .card.is-pinned {
	width: calc(40% - 10px);
	height: calc(40% - 10px);
}

.grid.size-5.pin-2-3 >>> .card.is-pinned {
	width: calc(40% - 10px);
	height: calc(60% - 10px);
}

.grid.size-5.pin-3-3 >>> .card.is-pinned {
	width: calc(60% - 10px);
	height: calc(60% - 10px);
}

.grid.size-5.pin-4-4 >>> .card.is-pinned {
	width: calc(80% - 10px);
	height: calc(80% - 10px);
}

.grid.size-5.pin-4-3 >>> .card.is-pinned {
	width: calc(80% - 10px);
	height: calc(60% - 10px);
}

.grid.size-5.pin-5-3 >>> .card.is-pinned {
	width: calc(100% - 10px);
	height: calc(60% - 10px);
}

.grid.size-5.pin-5-4 >>> .card.is-pinned {
	width: calc(100% - 10px);
	height: calc(80% - 10px);
}

.grid.size-5.pin-5-5 >>> .card.is-pinned {
	width: calc(100% - 10px);
	height: calc(100% - 10px);
}

.grid.size-6 >>> .card {
	width: calc(16.5% - 10px);
	height: calc(16.5% - 10px);
}

.grid.size-6 >>> .card.width-2 {
	width: calc(33% - 10px);
}

.grid.size-6 >>> .card.width-3 {
	width: calc(49.5% - 10px);
}

.grid.size-6 >>> .card.width-4 {
	width: calc(66% - 10px);
}

.grid.size-6 >>> .card.width-5 {
    width: calc(82.5% - 10px);
}

.grid.size-6 >>> .card.width-6 {
	width: calc(100% - 10px);
}

.grid.size-6.pin-2-2 >>> .card.is-pinned {
	width: calc(33% - 10px);
	height: calc(33% - 10px);
}

.grid.size-6.pin-3-3 >>> .card.is-pinned {
	width: calc(49.5% - 10px);
	height: calc(49.5% - 10px);
}

.grid.size-6.pin-4-4 >>> .card.is-pinned {
	width: calc(66% - 10px);
	height: calc(66% - 10px);
}

.grid.size-6.pin-5-5 >>> .card.is-pinned {
	width: calc(82.5% - 10px);
	height: calc(82.5% - 10px);
}

.grid.size-7 >>> .card {
	width: calc(14.28% - 10px);
	height: calc(14.28% - 10px);
}

.grid.size-7 >>> .card.width-2 {
	width: calc(28.56% - 10px);
}

.grid.size-7 >>> .card.width-3 {
	width: calc(42.84% - 10px);
}

.grid.size-7 >>> .card.width-4 {
	width: calc(57.12% - 10px);
}

.grid.size-7 >>> .card.width-5 {
    width: calc(71.4% - 10px);
}

.grid.size-7 >>> .card.width-6 {
	width: calc(85.68% - 10px);
}

.grid.size-7 >>> .card.width-7 {
	width: calc(100% - 10px);
}

.grid.size-7.pin-2-2 >>> .card.is-pinned {
	width: calc(28.56% - 10px);
	height: calc(28.56% - 10px);
}

.grid.size-7.pin-3-3 >>> .card.is-pinned {
	width: calc(42.84% - 10px);
	height: calc(42.84% - 10px);
}

.grid.size-7.pin-4-4 >>> .card.is-pinned {
	width: calc(57.12% - 10px);
	height: calc(57.12% - 10px);
}

.grid.size-7.pin-5-5 >>> .card.is-pinned {
	width: calc(71.4% - 10px);
	height: calc(71.4% - 10px);
}

.grid.size-7.pin-6-6 >>> .card.is-pinned {
	width:  calc(85.68% - 10px);
	height:  calc(85.68% - 10px);
}

.grid.size-1_5 >>> .card.index-2 {
	left: 50%;
}

.grid.size-2 >>> .card.index-2,
.grid.size-2 >>> .card.index-4 {
	left: 50%;
}

.grid.size-2 >>> .card.index-3,
.grid.size-2 >>> .card.index-4 {
	top: 50%;
}

.grid.size-2_5 >>> .card.index-2,
.grid.size-2_5 >>> .card.index-5 {
	left: 33.3%;
}

.grid.size-2_5 >>> .card.index-3,
.grid.size-2_5 >>> .card.index-6 {
	left: 66.6%;
}

.grid.size-2_5 >>> .card.index-4,
.grid.size-2_5 >>> .card.index-5,
.grid.size-2_5 >>> .card.index-6 {
	top: 50%;
}

.grid.size-3 >>> .card.index-2,
.grid.size-3 >>> .card.index-5,
.grid.size-3 >>> .card.index-8 {
	left: 33.3%;
}

.grid.size-3 >>> .card.index-3,
.grid.size-3 >>> .card.index-6,
.grid.size-3 >>> .card.index-9 {
	left: 66.6%
}

.grid.size-3 >>> .card.index-4,
.grid.size-3 >>> .card.index-5,
.grid.size-3 >>> .card.index-6 {
	top: 33.3%;
}

.grid.size-3 >>> .card.index-7,
.grid.size-3 >>> .card.index-8,
.grid.size-3 >>> .card.index-9 {
	top: 66.6%;
}

.grid.size-4 >>> .card.index-2,
.grid.size-4 >>> .card.index-6,
.grid.size-4 >>> .card.index-10,
.grid.size-4 >>> .card.index-14 {
	left: 25%;
}

.grid.size-4 >>> .card.index-3,
.grid.size-4 >>> .card.index-7,
.grid.size-4 >>> .card.index-11,
.grid.size-4 >>> .card.index-15 {
	left: 50%;
}

.grid.size-4 >>> .card.index-4,
.grid.size-4 >>> .card.index-8,
.grid.size-4 >>> .card.index-12,
.grid.size-4 >>> .card.index-16 {
	left: 75%;
}

.grid.size-4 >>> .card.index-5,
.grid.size-4 >>> .card.index-6,
.grid.size-4 >>> .card.index-7,
.grid.size-4 >>> .card.index-8 {
	top: 25%;
}

.grid.size-4 >>> .card.index-9,
.grid.size-4 >>> .card.index-10,
.grid.size-4 >>> .card.index-11,
.grid.size-4 >>> .card.index-12 {
	top: 50%;
}

.grid.size-4 >>> .card.index-13,
.grid.size-4 >>> .card.index-14,
.grid.size-4 >>> .card.index-15,
.grid.size-4 >>> .card.index-16 {
	top: 75%;
}

.grid.size-5 >>> .card.index-2,
.grid.size-5 >>> .card.index-7,
.grid.size-5 >>> .card.index-12,
.grid.size-5 >>> .card.index-17,
.grid.size-5 >>> .card.index-22 {
	left: 20%;
}

.grid.size-5 >>> .card.index-3,
.grid.size-5 >>> .card.index-8,
.grid.size-5 >>> .card.index-13,
.grid.size-5 >>> .card.index-18,
.grid.size-5 >>> .card.index-23 {
	left: 40%;
}

.grid.size-5 >>> .card.index-4,
.grid.size-5 >>> .card.index-9,
.grid.size-5 >>> .card.index-14,
.grid.size-5 >>> .card.index-19,
.grid.size-5 >>> .card.index-24 {
	left: 60%;
}

.grid.size-5 >>> .card.index-5,
.grid.size-5 >>> .card.index-10,
.grid.size-5 >>> .card.index-15,
.grid.size-5 >>> .card.index-20,
.grid.size-5 >>> .card.index-25 {
	left: 80%;
}

.grid.size-5 >>> .card.index-6,
.grid.size-5 >>> .card.index-7,
.grid.size-5 >>> .card.index-8,
.grid.size-5 >>> .card.index-9,
.grid.size-5 >>> .card.index-10 {
	top: 20%;
}

.grid.size-5 >>> .card.index-11,
.grid.size-5 >>> .card.index-12,
.grid.size-5 >>> .card.index-13,
.grid.size-5 >>> .card.index-14,
.grid.size-5 >>> .card.index-15 {
	top: 40%;
}

.grid.size-5 >>> .card.index-16,
.grid.size-5 >>> .card.index-17,
.grid.size-5 >>> .card.index-18,
.grid.size-5 >>> .card.index-19,
.grid.size-5 >>> .card.index-20 {
	top: 60%;
}

.grid.size-5 >>> .card.index-21,
.grid.size-5 >>> .card.index-22,
.grid.size-5 >>> .card.index-23,
.grid.size-5 >>> .card.index-24,
.grid.size-5 >>> .card.index-25 {
	top: 80%;
}


.grid.size-6 >>> .card.index-2,
.grid.size-6 >>> .card.index-8,
.grid.size-6 >>> .card.index-14,
.grid.size-6 >>> .card.index-20,
.grid.size-6 >>> .card.index-26,
.grid.size-6 >>> .card.index-32 {
	left: 16.5%;
}

.grid.size-6 >>> .card.index-3,
.grid.size-6 >>> .card.index-9,
.grid.size-6 >>> .card.index-15,
.grid.size-6 >>> .card.index-21,
.grid.size-6 >>> .card.index-27,
.grid.size-6 >>> .card.index-33 {
	left: 33%;
}

.grid.size-6 >>> .card.index-4,
.grid.size-6 >>> .card.index-10,
.grid.size-6 >>> .card.index-16,
.grid.size-6 >>> .card.index-22,
.grid.size-6 >>> .card.index-28,
.grid.size-6 >>> .card.index-34 {
	left: 49.5%;
}

.grid.size-6 >>> .card.index-5,
.grid.size-6 >>> .card.index-11,
.grid.size-6 >>> .card.index-17,
.grid.size-6 >>> .card.index-23,
.grid.size-6 >>> .card.index-29,
.grid.size-6 >>> .card.index-35 {
	left: 66%;
}

.grid.size-6 >>> .card.index-6,
.grid.size-6 >>> .card.index-12,
.grid.size-6 >>> .card.index-18,
.grid.size-6 >>> .card.index-24,
.grid.size-6 >>> .card.index-30,
.grid.size-6 >>> .card.index-36 {
	left: 82.5%;
}

.grid.size-6 >>> .card.index-7,
.grid.size-6 >>> .card.index-8,
.grid.size-6 >>> .card.index-9,
.grid.size-6 >>> .card.index-10,
.grid.size-6 >>> .card.index-11,
.grid.size-6 >>> .card.index-12 {
	top: 16.5%;
}

.grid.size-6 >>> .card.index-13,
.grid.size-6 >>> .card.index-14,
.grid.size-6 >>> .card.index-15,
.grid.size-6 >>> .card.index-16,
.grid.size-6 >>> .card.index-17,
.grid.size-6 >>> .card.index-18 {
	top: 33%;
}

.grid.size-6 >>> .card.index-19,
.grid.size-6 >>> .card.index-20,
.grid.size-6 >>> .card.index-21,
.grid.size-6 >>> .card.index-22,
.grid.size-6 >>> .card.index-23,
.grid.size-6 >>> .card.index-24 {
	top: 49.5%;
}

.grid.size-6 >>> .card.index-25,
.grid.size-6 >>> .card.index-26,
.grid.size-6 >>> .card.index-27,
.grid.size-6 >>> .card.index-28,
.grid.size-6 >>> .card.index-29,
.grid.size-6 >>> .card.index-30 {
	top: 66%;
}

.grid.size-6 >>> .card.index-31,
.grid.size-6 >>> .card.index-32,
.grid.size-6 >>> .card.index-33,
.grid.size-6 >>> .card.index-34,
.grid.size-6 >>> .card.index-35,
.grid.size-6 >>> .card.index-36 {
	top: 82.5%;
}

.grid.size-7 >>> .card.index-2,
.grid.size-7 >>> .card.index-9,
.grid.size-7 >>> .card.index-16,
.grid.size-7 >>> .card.index-23,
.grid.size-7 >>> .card.index-30,
.grid.size-7 >>> .card.index-37,
.grid.size-7 >>> .card.index-44 {
	left: calc(100% / 7);
}

.grid.size-7 >>> .card.index-3,
.grid.size-7 >>> .card.index-10,
.grid.size-7 >>> .card.index-17,
.grid.size-7 >>> .card.index-24,
.grid.size-7 >>> .card.index-31,
.grid.size-7 >>> .card.index-38,
.grid.size-7 >>> .card.index-45 {
	left: calc((100% / 7) * 2);
}

.grid.size-7 >>> .card.index-4,
.grid.size-7 >>> .card.index-11,
.grid.size-7 >>> .card.index-18,
.grid.size-7 >>> .card.index-25,
.grid.size-7 >>> .card.index-32,
.grid.size-7 >>> .card.index-39,
.grid.size-7 >>> .card.index-46 {
	left: calc((100% / 7) * 3);
}

.grid.size-7 >>> .card.index-5,
.grid.size-7 >>> .card.index-12,
.grid.size-7 >>> .card.index-19,
.grid.size-7 >>> .card.index-26,
.grid.size-7 >>> .card.index-33,
.grid.size-7 >>> .card.index-40,
.grid.size-7 >>> .card.index-47 {
	left: calc((100% / 7) * 4);
}

.grid.size-7 >>> .card.index-6,
.grid.size-7 >>> .card.index-13,
.grid.size-7 >>> .card.index-20,
.grid.size-7 >>> .card.index-27,
.grid.size-7 >>> .card.index-34,
.grid.size-7 >>> .card.index-41,
.grid.size-7 >>> .card.index-48 {
	left: calc((100% / 7) * 5);
}

.grid.size-7 >>> .card.index-7,
.grid.size-7 >>> .card.index-14,
.grid.size-7 >>> .card.index-21,
.grid.size-7 >>> .card.index-28,
.grid.size-7 >>> .card.index-35,
.grid.size-7 >>> .card.index-42,
.grid.size-7 >>> .card.index-49 {
	left: calc((100% / 7) * 6);
}

.grid.size-7 >>> .card.index-8,
.grid.size-7 >>> .card.index-9,
.grid.size-7 >>> .card.index-10,
.grid.size-7 >>> .card.index-11,
.grid.size-7 >>> .card.index-12,
.grid.size-7 >>> .card.index-13,
.grid.size-7 >>> .card.index-14 {
	top: calc((100% / 7) * 1);
}

.grid.size-7 >>> .card.index-15,
.grid.size-7 >>> .card.index-16,
.grid.size-7 >>> .card.index-17,
.grid.size-7 >>> .card.index-18,
.grid.size-7 >>> .card.index-19,
.grid.size-7 >>> .card.index-20,
.grid.size-7 >>> .card.index-21 {
	top: calc((100% / 7) * 2);
}

.grid.size-7 >>> .card.index-22,
.grid.size-7 >>> .card.index-23,
.grid.size-7 >>> .card.index-24,
.grid.size-7 >>> .card.index-25,
.grid.size-7 >>> .card.index-26,
.grid.size-7 >>> .card.index-27,
.grid.size-7 >>> .card.index-28 {
	top: calc((100% / 7) * 3);
}

.grid.size-7 >>> .card.index-29,
.grid.size-7 >>> .card.index-30,
.grid.size-7 >>> .card.index-31,
.grid.size-7 >>> .card.index-32,
.grid.size-7 >>> .card.index-33,
.grid.size-7 >>> .card.index-34,
.grid.size-7 >>> .card.index-35 {
	top: calc((100% / 7) * 4);
}

.grid.size-7 >>> .card.index-36,
.grid.size-7 >>> .card.index-37,
.grid.size-7 >>> .card.index-38,
.grid.size-7 >>> .card.index-39,
.grid.size-7 >>> .card.index-40,
.grid.size-7 >>> .card.index-41,
.grid.size-7 >>> .card.index-42 {
	top: calc((100% / 7) * 5);
}

.grid.size-7 >>> .card.index-43,
.grid.size-7 >>> .card.index-44,
.grid.size-7 >>> .card.index-45,
.grid.size-7 >>> .card.index-46,
.grid.size-7 >>> .card.index-47,
.grid.size-7 >>> .card.index-48,
.grid.size-7 >>> .card.index-49 {
	top: calc((100% / 7) * 6);
}

.grid.size-4 >>> .card:not(.is-pinned) .participant-status-icon {
	font-size: 80px!important;
}

.grid.size-5 >>> .card:not(.is-pinned) .participant-status-icon,
.grid.size-6 >>> .card:not(.is-pinned) .participant-status-icon,
.grid.size-7 >>> .card:not(.is-pinned) .participant-status-icon {
	font-size: 48px!important;
}

.grid.size-5 >>> .card-foot-actions-item .fa {
	font-size: 22px;
}

.grid.size-6 >>> .card:not(.is-pinned) .card-foot-actions-item,
.grid.size-7 >>> .card:not(.is-pinned) .card-foot-actions-item {
	width: auto;
	font-size: 16px;
	line-height: 35px;
	height: 35px;
	padding: 0px 4px;
}

.grid.size-6 >>> .card:not(.is-pinned) .card-foot-actions-item .fa,
.grid.size-7 >>> .card:not(.is-pinned) .card-foot-actions-item .fa {
	font-size: 16px;
}

.grid.size-6 >>> .card-foot,
.grid.size-7 >>> .card-foot {
	min-height: 33px;
}

.grid.size-6 >>> .card-foot-text,
.grid.size-7 >>> .card-foot-text {
	padding: 5px;
}

.grid.size-6 >>> .card-foot-text-title,
.grid.size-7 >>> .card-foot-text-title {
	font-size: 12px;
	height: 12px;
}

.grid.size-6 >>> .card-foot-text-more,
.grid.size-7 >>> .card-foot-text-more {
	height: 11px;
}

.grid.size-6 >>> .card-foot-text-more-text,
.grid.size-7 >>> .card-foot-text-more-text {
	font-size: 11px;
	line-height: 11px;
}

.grid.size-6 >>> .card-foot-text-more-text:after,
.grid.size-7 >>> .card-foot-text-more-text:after {
    padding: 0px 2px;
}

.grid.size-6 >>> .participant-status-label,
.grid.size-7 >>> .participant-status-label {
	right: 0px;
	top: 0px;
	margin: 0px;
	border-radius: 0px 2px 0px 2px;
	font-size: 11px;
}

.grid.size-6 >>> .is-streaming .participant-status-label,
.grid.size-7 >>> .is-streaming .participant-status-label {
	left: 0px!important;
	right: auto;
	top: 0px;
	margin: 0px;
	border-radius: 2px 0px 2px 0px;
	font-size: 11px;
}

</style>