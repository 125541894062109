<template>

<div class="video" ref="video">

	<app-room-participant v-if="is.loaded" :person="localPerson" :stream="localStream" :forced="true" :plain="true" :local="true" class="video-stream" v-on:attached="onAttached" />

	<div class="video-face" :style="styleFace">

		<div class="video-face-target"></div>
		
		<div class="video-face-detected" :class="{[classFaceDetected]: true}" :style="styleFaceDetected">

			<span>{{ statusText[face.status] }}</span>

		</div>

	</div>

</div>

</template>

<script>

import * as faceapi from 'face-api.js'

export default {

	data: function() {

		return {
			interval: 250,
			delay: false,
			is: {
				loaded: false,
				detecting: false
			},
			statusText: {
				1: 'Perfect! You\'re ready.',
				2: 'Can\'t detect your face! Check your lighting, the angle of your webcam, and the angle of your head.',
				3: 'Too close! Try moving away from the webcam.',
				4: 'Too far away! Try moving closer to the webcam.',
				5: 'Too far left! Adjust your webcam or seat so you\'re more central.',
				6: 'Too far right! Adjust your webcam or seat so you\'re more central.',
				7: 'Too high! Adjust your webcam or seat so you\'re more central.',
				8: 'Too low! Adjust your webcam or seat so you\'re more central.'
			},
			face: {
				x: 0,
				y: 0,
				width: 0,
				height: 0,
				status: false
			},
			size: {
				width: 0,
				height: 0
			},	
			video: false,
			canvas: false
		}

	},

	created: function() {

		Promise.all([
			faceapi.nets.tinyFaceDetector.loadFromUri('/models'),
		]).then(function() {

			this.is.loaded = true

		}.bind(this))

	},

	beforeDestroy: function() {

		clearTimeout(this.delay)

	},

	computed: {

		localPerson: function() {

			return this.$store.getters['people/self']

		},

		localStream: function() {

			return {
				identity: this.$store.getters['session/identity']
			}

		},

		styleFace: function() {

			return {
				width: (this.video) ? this.size.width + 'px' : 0, 
				height: (this.video) ? this.size.height + 'px' : 0
			}

		},

		classFaceDetected: function() {

			var classes = []

			if (this.is.detecting) {

				if (this.face.status === this.$constants.face.detected) {

					classes.push('is-success')

				} else if (this.face.status === this.$constants.face.undetected) {
					
					classes.push('is-fail')

				} else {

					classes.push('is-warning')

					if (this.face.status === this.$constants.face.small) {

						classes.push('is-small')

					}
					
				}
				
			}

			return classes.join(' ')

		},

		styleFaceDetected: function() {

			return {
				left: Math.round(this.face.left).toString() + 'px',
				top: Math.round(this.face.top).toString() + 'px',
				width: Math.round(this.face.width).toString() + 'px',
				height: Math.round(this.face.height).toString() + 'px'
			}

		}

	},

	methods: {

		onAttached: function(video) {

			video.addEventListener('loadeddata', function() {

				this.video = video

				this.onUpdate()

			}.bind(this))

		},

		onUpdate: function() {

			faceapi.detectSingleFace(this.video, new faceapi.TinyFaceDetectorOptions({
				inputSize: 256,
				scoreThreshold: 0.2
			})).then(function(detection) {

				if (detection) {

					this.size.width = this.video.getBoundingClientRect().width
					this.size.height = this.video.getBoundingClientRect().height

					detection = faceapi.resizeResults([detection], {
						width: this.size.width, 
						height: this.size.height
					})[0]

					this.face.status = this.$constants.face.detected

					this.face.left = detection.box.left
					this.face.top = detection.box.top 
					this.face.width = detection.box.width
					this.face.height = detection.box.height

					var position = {
						x: (100 / this.size.width) * (this.face.left + (this.face.width / 2)),
						y: (100 / this.size.height) * (this.face.top + (this.face.height / 2)),
						width: (100 / this.size.width) * this.face.width,
						height: (100 / this.size.height) * this.face.height,
					}
					
					if (position.height > 60) {

						this.face.status = this.$constants.face.big

					} else if (position.height < 30) {

						this.face.status = this.$constants.face.small

					} else if (position.x < 40) {

						this.face.status = this.$constants.face.left

					} else if (position.x > 60) {

						this.face.status = this.$constants.face.right

					} else if (position.y < 40) {

						this.face.status = this.$constants.face.high

					} else if (position.y > 60) {

						this.face.status = this.$constants.face.low

					}

				} else {

					this.face.status = this.$constants.face.undetected

					this.face.left = 0
					this.face.top = 0
					this.face.width = this.video.getBoundingClientRect().width
					this.face.height = this.video.getBoundingClientRect().height

				}

				this.delay = this.$_.delay(this.onUpdate.bind(this), this.interval)

				this.is.detecting = true

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.video {
	width: 100%;
	height: 100%;
}

.video-stream {
	width: 100%;
	height: 100%;
	z-index: 1;
}

.video-face {
	position: absolute;
	z-index: 2;
	left: 50%;
	transform: translateX(-50%);
}

.video-face-target {
	position: absolute;
	z-index: 1;
	border: 10px dashed rgba(35, 125, 198, 0.25);
	left: 30%;
	top: 30%;
	right: 30%;
	bottom: 30%;
}

.video-face-detected {
	position: absolute;
	z-index: 2;
	transition: all 100ms linear;
	border: 10px solid transparent;
	border-radius: 50%;
	opacity: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px;
	color: #fff;
	font-size: 20px;
	line-height: 24px;
	text-align: center;
	font-weight: 400;
}

@media (max-height: 799px) {

	.video-face-detected {
		font-size: 14px;
		line-height: 18px;
	}

}

.video-face-detected.is-small {
	font-size: 16px;
	line-height: 20px;
}

.video-face-detected.is-success {
	border-color: rgb(35, 125, 198);
	background-color: rgba(35, 125, 198, 0.4);
	opacity: 1;
}

.video-face-detected.is-warning {
	border-color: rgb(251, 181, 22);
	background-color: rgba(251, 181, 22, 0.4);
	opacity: 1;
}

.video-face-detected.is-fail {
	border-color: rgb(198, 35, 35);
	opacity: 1;
	border-radius: 0%;
	background-color: rgba(198, 35, 35, 0.5);
}

.video-face-detected span {
	display: block;
	width: 100%;
	max-width: 480px;
}

</style>
