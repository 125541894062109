import pusher from '@/service/pusher'

export default {

	namespaced: true,

	state: {

		audio: true,
		video: true

	},

	getters: {

		audio: function(state) {

			return state.audio

		},

		video: function(state) {

			return state.video

		}

	},

	mutations: {

		audio: function(state, value) {

			state.audio = value

		},

		video: function(state, value) {

			state.video = value

		}

	},

	actions: {

		init: function(context) {

			pusher.on('user', 'settings/mute', function() {

				context.commit('audio', false)

			})

			pusher.on('user', 'settings/unmute', function() {

				context.commit('audio', true)

			})

		}

	}

}