<template>

<div>

	<app-actions-items v-if="$isLeadExaminer && $isEventMarking">

		<app-actions-item :shortcut="['shift', 'm']" icon="users" :active="isExaminers" name="Examiners" v-on:click="onSectionClick($constants.lead.section.examiners)" />
		<app-actions-item :shortcut="['shift', 'm']" icon="marker" :active="isMarking" name="Marking" v-on:click="onSectionClick($constants.lead.section.marking)" />
		<app-actions-item :shortcut="['shift', 'v']" icon="video" :active="isStandardisation" name="Videos" v-on:click="onSectionClick($constants.lead.section.standardisation)" />
		<app-actions-item :count="flags" :shortcut="['shift', 'f']" icon="flag" :active="isFlags" name="Flags" v-on:click="onSectionClick($constants.lead.section.flags)" />

	</app-actions-items>

</div>

</template>

<script>

export default {

	computed: {

		flags: function() {

			return this.$_.filter(this.$store.getters['flags'], function(flag) {

				return this.$_.contains([
					this.$constants.flag.case,
					this.$constants.flag.inconsistency,
					this.$constants.flag.unable,
					this.$constants.flag.training,
				], flag.type) && this.$store.getters['overview/associated'][flag.attach]

			}.bind(this)).length

		},

		isMarking: function() {
			
			return this.currentSection === this.$constants.lead.section.marking

		},

		isExaminers: function() {
			
			return this.currentSection === this.$constants.lead.section.examiners

		},

		isFlags: function() {
			
			return this.currentSection === this.$constants.lead.section.flags

		},

		isStandardisation: function() {
			
			return this.currentSection === this.$constants.lead.section.standardisation

		},

		currentSection: function() {

			return this.$store.getters['session/lead/section']

		}

	},

	methods: {

		onSectionClick: function(value) {

			this.$pubsub.$emit('lead.section', value)

		}

	}

}

</script>

<style scoped>

</style>
