<template>

    <div class="standardisation" onboarding="sidebar.standardisation">
        
        <button v-for="consult in consults" :key="consult.text" :class="{'is-disabled': true, 'is-active': consult.active}" class="standardisation-item">
        
            <div class="standardisation-item-name">#{{ consult.text }}</div>
        
        </button>
    
    </div>
    
    </template>
    
    <script>
    
    export default {

        props: ['standardisation'],
    
        computed: {
    
            consults: function() {
    
                return this.standardisation
    
            }
    
        }
    
    }
    
    </script>
    
    <style scoped>
    
    .standardisation {
        user-select: none;
        display: flex;
        flex-direction: column;
        border-radius: 4px;
        flex-shrink: 0;
        background-color: #fff;
        margin-top: 10px;
        box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
    }
    
    .standardisation-item {
        padding: 0px 10px;
        min-height: 48px;
        display: flex;
        color: #333;
        font-weight: 300;
        flex-direction: row;
        text-align: left;
        border: 1px solid #ddd;
        align-items: center;
        border-width: 0px 1px 1px 1px;
        display: flex;
    }
    
    .standardisation-item.is-disabled {
        pointer-events: none;
    }
    
    .standardisation-item:first-child {
        border-top-width: 1px;
        border-radius: 4px 4px 0px 0px;
    }
    
    .standardisation-item:last-child {
        border-radius: 0px 0px 4px 4px;
    }
    
    .standardisation-item:hover {
        background-color: #eee;
    }
    
    .standardisation-item-name {
        flex-grow: 1;
        font-size: 16px;
    }
    
    .standardisation-item-button {
        flex-shrink: 0;
        color: #ccc;
        font-size: 24px;
    }
    
    .standardisation-item:hover .standardisation-item-button {
        color: #287ABD;
    }
    
    .standardisation-item.is-active {
        background-color: #287ABD;
        color: #fff;
    }
    
    .standardisation-item.is-active .standardisation-item-button {
        color: #fff;
    }
    
    .standardisation-item.is-head {
        border-top: 0px;
        background-color: #287ABD;
        color: #fff;
        font-weight: 400;
        font-size: 16px;
        border-color: #287ABD;
        height: 40px;
    }
    
    .standardisation-item.is-search .fa {
        position: absolute;
        right: 12px;
        top: 50%;
        margin-top: -10px;
        font-size: 20px;
        color: #287ABD;
    }
    
    .standardisation-item.is-search input {
        font-size: 16px;
    }
    
    .standardisation-suggestions {
        position: absolute;
        left: -1px;
        top: 44px;
        background-color: #287ABD;
        border-radius: 0px 0px 4px 4px;
        right: -1px;
        overflow: hidden;
        z-index: 5;
    }
    
    .standardisation-suggestions-item {
        color: #fff;
        padding: 10px;
        font-size: 16px;
        text-align: left;
        width: 100%;
        border-bottom: 1px solid #fff;
    }
    
    .standardisation-suggestions-item:last-child {
        border: 0px;
    }
    
    .standardisation-suggestions-item:hover {
        background-color: rgba(0, 0, 0, 0.15);
    }
    
    </style>
    