<template>
	
<app-scroll class="content" :disabled="noscroll">

	<slot></slot>

</app-scroll>

</template>

<script>

export default {

	name: 'Content',

	props: ['noscroll']
	
}

</script>

<style scoped>

.content {
	flex-grow: 1;
	background-color: #fff;
	flex-basis: 0;
    overflow: hidden;
}

</style>