<template>

<div class="success">

	<i class="success-icon fa fa-times-circle is-failed" v-if="!progress.passed" />
	<i class="success-icon fa fa-check-circle" v-if="progress.passed" />

	<div class="success-text">{{ template.text.feedback }}</div>

</div>

</template>

<script>

export default {

	props: ['template', 'check', 'progress']

}

</script>

<style scoped>

.success {
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 100%;
	max-width: 480px;
	align-items: center;
	background-color: #fff;
	padding: 40px 20px;
	border: 1px solid #ddd;
	border-radius: 4px;
}

.success-icon {
	color: #6db350;
	font-size: 128px;
}

.success-icon.is-failed {
	color: #c62323;
}

.success-text {
	font-size: 16px;
	line-height: 20px;
	font-weight: 400;
	text-align: center;
	color: #333;
	margin-top: 40px;
}

</style>