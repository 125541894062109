<template>

<div class="availability" :class="{'is-lead': $isLeadExaminer}">

	<div class="availability-head">Upcoming Availability</div>

	<div class="availability-text" v-if="items.length">Please update your availability for the following diets.<br/>Click the coloured <span class="is-green">Yes</span> or <span class="is-red">No</span> toggles to update availability for each set of dates.<br/>A toggle will show as <span class="is-yellow">Allocated</span> if the college have assigned you to a date.</div>
	
	<div class="availability-text" v-if="!items.length">There are no upcoming diets to update. We will inform you by e-mail when the next of diets are ready.</div>

	<div class="availability-table" :class="{'is-saving': saving}" v-if="items.length">

		<div class="availability-row">
			<div class="availability-cell">Diet</div>
			<div class="availability-cell is-bordered" v-if="$isLeadExaminer">Roleplayer Calibration</div>
			<div class="availability-cell is-bordered">Exam Day</div>
			<div class="availability-cell is-bordered">Marking Standardisation</div>
			<div class="availability-cell">Marking Complete</div>
			<div class="availability-cell" v-if="!$isLeadExaminer">Marking Availability</div>
			<div class="availability-cell" v-if="$isLeadExaminer">Available</div>
		</div>

		<div class="availability-row" v-for="(item, index) in items" :key="index">

			<div class="availability-cell">{{ item.name }}</div>

			<div class="availability-cell is-bordered" v-if="$isLeadExaminer">{{ item.dates.calibration | formatDate('Do MMM') }}</div>

			<div class="availability-cell is-bordered">{{ item.dates.exam | formatDate('Do MMM') }}</div>

			<div class="availability-cell is-bordered">{{ item.dates.markingStart | formatDate('Do MMM') }}</div>

			<div class="availability-cell">{{ item.dates.markingEnd | formatDate('Do MMM') }}</div>

			<div v-if="item.status.marking === 1" v-on:click="onToggleClick(index, 'marking')" :class="{'is-disabled': item.locked}" class="availability-cell is-toggle" v-tooltip="'<b>You are set as available.</b><br/>' + ((item.locked) ? 'We are working to perform allocations. Availability cannot be changed whilst this is in progress.' : 'Click to toggle to unavailable')" data-theme="green">Yes</div>
			<div v-if="item.status.marking === 2" v-on:click="onToggleClick(index, 'marking')" :class="{'is-disabled': item.locked}" class="availability-cell is-toggle" v-tooltip="'<b>You are set as unavailable.</b><br/>' + ((item.locked) ? 'We are working to perform allocations. Availability cannot be changed whilst this is in progress.' : 'Click to toggle to available')" data-theme="red">No</div>
			<div v-if="item.status.marking === 3" class="availability-cell" v-tooltip="'<b>You have been allocated to this date.</b><br/>You can no longer toggle your availability for this date.'" data-theme="yellow">Allocated</div>
		</div>

	</div>

	<div class="availability-info-wrapper" v-if="items.length">
		<div v-if="!$isLeadExaminer" class="availability-info is-marking">You <b>must</b> be available for the marking standardisation date and be able to mark 60 cases before 6pm on the marking completion date.</div>
		<div v-if="$isLeadExaminer" class="availability-info is-marking">You <b>must</b> be available for all of the dates shown above and post marking to support QA.</div>
	</div>

	<div class="availability-buttons" v-if="items.length">

		<app-button v-if="changed || !saved" :loading="saving" :disabled="!changed" v-on:click="onSaveClick">Save</app-button>
		<app-button v-if="!changed && saved" theme="green">Saved</app-button>
		<app-button v-if="changed" :disabled="saving" theme="plain" v-on:click="$emit('close')">Cancel</app-button>
		<app-button v-if="(saved && !changed) || !changed" theme="plain" v-on:click="$emit('close')">Close</app-button>

	</div>

	<div class="availability-buttons" v-if="!items.length">

		<app-button v-on:click="$emit('close')">Close</app-button>

	</div>

</div>

</template>

<script>

export default {

	data: function() {

		return {
			saving: false,
			saved: false,
			changed: false,
			items: [],
			original: []
		}

	},

	created: function() {

		this.reset()

	},

	methods: {

		reset: function() {

			this.items = this.$util.copy(this.$store.getters['user/availability'])
			this.original = this.$util.copy(this.$store.getters['user/availability'])
			
			this.changed = !this.$_.isEqual(this.items, this.original)

		},

		onSaveClick: function() {

			this.saving = true

			this.$api.request('user/availability', {
				availability: this.items
			}).then(function(json) {

				this.$store.dispatch('user/availability/init', json.availability)

				this.reset()

				this.saving = false
				this.saved = true

			}.bind(this), function() {

				this.saving = false

			}.bind(this))

		},

		onToggleClick: function(index, type) {

			if (!this.items[index].locked) {

				this.items[index].status[type] = this.items[index].status[type] === 1 ? 2 : 1
				this.changed = !this.$_.isEqual(this.items, this.original)

			}

		}

	}

}

</script>

<style scoped>

.availability {
	background-color: #fff;
	width: 742px;
	display: block;
	padding: 20px;
	border: 1px solid #ddd;
	border-radius: 4px;
	align-items: center;
	box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
	user-select: none;
	max-height: calc(100vh - 200px);
	overflow: auto;
}

.availability-head {
	font-size: 24px;
	font-weight: 500;
	text-align: center;
	margin-bottom: 16px;
}

.availability-text {
	font-size: 14px;
	line-height: 28px;
	text-align: center;
	margin-bottom: 16px;
}

.availability-text span {
	background-color: red;
	padding: 2px 4px;
	border-radius: 4px;
	border: 1px solid #ddd;
	font-weight: 500;
}

.availability-text span.is-green {
	background-color: #dff0d8;
}

.availability-text span.is-red {
	background-color: #f2dede;
}

.availability-text span.is-yellow {
	background-color: #fcf8e3;
}

.availability-table.is-saving {
	pointer-events: none;
}

.availability-row {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	border-bottom: 1px solid #ddd;
	overflow: hidden;
}

.availability.is-lead .availability-row {
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.availability-row:first-child {
	background-color: #287ABD;
	border-radius: 4px 4px 0px 0px;
	color: #fff;
	font-weight: 500;
	border-bottom: 0px;
}

.availability-row .is-bordered {
	border-left: 2px solid #ddd;
}

.availability-row:first-child .is-bordered {
	border-left: 2px solid #fff;
}

.availability.is-lead .availability-row .is-bordered {
	border-left: 0px!important;
}

.availability-row:last-child {
	border-radius: 0px 0px 4px 4px;
}

.availability-row:hover {
	background-color: #efefef;
}

.availability-row:first-child:hover {
	background-color: #287ABD;
}

.availability-cell {
	font-size: 14px;
	line-height: 18px;
	padding: 10px 5px;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
}

.availability-cell.is-toggle {
	cursor: pointer;
}

.availability-cell.is-disabled {
	cursor: not-allowed;
	opacity: 0.5;
}

.availability-cell:first-child {
	border-left: 1px solid #ddd;
}

.availability-cell:last-child {
	border-right: 1px solid #ddd;
}

.availability-cell[data-theme="green"] {
	background-color: #dff0d8;
	font-weight: 500;
}

.availability-cell[data-theme="green"].is-toggle:hover {
	background-color: #d0e9c6;
}

.availability-cell[data-theme="red"] {
	background-color: #f2dede;
	font-weight: 500;
}

.availability-cell[data-theme="red"].is-toggle:hover {
	background-color: #ebcccc;
}

.availability-cell[data-theme="yellow"] {
	background-color: #fcf8e3;
	font-weight: 500;
}

.availability-cell[data-theme="yellow"].is-toggle:hover {
	background-color: #faf2cc;
}

.availability-info-wrapper {
	display: flex;
}

.availability-info {
	background-color: #fbb516;
	color: #333;
	padding: 10px;
	border-radius: 4px;
	width: 300px;
	text-align: center;
	font-size: 14px;
	line-height: 18px;
	margin-top: 14px;
}

.availability-info.is-calibration {
	margin-left: 0px;
}

.availability-info.is-marking {
	margin-left: 100px;
}

.availability.is-lead .availability-info.is-marking {
	margin-left: 400px;
}

.availability-info:after {
	display: block;
	content: '';
	position: absolute;
	right: 48px;
	top: -10px;
	width: 0px;
	height: 0px;
	border-style: solid;
	border-width: 0 10px 10px 10px;
	border-color: transparent transparent #fbb516 transparent;
	transform: rotate(0deg);
}

.availability-buttons {
	display: flex;
	justify-content: center;
	margin-top: 20px;
}

.availability-buttons >>> .button {
	width: 150px;
}

</style>