<template>

<div class="current" v-if="isStation && (hasAdjustment || hasDisabilities)">
	
	<div class="current-item current-item-head">

		<div class="current-item-name">Additional information</div>

	</div>

	<div class="current-item is-text" v-if="hasAdjustment">

		The candidate has a reasonable adjustment for {{ adjustmentText }}.

	</div>

	<div class="current-item is-text" v-if="hasDisabilities">

		The follow disabilities are XYZ

	</div>

	<div class="current-item is-text" v-if="hasDisabilityComment">

		The follow disabilities are XYZ

	</div>

</div>

</template>

<script>

export default {

	computed: {

		candidate: function() {

			return this.$store.getters['people'][this.isActive.candidate]
				
		},

		hasDisabilityComment: function() {

			return false

		},

		hasDisabilities: function() {

			return false

		},

		hasAdjustment: function() {

			return (this.isStation && !this.isCandidate) ? this.candidate.ra.brief || this.candidate.ra.roleplay : false

		},

		adjustmentText: function() {

			var text = [], minutes = 0

			if (this.candidate.ra.brief) {

				// if (this.candidate.ra.brief.type) {
        //
				// 	minutes = this.candidate.ra.brief.amount
        //
				// 	text.push(minutes)
				// 	text.push((minutes > 1) ? 'minutes' : 'minute')
        //
				// } else {
        //
				// 	text.push(this.candidate.ra.brief.amount + '%')
        //
				// }

				// text.push('extra reading time')

			}

			if (this.candidate.ra.roleplay) {

				// if (this.candidate.ra.brief) text.push('and')

				if (this.candidate.ra.roleplay.type) {

					minutes = this.candidate.ra.roleplay.amount

					text.push(minutes)
					text.push((minutes > 1) ? 'minutes' : 'minute')

				} else {

					text.push(this.candidate.ra.roleplay.amount)
					text.push('%')

				}

				text.push('extra consult time')

			}

			return text.join(' ')

		}

	}

}

</script>

<style scoped>

.current {
	user-select: none;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	border-radius: 4px;
	flex-shrink: 0;
	background-color: #fff;
	margin-top: 10px;
	box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
}

.current-item {
	padding: 10px;
	height: 56px;
	display: flex;
	color: #333;
	flex-direction: column;
	justify-content: center;
	border: 1px solid #ddd;
	border-width: 0px 1px 1px 1px;
	pointer-events: none;
}

@media (max-height: 799px) {

	.current-item-wrapper.is-webinar .current-item {
		height: auto;
		text-align: center;
	}

}

.current-item.is-text {
	padding: 10px;
	font-size: 14px;
	font-weight: 300;
	height: auto;
	line-height: 18px;
	color: #333;
}

.current-item:last-child {
	border-radius: 0px 0px 4px 4px;
}

.current-item-head {
	border-top: 0px;
	background-color: #287ABD;
	color: #fff;
	border-color: #287ABD;
	height: 40px;
}

.current-item-name {
	font-size: 16px;
	font-weight: 300;
}

@media (max-height: 799px) {

	.current-item-name {
		height: 32px;
		margin-bottom: 10px;
	}

}

.current-item-name small {
	font-size: 11px;
	line-height: 14px;
	display: flex;
	flex-wrap: wrap;
	margin-top: 10px;
}

.current-item-name span {
	margin-right: 4px;
}

.current-item-head .current-item-name {
	margin-bottom: 0px;
	font-weight: 400;
}

</style>
