<template>
    
<div v-shortkey="['esc']" v-on:shortkey="onExpandClick" ref="panel" tabindex="0" class="extend" v-if="is.expanded" :class="{'is-active': is.expanded}">

	<div class="extend-buttons">

		<button class="extend-buttons-item" v-if="is.expanded" v-on:click="onExpandClick" v-tooltip.left="'Close panel'"><i class="fa fa-remove"></i></button>

	</div>

	<div class="extend-content">

		<div class="extend-head">

			<div class="extend-head-title">Device check</div>

		</div>

		<app-scroll class="extend-body" v-if="profile">

			<div class="extend-metrics" v-for="(check, index) in profile.data.check.results" :key="index">
				<div class="extend-metrics-label">Time</div>
				<div class="extend-metrics-value">{{ check.time | fromNow }}</div>
				<div class="extend-metrics-label">Result</div>
				<div class="extend-metrics-value" v-if="check.result" data-value="1"><span>Passed</span></div>
				<div class="extend-metrics-value" v-if="!check.result" data-value="0"><span>Failed</span></div>

				<template v-if="!check.result">

					<div class="extend-metrics-label">Camera</div>
					<div class="extend-metrics-value">{{ statusText[check.video] }}</div>

					<div class="extend-metrics-label">Microphone</div>
					<div class="extend-metrics-value">{{ statusText[check.audio] }}</div>

					<div class="extend-metrics-label" v-if="$store.getters['event/geolocation/enabled']">Location</div>
					<div class="extend-metrics-value" v-if="$store.getters['event/geolocation/enabled']">{{ statusText[check.location] }}</div>

					<div class="extend-metrics-label">Connection</div>
					<div class="extend-metrics-value">{{ statusText[check.twilio] }}</div>

					<div class="extend-metrics-label">Quality</div>
					<div class="extend-metrics-value">{{ statusText[check.network] }}</div>

				</template>

			</div>

		</app-scroll>

	</div>

</div>

</template>

<script>

import Vue from 'vue'

export default {

	data: function() {

		return {
			is: {
				expanded: false
			},
			profile: false,
			statusText: {
				1: 'Checking',
				2: 'Not found',
				3: 'Asking permission',
				4: 'Permission denied',
				5: 'Checking',
				6: 'Unknown',
				7: 'OK',
				8: 'Requires camera',
				9: 'Requires microphone',
				10: 'Requires camera & microphone',
				11: 'Failed',
				12: 'OK',
				13: 'OK',
				14: 'Weak',
				15: 'Bad',
				16: 'Requires connection',
				17: 'Not completed',
				18: 'Requires geolocation'
			},
		}

	},

	created: function() {

		this.$pubsub.$on('connections.check.toggle', this.onExpandClick.bind(this))

	},

	beforeDestroy: function() {

		this.$pubsub.$off('connections.check.toggle', this.onExpandClick.bind(this))

	},

	methods: {

		onExpandClick: function(e) {

			if (e.data) Vue.set(this, 'profile', e.data)

			this.$nextTick(function() {
				
				this.is.expanded = !this.is.expanded

				this.$nextTick(function() {

					if (this.is.expanded) this.$refs.panel.focus()
					
				}.bind(this))

			}.bind(this))

		}

	}
	
}

</script>

<style scoped>

.extend {
	position: fixed;
	right: -256px;
	user-select: none;
	top: 0px;
	width: 256px;
	bottom: 54px;
	z-index: 2;
	transition: right 300ms ease-in-out;
    background-color: #2A7ABB;
}

.extend.is-active {
    right: 0px;
}

.extend-buttons {
	position: absolute;
	left: -45px;
	width: 45px;
	height: 45px;
	top: 0px;
	display: flex;
	align-items: flex-end;
	flex-direction: column;
}

.extend-buttons-item {
	width: 45px;
	height: 45px;
    background-color: #2A7ABB;
	line-height: 45px;
	color: #fff;
	font-size: 20px;
	text-align: center;
	cursor: pointer;
	transition: all 100ms linear;
}

.extend:not(.is-active) .extend-buttons-item {
    background-color: #287ABD;
}

.extend-buttons-item:not(.is-active):focus,
.extend-buttons-item:not(.is-active):hover {
    background-color: #56a0dc;
}

.extend:not(.is-active) .extend-buttons-item:not(.is-active):focus,
.extend:not(.is-active) .extend-buttons-item:not(.is-active):hover {
    background-color: #2A7ABB;
}

.extend-buttons-item.is-saved {
    background-color: #38b56a;
}

.extend-content {
	width: 100%;
	height: 100%;
}

.extend-head {
    color: #fff;
    font-size: 24px;
    font-weight: 300;
    flex-shrink: 0;
	padding: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row;
    align-items: center;
}

.extend-head-title {
    flex-grow: 1;
}

.extend-body {
    flex-grow: 1;
    flex-basis: 0;
	height: calc(100% - 44px);
}

.extend-body >>> p {
	color: #fff;
	font-size: 14px;
	line-height: 18px;
}

.extend-widget {
	display: flex;
	margin-top: 15px;
}

.extend-widget-button {
	width: 40px;
	height: 40px;
	border-radius: 4px;
	line-height: 40px;
	text-align: center;
	font-size: 20px;
	cursor: pointer;
	color: #fff;
	flex-shrink: 0;
	background-color: rgba(255, 255, 255, 0.25);
}

.extend-widget-button.is-disabled {
	pointer-events: none;
	opacity: 0.25;
}

.extend-widget-button:hover {
	background-color: rgba(255, 255, 255, 0.5);
}

.extend-widget-input {
	width: 156px;
	padding: 0px 10px;
}

.extend-widget-input input {
	width: 136px;
	text-align: center;
	line-height: 40px;
	font-size: 32px;
	color: #fff;
}

.extend-widget-input input::placeholder {
	color: rgba(255, 255, 255, 0.5);
}

.extend-widget-confirm {
	width: 100%;
	margin-top: 15px;
}

.extend-metrics {
	display: grid;
	grid-gap: 5px;
	padding: 10px;
	grid-template-columns: 90px 1fr;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.extend-metrics-label,
.extend-metrics-value {
	color: #fff;
	line-height: 20px;
	font-size: 14px;
}

.extend-metrics-label {
	font-weight: 500;
}

.extend-metrics-value span {
	display: inline;
	padding: 2px 4px;
	border-radius: 4px;
}

.extend-metrics-value[data-value="0"] span {
	background-color: #d34545;
}

.extend-metrics-value[data-value="1"] span {
	background-color: #21ca21;
}

</style>