import {createLocalTracks, LocalVideoTrack} from 'twilio-video'
import _ from 'underscore'
import pubsub from '@/service/pubsub'

var isIOS = require('is-ios')

export default {

	namespaced: true,

	state: {

		ios: isIOS,
		initiated: false,
		video: false,
		audio: false,
		screen: false,
		share: false,
		inputs: {
			video: [],
			audio: []
		},
		current: {
			audio: false,
			video: false
		},
		fullscreen: false

	},

	getters: {

		initiated: function(state) {

			return state.initiated

		},

		screen: function(state) {

			return state.screen

		},

		tracks: function(state) {

			return [
				state.video,
				state.audio
			]

		},

		ios: function(state) {

			return state.ios;

		},

		video: function(state) {

			return state.video

		},

		'video/inputs': function(state) {

			return state.inputs.video

		},

		'video/current': function(state) {

			return state.current.video

		},

		audio: function(state) {

			return state.audio

		},

		'audio/inputs': function(state) {

			return state.inputs.audio

		},

		'audio/current': function(state) {

			return state.current.audio

		},

		fullscreen: function(state) {

			return state.fullscreen

		}

	},

	mutations: {

		initiated: function(state, value) {

			state.initiated = value

		},

		screen: function(state, value) {

			if (state.screen) state.share.getTracks()[0].stop()

			state.screen = value

		},

		video: function(state, value) {

			state.video = value

		},

		'video/current': function(state, value) {

			state.current.video = value

		},

		audio: function(state, value) {

			state.audio = value

		},

		'audio/current': function(state, value) {

			state.current.audio = value

		},

		fullscreen: function(state, value) {

			state.fullscreen = value

		}

	},

	actions: {

		fullscreen: function() {

			if (document.fullscreenElement) {

				document.exitFullscreen();

			} else {

				document.documentElement.requestFullscreen();

			}
		
		},

		screenshare: function(context) {

			return new Promise(function(resolve, reject) {

				if (context.getters['screen']) {

					context.commit('screen', false)

					resolve()

				} else {

					navigator.mediaDevices.getDisplayMedia().then(function(stream) {

						context.state.share = stream

						context.commit('screen', new LocalVideoTrack(stream.getTracks()[0], {
							name: 'screenshare'
						}))

						stream.getTracks()[0].onended = function() {

							context.commit('screen', false)

						}

						resolve()

					}).catch(function() {

						reject()

					})
					
				}

			})

		},

		audio: function(context) {

			return new Promise(function(resolve, reject) {

				createLocalTracks({
					video: false,
					audio: {
						deviceId: context.getters['audio/current']
					}
				}).then(function(tracks) {

					tracks.forEach(function(track) {

						if (track.kind === 'audio') {

							context.commit('audio', track)

							pubsub.$emit('localtrackchange')

						}

					})

					resolve()

				}, function(e) {

					reject(e)

				})

			})

		},

		video: function(context) {

			return new Promise(function(resolve, reject) {

				createLocalTracks({
					video: {
						deviceId: context.getters['video/current']
					},
					audio: false
				}).then(function(tracks) {

					tracks.forEach(function(track) {

						if (track.kind === 'video') {

							context.commit('video', track)

							pubsub.$emit('localtrackchange')

						}

					})

					resolve()

				}, function(e) {

					reject(e)

				})

			})

		},

		detect: function(context) {

			return new Promise(function(resolve) {

				context.state.inputs.audio = []
				context.state.inputs.video = []

				navigator.mediaDevices.enumerateDevices().then(function(devices) {
						
					_.each(devices, function(device) {

						if (device.kind === 'audioinput') {

							if (device.deviceId !== 'default') {

								context.state.inputs.audio.push(device)

								if (!context.getters['audio/current'] && device.label !== '') context.commit('audio/current', device.deviceId)

							}

						} else if (device.kind === 'videoinput') {

							context.state.inputs.video.push(device)

							if (!context.getters['video/current'] && device.label !== '') context.commit('video/current', device.deviceId)

						}

					})

					resolve()

				})

			})

		},

		init: function(context) {

			return new Promise(function(resolve) {

				document.addEventListener("fullscreenchange", function() {

					if (document.fullscreenElement) {

						context.commit('fullscreen', true)

					} else {

						context.commit('fullscreen', false)

					}

				})

				if (document.fullscreenElement) {

					context.commit('fullscreen', true)

				} else {

					context.commit('fullscreen', false)

				}

				navigator.mediaDevices.addEventListener('ondevicechange', function() {

					context.dispatch('detect')

				})

				context.dispatch('detect').then(function() {

					resolve()

				})

			})
			
		}

	}

}