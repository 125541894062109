<template>

<div class="onboarding" v-on:click="onClick" v-on:keypress.tab="onClick" v-if="currentLine">

	<div class="onboarding-cover">

		<div class="onboarding-cover-tab" v-on:click="onExitClick">Walkthrough<small>Click here to exit</small></div>

	</div>

	<com-message v-if="currentLine.type === 'message'" :line="currentLine" />

	<com-highlight v-show="currentLine.type === 'highlight' && state.highlight && !state.delaying" :key="state.scene + '/' + state.line" :line="currentLine" :element="state.highlight" />

	<div class="onboarding-highlight" :class="{'is-message': currentLine.type === 'message'}" v-if="isHighlighting && !state.delaying">
		<div :style="{top: highlight.top + 'px', bottom: highlight.bottom + 'px', width: highlight.left + 'px'}"></div>
		<div :style="{height: highlight.top + 'px'}"></div>
		<div :style="{top: highlight.top + 'px', bottom: highlight.bottom + 'px', width: highlight.right + 'px'}"></div>
		<div :style="{height: highlight.bottom + 'px'}"></div>
	</div>

	<com-scene :key="state.scene" :scene="currentScene" :initTime="state.time" />

</div>

</template>

<script>

import comScene from './onboarding/Scene'
import comMessage from './onboarding/Message'
import comHighlight from './onboarding/Highlight'

import deepmerge from 'deepmerge'
import Vue from 'vue'

export default {

	props: ['script'],

	components: {
		'com-scene': comScene,
		'com-message': comMessage,
		'com-highlight': comHighlight
	},

	data: function() {

		return {
			highlight: {
				left: 0,
				top: 0,
				right: 0,
				bottom: 0
			},
			state: {
				scene: 0,
				line: -1,
				highlight: false,
				time: false,
				startTime: false,
				delaying: false,
				paused: false
			}
		}

	},

	computed: {

		isHighlighting: function() {

			return this.$_.contains(['highlight', 'message'], this.currentLine.type)

		},

		scenario: function() {

			return this.state.script.scenario

		},

		scenes: function() {

			return this.state.script.scenes

		},

		currentScene: function() {

			return this.scenes[this.state.scene]

		},

		lines: function() {

			return this.currentScene.lines

		},

		currentLine: function() {

			return (this.state.line >= 0) ? this.lines[this.state.line] : false

		}

	},

	watch: {

		window: {
			deep: true,
			handler: function() {

				this.calculateHighlight()

			}
		}

	},

	created: function() {

		Vue.set(this.state, 'script', deepmerge({}, this.script))
		this.state.time = this.$store.getters['time']
		this.state.startTime = this.$store.getters['time']

		this.nextLine()

	},

	methods: {

		onExitClick: function() {

			this.$store.commit('onboarding/active', false)

		},

		onClick: function() {

			if (!this.state.paused) this.nextLine()

		},

		nextLine: function() {

			this.state.paused = false
			this.state.highlight = false

			if (this.state.line >= this.lines.length - 1) {

				if (this.state.scene >= this.scenes.length - 1) {

					this.$store.commit('onboarding/active', false)
					this.$store.commit('onboarding/taken', true)

					return

				} else {

					this.state.line = 0

					this.state.time = this.$store.getters['time']
					this.state.startTime = this.$store.getters['time']

					this.state.scene++

				}

			} else {

				this.state.line++

			}

			if (this.currentLine.type === 'highlight') {

				if (this.currentLine.delay) {

					this.state.delaying = true

					this.$_.delay(this.calculateHighlight.bind(this), this.currentLine.delay * 1000)

				} else {

					this.$nextTick(this.calculateHighlight.bind(this))

				}

			} else if (this.currentLine.type === 'clock') {

				this.state.time = this.state.startTime - (this.currentLine.value - (this.$store.getters['time'] - this.state.startTime))

				this.$nextTick(this.nextLine.bind(this))

			} else if (this.currentLine.type === 'pause') {

				this.state.paused = true

				this.$_.delay(this.nextLine.bind(this), this.currentLine.duration * 1000)

			} else if (this.currentLine.type === 'modal') {

				this.state.script.scenes[this.state.scene].modal = this.currentLine.value

				this.$nextTick(this.nextLine.bind(this))

			} else if (this.currentLine.type === 'setup') {

				Vue.set(this.state.script.scenes[this.state.scene], 'setup', deepmerge(this.currentScene.setup, this.currentLine.value))

				this.$nextTick(this.nextLine.bind(this))

			} else if (this.currentLine.type === 'setup.pinned') {

				Vue.set(this.state.script.scenes[this.state.scene].setup, 'pinned', this.currentLine.value)

				this.$nextTick(this.nextLine.bind(this))

			} else if (this.currentLine.type === 'setup.participants') {

				if (this.currentLine.add) {

					Vue.set(this.state.script.scenes[this.state.scene].setup, 'participants', deepmerge(this.currentScene.setup.participants, this.currentLine.add))

				}
				
				if (this.currentLine.update) {

					Vue.set(this.state.script.scenes[this.state.scene].setup, 'participants', deepmerge(this.currentScene.setup.participants, this.currentLine.update))

				}
				
				if (this.currentLine.remove) {

					Vue.delete(this.state.script.scenes[this.state.scene].setup.participants, this.currentLine.remove[0])

				}

				this.$nextTick(this.nextLine.bind(this))

			} else if (this.currentLine.type === 'setup.cards') {

				if (this.currentLine.add) {

					Vue.set(this.state.script.scenes[this.state.scene].setup, 'cards', deepmerge(this.currentScene.setup.cards, this.currentLine.add))

				}
				
				if (this.currentLine.update) {

					Vue.set(this.state.script.scenes[this.state.scene].setup, 'cards', deepmerge(this.currentScene.setup.cards, this.currentLine.update))

				}
				
				if (this.currentLine.remove) {

					Vue.delete(this.state.script.scenes[this.state.scene].setup.cards, this.currentLine.remove[0])

				}

				this.$nextTick(this.nextLine.bind(this))

			} else if (this.currentLine.type === 'sidebar') {

				Vue.set(this.state.script.scenes[this.state.scene], 'sidebar', deepmerge(this.currentScene.sidebar, this.currentLine.value))

				this.$nextTick(this.nextLine.bind(this))

			} else if (this.currentLine.type === 'panel') {

				Vue.set(this.state.script.scenes[this.state.scene], 'panel', deepmerge(this.currentScene.panel, this.currentLine.value))

				this.$nextTick(this.nextLine.bind(this))

			} else if (this.currentLine.type === 'sidebar.chat') {

				this.state.script.scenes[this.state.scene].sidebar.chat.messages.push({
					id: this.currentLine.id,
					name: this.currentLine.name,
					content: this.currentLine.content,
					time: this.$store.getters['time'] - this.state.time
				})

				this.$nextTick(this.nextLine.bind(this))

			} else if (this.currentLine.type === 'sidebar.standardisation') {

				Vue.set(this.state.script.scenes[this.state.scene].sidebar, 'standardisation', this.currentLine.value)

				this.$nextTick(this.nextLine.bind(this))

			}

		},

		calculateHighlight: function() {

			if (this.currentLine.type === 'highlight') {

				this.state.highlight = document.querySelector('[onboarding="' + this.currentLine.focus + '"]')
				this.state.delaying = false

				if (this.state.highlight) {

					this.highlight.top = this.state.highlight.getBoundingClientRect().top
					this.highlight.bottom = (this.state.highlight.getBoundingClientRect().bottom < this.window.height) ? this.window.height - this.state.highlight.getBoundingClientRect().bottom : 0
					this.highlight.left = this.state.highlight.getBoundingClientRect().left
					this.highlight.right = (this.state.highlight.getBoundingClientRect().right < this.window.width) ? this.window.width - this.state.highlight.getBoundingClientRect().right : 0

				}

			}

		}

	}

}

</script>

<style scoped>

.onboarding {
	position: fixed;
	z-index: 100000;
	left: 0px;
	top: 0px;
	background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
	right: 0px;
	bottom: 0px;
	user-select: none;
}

.onboarding-cover {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 3;
	border: 5px solid #287ABD;
}

.onboarding-cover-tab {
	background-color: #287ABD;
	color: #fff;
	font-size: 28px;
	font-weight: 400;
	left: 5px;
	top: 5px;
	width: 235px;
	height: 80px;
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	text-align: center;
	letter-spacing: 1px;
	cursor: pointer;
}

.onboarding-cover-tab small {
	font-size: 14px;
	display: block;
	font-weight: 300;
	margin-top: 4px;
}

.onboarding-highlight {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 2;
}

.onboarding-highlight.is-message {
	background-color: rgba(0, 0, 0, 0.75);
}

.onboarding-highlight.is-message div {
	display: none;
}

.onboarding-highlight div {
	position: absolute;
	background-color: rgba(0, 0, 0, 0.75);
}

.onboarding-highlight div:nth-child(1) {
	left: 0px;
}

.onboarding-highlight div:nth-child(2) {
	left: 0px;
	right: 0px;
	top: 0px;
}

.onboarding-highlight div:nth-child(3) {
	right: 0px;
}

.onboarding-highlight div:nth-child(4) {
	left: 0px;
	right: 0px;
	bottom: 0px;
}

</style>