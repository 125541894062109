<template>

<div class="online" v-if="isActive || (isCandidate && $store.getters['event/chaperones/enabled'])">

	<template v-if="isActive && !$isInterval && (!isStation || (time > isActive.roleplay || !isCandidate))">

		There {{ auxiliary }} 
		<template v-if="examinerCount">{{ examinerCount }} <template v-if="examinerCount === 1">{{ $language.role.examiner }}</template><template v-if="examinerCount > 1">{{ $language.role.examiners }}</template></template>
		<template v-if="examinerCount && observerCount"> and </template>
		<template v-if="observerCount">{{ observerCount }} {{ observerNoun }} watching</template>
		<template v-if="!count">no {{ $language.role.observers }} watching</template>.

	</template>
	
	<template v-if="isCandidate && (!isActive || $isInterval || (isStation && (time >= isActive.start && time <= isActive.roleplay)))">

		You are being observed by a chaperone.

	</template>
	
</div>

</template>

<script>

export default {

	props: ['chaperone'],

	computed: {

		count: function() {

			return this.examinerCount + this.observerCount

		},

		observerCount: function() {

			var count = 0

			this.$_.each(this.isActive.observers, function(identity) {

				if (this.$_.contains(this.$store.getters['room/streaming'], identity) && this.$store.getters['people'][identity].role === this.$constants.role.observer) count++

			}.bind(this))

			return count

		},

		examinerCount: function() {

			var count = 0

			this.$_.each(this.isActive.examiner, function(identity) {

				if (this.$_.contains(this.$store.getters['room/streaming'], identity)) count++

			}.bind(this))

			this.$_.each(this.isActive.layexaminer, function(identity) {

				if (this.$_.contains(this.$store.getters['room/streaming'], identity)) count++

			}.bind(this))

			return count

		},

		observerNoun: function() {

			return (this.observerCount === 1) ? this.$language.role.observer : this.$language.role.observers

		},

		auxiliary: function() {

			return (this.count === 1) ? 'is' : 'are'

		}

	}

}

</script>

<style scoped>

.online {
	font-size: 12px;
	line-height: 14px;
	font-weight: 300;
	user-select: none;
	color: #333;
}

</style>
