<template>

<app-actions-icons class="icons">

	<template v-if="isAccepted">

	<app-actions-icon :shortcut="['ctrl', 'm']" icon="comments" name="Direct messages" v-on:click="onChat" :counter="unreadCount" />

	<app-actions-icon :shortcut="['ctrl', 'a']" icon="bullhorn" name="Announcements" v-on:click="onAnnouncement" v-if="hasAnnouncements" />

	<app-actions-icon :shortcut="['ctrl', 'i']" icon="exclamation-triangle" name="Report incident" v-on:click="onIncident" />

	<div class="divide"></div>

	<app-actions-icon :shortcut="['ctrl', 't']" icon="life-ring" name="Download Tech Support Software" v-on:click="onSupport" />

	<app-actions-icon :shortcut="['ctrl', 's']" icon="user-cog" name="Camera & microphone preview" :disabled="(isStation && !isStationPreparing) || isMeeting" v-on:click="onSetup" />

	<app-actions-icon :shortcut="['ctrl', 'w']" icon="graduation-cap" name="Walkthrough" v-if="canOnboard" :disabled="isCandidate && (isStation || isMeeting || isBreak)" v-on:click="onOnboardingClick" />

	<app-actions-icon :shortcut="['ctrl', 'f']" icon="expand" name="Go fullscreen" v-if="!isFullscreen" v-on:click="onFullscreen" />

	<app-actions-icon :shortcut="['ctrl', 'f']" icon="compress" name="Stop fullscreen" v-if="isFullscreen" v-on:click="onFullscreen" />

	<app-actions-icon :shortcut="['ctrl', 'r']" icon="sync-alt" name="Refresh" v-on:click="onRefresh" />

	<div class="divide"></div>

	</template>

	<app-actions-icon :shortcut="['ctrl', 'l']" icon="sign-out-alt" name="Log out" v-on:click="onLogout"  />

	<template v-if="hasTools && isAccepted">

	<div class="divide"></div>

	<app-actions-icon :shortcut="['ctrl', 'o']" icon="tools" name="Show observer tools" v-on:click="is.expanded = true" v-if="!is.expanded" />

	<app-actions-icon :shortcut="['ctrl', 'o']" icon="times" :stack="true" name="Hide observer tools" v-on:click="is.expanded = false" v-if="is.expanded" class="tools-hide" />

	<template v-if="is.expanded">

		<com-actions-observer />

	</template>

	</template>

</app-actions-icons>

</template>

<script>

import comActionsObserver from '../actions/Observer'
import util from '../../../util'

export default {

	components: {
		'com-actions-observer': comActionsObserver
	},

	data: function() {

		return {
			is: {
				expanded: false
			}
		}

	},

	computed: {
		
		canOnboard: function() {

			return this.$store.getters['session/onboarding/enabled']

		},

		hasAnnouncements: function() {

			return this.$store.getters['announcements'].length

		},

		hasTools: function() {

			return this.hasPermission(this.$constants.observer.permission.adhoc) 
				|| this.hasPermission(this.$constants.observer.permission.pause)
				|| this.hasPermission(this.$constants.observer.permission.announce)
				|| this.hasPermission(this.$constants.observer.permission.logout)
				|| this.hasPermission(this.$constants.observer.permission.refresh)
				|| this.hasPermission(this.$constants.observer.permission.reassign)

		},

		isStationPreparing: function() {

			return this.time < this.$store.getters['schedule/active'].roleplay

		},

		isFullscreen: function() {

			return this.$store.getters['device/fullscreen']

		},

		unreadCount: function() {

			var count = 0 

			this.$_.each(this.$store.getters['chats'], function(chat) {

				count += this.$_.filter(this.$store.getters['messages'], function(message) {

					return message.time > chat.read && message.user !== this.$store.getters['session/identity'] && message.chat === chat.identity

				}.bind(this)).length

			}.bind(this)) 

			return count 

		}

	},

	methods: {

		onSupport: function() {

			window.open('https://sos.splashtop.com')

		},

		onSetup: function() {

			this.$pubsub.$emit('content.setup')

		},

		onFullscreen: function() {

			this.$store.dispatch('device/fullscreen')

		},

		onRefresh: function() {

			this.$monitor.log('user/refresh')

			//	window.location.href = window.location.pathname + '?quick'
			window.location.reload()

		},

		onLogout: function() {
      // Delete all the cookies for pusher failover if exists
      util.deletePusherFailoverCookies()
      util.deleteExtraPusherFailoverCookies()

			this.$pubsub.$emit('modal.logout')

		},

		onIncident: function() {

			this.$pubsub.$emit('modal.incident')

		},

		onChat: function() {

			this.$pubsub.$emit('panel.chats')

		},

		onAnnouncement: function() {

			this.$pubsub.$emit('panel.announcements')

		},

		onOnboardingClick: function() {

			this.$store.commit('onboarding/active', true)

		}

	}

}

</script>

<style scoped>

.divide {
	width: 1px;
	background-color: rgba(255, 255, 255, 0.25);
	height: 54px;
	margin-right: 14px;
	margin-left: 10px;
	margin-top: -5px;
}

.tools-hide {
	background-color: transparent!important;
    border-color: transparent!important;
    color: #fff!important;
}

.icons {
	position: relative;
}

</style>