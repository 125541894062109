<template>

<div class="standardisation">
	
	<button v-on:click="onConsultClick(consult)" v-for="consult in consults" :key="consult.identity" :class="{'is-disabled': !$isLead || is.loading, 'is-active': consult.identity === current}" class="standardisation-item" v-tooltip.right="tooltip(consult.identity)">
	
		<div class="standardisation-item-name">#{{ consult.name }}</div>
		<div class="standardisation-item-button" v-if="$isLead && is.loading !== consult.identity && consult.identity !== current"><i class="fa fa-play-circle"></i></div>
		<div class="standardisation-item-button" v-if="$isLead && is.loading !== consult.identity && consult.identity === current"><i class="fa fa-stop-circle"></i></div>
		<div class="standardisation-item-button" v-if="$isLead && is.loading === consult.identity"><i class="fa fa-spin fa-spinner"></i></div>
	
	</button>

	<div class="standardisation-item is-search" v-if="$isLead">
	
		<input type="text" placeholder="Search consult..." :disabled="is.adding" v-model="search" />

		<i class="fa fa-spin fa-spinner" v-if="is.searching || is.adding" />

		<div class="standardisation-suggestions" v-if="suggestions.length">

			<button v-on:click="onSuggestionClick(suggestion)" class="standardisation-suggestions-item" v-for="suggestion in suggestions" :key="suggestion.identity">#{{ suggestion.name }}</button>

		</div>
	
	</div>

</div>

</template>

<script>

export default {

	data: function() {

		return {
			is: {
				adding: false,
				loading: false,
				searching: false
			},
			inventory: false,
			search: '',
			suggestions: []
		}

	},

	computed: {

		current: function() {

			return this.isActive.meta.marksheet

		},

		consults: function() {

			return this.isActive.consults 

		}

	},

	watch: {

		search: function(n) {

			if (n.length > 2 && !this.is.adding) {
				
				this.onSearch()

			} else {

				this.suggestions = []
				this.is.searching = false

			}

		}

	},

	methods: {

		onSearch: function() {

			this.is.searching = true

			this.$api.request('standardisation/search', {
				room: this.isActive.identity,
				search: this.search
			}).then(function(json) {

				this.suggestions = json.results

				this.is.searching = false

			}.bind(this))

		},

		tooltip: function(consult) {

			if (this.$isLead) {

				if(consult === this.current) {

					return 'Click to close consult' 

				} else {

					return (this.current) ? 'Click to switch consult' : 'Click to open consult' 

				}

			} else {

				return false

			}

		},

		onSuggestionClick: function(consult) {

			this.is.adding = true

			this.suggestions = []

			this.search = consult.name

			this.$api.request('standardisation/add', {
				room: this.isActive.identity,
				consult: consult.identity
			}).then(function() {

				this.is.adding = false
				this.is.searching = false

				this.search = ''

				this.onConsultClick(consult)

			}.bind(this))

		},

		onConsultClick: async function(consult) {

			if (this.$isLead) {

				this.is.loading = consult.identity

				var same = this.current === consult.identity

				if (this.current) {

					await this.$api.request('standardisation/end', {
						room: this.isActive.identity,
						inventory: this.inventory
					})

				}

				if (!same) {

					this.$api.request('standardisation/start', {
						room: this.isActive.identity,
						consult: consult.identity
					}).then(function(json) {

						this.inventory = json.identity

						this.is.loading = false

					}.bind(this))


				} else {

					this.is.loading = false

				}

			}

		}

	}

}

</script>

<style scoped>

.standardisation {
	user-select: none;
	display: flex;
	flex-direction: column;
	border-radius: 4px;
	flex-shrink: 0;
	background-color: #fff;
	margin-top: 10px;
	box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
}

.standardisation-item {
	padding: 0px 10px;
	min-height: 48px;
	display: flex;
	color: #333;
	font-weight: 300;
	flex-direction: row;
	text-align: left;
	border: 1px solid #ddd;
	align-items: center;
	border-width: 0px 1px 1px 1px;
	display: flex;
}

.standardisation-item.is-disabled {
	pointer-events: none;
}

.standardisation-item:first-child {
	border-top-width: 1px;
	border-radius: 4px 4px 0px 0px;
}

.standardisation-item:last-child {
	border-radius: 0px 0px 4px 4px;
}

.standardisation-item:hover {
	background-color: #eee;
}

.standardisation-item-name {
	flex-grow: 1;
	font-size: 16px;
}

.standardisation-item-button {
	flex-shrink: 0;
	color: #ccc;
	font-size: 24px;
}

.standardisation-item:hover .standardisation-item-button {
	color: #287ABD;
}

.standardisation-item.is-active {
	background-color: #287ABD;
	color: #fff;
}

.standardisation-item.is-active .standardisation-item-button {
	color: #fff;
}

.standardisation-item.is-head {
	border-top: 0px;
	background-color: #287ABD;
	color: #fff;
	font-weight: 400;
	font-size: 16px;
	border-color: #287ABD;
	height: 40px;
}

.standardisation-item.is-search .fa {
	position: absolute;
	right: 12px;
	top: 50%;
	margin-top: -10px;
	font-size: 20px;
	color: #287ABD;
}

.standardisation-item.is-search input {
	font-size: 16px;
}

.standardisation-suggestions {
	position: absolute;
	left: -1px;
	top: 44px;
	background-color: #287ABD;
	border-radius: 0px 0px 4px 4px;
	right: -1px;
	overflow: hidden;
	z-index: 5;
}

.standardisation-suggestions-item {
	color: #fff;
	padding: 10px;
	font-size: 16px;
	text-align: left;
	width: 100%;
	border-bottom: 1px solid #fff;
}

.standardisation-suggestions-item:last-child {
	border: 0px;
}

.standardisation-suggestions-item:hover {
	background-color: rgba(0, 0, 0, 0.15);
}

</style>
