<template>

<div ref="wrapper" tabindex="0" class="welcome">

	<h1>Hello, {{ name }}!</h1>

	<div class="welcome-intro" v-if="intro" v-html="intro"></div>
	
	<div class="welcome-about">

		<div class="welcome-about-item">
			<b>Event</b>{{ event }}
		</div>

		<div class="welcome-about-item">
			<b>Role</b>{{ role }}
		</div>

		<div class="welcome-about-item">
			<b>Date</b>{{ time | formatDate('DD/MM/YYYY') }}
		</div>

		<div class="welcome-about-item">
			<b>Start time</b>{{ time | formatDate('h:mma') }}
		</div>

	</div>

	<div class="welcome-preload" v-if="is.preloading">

		<div class="welcome-preload-text">Please wait, pre-loading documents...</div>

		<div class="welcome-preload-progress" :style="{width: progress}"></div>

	</div>
	
	<div class="welcome-consent" v-if="mustConsent && !is.preloading" :class="{'is-active': is.consented}">

		<div class="welcome-consent-text">{{ mustConsent.text }}<small v-if="mustConsent.given && is.consented">Consented at {{ mustConsent.given | formatDate('HH:mm on Do MMMM YYYY') }}</small></div>
		<button class="welcome-consent-check" v-tooltip="'Click to consent'" v-on:click="is.consented = !is.consented"><i class="fa fa-check"></i></button>

	</div>

	<div class="welcome-buttons" v-if="!is.preloading">
		
		<app-button :disabled="mustConsent && !is.consented" v-on:click="onProceedClick">Proceed</app-button>

	</div>

</div>

</template>

<script>

export default {

	data: function() {

		return {
			is: {
				preloading: false,
				consented: false
			},
			preloadTotal: 0,
			preload: []
		}

	},

	created: function() {

		this.is.consented = (this.mustConsent) ? this.mustConsent.given : false

		//if (!this.isCandidate && !this.isRoamingChaperone) {
		/*if (this.isRoamingObserver) {

			var stations = this.$_.where(this.$store.getters[(this.isRoamingObserver) ? 'overview' : 'schedule'], function(item) {

				return item.type === this.$constants.schedule.type.station

			}.bind(this))

			this.$_.each(stations, function(schedule) {

				if (!this.$_.findWhere(this.preload, {
					identity: schedule.station
				}) && schedule.brief) {

					this.$_.each(schedule.brief, function(name) {

						this.$store.dispatch('shredded/cache', {
							type: 'station',
							brief: name,
							identity: schedule.station
						})
						
					}.bind(this))

				}

			}.bind(this))
			
			if (this.preload.length) {

				this.is.preloading = true

				this.preloadTotal = this.preload.length

				this.preloadDocument()

			}

		}*/

		this.$nextTick(function() {

			this.$refs.wrapper.focus()

		}.bind(this))

	},

	computed: {
		
		mustConsent: function() {

			return this.$store.getters['session/consent']

		},

		progress: function() {

			return ((100 / this.preloadTotal) * (this.preloadTotal - this.preload.length)).toString() + '%'

		},

		name: function() {

			var parts = this.$store.getters['people/self'].name.split(' ')

			return parts[0]

		},

		event: function() {

			return this.$store.getters['event'].name

		},

		date: function() {

			return this.$store.getters['event'].date

		},

		time: function() {

			if (this.isRoamingObserver) {

				return this.$store.getters['overview/start']

			} else if (this.isRoamingChaperone) {

				return this.$store.getters['structure'][0].start

			} else {

				return (this.$store.getters['schedule'].length) ? this.$store.getters['schedule/start'] : this.$store.getters['event/date']
				
			}

		},

		role: function() {

			return this.$store.getters['language/role'](this.$store.getters['people/self'].role)

		},

		intro: function() {

			return this.$store.getters['event'].intro

		}

	},

	methods: {

		preloadDocument: async function() {

			var preloadData = this.preload.shift()

			await this.$store.dispatch('shredded/cache', preloadData)

			if (this.preload.length) {

				this.preloadDocument()

			} else {

				this.is.preloading = false

			}

		},

		onProceedClick: function() {

			this.$store.commit('session/accepted', true)

			this.$emit('proceed')
			
		}

	}

}

</script>

<style scoped>

.welcome {
	width: 640px;
	user-select: none;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	background-color: #fff;
	border: 1px solid #ddd;
	border-radius: 4px;
	box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
}

.welcome h1 {
	margin-bottom: 20px;
	font-size: 24px;
	text-align: center;
	padding: 20px 20px 0px 20px;
	font-weight: 300;
}

.welcome-about {
	display: flex;
	flex-direction: row;
	width: 100%;
	margin-bottom: 20px;
	padding: 0px 20px;
	color: #333;
}

.welcome-about-item {
	width: 33.3%;
	font-weight: 300;
	font-size: 16px;
}

.welcome-about-item b {
	font-size: 12px;
	margin-bottom: 4px;
	display: block;
	font-weight: 400;
	color: #333;
}

.welcome-intro {
	margin-bottom: 20px;
	text-align: center;
}

.welcome-intro >>> p {
	font-size: 16px;
	line-height: 20px;
}

.welcome-intro >>> p b {
	font-weight: 500;
}

.welcome-intro >>> p i {
	font-style: italic;
}

.welcome-buttons {
	width: 100%;
	display: flex;
	padding: 20px;
	flex-direction: row;
	justify-content: center;
}

.welcome-preload {
	padding: 0px 20px 20px 20px;
	height: 32px;
	border-radius: 4px;
	background-color: #eee;
}

.welcome-preload-progress {
	width: 0%;
	border-radius: 4px;
	transition: 100ms linear;
	height: 32px;
	background-image: linear-gradient(45deg, #287ABD 25%, #4b9adb 25%, #4b9adb 50%, #287ABD 50%, #287ABD 75%, #4b9adb 75%, #4b9adb 100%);
	background-size: 56.57px 56.57px;
}

.welcome-preload-text {
	font-size: 14px;
	position: absolute;
	top: 40px;
	text-align: center;
}

.welcome-consent {
	background-color: #FBB516;
	padding: 10px 20px;
	display: flex;
	color: #343434;
	align-items: center;
}

.welcome-consent.is-active {
	background-color: #21874A;
	color: #fff;
}

.welcome-consent:last-child {
	margin-bottom: 0px;
}

.welcome-consent-text {
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	flex-grow: 1;
}

.welcome-consent-text small {
	font-size: 12px;
	display: block;
	opacity: 0.8;
}

.welcome-consent-check {
	flex-shrink: 0;
	width: 40px;
	text-align: center;
	line-height: 40px;
	height: 40px;
	background-color: #fff;
	font-size: 16px;
	border-radius: 4px;
	color: #ddd;
	cursor: pointer;
}

.welcome-consent-check:focus {
	box-shadow: rgba(255, 255, 255, 0.5) 0 0 0 1px inset, rgb(255 255 255 / 50%) 0 2px 2px 0, rgba(0, 0, 0, .1) 0 4px 4px 0, rgb(255 255 255 / 50%) 0 0 0 4px;
}

.welcome-consent.is-active .welcome-consent-check {
	color: #27a258;
}

</style>
