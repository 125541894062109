<template>

<div class="shredded" onboarding="shredded" ref="wrapper">

	<iframe :src="embedURL" class="shredded-embed" v-if="is.loaded && !is.shredded"></iframe>

	<template v-if="is.shredded">

		<panZoom class="shredded-panzoom" v-if="is.loaded" v-on:init="onInitiated" :options="options">

			<div class="shredded-wrapper" :style="styleWrapper">

				<div class="shredded-page" v-for="(page, index) in pages" :key="index" :style="{width: page.size.x + 'px', height: page.size.y + 'px'}">

					<div class="shredded-piece" v-for="(part, index) in page.parts" :key="index" :style="{width: page.segment.x + 'px', height: page.segment.y + 'px', backgroundImage: 'url(' + part + ')'}"></div>

				</div>

			</div>

		</panZoom>

		<div class="is-zoom shredded-controls" v-if="showNote" onboarding="shredded.zoom">
			<div class="shredded-controls-button" v-tooltip="'Zoom in'" v-on:click="onZoomIn"><i class="fa fa-search-plus"></i></div>
			<div class="shredded-controls-button" v-tooltip="'Zoom out'" v-on:click="onZoomOut"><i class="fa fa-search-minus"></i></div>
		</div>

		<div class="is-scroll shredded-controls" v-if="showNote" onboarding="shredded.scroll">
			<div class="shredded-controls-button" v-tooltip="'Scroll up'" v-on:click="onScrollUp"><i class="fa fa-chevron-up"></i></div>
			<div class="shredded-controls-button" v-tooltip="'Scroll down'" v-on:click="onScrollDown"><i class="fa fa-chevron-down"></i></div>
		</div>

		<div class="shredded-note" v-if="is.helping && showNote" v-on:click="onHelpClick">
			Tip: you can drag the page to pan, use the arrow keys to scroll up or down, double click or use the mouse wheel to zoom. <i class="fa fa-remove"></i>
		</div>

	</template>

</div>

</template>

<script>

export default {

	props: ['showNote', 'shredded', 'embed', 'initialZoom'],

	data: function() {

		return {
			pages: [],
			is: {
				helping: true,
				loaded: false,
				shredded: false
			},
			panzoom: false,
			options: {
				initialZoom: 0.4,
				maxZoom: 1,
				minZoom: 0.4,
				smoothScroll: true,
				zoomSpeed: 0.065,
				bounds: true
			},
			size: {
				x: 0,
				y: 0
			}
		}

	},

	computed: {

		embedURL: function() {

			return this.embed

		},

		styleWrapper: function() {

			return {
				width: this.size.x.toString() + 'px',
				height: (this.size.y * 1.25).toString() + 'px'
			}

		}

	},

	watch: {

		showNote: function() {

			this.$_.delay(function() {

				if (this.panzoom) this.panzoom.smoothMoveTo((this.showNote) ? (this.$refs.wrapper.offsetWidth / 2) - (this.size.x * 0.2) : 10, (this.showNote) ? 25 : 10)

			}.bind(this), 600)

		}

	},

	created: function() {

		this.is.shredded = this.shredded

		this.options.initialZoom = this.initialZoom || 0.4

		if (this.is.shredded) {

			window.fetch(this.$url + this.shredded).then(function(response) {

				response.json().then(function(json) {

					this.onLoaded(json.data)
		
				}.bind(this))

			}.bind(this))

		}

	},

	methods: {

		onInitiated: function(el) {

			this.panzoom = el

			this.panzoom.moveTo((this.showNote) ? (this.$refs.wrapper.offsetWidth / 2) - (this.size.x * 0.2) : 10, (this.showNote) ? 25 : 10)

		},

		onLoaded: function(json) {

			if (this.is.shredded) {

				this.pages = json.pages
				this.size = json.size

			}

			this.is.loaded = true

		},

		onFailed: function() {

		},

		onHelpClick: function() {

			this.is.helping = false

		},

		onScrollUp: function() {

			this.panzoom.moveBy(0, 100, true)

		},

		onScrollDown: function() {

			this.panzoom.moveBy(0, -100, true)

		},

		onZoomIn: function() {

			this.options.initialZoom += 0.1
			this.options.initialZoom = (this.options.initialZoom > this.options.maxZoom) ? this.options.maxZoom : this.options.initialZoom
			this.panzoom.smoothZoomAbs(this.$refs.wrapper.offsetWidth / 2, this.$refs.wrapper.offsetHeight / 2, this.options.initialZoom)

		},

		onZoomOut: function() {

			this.options.initialZoom -= 0.1
			this.options.initialZoom = (this.options.initialZoom < this.options.minZoom) ? this.options.minZoom : this.options.initialZoom
			this.panzoom.smoothZoomAbs(this.$refs.wrapper.offsetWidth / 2, this.$refs.wrapper.offsetHeight / 2, this.options.initialZoom)

		}

	}

}

</script>

<style scoped>

.shredded {
	background-color: #ddd;
	flex-grow: 1;
	flex-basis: 0;
	overflow: hidden;
	height: 100%;
}

.shredded-embed {
	width: 100%;
	height: 100%;
}

.shredded-panzoom {
	width: 100%;
	height: 100%;
}

.shredded-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.shredded-page {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	background-color: #fff;
	margin-bottom: 25px;
}

.shredded-page:last-child {
	margin-bottom: 0px;
}

.shredded-piece {
	background-size: cover;
}

.shredded-note {
	position: absolute;
	left: 10px;
	bottom: 10px;
	z-index: 2;
	background-color: #287ABD;
	width: 240px;
	color: #fff;
	font-size: 14px;
	padding-right: 34px;
	line-height: 18px;
	font-weight: 300;
	padding: 10px;
	border-radius: 4px;
	cursor: pointer;
}

.shredded-note .fa {
	position: absolute;
	right: 3px;
	top: 4px;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	width: 24px;
}

.shredded-controls {
	position: absolute;
	right: 10px;
	z-index: 2;
	display: flex;
	flex-direction: column;
}

.shredded-controls.is-zoom {
	top: 10px;
}

.shredded-controls.is-scroll {
	bottom: 10px;
}

.shredded-controls-button {
	width: 40px;
	height: 40px;
	line-height: 40px;
	color: #fff;
	background-color: #287ABD;
	border-radius: 4px;
	font-size: 24px;
	text-align: center;
	cursor: pointer;
}

.shredded-controls-button:hover {
	background-color: #1b649e;
}

.shredded-controls-button:first-child {
	margin-bottom: 2px;
}

</style>
