<template>

<app-modal :active="is.active">

	<template v-slot:head>

		<h1>Re-assign user</h1>

	</template>

	<p>Move a user from their current activity, if any, to an active scheduled item. If it is a station they can be assigned to a specific role, replacing the default user.</p>

	<app-directory v-on:change="onDirectoryChange" />

	<select class="select" v-model="input.schedule" :disabled="is.loading">
		<option value="0">Select the scheduled item...</option>
		<option v-for="item in schedule" :key="item.identity" :value="item.identity">{{ item.name }}</option>
	</select>

	<select class="select" v-model="input.role" :disabled="is.loading">
		<option value="0">Select the role...</option>
		<option value="4">{{ this.$language.role.observer }}</option>
		<option value="2">{{ this.$language.role.roleplayer }}</option>
		<option value="3">{{ this.$language.role.examiner }}</option>
		<option value="5">{{ this.$language.role.layexaminer }}</option>
	</select>
	
	<template v-slot:buttons>

		<app-button :loading="is.loading" :disabled="!is.valid" v-on:click="onSubmitClick">Confirm</app-button>

		<app-button :disabled="is.loading" theme="white" v-on:click="onClose">Cancel</app-button>

	</template>

</app-modal>

</template>

<script>

import Modal from '@/mixin/modal.js'

export default {

	mixins: [Modal],

	data: function() {

		return {

			name: 'reassign',

			input: {
				schedule: 0,
				role: 0,
				recipient: false
			}

		}

	},

	computed: {

		schedule: function() {

			return this.$_.filter(this.$store.getters['overview'], function(item) {

				return item.start <= this.$store.getters['time'] && item.end >= this.$store.getters['time']

			}.bind(this))

		},

		isStation: function() {

			return false

		}

	},

	methods: {

		reset: function() {

			this.is.loading = false 
			this.input.recipient = false
			this.input.schedule = 0
			this.input.role = 0

		},

		onInputChange: function() {

			this.is.valid = this.input.recipient && this.input.schedule && (this.input.role || !this.isStation)

		},

		onDirectoryChange: function(recipient) {

			this.input.recipient = recipient

		},

		onSubmitClick: function() {

			this.$api.request('observer/reassign', {
				recipient: this.input.recipient,
				schedule: this.input.schedule,
				role: this.input.role
			}).then(function() {

				this.onClose()

			}.bind(this), function() {

				this.is.loading = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.textarea {
	border: 1px solid #ddd;
	border-radius: 4px;
	font-size: 14px;
	line-height: 20px;
	padding: 3px 10px;
	text-align: left;
	background-color: #fff;
	margin-bottom: 20px;
	width: 100%;
	height: 66px;
	resize: none;
}

.select {
	border: 1px solid #ddd;
	border-radius: 4px;
	font-size: 14px;
	line-height: 40px;
	padding: 3px 10px;
	text-align: left;
	background-color: #fff;
	margin-bottom: 20px;
	width: 100%;
}

p {
	font-size: 14px;
	line-height: 20px;
	font-weight: 300;
	margin-bottom: 10px;
}

</style>
