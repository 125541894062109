<template>

<div class="card" :class="{[classIndex]: true, [classType]: true, [classWidth]: true, 'is-plain': plain, 'is-miniview': miniview, 'is-expanded': expanded, 'allow-fullscreen': allowFullsceen, 'is-pinned': pin.active, 'is-focussed': focus}">

	<div class="card-body">

		<slot></slot>

	</div>

	<div class="card-foot" v-if="(title || lead || subtitle || role) && !plain && !miniview">

		<div class="card-foot-text">

			<div class="card-foot-text-title" v-if="title">{{ title }}</div>

			<div class="card-foot-text-more" v-if="!hideMore && (lead || subtitle || role || circuit) && !miniview">

				<div class="card-foot-text-more-lead" v-if="lead">Lead</div>
				<div class="card-foot-text-more-text" v-if="subtitle">{{ subtitle }}</div>
				<div class="card-foot-text-more-text" v-if="role">{{ role }}</div>
				<div class="card-foot-text-more-text" v-if="circuit">{{ circuit }}</div>

			</div>

		</div>

		<div class="card-foot-actions" :onboarding="onboardingPrefix + '.actions'" v-if="hasActions">

			<button :onboarding="onboardingPrefix + '.expand'" class="card-foot-actions-item" v-if="!pin.active && !expanded && expandable" v-on:click="onExpandClick" v-tooltip="'Click to enlarge'"><i class="fa fa-expand-alt"></i></button>
			<button :onboarding="onboardingPrefix + '.collapse'" class="card-foot-actions-item" v-if="(pin.active || expanded) && expandable" v-on:click="onExpandClick" v-tooltip="'Click to shrink'"><i class="fa fa-compress-alt"></i></button>
			<button :onboarding="onboardingPrefix + '.close'" class="card-foot-actions-item" v-if="closable" v-tooltip="'Click to close'"><i class="fa fa-remove"></i></button>

			<slot name="actions"></slot>

		</div>

	</div>

</div>

</template>

<script>

export default {

	props: ['title', 'miniview', 'onboardingPrefix', 'closable', 'subtitle', 'type', 'role', 'focus', 'circuit', 'lead', 'plain', 'hidePin', 'hideMore', 'pinSettings', 'expandable', 'expanded', 'allowFullsceen'],

	data: function() {

		return {
            index: 0,
			width: false,
			is: {
				expanded: false
			},
			pin: {
				enabled: false,
				active: false,
				loading: false,
				editable: false,
				hidden: false
			}
		}

	},

	watch: {

		pinSettings: function(n) {

			this.pin = (n) ? n : this.pin

		}

	},

	computed: {

		showPin: function() {

			return this.pin.enabled && (this.pin.editable || this.pin.active) && !this.pin.hidden && !this.hidePin

		},

		hasActions: function() {

			return (this.pin.enabled && !this.pin.hidden) || this.expandable

        },
        
        classType: function() {

            var type = 'type-'

            if (this.type === this.$constants.card.type.participant) type += 'participant'
            if (this.type === this.$constants.card.type.file) type += 'file'
            if (this.type === this.$constants.card.type.notepad) type += 'notepad'

            return type

        },

		classIndex: function() {

			return 'index-' + this.index.toString()

		},

		classWidth: function() {

			return 'width-' + ((this.width && this.type !== this.$constants.card.type.participant) ? this.width.toString() : 'auto')

		},

		tipPin: function() {

			if (this.pin.editable) {

				return (this.pin.active) ? 'Unpin user' : 'Pin user'

			} else {

				return (this.pin.active) ? 'Pinned user' : false

			}

		}

	},

	created: function() {

		this.is.expanded = this.expanded

	},

	beforeDestroy: function() {

		if (this.is.expanded) this.$emit('collapse')

	},

	methods: {

		onExpandClick: function() {

			if (this.expandable) {

				this.is.expanded = !this.is.expanded

				this.$emit('expand')

			}

		},

		onPinClick: function() {

			if (!this.pin.loading && this.pin.editable) {

				this.$emit('pin')

			}

		}

	}

}

</script>

<style scoped>

.card {
	position: absolute;
	box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
	border: 1px solid #ddd;
	border-radius: 4px;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	transition: all 600ms ease-in-out;
	user-select: none;
}

.card.is-miniview {
	right: 20px!important;
	top: 10px!important;
	left: auto!important;
	z-index: 99!important;
	width: 128px!important;
	height: 128px!important;
	border-radius: 64px!important;
	opacity: 0!important;
}

.card.is-miniview.card.is-focussed {
	z-index: 101!important;
	opacity: 1!important;
}

.card.allow-fullscreen.is-expanded {
	left: 0px!important;
	top: 0px!important;
	z-index: 100!important;
	width: calc(100% - 10px)!important;
	height: calc(100% - 10px)!important;
}

.card.is-plain {
	transition: none;
	border-radius: 0px;
	border: 0px;
	box-shadow: none;
}

.card.is-focussed {
	box-shadow: 0px 0px 10px #287ABD;
}

.card-body {
	flex-grow: 1;
	overflow: hidden;
}

.card-foot {
	flex-shrink: 0;
	background-color: #fff;
	border-top: 1px solid #ddd;
	display: flex;
	flex-direction: row;
	min-height: 48px;
}

.card-foot-text {
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex-grow: 1;
	flex-basis: 0;
	padding: 5px;
	min-width: 0;
}

.card-foot-text-title {
	font-size: 16px;
	height: 16px;
	overflow: hidden;
	width: 100%;
	white-space: no-wrap;
	text-overflow: ellipsis;
	font-weight: 400;
	color: #333;
}

.card-foot-text-more {
	display: flex;
	flex-direction: row;
	height: 14px;
	margin-top: 2px;
}

.card-foot-text-more-lead {
	font-size: 10px;
	color: #fff;
	letter-spacing: 1px;
	padding: 2px 4px;
	font-weight: 500;
	border-radius: 4px;
	margin-right: 4px;
	background-color: #287ABD;
}

.card-foot-text-more-text {
	font-size: 12px;
	color: #333;
	font-weight: 300;
	line-height: 14px;
}

.card-foot-text-more-text:after {
	content: '/';
	padding: 0px 4px;
	color: #999;
}

.card-foot-text-more-text:last-child:after {
	display: none;
}

.card-foot-actions {
	display: flex;
	flex-direction: row;
	flex-shrink: 0;
}

.card-foot-actions-item {
	width: 48px;
	text-align: center;
	border-left: 1px solid #ddd;
	font-size: 24px;
	color: #999;
}

.card-foot-actions-item.is-disabled {
	cursor: not-allowed;
	color: #ddd;
	pointer-events: none;
}

.card-foot-actions-item .fa {
	font-size: 24px;
}

.card-foot-actions-item.is-loading {
	background-image: url('data:image/gif;base64,R0lGODlhEAALAPQAAP///yN9xt7r9tbm8+zz+SiAxyN9xkqU0JO/4nWt28Pb70COzWKi1pnD5Hmv3Mbd70OQziZ/xmak1+nx+N3q9fX4+1OZ0uDs9vP3+7/Z7qzO6dHj8vD1+gAAAAAAAAAAACH+GkNyZWF0ZWQgd2l0aCBhamF4bG9hZC5pbmZvACH5BAALAAAAIf8LTkVUU0NBUEUyLjADAQAAACwAAAAAEAALAAAFLSAgjmRpnqSgCuLKAq5AEIM4zDVw03ve27ifDgfkEYe04kDIDC5zrtYKRa2WQgAh+QQACwABACwAAAAAEAALAAAFJGBhGAVgnqhpHIeRvsDawqns0qeN5+y967tYLyicBYE7EYkYAgAh+QQACwACACwAAAAAEAALAAAFNiAgjothLOOIJAkiGgxjpGKiKMkbz7SN6zIawJcDwIK9W/HISxGBzdHTuBNOmcJVCyoUlk7CEAAh+QQACwADACwAAAAAEAALAAAFNSAgjqQIRRFUAo3jNGIkSdHqPI8Tz3V55zuaDacDyIQ+YrBH+hWPzJFzOQQaeavWi7oqnVIhACH5BAALAAQALAAAAAAQAAsAAAUyICCOZGme1rJY5kRRk7hI0mJSVUXJtF3iOl7tltsBZsNfUegjAY3I5sgFY55KqdX1GgIAIfkEAAsABQAsAAAAABAACwAABTcgII5kaZ4kcV2EqLJipmnZhWGXaOOitm2aXQ4g7P2Ct2ER4AMul00kj5g0Al8tADY2y6C+4FIIACH5BAALAAYALAAAAAAQAAsAAAUvICCOZGme5ERRk6iy7qpyHCVStA3gNa/7txxwlwv2isSacYUc+l4tADQGQ1mvpBAAIfkEAAsABwAsAAAAABAACwAABS8gII5kaZ7kRFGTqLLuqnIcJVK0DeA1r/u3HHCXC/aKxJpxhRz6Xi0ANAZDWa+kEAA7AAAAAAAAAAAA');
	background-position: 50% 50%;
	background-repeat: no-repeat;
	pointer-events: none;
}

.card-foot-actions-item.is-loading .fa {
	display: none;
}

.card-foot-actions-item:hover {
	color: #333;
}

.card-foot-actions-item.is-active,
.card-foot-actions-item.is-active:hover {
	color: #287ABD;
}

.card-foot-actions-item-counter {
	position: absolute;
	height: 16px;
	border-radius: 4px;
	background-color: red;
	color: #fff;
	min-width: 16px;
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	padding: 0px 4px;
	right: -4px;
	top: -4px;
	z-index: 2;
}

</style>