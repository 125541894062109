<template>

<div class="foot" :class="{'is-paused': isPaused}">

	<com-actions class="foot-actions" />

	<div class="foot-text" v-if="isPaused && pauseEnd">
		<b>event resuming in {{ resuming }}</b>
	</div>

	<div class="foot-text" v-if="isPaused && !pauseEnd">
		<b>event paused</b>
	</div>

	<div class="foot-text" v-if="!isPaused">
		designed by <b>Professional Role Players</b>
	</div>

	<div class="foot-clock" v-if="!isPaused">{{ time | formatDate('HH:mm') }}</div>
	<div class="foot-clock" v-if="isPaused"><i class="fa fa-pause"></i></div>

</div>

</template>

<script>

import comActions from './foot/Actions'

export default {

	components: {
		'com-actions': comActions
	},

	computed: {

		pauseEnd: function() {

			return this.$store.getters['event/paused/end']

		},

		resuming: function() {

			return (this.pauseEnd) ? this.countdown(this.pauseEnd, false, true) : false

		}

	}

}

</script>

<style scoped>

.foot {
	position: fixed;
	left: 0px;
	width: 100%;
	bottom: 0px;
	background-color: #FBB516;
	height: 54px;
	z-index: 100;
	display: flex;
	flex-direction: row;
	transition: background-color 100ms linear;
}

.foot.is-paused {
	background-color: #c62323;
}

.foot-clock {
	height: 54px;
	line-height: 54px;
	font-weight: 500;
	font-size: 14px;
	padding: 0px 10px;
	margin-left: 20px;
	border-left: 1px solid rgba(255, 255, 255, 0.25);
	width: 80px;
	text-align: center;
}

.foot.is-paused .foot-clock {
	color: #fff;
}

.foot-actions {
	margin-top: 0px!important;
	flex-grow: 1;
	padding: 5px 10px;
}

.foot-text {
	line-height: 54px;
	font-size: 14px;
	font-weight: 300;
	color: #343434;
}

.foot.is-paused .foot-text {
	color: #fff;
}

.foot-text b {
	font-weight: 500;
	color: #000;
}

.foot.is-paused .foot-text b {
	color: #fff;
}

</style>
